import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"
import { useQuery } from "@apollo/client"
import { Navigate, Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { P } from "@spillchat/puddles"
import {
  CalendarDaysIcon,
  HeartIcon,
  LightBulbIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import {
  AdminTherapyIndexGetUsageQuery,
  AdminTherapyIndexGetUsageQueryVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { UserInvitesList } from "features/admin/components/PeopleList/UserInvitesList"
import { UserInvitesEmpty } from "features/admin/components/PeopleList/UserInvitesEmpty"
import { useAdminOnboarding } from "common/hooks/useAdminOnboarding"
import { queries } from "features/admin-therapy/admin-therapy.queries"
import { AdminTherapySpecialisedSupport } from "features/admin-therapy-specialised-support/admin-therapy-specialised-support.page"
import { AdminTherapyNeeds } from "features/admin-therapy-needs/admin-therapy-needs.page"
import { AdminTherapyActivity } from "features/admin-therapy-activity/admin-therapy-activity.page"
import { AdminTherapyNavigation } from "features/admin-therapy/components/AdminTherapyNavigation"
import { AdminTherapySpecialisedSupportEdit } from "features/admin-therapy-specialised-support-edit/admin-therapy-specialised-support-edit.page"
import { AdminTherapySpecialisedSupportEnrol } from "features/admin-therapy-specialised-support-enrol/admin-therapy-specialised-support-enrol.page"
import { AdminTherapySpecialisedSupportGuide } from "features/admin-therapy-specialised-support-guide/admin-therapy-specialised-support-guide.page"
import { AdminTherapySpecialisedSupportReview } from "features/admin-therapy-specialised-support-review/admin-therapy-specialised-support-review.page"
import { AdminTherapySpecialisedSupportCreateAdhd } from "features/admin-therapy-specialised-support-create/adhd/admin-therapy-specialised-support-create-adhd.page"

import { AccountSettings } from "./Settings"

interface TherapyContextData {
  /**
   * We need to know how many sessions a company has in a few places.
   * So if we calculate it anywhere, we update the context value so we
   * can access it anywhere.
   */
  currentSessionCoverage: number
  setCurrentSessionCoverage: Dispatch<SetStateAction<number>>
  /**
   * Allows us to set the estimated monthly cost of the company.
   * We set this to default as a string, as we need to format it.
   */
  estimatedMonthlyCost: string
  setEstimatedMonthlyCost: Dispatch<SetStateAction<string>>
  /**
   * Allows us to set the individual unit price of a session.
   * We set this to default as a string, as we need to format it.
   */
  individualUnitPrice: string
  setIndividualUnitPrice: Dispatch<SetStateAction<string>>
}

const TherapyContext = createContext<TherapyContextData>({
  currentSessionCoverage: 0,
  estimatedMonthlyCost: "",
  individualUnitPrice: "",
  setCurrentSessionCoverage: () => {},
  setEstimatedMonthlyCost: () => {},
  setIndividualUnitPrice: () => {},
})

export const useTherapyContext = (): TherapyContextData =>
  useContext(TherapyContext)

export const Therapy: FunctionComponent = () => {
  const [currentSessionCoverage, setCurrentSessionCoverage] = useState(0)
  const [estimatedMonthlyCost, setEstimatedMonthlyCost] = useState("")
  const [individualUnitPrice, setIndividualUnitPrice] = useState("")

  const { data, loading } = useQuery<
    AdminTherapyIndexGetUsageQuery,
    AdminTherapyIndexGetUsageQueryVariables
  >(queries.getUsage)

  const subscriptionPlan = data?.user?.company?.subscriptionPlan
  const { helpers } = useAdminOnboarding({ subscriptionPlan })

  const canPreApproveTherapy =
    data?.user?.company?.featuresAndSettings.preApproveTherapy.value ?? false

  const hasLifeEvents =
    data?.user?.company?.featuresAndSettings?.therapyPackage?.active === true
  const legacyPlans = [SpillSubscriptionPlan.PROPER, SpillSubscriptionPlan.LITE]

  const checkInviteAndIntegration = () => {
    if (subscriptionPlan == null) {
      return false
    }

    if (legacyPlans.includes(subscriptionPlan)) {
      return true
    }

    return (
      helpers?.hasInvitedUsers === true || helpers?.hasAddedIntegration === true
    )
  }

  const hasInvitedOrIntegrated = checkInviteAndIntegration()

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const tabLinks = [
    {
      label: "Support",
      path: "support",
      visible: canPreApproveTherapy === true,
    },
    { label: "Activity", path: "activity", visible: true },
    {
      label: "Specialised support",
      path: "specialised-support",
      visible: hasLifeEvents === true,
    },
    {
      label: "Budget",
      path: "needs",
      visible: true,
    },
    { label: "Settings", path: "settings", visible: true },
  ]

  const TabContainer = ({
    children,
    condition,
  }: {
    children: ReactNode
    condition: boolean
  }) => {
    return (
      <>
        {condition ? (
          <>{children}</>
        ) : (
          <>
            <div className="flex justify-center items-center py-12">
              {subscriptionPlan != null && (
                <UserInvitesEmpty {...{ subscriptionPlan }} />
              )}
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Helmet title="Therapy | Spill" />
      <TherapyContext.Provider
        value={{
          currentSessionCoverage,
          setCurrentSessionCoverage,
          estimatedMonthlyCost,
          setEstimatedMonthlyCost,
          individualUnitPrice,
          setIndividualUnitPrice,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={canPreApproveTherapy === true ? "support" : "activity"}
              />
            }
          />
          <Route
            path="/specialised-support"
            element={
              <>
                <Helmet title="Specialised support | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <TabContainer condition={hasInvitedOrIntegrated}>
                  <AdminTherapySpecialisedSupport />
                </TabContainer>
              </>
            }
          />
          <Route
            path="/specialised-support/adhd-support"
            element={
              <>
                <Helmet title="ADHD support | Specialised support | Spill" />
                <AdminTherapySpecialisedSupportGuide
                  content={{
                    title: "ADHD Support",
                    description: (
                      <>
                        <P>
                          With ADHD support, you can provide specialist help for
                          employees who experience ADHD.
                        </P>
                        <P>
                          This can be turned on for your whole team, and
                          employees can access it regardless of whether they
                          have received an official diagnosis or not. Employees
                          do not need to make themselves known to an admin in
                          order to access the support.
                        </P>
                      </>
                    ),
                    image: imageAdhd,
                    featureList: [
                      {
                        icon: <LightBulbIcon />,
                        text: "Sessions with an ADHD specialist",
                      },
                      {
                        icon: <HeartIcon />,
                        text: "A recommended 2 sessions per employee",
                      },
                      {
                        icon: <CalendarDaysIcon />,
                        text: "Sessions come out of employee's existing credits",
                      },
                    ],
                    link: "/admin/therapy/specialised-support/create/adhd",
                  }}
                />
              </>
            }
          />
          <Route
            path="/specialised-support/guide"
            element={
              <>
                <Helmet title="Specialised support guide | Spill" />
                <AdminTherapySpecialisedSupportGuide
                  content={{
                    title: "Custom Support",
                    description: (
                      <>
                        <P muted>
                          Custom support allows you to customise Spill to the
                          unique situations people on your team are in.
                        </P>
                        <P muted>
                          Support can be offered company-wide or to a group of
                          one or more individuals. Recent examples of custom
                          support include offering additional sessions to people
                          during menopause, giving support to an individual
                          caring for a sick parent and rolling out additional
                          company-wide support following redundancies. You can
                          make the support as specific as you need.
                        </P>
                      </>
                    ),
                    image:
                      "https://spill-public-assets.s3.eu-west-2.amazonaws.com/custom-support/thumbnails/generic-green.svg",
                    featureList: [
                      {
                        icon: <UsersIcon />,
                        text: "Specialists tailored to your needs",
                      },
                      {
                        icon: <HeartIcon />,
                        text: "Set the number of sessions",
                      },
                      {
                        icon: <CalendarDaysIcon />,
                        text: "Set the support duration and timing",
                      },
                    ],
                    link: "/admin/access/invite/custom",
                  }}
                />
              </>
            }
          />
          <Route
            path="/specialised-support/create/adhd"
            element={
              <>
                <Helmet title="Activate ADHD support | Spill" />
                <AdminTherapySpecialisedSupportCreateAdhd />
              </>
            }
          />
          <Route
            path="/specialised-support/:packageId"
            element={
              <>
                <Helmet title="Edit Specialised support | Spill" />
                <AdminTherapySpecialisedSupportEdit />
              </>
            }
          />
          <Route
            path="/specialised-support/:packageId/enrol"
            element={
              <>
                <Helmet title="Enrol employees | Spill" />
                <AdminTherapySpecialisedSupportEnrol />
              </>
            }
          />
          <Route
            path="/specialised-support/:packageId/request/:requestId"
            element={
              <>
                <Helmet title="Review request | Spill" />
                <AdminTherapySpecialisedSupportReview />
              </>
            }
          />
          <Route
            path="/support/*"
            element={
              <>
                <Helmet title="Support | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <TabContainer condition={hasInvitedOrIntegrated}>
                  <div className="flex flex-col sm:flex-row gap-9 mt-8">
                    <div className="overflow-y-auto w-full">
                      <UserInvitesList />
                    </div>
                  </div>
                </TabContainer>
              </>
            }
          />
          <Route
            path="/activity/*"
            element={
              <>
                <Helmet title="Activity | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <TabContainer condition={hasInvitedOrIntegrated}>
                  <AdminTherapyActivity />
                </TabContainer>
              </>
            }
          />
          <Route
            path="/needs/*"
            element={
              <>
                <Helmet title="Needs | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <AdminTherapyNeeds />
              </>
            }
          />
          <Route
            path="/settings/*"
            element={
              <>
                <Helmet title="Settings | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <AccountSettings />
              </>
            }
          />
        </Routes>
      </TherapyContext.Provider>
    </>
  )
}
