import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import { H2, H6, P } from "@spillchat/puddles"

import TeamsLogo from "common/assets/images/Microsoft-Teams-logo.svg?react"
import SlackLogo from "common/assets/images/Slack-logo.svg?react"

export const PlatformSelect: FunctionComponent = () => {
  return (
    <div className="flex flex-col gap-4">
      <H2>Create accounts for your team</H2>

      <div className="mb-4">
        <P>
          Adding Spill to your company workspace will give employees an easy way
          to book therapy in just a few clicks.
        </P>
        <P>
          Don’t worry, your employees won’t be contacted by Spill until later in
          the process.
        </P>
      </div>

      <div className="w-full text-center flex flex-col sm:flex-row justify-between rounded-lg gap-8">
        <Link
          to="/admin/access/install/slack"
          className="flex flex-col items-center w-full sm:w-1/2 bg-mono-white hover:bg-grey-50 border border-grey-200 rounded-lg transition-colors justify-center gap-4 h-60"
        >
          <div className="my-2 w-full flex flex-col items-center">
            <SlackLogo className="py-4 max-w-[48px]" />
            <P weight="medium">Add to Slack</P>
          </div>
        </Link>
        <div className="self-center">
          <H6>or</H6>
        </div>
        <Link
          to="/admin/access/install/teams"
          className="flex flex-col cursor-pointer items-center w-full sm:w-1/2 bg-mono-white hover:bg-grey-50 border border-grey-200 rounded-lg transition-colors justify-center gap-4 h-60"
        >
          <div className="my-2 w-full flex flex-col items-center">
            <TeamsLogo className="py-4 max-w-[48px]" />
            <P weight="medium">Add to Teams</P>
          </div>
        </Link>
      </div>
    </div>
  )
}
