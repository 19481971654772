import { Form, H3, P, TextArea } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

interface WhatBroughtYouToSpillProps {
  checkValid: () => void
}

export const WhatBroughtYouToSpill: FunctionComponent<
  WhatBroughtYouToSpillProps
> = ({ checkValid }) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  return (
    <div className="flex flex-col gap-5 fieldsContainer">
      <div className="flex flex-col gap-2">
        <H3>What brought you to Spill today?</H3>
        <P muted>Briefly describe why you’re booking this session</P>
      </div>

      <Form.Field
        control={form.control}
        name="preSession.notes.value"
        render={({ field }) => (
          <Form.Item className="w-full">
            <Form.Control>
              <TextArea
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                rows={5}
                className="h-auto min-h-20 max-h-64"
                onBlur={checkValid}
                placeholder='e.g. "I&apos;ve been feeling constantly anxious because" or "I would like to explore why I&apos;m feeling so drained"'
              />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="preSession.notes"
        render={() => (
          <Form.Item className="w-full">
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
