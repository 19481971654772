import { FunctionComponent } from "react"

interface TherapyBookingValidationContainerProps {
  registerFields: (fieldNames: string[], ref: HTMLElement | null) => void
  fieldNames: string[]
  children: React.ReactNode
}

export const TherapyBookingValidationContainer: FunctionComponent<
  TherapyBookingValidationContainerProps
> = ({ ...props }) => (
  <div ref={ref => props.registerFields(props.fieldNames, ref)}>
    {props.children}
  </div>
)
