import { Form, H3, Input, ToggleButton } from "@spillchat/puddles"
import { FunctionComponent, useMemo } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

interface RecentLossProps {
  checkValid: () => void
}

export const RecentLoss: FunctionComponent<RecentLossProps> = ({
  checkValid,
}) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const recentLoss = useMemo(() => {
    return form.getValues("preSession.recentLoss.value")
  }, [form.watch("preSession.recentLoss.value")])

  const updateRecentLoss = (status: string) => {
    if (status === recentLoss) {
      form.setValue("preSession.recentLoss.value", null)
      checkValid()
      return
    }
    form.setValue("preSession.recentLoss.value", status)
    checkValid()
  }

  return (
    <div className="flex flex-col gap-3 fieldsContainer">
      <div className="flex flex-col gap-2">
        <H3>Have you experienced a recent loss?</H3>
      </div>
      <Form.Field
        control={form.control}
        name="preSession.recentLoss"
        render={({ field }) => (
          <Form.Item className="w-full">
            <Form.Control>
              <Input {...field} value={field.value.value ?? ""} type="hidden" />
            </Form.Control>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              {["Yes", "No"].map(status => {
                return (
                  <div key={status} className="col-span-1">
                    <ToggleButton
                      widthClass="w-full"
                      toggled={recentLoss === status}
                      onClick={() => updateRecentLoss(status)}
                    >
                      {status}
                    </ToggleButton>
                  </div>
                )
              })}
            </div>
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
