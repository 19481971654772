import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Button } from "@spillchat/puddles"

import { MessageButtonStyle } from "types/graphql"
import { Markdown } from "common/components/Markdown"
import { useAnalytics } from "common/context/analyticsContext"

const FEATURE_TITLE_MAP: Record<string, string> = {
  ask_a_therapist: "Ask a Therapist",
}

const BUTTON_VARIANT_MAP: Record<
  MessageButtonStyle,
  "destructive" | "secondary" | "primary"
> = {
  DANGER: "destructive",
  DEFAULT: "secondary",
  PRIMARY: "primary",
}

interface Message {
  id: string
  content: string
  feature: string
  buttons: Array<{
    style: MessageButtonStyle
    text: string
    url?: string | null
    actionId?: string | null
  }>
}

export function MessagePage(props: { message: Message }): JSX.Element {
  const { track } = useAnalytics()

  const { id, buttons, content = "", feature = "" } = props.message ?? {}

  useEffect(() => {
    if (id === undefined || feature === "") return

    track("User Read Message on Web", { Feature: feature })
  }, [id, feature])

  const trackButton = (url: string, actionId?: string | null) => {
    track("User Clicked Message Button", { URL: url, Action: actionId })
  }

  return (
    <div className="flex flex-col items-center grow p-6">
      <div className="flex flex-col gap-md max-w-xl w-full">
        <h2 className="mt-4">{FEATURE_TITLE_MAP[feature]}</h2>

        <div className="flex flex-col gap-sm">
          <Markdown
            options={{
              overrides: { h1: ({ children }) => <h4>{children}</h4> },
            }}
          >
            {content}
          </Markdown>
        </div>

        <div className="flex flex-col sm:flex-row flex-wrap gap-4">
          {buttons.map(({ style, text, url, actionId }) => {
            if (url == null) return null

            const urlObject = new URL(url)

            if (urlObject.origin === window.location.origin) {
              return (
                <Button asChild key={url} variant={BUTTON_VARIANT_MAP[style]}>
                  <Link
                    to={`${urlObject.pathname}${urlObject.search}${urlObject.hash}`}
                    onClick={() => trackButton(url, actionId)}
                  >
                    {text}
                  </Link>
                </Button>
              )
            }

            return (
              <Button asChild key={url} variant={BUTTON_VARIANT_MAP[style]}>
                <a
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={() => trackButton(url, actionId)}
                >
                  {text}
                </a>
              </Button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
