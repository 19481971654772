import {
  Button,
  P,
  H4,
  H3,
  HeaderCard,
  Modal,
  ButtonProps,
} from "@spillchat/puddles"
import { addMonths, format, startOfMonth } from "date-fns"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { gql, useMutation } from "@apollo/client"
import { loadStripe } from "@stripe/stripe-js"
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js"
import { toast } from "sonner"
import {
  CalendarIcon,
  CheckIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import {
  PlanSettingsCreateBillingPortalMutation,
  PlanSettingsCreateBillingPortalMutationVariables,
  PlanSettingsCreateCheckoutSessionMutation,
  PlanSettingsCreateCheckoutSessionMutationVariables,
  SpillSubscriptionPlan,
  SpillSubscriptionStatus,
} from "types/graphql"
import { UpgradePlanModal } from "features/admin/components/upgrade-plan/UpgradePlanModal"
import { config } from "config"
import { formatCurrency } from "common/helpers/formatNumber"

/**
 * Displays details about a companys plan and allows them to find out ways that they
 * can change their plan. uses the HeaderCard component to display the details.
 */

interface PlanSettingsProps {
  billableUserCount: number
  subscriptionPlan?: SpillSubscriptionPlan | null
  subscriptionStatus?: SpillSubscriptionStatus | null
  numberInSessionPack?: number | null
  hasTrial: boolean
  usesStripe: boolean
  upcomingSessionPackSize?: number | null
  planPrice?: {
    perSession: number
    perPackUpcoming?: number | null
    perPackCurrent?: number | null
    currency: string
  } | null
}

const mutations = {
  createCheckoutSession: gql`
    mutation PlanSettingsCreateCheckoutSession {
      createCheckoutSession
    }
  `,
  createBillingPortal: gql`
    mutation PlanSettingsCreateBillingPortal($isInvoice: Boolean!) {
      createBillingPortal(isInvoice: $isInvoice)
    }
  `,
}

export const PlanSettings: FunctionComponent<PlanSettingsProps> = props => {
  const navigate = useNavigate()
  const stripePromise = useMemo(
    async () => await loadStripe(config.stripe.key),
    []
  )

  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false)

  const [planChangeModal, setPlanChangeModal] = useState(false)
  const [
    createCheckoutSession,
    { loading: retrievingCheckoutSession, data: paymentSecretData },
  ] = useMutation<
    PlanSettingsCreateCheckoutSessionMutation,
    PlanSettingsCreateCheckoutSessionMutationVariables
  >(mutations.createCheckoutSession)

  const [createBillingPortal, { data: billingPortal }] = useMutation<
    PlanSettingsCreateBillingPortalMutation,
    PlanSettingsCreateBillingPortalMutationVariables
  >(mutations.createBillingPortal)

  const {
    billableUserCount,
    subscriptionPlan,
    subscriptionStatus,
    numberInSessionPack,
    upcomingSessionPackSize,
    planPrice,
  } = props

  const isActive = subscriptionStatus === SpillSubscriptionStatus.ACTIVE
  const isStarterPlan = subscriptionPlan === SpillSubscriptionPlan.STARTER
  const isEssentialPlan = subscriptionPlan === SpillSubscriptionPlan.ESSENTIAL

  const currentPlan =
    subscriptionPlan === SpillSubscriptionPlan.TEAM
      ? "Team Plan"
      : subscriptionPlan === SpillSubscriptionPlan.ESSENTIAL
        ? "Essential Plan"
        : "Starter Plan"

  const handleAddCard = async () => {
    try {
      await createCheckoutSession()
      setIsBillingModalVisible(true)
    } catch {
      toast.error(
        "Something went wrong. Please contact Spill support at support@spill.chat."
      )
    }
  }

  const handleChangeDetails = async () => {
    await createBillingPortal({ variables: { isInvoice: false } })
  }

  const handleGetInvoices = async () => {
    await createBillingPortal({ variables: { isInvoice: true } })
  }

  useEffect(() => {
    if (
      billingPortal?.createBillingPortal != null &&
      billingPortal.createBillingPortal != ""
    ) {
      location.assign(billingPortal.createBillingPortal)
    }
  }, [billingPortal?.createBillingPortal])

  const options = { clientSecret: paymentSecretData?.createCheckoutSession }

  const handlePlanAction = (): Omit<ButtonProps, "variant"> | undefined => {
    if (isStarterPlan) {
      return {
        children: "Change plan",
        size: "sm",
        onClick: () => setPlanChangeModal(true),
      }
    }

    return {
      children: "Change plan",
      size: "sm",
      onClick: () => navigate("/admin/help?topic=billing"),
    }
  }

  const handlePlanSecondaryAction = ():
    | Omit<ButtonProps, "variant">
    | undefined => {
    if (props.hasTrial || props.numberInSessionPack == null) {
      return {}
    }

    return {
      children: "Change session pack",
      size: "sm",
      onClick: () => navigate("/admin/session-pack"),
    }
  }

  return (
    <div className="flex flex-col gap-7">
      {paymentSecretData && (
        <Modal.Root
          open={isBillingModalVisible}
          onOpenChange={setIsBillingModalVisible}
        >
          <Modal.Content
            hideDefaultClose={true}
            className="max-w-screen-lg lg:h-[calc(100vh-theme(space.12))] overflow-scroll bg-[#041549] lg:p-4 h-full border-0 !rounded-lg overscroll-none"
            onInteractOutside={e => {
              e.preventDefault()
              setIsBillingModalVisible(false)
            }}
          >
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Modal.Content>
        </Modal.Root>
      )}

      {!isActive && isStarterPlan && (
        <>
          <H4 sectionHeader>Billing Details</H4>

          <H3>Current Plan: {currentPlan}</H3>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="flex flex-col gap-4">
              <P weight="medium">All the benefits of Starter Plan</P>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <CheckIcon className="size-4" />
                  <P>Course of therapy</P>
                </div>
                <div className="flex gap-2 items-center">
                  <CheckIcon className="size-4" />
                  <P>Access to next-day session slots</P>
                </div>
                <div className="flex gap-2 items-center">
                  <CheckIcon className="size-4" />
                  <P>Only pay for sessions if they are used</P>
                </div>
                <div className="flex gap-2 items-center">
                  <CheckIcon className="size-4" />
                  <P>Give individual employees access</P>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <P weight="medium">Monthly access fee</P>
              <P>£0 per month</P>
            </div>
            <div className="flex flex-col gap-4">
              <P weight="medium">Cost of therapy</P>
              <P>
                {formatCurrency(
                  planPrice?.perSession ?? 90,
                  planPrice?.currency
                )}{" "}
                per 50 minute counselling&nbsp;session
              </P>
            </div>
          </div>

          {!isActive ? (
            <Button
              loading={retrievingCheckoutSession}
              onClick={async () => await handleAddCard()}
            >
              Set up card or direct debit details
            </Button>
          ) : (
            <Button onClick={() => setPlanChangeModal(true)}>
              Change plan
            </Button>
          )}
        </>
      )}

      <H4 sectionHeader>Plan Settings</H4>

      <div className="grid grid-cols-1 md:grid-cols-6 gap-8 items-start">
        <div className="col-span-2">
          <HeaderCard
            title="Employees covered"
            icon={<UsersIcon width={24} />}
            primaryDetail={billableUserCount.toString()}
            primaryAction={{
              children: "Manage access",
              size: "sm",
              onClick: () => navigate("/admin/access"),
            }}
          />
        </div>
        <div className="col-span-2">
          {subscriptionStatus === SpillSubscriptionStatus.ACTIVE ? (
            <HeaderCard
              title="Next invoice"
              icon={<CalendarIcon width={24} />}
              primaryDetail={format(
                startOfMonth(addMonths(new Date(), 1)),
                "d MMM yyyy"
              )}
              primaryAction={{
                children: "Update billing details",
                size: "sm",
                onClick: props.usesStripe
                  ? handleChangeDetails
                  : () => navigate("/admin/help?topic=billing"),
              }}
            />
          ) : (
            <HeaderCard
              title="Payment method"
              icon={<CreditCardIcon width={24} />}
              primaryDetail="None"
              primaryAction={{
                children: "Add payment method",
                size: "sm",
                onClick: async () => await handleAddCard(),
                loading: retrievingCheckoutSession,
              }}
            />
          )}
        </div>
        <div className="col-span-2">
          <HeaderCard
            title="Plan"
            icon={<CreditCardIcon width={24} />}
            primaryDetail={currentPlan}
            primaryAction={handlePlanAction()}
            secondaryAction={handlePlanSecondaryAction()}
          />
        </div>
      </div>

      {isEssentialPlan && (
        <>
          <H4 sectionHeader>Plan Details</H4>

          <H3>Current Plan: {currentPlan}</H3>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="flex flex-col gap-4">
              <P weight="medium">All the benefits of {currentPlan}</P>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <CheckIcon className="size-4" />
                  <P>Access to next-day session slots</P>
                </div>
                <div className="flex gap-2 items-center">
                  <CheckIcon className="size-4" />
                  <P>Advice library for employees</P>
                </div>
                <div className="flex gap-2 items-center">
                  <CheckIcon className="size-4" />
                  <P>Slack & Teams integrations</P>
                </div>
              </div>
            </div>
            {numberInSessionPack != null && (
              <div className="flex flex-col gap-8">
                {!props.hasTrial && (
                  <div className="flex flex-col gap-4">
                    <P weight="medium">Current monthly subscription</P>
                    <P>
                      {formatCurrency(
                        planPrice?.perPackCurrent ?? 0,
                        planPrice?.currency
                      )}{" "}
                      per month ({numberInSessionPack}{" "}
                      {numberInSessionPack !== 1 ? "sessions" : "session"})
                    </P>
                  </div>
                )}
                {upcomingSessionPackSize != null && (
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-1">
                      <ExclamationCircleIcon className="size-4 text-teal-600" />
                      <P weight="medium">Upcoming monthly subscription</P>
                    </div>
                    <P>
                      {formatCurrency(
                        planPrice?.perPackUpcoming ?? 0,
                        planPrice?.currency
                      )}{" "}
                      per month ({upcomingSessionPackSize}{" "}
                      {upcomingSessionPackSize !== 1 ? "sessions" : "session"})
                    </P>
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col gap-4">
              <P weight="medium">Cost of therapy</P>
              <P>
                {formatCurrency(
                  planPrice?.perSession ?? 90,
                  planPrice?.currency
                )}{" "}
                per additional 50 minute counselling&nbsp;session
              </P>
            </div>
          </div>

          {!isActive && (
            <Button loading={retrievingCheckoutSession} onClick={handleAddCard}>
              Set up card or direct debit details
            </Button>
          )}
        </>
      )}
      {isActive && props.usesStripe && (
        <>
          <H4>Past invoices</H4>
          <Button size="sm" onClick={handleGetInvoices}>
            Get past invoices
          </Button>
          <P>
            If your invoice isn't listed,{" "}
            <Link to="/admin/help?topic=billing">
              <Button variant="tertiary" size="sm">
                get in touch
              </Button>
            </Link>
          </P>
        </>
      )}

      <Modal.Root open={planChangeModal} onOpenChange={setPlanChangeModal}>
        <Modal.Content
          className="md:min-w-[876px]"
          onInteractOutside={e => {
            e.preventDefault()
            setPlanChangeModal(false)
          }}
        >
          <UpgradePlanModal onClose={() => setPlanChangeModal(false)} />
        </Modal.Content>
      </Modal.Root>
    </div>
  )
}
