import { Button, H3, P } from "@spillchat/puddles"
import { FunctionComponent, ReactNode, useEffect, useState } from "react"

import { TherapyBookingValidationContainer } from "./therapy-booking-validation-container"

interface TherapyBookingViewEditFieldProps {
  readOnlyValue: string | ReactNode | undefined
  children: React.ReactNode
  title: string
  description: string
  /**
   * If any of these are empty, the field will be editable
   */
  validationFieldValues: (string | undefined)[]
  validationFieldNames: string[]
  registerFields: (fieldNames: string[], ref: HTMLElement | null) => void
}

export const TherapyBookingViewEditField: FunctionComponent<
  TherapyBookingViewEditFieldProps
> = ({ ...props }) => {
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (
      props.validationFieldValues.some(
        field => field?.trim() === "" || field === undefined
      )
    ) {
      setIsEditing(true)
      return
    }
  }, [])

  return (
    <TherapyBookingValidationContainer
      fieldNames={props.validationFieldNames}
      registerFields={props.registerFields}
    >
      <div className="flex gap-4 flex-col scroll-mt-16">
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            <H3>{props.title}</H3>
            {isEditing && <P muted>{props.description}</P>}
          </div>
          {!isEditing && (
            <Button variant="tertiary" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          )}
        </div>

        <div>
          {isEditing ? (
            <>{props.children}</>
          ) : typeof props.readOnlyValue === "string" ? (
            <P muted>{props.readOnlyValue}</P>
          ) : (
            props.readOnlyValue
          )}
        </div>
      </div>
    </TherapyBookingValidationContainer>
  )
}
