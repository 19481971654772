import { gql, useMutation } from "@apollo/client"
import { EnvelopeIcon } from "@heroicons/react/24/outline"
import { Button, H3, Input, P, Select } from "@spillchat/puddles"
import React, { FunctionComponent, useState } from "react"
import { toast } from "sonner"
import { Link } from "react-router-dom"

import { useAnalytics } from "common/context/analyticsContext"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"
import {
  UpdateEmailPlatformAccessMethodMutation,
  UpdateEmailPlatformAccessMethodMutationVariables,
} from "types/graphql"

import { CompanyPlatform } from "../access.page"

interface EmailAccessProps {
  companyId: string
  emailPlatform: CompanyPlatform
}

const mutation = gql(`
  mutation UpdateEmailPlatformAccessMethod(
    $companyPlatformId: ID!,
    $allowAccessAll: Boolean!,
    $emailDomain: String!
  ) {
    updateAccessMethod(
      companyPlatformId: $companyPlatformId,
      allowAccessAll: $allowAccessAll,
      externalId: $emailDomain
    ) {
      allowAccessAll
      externalId
    }
  }
`)

export const EmailAccess: FunctionComponent<EmailAccessProps> = ({
  companyId,
  emailPlatform,
}) => {
  const { flags } = useUser()
  const { track } = useAnalytics()

  const emailDomain = emailPlatform.externalId

  const [editing, setEditing] = useState<boolean>(false)
  const [allowAccessAllFormValue, setAllowAccessAllFormValue] =
    useState<string>(`${emailPlatform.allowAccessAll}`)
  const [emailDomainFormValue, setEmailDomainFormValue] = useState<string>(
    companyId == emailDomain ? "" : emailDomain
  )

  const [saveAccessUpdate] = useMutation<
    UpdateEmailPlatformAccessMethodMutation,
    UpdateEmailPlatformAccessMethodMutationVariables
  >(mutation)

  if (!flags[FEATURE_FLAGS.DOMAIN_ACCESS]) {
    return <></>
  }

  const toggleEdit = () => {
    setEditing(!editing)
  }

  const saveEmailAccess = async (_e: React.MouseEvent) => {
    const emailDomainSubmission = emailDomainFormValue.startsWith("@")
      ? emailDomainFormValue.slice(1)
      : emailDomainFormValue

    await saveAccessUpdate({
      variables: {
        companyPlatformId: emailPlatform.id,
        allowAccessAll: allowAccessAllFormValue == "true",
        emailDomain: emailDomainSubmission,
      },
    })

    track("Admin updates email domain access", {
      companyId: companyId,
      allowAccessAll: allowAccessAllFormValue == "true",
      emailDomain: emailDomainSubmission,
      sourceUrl: window.location.href,
    })

    setEditing(false)
    toast.success("Saved.")
  }

  return (
    <div className="flex flex-col gap-y-3 w-2/3">
      <div className="flex flex-row justify-between">
        <EnvelopeIcon className="size-6" />
        <Button variant="tertiary" onClick={toggleEdit}>
          {editing ? "Cancel" : "Edit"}
        </Button>
      </div>
      <H3>Email</H3>
      {editing ? (
        <>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <P weight="medium">Domain access</P>
              <Select.Root
                value={allowAccessAllFormValue}
                onValueChange={newValue => setAllowAccessAllFormValue(newValue)}
              >
                <Select.Trigger className="w-full">
                  <Select.Value placeholder="Choose access" />
                </Select.Trigger>
                <Select.Content>
                  <Select.Item value="false">
                    Only people you invite
                  </Select.Item>
                  <Select.Item value="true">
                    Anyone with your email domain
                  </Select.Item>
                </Select.Content>
              </Select.Root>
            </div>
            {allowAccessAllFormValue == "true" && (
              <div className="flex flex-col gap-y-2 mt-2">
                <P weight="medium">Domain name</P>
                <Input
                  value={emailDomainFormValue}
                  onChange={newValue =>
                    setEmailDomainFormValue(newValue.target.value)
                  }
                ></Input>
              </div>
            )}
            <Button onClick={saveEmailAccess}>Save</Button>
          </div>
        </>
      ) : (
        <>
          <P muted>
            {allowAccessAllFormValue == "true"
              ? `Anyone with @${emailDomainFormValue} domain`
              : "Only people you invite can log in"}
          </P>
        </>
      )}
      <Button asChild variant="secondary">
        <Link to="/admin/access/invite/email" className="mt-2">
          Add person via email
        </Link>
      </Button>
    </div>
  )
}
