import { Form, H3, Input, ToggleButton } from "@spillchat/puddles"
import { FunctionComponent, useMemo } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

interface PreviousBereavementCounsellingProps {
  checkValid: () => void
}

export const PreviousBereavementCounselling: FunctionComponent<
  PreviousBereavementCounsellingProps
> = ({ checkValid }) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const recentLoss = useMemo(() => {
    return form.getValues("preSession.previousBereavementCounselling.value")
  }, [form.watch("preSession.previousBereavementCounselling.value")])

  const updateRecentLoss = (status: string) => {
    if (status === recentLoss) {
      form.setValue("preSession.previousBereavementCounselling.value", null)
    } else {
      form.setValue("preSession.previousBereavementCounselling.value", status)
    }
    checkValid()
  }

  return (
    <div className="flex flex-col gap-3 fieldsContainer">
      <div className="flex flex-col gap-2">
        <H3>
          Have you received any form of support like bereavement counselling
          elsewhere?
        </H3>
      </div>
      <Form.Field
        control={form.control}
        name="preSession.previousBereavementCounselling"
        render={({ field }) => (
          <Form.Item className="w-full">
            <Form.Control>
              <Input {...field} value={field.value.value ?? ""} type="hidden" />
            </Form.Control>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              {["Yes", "No"].map(status => {
                return (
                  <div key={status} className="col-span-1">
                    <ToggleButton
                      widthClass="w-full"
                      toggled={recentLoss === status}
                      onClick={() => updateRecentLoss(status)}
                    >
                      {status}
                    </ToggleButton>
                  </div>
                )
              })}
            </div>
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
