import { Alert, P } from "@spillchat/puddles"
import { FC } from "react"

export const CrisisAlert: FC = () => {
  const crisisServicesTo = "/resources/crisis-lines"

  return (
    <Alert variant="warning">
      <P>
        If you feel you need urgent support or that you may be at risk of
        hurting yourself or others, please contact emergency services on 999 or
        NHS advice on 111 (in the UK), or attend your nearest emergency
        department.
        <a
          className="underline decoration-solid font-semibold pl-1"
          href={crisisServicesTo}
        >
          You can also see a list of alternative crisis services here.
        </a>
      </P>
    </Alert>
  )
}
