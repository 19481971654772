import { gql, useLazyQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"
import cn from "classnames"
import { FunctionComponent, useEffect } from "react"
import { motion } from "framer-motion"
import { FireIcon } from "@heroicons/react/24/outline"

import {
  BurnoutBucket,
  BurnoutResultsGetRecentBurnoutResponseQuery as QueryData,
  BurnoutResultsGetRecentBurnoutResponseQueryVariables as QueryVariables,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { Markdown } from "common/components/Markdown"
import { ADVICE_MAP } from "features/pulse/constants/burnoutAdvice"
import { useAnalyticsPulse } from "features/pulse/hooks/useAnalyticsPulse"
import { getTypeformFeedbackLink } from "features/pulse/helpers/getTypeformFeedbackLink"

export const fragments = {
  burnoutResponseFields: gql`
    fragment BurnoutResultsBurnoutResponseFields on BurnoutResponse {
      id
      bucket
    }
  `,
  meetingFields: gql`
    fragment BurnoutResultsMeetingFields on Meeting {
      urlId
      redirectUrl
    }
  `,
}

export const queries = {
  getResults: gql`
    query BurnoutResultsGetRecentBurnoutResponse {
      recentBurnoutResponse {
        ...BurnoutResultsBurnoutResponseFields
      }
    }
    ${fragments.burnoutResponseFields}
  `,
}

const burnoutRiskScore: Record<BurnoutBucket, string> = {
  LOW_RISK: "low",
  MODERATE_RISK: "moderate",
  HIGH_RISK: "high",
  UNKNOWN_RISK: "unknown",
}

/**
 * A container for the staggered in animation for the flames on top of the results page.
 */
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
}

/**
 * The transition for an individual animated item, ideally used within the above container.
 */
const item = {
  hidden: { opacity: 0, scale: 0.75, y: 5, rotate: -15 },
  show: { opacity: 1, scale: 1, y: 0, rotate: 0 },
}

const AnimatedFire = motion(FireIcon)

interface BurnoutResultsProps {
  redirectUrl: string
  newestBurnoutRisk: BurnoutBucket | null // Null means the user landed directly on the resultss page and did not fill out the survey
}

export const BurnoutResults: FunctionComponent<BurnoutResultsProps> = props => {
  const { user } = useAuth()
  const { track } = useAnalyticsPulse()

  const [queryMostRecent, { data, loading }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(queries.getResults, {
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    track("User opened burnout results page")
  }, [])

  useEffect(() => {
    // Only fetch recentBurnoutResponse if a user lands
    // directly on the results page with no new response
    if (props.newestBurnoutRisk === null && user && loading === false) {
      void queryMostRecent()
    }
  }, [props.newestBurnoutRisk, user, loading])

  if (loading) return null

  const burnoutRisk =
    props.newestBurnoutRisk ?? data?.recentBurnoutResponse?.bucket

  if (burnoutRisk === undefined) {
    return (
      <div className="flex flex-col gap-10 items-center">
        {user && (
          <div className="max-w-lg text-center italic">
            <div className="text-mono-white">
              It looks like you don't have any burnout responses. Make sure
              you're logged in with Slack when you fill out your Pulse surveys
              if you'd like to be able to see your results.
            </div>
          </div>
        )}
        <div className="max-w-sm">
          <JoinMeetingButton redirectUrl={props.redirectUrl} />
        </div>
      </div>
    )
  }
  // @ts-expect-error Don't know the reason just found a TODO to unignore it
  const onClickLink = e => {
    //eslint-disable-next-line
    track("User clicked on a link", { href: e.target["href"] })
  }

  return (
    <>
      <Helmet title="Spill | Team Check-in" />

      <div className="flex flex-col gap-md items-center max-w-lg p-md w-full">
        <motion.div
          className="flex items-end justify-center w-full"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <AnimatedFire
            variants={item}
            className={cn("size-20", {
              "fill-teal-600": burnoutRisk === BurnoutBucket.LOW_RISK,
              "fill-yellow-600": burnoutRisk === BurnoutBucket.MODERATE_RISK,
              "fill-red-600": burnoutRisk === BurnoutBucket.HIGH_RISK,
            })}
          />
          <AnimatedFire
            variants={item}
            className={cn("relative top-1.5 ml-2.5 size-28", {
              "fill-grey-600": burnoutRisk === BurnoutBucket.LOW_RISK,
              "fill-yellow-600": burnoutRisk === BurnoutBucket.MODERATE_RISK,
              "fill-red-600": burnoutRisk === BurnoutBucket.HIGH_RISK,
            })}
          />
          <AnimatedFire
            variants={item}
            className={cn("relative top-2.5 size-40", {
              "fill-red-600": burnoutRisk === BurnoutBucket.HIGH_RISK,
              "fill-grey-600": [
                BurnoutBucket.LOW_RISK,
                BurnoutBucket.MODERATE_RISK,
              ].includes(burnoutRisk),
            })}
          />
        </motion.div>

        <h2 className="cursor-default leading-snug text-grey-100 w-full">
          You're at{" "}
          <span
            className={cn({
              "text-teal-600": burnoutRisk === BurnoutBucket.LOW_RISK,
              "text-yellow-600": burnoutRisk === BurnoutBucket.MODERATE_RISK,
              "text-red-600": burnoutRisk === BurnoutBucket.HIGH_RISK,
              "text-grey-100": burnoutRisk === BurnoutBucket.UNKNOWN_RISK,
            })}
          >
            {burnoutRiskScore[burnoutRisk]}
          </span>{" "}
          risk of burnout.
        </h2>

        <div className="flex flex-col gap-sm">
          <Markdown
            className="cursor-default gap-[inherit]"
            options={{
              overrides: {
                p: {
                  props: {
                    className: "font-semibold text-grey-100 tracking-wide",
                  },
                },
                a: {
                  props: {
                    className:
                      "font-extrabold underline text-grey-100 tracking-wide",
                    target: "_blank",
                    rel: "noopener noreferrer",
                    onClick: onClickLink,
                  },
                },
              },
            }}
          >
            {ADVICE_MAP[burnoutRisk]}
          </Markdown>
        </div>

        <JoinMeetingButton redirectUrl={props.redirectUrl} />

        <p className="cursor-default text-grey-100 w-full">
          Did you find this burnout assessment helpful?{" "}
          <a
            className="underline"
            href={getTypeformFeedbackLink("7", user?.id)}
            rel="noopener noreferrer"
            target="_blank"
          >
            Let us know!
          </a>
        </p>
      </div>
    </>
  )
}

const JoinMeetingButton: FunctionComponent<{ redirectUrl: string }> = props => {
  const { track } = useAnalyticsPulse()
  return (
    <a
      className="btn btn-neutral-dark btn-lg font-bold w-full bg-grey-400 shadow-lg"
      href={props.redirectUrl}
      onClick={e => {
        e.preventDefault()
        track("User Clicked Join Meeting")
        window.open(props.redirectUrl, "_blank", "noreferrer")
      }}
    >
      Join meeting
    </a>
  )
}
