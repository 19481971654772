import { createContext, ReactNode, useContext, useEffect } from "react"
import mixpanel, { Callback } from "mixpanel-browser"
import { useLocation } from "react-router"
import { isNil } from "lodash"

import { config } from "config"
import { UserRole } from "types/graphql"

import { useAuth } from "./authContext"

export type PushParams = [
  "setPath" | "trackPageView" | "identify",
  (string | object)?,
]

type AnalyticsContextData = {
  track: typeof mixpanel.track
  trackPageView: (path: string) => void
  hubspotIdentify: (email: string) => void
}

const AnalyticsContext = createContext<AnalyticsContextData>({
  track: (...p) => mixpanel.track(...p),
  trackPageView: (_path: string) => {},
  hubspotIdentify: (_email: string) => {},
})

export function useAnalytics(): AnalyticsContextData {
  return useContext(AnalyticsContext)
}

type AnalyticsProviderProps = {
  children: ReactNode
}

const mixpanelAnalytics = config.mixpanel.sendEvents
  ? mixpanel
  : {
      track: (
        _event: string,
        _properties?: object,
        _options?: object,
        _callback?: Callback
      ) => {
        // eslint-disable-next-line no-console
        console.info("Mixpanel track", _event, _properties)
      },
      identify: (_id: string) => {
        // eslint-disable-next-line no-console
        console.info("Mixpanel identify", _id)
      },
    }

// const hubspotAnalytics =
//   typeof window !== "undefined" && !isNil(window._hsq)
//     ? window._hsq
//     : { push: (_param: PushParams) => {} }

export function AnalyticsProvider({
  children,
}: AnalyticsProviderProps): JSX.Element {
  const { pathname } = useLocation()
  const { user } = useAuth()

  const isAdmin = user?.role === UserRole.ADMIN

  useEffect(() => {
    // this is a hack so the component can render the helmet tags before tracking the page view
    // this is primarily a problem in hubspot where the title meta tags don't match the url
    setTimeout(() => trackPageView(pathname), 0)
  }, [pathname])

  // Identify v2 and v3 users in Mixpanel
  useEffect(() => {
    if (user) {
      mixpanelAnalytics.identify(user.id)
    }
  }, [user?.id])

  // Identify v3 users in Hubspot
  useEffect(() => {
    const email = user?.primaryEmail
    if (isAdmin && !isNil(email)) hubspotIdentify(email)
  }, [user])

  const track = async (
    ...args: Parameters<typeof mixpanel.track>
  ): Promise<ReturnType<typeof mixpanel.track>> => {
    return await new Promise(resolve => {
      const [event, properties, options, callback] = args
      return mixpanelAnalytics.track(event, properties, options, response => {
        if (callback !== undefined) callback(response)
        resolve()
      })
    })
  }

  const trackPageView = (path: string) => {
    void track("Page View", { path })
    // Only track Hubspot page views for unauthenticated users and v3 admins
    if (isNil(user) || isAdmin) {
      // hubspotAnalytics.push(["setPath", path])
      // hubspotAnalytics.push(["trackPageView"])
    }
  }

  const hubspotIdentify = (_email: string) => {
    // hubspotAnalytics.push(["identify", { email }])
  }

  return (
    <AnalyticsContext.Provider
      value={{ track, trackPageView, hubspotIdentify }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
