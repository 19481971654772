import { UserIcon, UsersIcon } from "@heroicons/react/24/outline"
import React from "react"

import { AppointmentTypeFeature, BookableAppointmentType } from "types/graphql"
import { isEnum } from "types/typeguards"

const parenthoodTypes = [
  BookableAppointmentType.PARENTHOOD_CONSULTATION,
  BookableAppointmentType.PARENTHOOD_SESSION,
]

export const isParenthoodAppointmentType = (
  appointmentType: BookableAppointmentType
): boolean => {
  return parenthoodTypes.includes(appointmentType)
}

const bereavementTypes = [
  BookableAppointmentType.BEREAVEMENT_CONSULTATION,
  BookableAppointmentType.BEREAVEMENT_SESSION,
]

export const isBereavementAppointmentType = (
  appointmentType: BookableAppointmentType
): boolean => {
  return bereavementTypes.includes(appointmentType)
}

export const appointmentTypeToBookableAppointmentType = (
  appointmentType: AppointmentTypeFeature | undefined | null
): BookableAppointmentType => {
  return isEnum(appointmentType, BookableAppointmentType)
    ? (appointmentType as unknown as BookableAppointmentType)
    : BookableAppointmentType.ONE_OFF_THERAPY_SESSION
}

export const getAppointmentLengthInMinutes = (
  appointmentType: BookableAppointmentType
): number => {
  switch (appointmentType) {
    case BookableAppointmentType.COURSE_SESSION_25_MINUTES:
    case BookableAppointmentType.PRIVATE_COURSE_SESSION_25_MINUTES:
    case BookableAppointmentType.BEREAVEMENT_CONSULTATION:
    case BookableAppointmentType.PARENTHOOD_CONSULTATION:
    case BookableAppointmentType.SERIES_CONSULTATION_SESSION:
      return 25
    default:
      return 50
  }
}

export const getAppointmentTypeIcon = (
  appointmentType: BookableAppointmentType
): React.ReactNode => {
  switch (appointmentType) {
    case BookableAppointmentType.COURSE_SESSION_25_MINUTES:
    case BookableAppointmentType.SERIES_THERAPY_SESSION:
    case BookableAppointmentType.SERIES_CONSULTATION_SESSION:
      return <UsersIcon width={24} height={24} />
    default:
      return <UserIcon width={24} height={24} />
  }
}
