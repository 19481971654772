import { gql } from "@apollo/client"

export const queries = {
  getAskATherapistData: gql(`
      query AskATherapistPageGetData {
        user {
          id
          askATherapistQuestions {
            id
            question
            counsellorResponse
            counsellorAvatarUrl
            counsellorFirstName
            counsellorLastName
            createdAt
          }
          company {
            subscriptionStatus
            inActiveTrialPeriod
          }
          accessToTherapyFeatures {
            askATherapist {
              hasAccess
              reason
            }
          }
        }
      }
    `),
  getAskATherapistCounsellors: gql(`
      query AskATherapistPageGetCounsellors {
        allQuestionAnsweringCounsellors {
          id
          avatarUrl
          bio
          firstName
          lastName
          professionalBody
        }
      }
    `),
  getQuestion: gql(`
      query AskATherapistPageGetQuestion($id: ID!) {
        question: askATherapistQuestion(id: $id) {
          id
          question
          counsellorResponse
          counsellorAvatarUrl
          counsellorFirstName
          counsellorLastName
          counsellorBio
          counsellorProfessionalBody
          counsellorId
          createdAt
          answeredAt
          spillResponse
          allowedAddingToAdviceLibraryAt
          addedToAdviceLibraryAt

        }
      }
    `),
  getFollowUpQuestion: gql(`
      query AskATherapistPageGetFollowUpQuestion($parentId: ID!) {
       followUpQuestion: getFollowUpQuestion(parentId: $parentId) {
            id
            question
            counsellorResponse
            spillResponse
            answeredAt
            createdAt
        }
      }
    `),
}
export const fragments = {
  askATherapistQuestionFields: gql`
    fragment AskATherapistPageAskATherapistQuestionFields on AskATherapistQuestion {
      id
      question
    }
  `,
}
