import plugin from "tailwindcss/plugin"

/** @type {import('tailwindcss').Config} */
const config = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
    "./public/index.html",
    "./node_modules/@spillchat/puddles/dist/**/*.js",
  ],
  theme: {
    fontFamily: {
      display: ["MarkPro", "Sans-Serif"],
      inter: ["Inter", "Sans-Serif"],
      mono: [
        "ui-monospace",
        "SFMono-Regular",
        "Menlo",
        "Monaco",
        "Consolas",
        "Liberation Mono",
        "Courier New",
        "monospace",
      ],
      sans: ["Inter", "Lucida Grande", "Tahoma", "Sans-Serif"],
    },
    fontSize: {
      xxs: ".70rem",
      xs: ".75rem",
      sm: ".875rem",
      tiny: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
    },

    colors: {
      transparent: "transparent",
      mono: {
        black: "#000000",
        white: "#FFFFFF",
      },
      grey: {
        100: "#F6F5F9",
        200: "#D3D3DB",
        400: "#707078",
        600: "#6C6C71",
      },
      blue: {
        100: "#E8EDFF",
        200: "#C8D5FF",
        400: "#8DA9FF",
        800: "#0C3CAD",
        900: "#0C1A66",
      },
      teal: {
        100: "#D6F5EF",
        200: "#A4EDE3",
        400: "#35D0BA",
        600: "#08B89F",
      },
      red: {
        100: "#F9D3CF",
        200: "#FFB0A8",
        400: "#FB7466",
        600: "#D82C29",
      },
      yellow: {
        100: "#FFF8E7",
        200: "#FFECBE",
        400: "#F8CF60",
        600: "#EFB108",
      },
    },

    extend: {
      screens: {
        short: { raw: "(max-height: 759px)" },
        tall: { raw: "(min-height: 760px)" },
      },
      aria: {
        invalid: 'invalid="true"',
      },
    },
  },
  corePlugins: {
    // Disable preflight to avoid conflicts between Puddles (Which is also tailwind) & Tailwind in the same project
    // https://tailwindcss.com/docs/preflight#disabling-preflight
    // The problem is that we haave a conflicting reset where submit buttons are made transparent
    // See discussion here: https://github.com/tailwindlabs/tailwindcss/discussions/5969
    // If we can make our CSS imports work in the specified order, we can re-enable this
    preflight: false,
  },
  plugins: [
    /* eslint-disable-next-line @typescript-eslint/unbound-method -- This seems to be a false positive? */
    plugin(({ addVariant }) => {
      addVariant("child", "&>.child")
    }),
  ],
}

export default config
