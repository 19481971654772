import { gql } from "@apollo/client"

export const therayBookingMutations = {
  bookAppointment: gql`
    mutation BookTherapyAppointment(
      $appointmentType: BookableAppointmentType!
      $baselineQuestions: [BaselineQuestion]!
      $filter: AvailableAppointmentSlotsFilter
      $startTime: DateTime!
      $timeZone: String!
      $userInfo: BookAppointmentUserInfoInput!
      $email: String!
    ) {
      bookAppointment(
        appointmentType: $appointmentType
        baselineQuestions: $baselineQuestions
        filter: $filter
        startTime: $startTime
        timeZone: $timeZone
        userInfo: $userInfo
      ) {
        id
        appointmentType
        startsAt
        status
        roomUrl
        counsellor {
          id
          avatarUrl
          calendarId
          firstName
          lastName
          videoCallUrl
        }
      }
      addLoginEmail(email: $email) {
        id
      }
    }
  `,
}
