export const formatCurrency = (
  amount: number,
  currencyCode?: string | null
): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode ?? "GBP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    unitDisplay: "long",
  }).format(amount)
}
