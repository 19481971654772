/**
 * Allows the user to defined how the handle the extension requests from the user
 */

import { H4, Indicator, P, SelectableCard } from "@spillchat/puddles"
import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { z } from "zod"

import { useUser } from "common/context/userContext"
import { SpillSubscriptionPlan } from "types/graphql"
import { FEATURE_FLAGS } from "common/constants/flags"

import { PmiSettings } from "./PmiSettings"

import { BillingFormValues } from "."

interface ExtensionRequestProps {
  userCanRequestTherapy?:
    | {
        active?: boolean | undefined
        value?: boolean | null | undefined
      }
    | null
    | undefined
  subscriptionPlan: string
}

export const ExtensionRequests: FunctionComponent<
  ExtensionRequestProps
> = props => {
  const { company, flags } = useUser()

  const { setValue, register, watch } = useFormContext<BillingFormValues>()
  const watchedUserCanRequestTherapy = watch("userCanRequestTherapy")

  const propsSchema = z.object({
    userCanRequestTherapy: z.object({
      active: z.boolean(),
      value: z.boolean(),
    }),
  })

  const safeParsedProps = propsSchema.safeParse(props)
  const requiresUpgrade = props.userCanRequestTherapy?.active === false
  const companyOnEssentialPlan =
    company.subscriptionPlan === SpillSubscriptionPlan.ESSENTIAL

  const userCanRequestTherapyOptions = [
    {
      value: false,
      label: "Requests disabled",
      description: companyOnEssentialPlan
        ? "Fixed monthly limit"
        : "Employees can only pay privately",
    },
    {
      value: true,
      label: "Requests enabled",
      description: "Employees can request sessions",
    },
  ]

  useEffect(() => {
    setValue(
      "userCanRequestTherapy",
      safeParsedProps.success
        ? safeParsedProps.data.userCanRequestTherapy.value
        : false
    )
  }, [])

  return (
    <div className="flex flex-col gap-4">
      <H4 sectionHeader>Extra sessions</H4>
      <P>
        Choose the options available to employees when they reach their session
        limit but want more sessions.
      </P>

      <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
        {userCanRequestTherapyOptions.map(option => {
          return (
            <div
              key={`sessionCapLimitOptions-${String(option.value)}`}
              className="col-span-2"
            >
              <SelectableCard
                size="lg"
                title={option.label}
                value={String(option.value)}
                disabled={requiresUpgrade}
                {...register("userCanRequestTherapy")}
                {...(option.value && {
                  label: <Indicator variant="ok">Recommended</Indicator>,
                })}
                checked={watchedUserCanRequestTherapy === option.value}
                onClick={() => {
                  setValue("userCanRequestTherapy", option.value)
                }}
                subtitle={option.description}
                type="radio"
              />
            </div>
          )
        })}
        {flags[FEATURE_FLAGS.PMI_INTEGRATION] && <PmiSettings />}
      </div>
    </div>
  )
}
