import { useState, useRef } from "react"
import { Form } from "@spillchat/puddles"

import { TherapyBookingFormSchema } from "./useTherapyBookingForm"

interface UseBookingFormValidationProps {
  formSection: "userInfo" | "clinicalInfo" | "preSession"
  onNext: () => void
}

type FieldRefs = Record<string, HTMLElement | null>

export const useBookingFormValidation = (
  props: UseBookingFormValidationProps
): {
  validationFormSectionAndContinue: () => Promise<void>
  checkValid: () => Promise<void>
  isInvalid: boolean
  registerFieldRef: (fieldNames: string[], ref: HTMLElement | null) => void
} => {
  const [isInvalid, setIsInvalid] = useState(false)
  const fieldRefs = useRef<FieldRefs>({}) // Typed ref mapping for fields

  const form = Form.useFormContext<TherapyBookingFormSchema>()

  /**
   * Register a container element for one or multiple field names.
   * @param fieldNames An array of field names that map to the same container.
   * @param ref The container element reference.
   */
  const registerFieldRef = (fieldNames: string[], ref: HTMLElement | null) => {
    fieldNames.forEach(fieldName => {
      if (ref) {
        fieldRefs.current[fieldName] = ref
      } else {
        delete fieldRefs.current[fieldName] // Cleanup if ref is unmounted
      }
    })
  }

  const checkValid = async (): Promise<void> => {
    if (!isInvalid) return

    const isValid = await form.trigger(props.formSection)
    if (isValid) {
      setIsInvalid(false)
    }
  }

  const validationFormSectionAndContinue = async (): Promise<void> => {
    await form.trigger(props.formSection)

    const errors = form.formState.errors[props.formSection]
    if (errors) {
      setIsInvalid(true)
      const keys = Object.keys(errors) as (keyof typeof errors)[] // Type-safe keys
      let highestElement: HTMLElement | null = null
      let highestOffsetTop = Infinity
      keys.forEach(key => {
        const fieldName = props.formSection + "." + key
        const element = fieldRefs.current[fieldName]
        if (element instanceof HTMLElement) {
          const offsetTop = element.getBoundingClientRect().top + window.scrollY
          if (offsetTop < highestOffsetTop) {
            highestOffsetTop = offsetTop
            highestElement = element
          }
        }
      })
      if (highestElement !== null) {
        ;(highestElement as HTMLElement).scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "smooth",
        })
      }
      return
    }

    props.onNext()
  }

  return {
    validationFormSectionAndContinue,
    checkValid,
    isInvalid,
    registerFieldRef,
  }
}
