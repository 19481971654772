import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "@spillchat/puddles"
import { z } from "zod"

import { GetAppointmentRescheduleInfoQuery } from "types/graphql"
import { useApp } from "common/context/appContext"

import { therapyRescheduleFormSchema } from "../therapy-reschedule.schema"

interface useTherapyRescheduleFormProps {
  appointmentData: GetAppointmentRescheduleInfoQuery
}

export type TherapyRescheduleFormSchema = z.input<
  typeof therapyRescheduleFormSchema
>

export function useTherapyRescheduleForm(
  props: useTherapyRescheduleFormProps
): ReturnType<typeof Form.useForm<TherapyRescheduleFormSchema>> {
  const { appTimeZone } = useApp()

  return Form.useForm<TherapyRescheduleFormSchema>({
    resolver: zodResolver(therapyRescheduleFormSchema),
    defaultValues: {
      appointmentId: props.appointmentData.appointment?.id,
      counsellorId: props.appointmentData.appointment?.counsellor.id,
      startTime: props.appointmentData.appointment?.startsAt,
      timeZone: appTimeZone,
    },
  })
}
