import { gql } from "@apollo/client"

export const queries = {
  accessGetUser: gql`
    query AccessGetUser {
      user {
        id
        company {
          id
          subscriptionPlan
          subscriptionStatus
          platforms {
            id
            name
            hasAnnouncedOnboarding
            platformType
            avatarUrl
            externalId
            allowAccessAll
            groups {
              id
              isPrivate
              name
            }
          }
        }
      }
    }
  `,
}
