import { gql } from "@apollo/client"

export const therapyBookingConfirmationQueries = {
  getAppointment: gql`
    query GetTherapyBookingConfirmation($appointmentId: ID!) {
      appointment(id: $appointmentId) {
        id
        appointmentType
        description
        roomUrl
        startsAt
        title
        matchingCriteria {
          gender
          languageCode
          lifeEvent
          pmiOnly
          specialism
        }
        counsellor {
          id
          firstName
          videoCallUrl
        }
      }
    }
  `,
}
