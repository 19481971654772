import { CalendarDaysIcon } from "@heroicons/react/24/outline"
import { IconCard, Button, P, Tag, Form } from "@spillchat/puddles"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { TZDate } from "@date-fns/tz"
import { addMinutes } from "date-fns"

import { toPascalCase } from "common/helpers/stringFormat"
import { BookableAppointmentType } from "types/graphql"

import { TherapyBookingFormSchema } from "../hooks/useTherapyBookingForm"
import { getAppointmentLengthInMinutes } from "../helpers/appointmentTypeHelper"

export interface TherapyBookingSummaryCardProps {
  onEditBooking: () => void
  bookingTypeName: string
  bookingTypeDescription: string
  appointmentType: BookableAppointmentType
}

export const TherapyBookingSummaryCard: FunctionComponent<
  TherapyBookingSummaryCardProps
> = (props: TherapyBookingSummaryCardProps) => {
  const [tags, setTags] = useState<{ text: string }[]>([])

  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const selectedCounsellor = form.watch("selectedCounsellorInfo")
  const timeZone = form.watch("timeZone")

  const appointmentTimeLength = getAppointmentLengthInMinutes(
    props.appointmentType
  )

  //Get selected time and date in format Friday, 22 Nov 2024 and 4.00pm
  const selectedTime = useMemo(() => {
    const time = form.getValues("startTime")

    if (time === undefined || time === null || time === "") {
      return undefined
    }

    const utcDate = new Date(time)

    const appointmentTimeStart = new TZDate(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      utcDate.getHours(),
      utcDate.getMinutes(),
      utcDate.getSeconds(),
      timeZone
    )

    const appointmentTimeEnd = addMinutes(
      appointmentTimeStart,
      appointmentTimeLength
    )

    const weekday = appointmentTimeStart.toLocaleDateString("en-GB", {
      weekday: "long",
    })
    return {
      day: `${weekday}, ${appointmentTimeStart.toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })}`,
      time: `${appointmentTimeStart
        .toLocaleTimeString("en-GB", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })
        .replace(" ", "")
        .toLowerCase()
        .replace(/am|pm/, "")} - ${appointmentTimeEnd
        .toLocaleTimeString("en-GB", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })
        .replace(" ", "")
        .toLowerCase()}
      `,
    }
  }, [form.getValues("startTime")])

  useEffect(() => {
    const filter = form.getValues("filter")

    //If a previous counsellor is selected, only show the counsellor name
    if (
      filter?.previousCounsellorId !== undefined &&
      filter?.previousCounsellorId !== null &&
      filter?.previousCounsellorId !== "" &&
      selectedCounsellor !== undefined
    ) {
      setTags([
        {
          text: selectedCounsellor.firstName,
        },
      ])
      return
    }

    const newTags: { text: string }[] = []
    if (
      filter?.gender !== null &&
      filter?.gender !== undefined &&
      filter?.gender !== ""
    ) {
      newTags.push({
        text: toPascalCase(filter?.gender).replace("_", "-"),
      })
    }

    if (
      filter?.language !== undefined &&
      filter?.language !== null &&
      filter?.language !== ""
    ) {
      const countryName = new Intl.DisplayNames([filter?.language], {
        type: "language",
      }).of(filter?.language)

      if (countryName !== undefined) {
        newTags.push({
          text: countryName,
        })
      }
    }

    if (
      filter?.specialism !== undefined &&
      filter?.specialism !== null &&
      filter?.specialism !== ""
    ) {
      newTags.push({
        text: filter.specialism,
      })
    }

    if (
      filter?.pmiOnly === true &&
      filter?.pmiProvider !== undefined &&
      filter?.pmiProvider !== null
    ) {
      newTags.push({
        text: filter.pmiProvider,
      })
    }

    setTags(newTags)
  }, [])

  return (
    <IconCard
      title={props.bookingTypeName}
      subtitle={`${appointmentTimeLength} minutes, Video call`}
      buttonFooter={
        <div className="flex gap-2 w-full">
          <Button
            variant="secondary"
            onClick={props.onEditBooking}
            fullWidth={true}
          >
            Edit booking
          </Button>
        </div>
      }
      icon={<CalendarDaysIcon width={24} height={24} />}
    >
      <div className="flex-col flex justify-between gap-2 w-full">
        <P weight="medium">{selectedTime?.day}</P>
        <P muted>
          {selectedTime?.time}
          {`(${timeZone.split("/").join(" / ")})`}
        </P>
      </div>
      <div className="flex flex-wrap gap-2 my-2">
        {tags?.map(tag => (
          <Tag key={tag.text} selectable={false} selected={false}>
            <div className="flex gap-1 items-center">{tag.text}</div>
          </Tag>
        ))}
      </div>
    </IconCard>
  )
}
