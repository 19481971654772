import { Button, H2, H3, HeaderCard, P } from "@spillchat/puddles"
import { Hit, SearchClient, SearchResponse } from "algoliasearch"
import { FunctionComponent, useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useInstantSearch } from "react-instantsearch"
import { useQuery } from "@apollo/client"
import { format } from "date-fns"
import { ChevronLeftIcon, UserIcon } from "@heroicons/react/24/outline"

import ImageBereavement from "common/assets/images/product/specialised-support/therapy-bereavement.png"
import ImageParenthood from "common/assets/images/product/specialised-support/therapy-parenthood.png"
import { useAuth } from "common/context/authContext"
import { config } from "config"
import { Markdown } from "common/components/Markdown"
import {
  AdviceLibraryGetCounsellorQuery,
  AdviceLibraryGetCounsellorQueryVariables,
} from "types/graphql"

import { queries } from "../advice-library.queries"

import { AdviceHit } from "./SearchResultHits"
import { AdviceLibraryTags } from "./AdviceLibraryTags"
import AdviceLibraryCounsellor from "./AdviceLibraryCounsellor"
import { AdviceLibraryResult } from "./AdviceLibraryResult"

type LandingPageProps = {
  searchClient: SearchClient
  setShowingResults: (value: boolean) => void
}

export const AdviceLibraryAnswer: FunctionComponent<LandingPageProps> = ({
  searchClient,
}) => {
  const { objectId } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { indexUiState } = useInstantSearch()
  const location = useLocation()

  if (objectId == null) {
    navigate("/advice-library")
  }

  const isAATEnabled = user?.featuresAndSettings.askATherapist.value === true
  const [obj, setObj] = useState<AdviceHit | null>(null)
  const [relatedQuestions, setRelatedQuestions] = useState<
    Hit<AdviceHit>[] | null
  >(null)

  const { data: counsellor } = useQuery<
    AdviceLibraryGetCounsellorQuery,
    AdviceLibraryGetCounsellorQueryVariables
  >(queries.getCounsellor, {
    variables: {
      counsellorId: obj?.counsellor_id ?? "",
    },
    skip: obj?.counsellor_id == null,
  })

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

    async function getObject() {
      const object = (await searchClient.getObject({
        indexName: config.adviceLibrary.searchIndexName,
        objectID: objectId ?? "",
      })) as AdviceHit

      const { results } = await searchClient.search<AdviceHit>({
        requests: [
          {
            indexName: config.adviceLibrary.searchIndexName,
            filters: `tags:"${object.tags.join('" OR tags:"')}" AND NOT objectID:${objectId}`,
            hitsPerPage: 3,
          },
        ],
      })

      const result = results[0] as SearchResponse<AdviceHit>

      setRelatedQuestions(result.hits)
      setObj(object)
    }

    void getObject()
  }, [location.pathname])

  return (
    <div className="min-h-full flex flex-col gap-8">
      <div className="flex flex-col lg:flex-row lg:gap-32">
        <div className="grow">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-8">
              <Button variant="tertiary" asChild>
                <Link to="/advice-library" className="no-underline">
                  <div className="flex gap-1 items-center">
                    <ChevronLeftIcon className="size-4" />
                    <P weight="medium">Back</P>
                  </div>
                </Link>
              </Button>
              <div className="flex flex-col gap-4">
                <H2>{obj?.question}</H2>
                <div className="flex flex-col lg:flex-row justify-between gap-4">
                  {obj?.tags && <AdviceLibraryTags tags={obj.tags} />}
                  {obj?.answered_at != null &&
                    obj?.answered_at_timestamp != null && (
                      <div className="flex items-center gap-2">
                        <P size="xs" muted>
                          {format(
                            new Date(obj.answered_at_timestamp * 1000),
                            "d MMMM yyyy"
                          )}
                        </P>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-4">
              <Markdown className="[&>p]:leading-relaxed">
                {obj?.answer ?? ""}
              </Markdown>
            </div>
            {counsellor != null && (
              <AdviceLibraryCounsellor
                counsellor={counsellor.questionAnsweringCounsellor}
              />
            )}
          </div>
        </div>
        <div className="min-w-64">
          <div className="flex flex-col sticky top-0">
            {isAATEnabled && (
              <div className="flex flex-col gap-4 border-b py-8">
                <div className="flex flex-col gap-4">
                  <H3>Got your own questions?</H3>
                  <P muted size="xs">
                    Use the Ask a Therapist feature to submit a question and one
                    of our therapists will reply within two days.
                  </P>
                </div>
                <Button>
                  <Link to="/therapy/ask-a-therapist">Ask a Therapist</Link>
                </Button>
              </div>
            )}
            {indexUiState.query === "Bereavement" && (
              <div className="flex flex-col gap-4 border-b py-8">
                <div className="flex flex-col gap-4">
                  <H3>Bereavement support</H3>
                  <div className="flex">
                    <div className="flex flex-col gap-4">
                      <P muted>
                        Your company pays for specialist Bereavement support
                        on&nbsp;Spill
                      </P>
                      <Button variant="secondary">
                        <Link to="">Find out more</Link>
                      </Button>
                    </div>
                    <img
                      className="w-20 h-20"
                      src={ImageBereavement}
                      alt="Someone has a Spill session using their laptop"
                    />
                  </div>
                </div>
              </div>
            )}
            {indexUiState.query === "Parenthood" && (
              <div className="flex flex-col gap-4 border-b py-8">
                <div className="flex flex-col gap-4">
                  <H3>Parenthood support</H3>
                  <div className="flex">
                    <div className="flex flex-col gap-4">
                      <P muted>
                        Your company pays for specialist Parenthood support
                        on&nbsp;Spill
                      </P>
                      <Button variant="secondary">
                        <Link to="">Find out more</Link>
                      </Button>
                    </div>
                    <img
                      className="w-20 h-20"
                      src={ImageParenthood}
                      alt="Someone has a Spill session using their laptop"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col gap-4 py-8">
              <div className="flex flex-col gap-4">
                <H3>Still need help?</H3>
                <P muted size="xs">
                  If you're still struggling with something, speaking to someone
                  in private can help.
                </P>
              </div>
              <HeaderCard
                title="Book therapy"
                icon={<UserIcon width={24} />}
                description="Speak with one of Spill's counsellors"
              >
                <div className="flex gap-4">
                  <Button variant="secondary" size="sm">
                    <Link to="/therapy/sessions">Go to Sessions</Link>
                  </Button>
                </div>
              </HeaderCard>
            </div>
          </div>
        </div>
      </div>
      {counsellor == null && <hr />}
      {relatedQuestions?.length !== 0 && (
        <div className="flex flex-col gap-8">
          <H3>Related questions</H3>
          <div className="grid lg:grid-cols-3 gap-8">
            {relatedQuestions?.map(question => {
              return (
                <AdviceLibraryResult key={question.objectID} hit={question} />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
