export enum FEATURE_FLAGS {
  INVITE_EMAIL_IN_TEAM = "web-app.team.inviteEmails",
  ALLOW_REQUEST_WHEN_BUDGET_USED = "web-app.allowRequestWhenBudgetUsed",
  PMI_INTEGRATION = "web-app.pmiIntegration",
  ADHD_CO_PAY = "web-app.adhdCoPay",
  PMI_FILTER = "web-app.pmiFilter",
  DOMAIN_ACCESS = "web-app.domainAccess",
  NEW_ACCESS_PAGE = "web-app.newAccessPage",
  NEW_ENGAGEMENT_PAGE = "web-app.engagementV2",
}
