import { FunctionComponent, useState } from "react"
import { Button, P, Select } from "@spillchat/puddles"
import { getTimezoneOffset } from "date-fns-tz"
import { sortBy, groupBy } from "lodash"

import { TIME_ZONES } from "common/constants"
import { useApp } from "common/context/appContext"

interface TimeZoneInputProps {
  onTimeZoneChange: (timeZone: string) => void
}

// Select option props
const timeZoneOptions = sortBy(
  TIME_ZONES.flatMap(timeZone => {
    const [continent, regionOrCity] = timeZone.split("/")

    if (continent === undefined || regionOrCity === undefined) return []

    // Timezone offset in milliseconds
    const offset = getTimezoneOffset(timeZone) / 60 / 60 / 1000
    const offsetHours = String(offset - (offset % 1))
    const offsetMinutes = offset % 1 === 0 ? "00" : Math.abs((offset % 1) * 60)

    return {
      group: continent,
      key: timeZone,
      // We show GMT instead of the more modern UTC
      // because it's more familiar with UK audiences
      // https://www.nngroup.com/articles/time-zone-selectors/#:~:text=GMT%20Slightly%20More%20Familiar%20than%20UTC
      label: `${continent} / ${regionOrCity} (GMT ${
        offset === 0 ? "±" : offset > 0 ? "+" : ""
      }${offsetHours}:${offsetMinutes})`,
      offset,
      value: timeZone,
    }
  }),
  ["offset", "group"]
)

const groupedTimeZoneOptions = groupBy(
  sortBy(timeZoneOptions, ["group", "offset"]),
  "group"
)

export const TimeZoneInput: FunctionComponent<TimeZoneInputProps> = ({
  onTimeZoneChange,
}) => {
  const [editing, setEditing] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)
  const { appTimeZone, setAppTimeZone } = useApp()

  //Read only mode. Shows a label with the selected timezone and tertiary edit button
  if (!editing) {
    return (
      <div className="flex items-center gap-2">
        <P>
          {timeZoneOptions.find(option => option.value === appTimeZone)?.label}
        </P>
        <Button
          type="button"
          variant="tertiary"
          onClick={() => {
            setEditing(true)
            setSelectOpen(true)
          }}
        >
          Change
        </Button>
      </div>
    )
  }

  return (
    <div className="max-w-sm">
      <Select.Root
        open={selectOpen}
        onOpenChange={setSelectOpen}
        value={appTimeZone ?? undefined}
        onValueChange={v => {
          setAppTimeZone(v)
          onTimeZoneChange(v)
        }}
      >
        <Select.Trigger>
          <Select.Value placeholder="Select time zone..." />
        </Select.Trigger>
        <Select.Content>
          {Object.entries(groupedTimeZoneOptions).map(([group, options]) => (
            <Select.Group key={group}>
              <Select.Label>{group}</Select.Label>
              {options.map(option => (
                <Select.Item key={option.key} value={option.value}>
                  {`${option.label}`}
                </Select.Item>
              ))}
            </Select.Group>
          ))}
        </Select.Content>
      </Select.Root>
    </div>
  )
}
