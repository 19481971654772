import { Checkbox, Input, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

interface UncontrolledCheckboxListProps {
  options: {
    key: number
    label: string
    selected: boolean
    value: string
  }[]
  onChange: (
    options: {
      key: number
      label: string
      selected: boolean
      value: string
    }[]
  ) => void
  allowMultiple?: boolean
  name: string
}

export const UncontrolledCheckboxList: FunctionComponent<
  UncontrolledCheckboxListProps
> = ({ options, onChange, allowMultiple = true, name }) => {
  const includeOther = options.some(option => option.label === "Other")
  const otherIsSelected =
    includeOther && options.find(option => option.label === "Other")?.selected

  const handleCheckboxChange = (index: number) => {
    const isSelected = options[index]?.selected === true

    const newSelectedOptions = allowMultiple
      ? options.map((option, i) =>
          i === index ? { ...option, selected: !isSelected } : option
        )
      : options.map((option, i) => ({
          ...option,
          selected: i === index ? !isSelected : false,
        }))

    onChange(newSelectedOptions)
  }

  const handleOtherChange = (value: string) => {
    const newOptions = options.map(option =>
      option.label === "Other" ? { ...option, value: value } : option
    )
    onChange(newOptions)
  }

  return (
    <>
      <div className="flex flex-col gap-3">
        {options.map((option, index) => (
          <div key={`${name}.options[${index}]`} className="mt-1">
            <Checkbox
              value={index}
              id={`${name}.options[${index}]`}
              label={{
                htmlFor: `${name}.options[${index}]`,
                children: (
                  <P weight={option.selected ? "bold" : "regular"}>
                    {option.label}
                  </P>
                ),
              }}
              checked={option.selected}
              onClick={() => handleCheckboxChange(index)}
            />
          </div>
        ))}
        {otherIsSelected === true && (
          <Input
            value={
              options.find(option => option.label === "Other")?.value ?? ""
            }
            onChange={e => handleOtherChange(e.target.value)}
            placeholder="Please specify"
          />
        )}
      </div>
    </>
  )
}
