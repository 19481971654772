import { useMutation, useQuery } from "@apollo/client"
import { Button, H2, H3, P } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import {
  ArrowsRightLeftIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  HandThumbUpIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import classNames from "classnames"
import { addMonths, format, startOfMonth } from "date-fns"
import { Link } from "react-router-dom"

import {
  ChangeSessionPackGetUsageQuery,
  ChangeSessionPackUpdateMutation,
  ChangeSessionPackUpdateMutationVariables,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useGoBack } from "common/hooks/useGoBack"
import { packMap } from "features/admin/helpers/sessionPack"

import { queries } from "./change-session-pack.queries"
import { mutations } from "./change-session-pack.mutations"

export const ChangeSessionPack: FunctionComponent = () => {
  const goBack = useGoBack({ isAdmin: true })

  const { data, loading } = useQuery<ChangeSessionPackGetUsageQuery>(
    queries.getUsage
  )

  const { companySessionPack } = data?.user?.company ?? {}

  const { sessionPackSize, upcomingSessionPackSize } = companySessionPack ?? {}

  const currentPackCount = upcomingSessionPackSize ?? sessionPackSize

  const [newSessionPack, setNewSessionPack] = useState<number | null>(null)

  const [updateSessionPack, { data: sessionPackUpdated, reset }] = useMutation<
    ChangeSessionPackUpdateMutation,
    ChangeSessionPackUpdateMutationVariables
  >(mutations.updateCompanySessionPack, {
    refetchQueries: [queries.getUsage],
    variables: {
      upcomingSessionPackSize: newSessionPack ?? currentPackCount!,
    },
  })

  const sessionPackMap = packMap.map(pack => ({
    price: pack.price,
    count: pack.sessionCount,
    current: pack.sessionCount === currentPackCount,
  }))

  const selectPack = (count: number) => {
    if (count !== currentPackCount) {
      reset()
      return setNewSessionPack(count)
    }
  }

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  return (
    <>
      <Helmet title="Change session pack | Spill" />
      <div className="fixed top-0 left-0 h-screen w-screen bg-mono-white z-50 flex flex-col overflow-hidden">
        <div className="bg-mono-white border-b w-full">
          <div className="flex justify-between items-center gap-4 max-w-screen-xl px-6 py-4 mx-auto">
            <button onClick={() => goBack()}>
              <div className="flex gap-2 items-center p-0">
                <ChevronLeftIcon className="size-4" />
                <P weight="medium">Back</P>
              </div>
            </button>
            <div>
              <H3>Change session pack</H3>
            </div>
            <Button variant="tertiary" size="sm" asChild>
              <span className="p-0">
                <XMarkIcon className="size-4" onClick={() => goBack()} />
              </span>
            </Button>
          </div>
        </div>
        <div className="flex justify-center items-center px-6 overflow-scroll grow">
          <div className="max-w-screen-md mx-auto w-full flex flex-col gap-8 h-full py-8 lg:py-24">
            <H2>Session pack options</H2>
            <div className="grid lg:grid-cols-2 lg:gap-12 relative h-full">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4 lg:pb-12">
                  {sessionPackMap.map(pack => {
                    return (
                      <button
                        key={pack.count}
                        onClick={() => selectPack(pack.count)}
                        className={classNames(
                          "p-4 flex border rounded-lg transition",
                          {
                            "bg-grey-100 border-grey-200 hover:border-blue-800":
                              !pack.current && newSessionPack !== pack.count,
                            "bg-teal-100 border-teal-200 cursor-not-allowed":
                              pack.current,
                            "bg-blue-100 border-blue-800":
                              newSessionPack === pack.count,
                          }
                        )}
                      >
                        <P weight="medium">
                          {pack.price} / month ({pack.count} session)
                        </P>
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className="flex flex-col gap-4">
                {newSessionPack == null &&
                  sessionPackUpdated == null &&
                  upcomingSessionPackSize == null && (
                    <div className="flex flex-col gap-8 sticky top-8 h-max py-8 lg:py-0">
                      <div className="flex flex-col gap-4">
                        <P weight="medium">Select a new session pack</P>
                        <P size="xs" muted>
                          Your monthly sessions will renew with your chosen
                          session pack on{" "}
                          {format(
                            startOfMonth(addMonths(new Date(), 1)),
                            "d MMMM yyyy"
                          )}
                          .
                        </P>
                      </div>
                    </div>
                  )}
                {sessionPackUpdated != null && (
                  <div className="flex flex-col gap-8 sticky top-8 h-max py-8 lg:py-0">
                    <div className="flex flex-col gap-4">
                      <P weight="medium">Change confirmed</P>
                      <P size="xs" muted>
                        Your monthly sessions will renew with the{" "}
                        {currentPackCount} session pack on{" "}
                        {format(
                          startOfMonth(addMonths(new Date(), 1)),
                          "d MMMM yyyy"
                        )}
                        .
                      </P>
                    </div>
                    <div>
                      <Button asChild>
                        <Link to="/admin/therapy/needs">Back to Therapy</Link>
                      </Button>
                    </div>
                  </div>
                )}
                {upcomingSessionPackSize != null && newSessionPack == null && (
                  <div className="flex flex-col gap-8 sticky top-8 h-max py-8 lg:py-0">
                    <div className="flex flex-col gap-4">
                      <P weight="medium">Upcoming session pack</P>
                      <P size="xs" muted>
                        Your monthly sessions will renew with the{" "}
                        {currentPackCount} session pack on{" "}
                        {format(
                          startOfMonth(addMonths(new Date(), 1)),
                          "d MMMM yyyy"
                        )}
                        .
                      </P>
                    </div>
                  </div>
                )}
                {newSessionPack != null && sessionPackUpdated == null && (
                  <div className="flex flex-col gap-8 sticky top-8 h-max py-8 lg:py-0">
                    <div className="border-y py-4">
                      <div className="flex flex-col gap-4">
                        <P weight="medium">Next month</P>
                        <P size="xs" muted>
                          Your monthly sessions reset on{" "}
                          {format(
                            startOfMonth(addMonths(new Date(), 1)),
                            "d MMMM yyyy"
                          )}
                          .
                        </P>
                        <div className="flex justify-between">
                          <div className="flex items-center gap-2">
                            <UsersIcon className="size-4 text-grey-400" />
                            <P weight="medium">{newSessionPack} session pack</P>
                          </div>
                          <div>
                            <P>
                              {sessionPackMap.find(pack => {
                                return pack.count === newSessionPack
                              })?.price ?? "-"}
                            </P>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Button onClick={async () => await updateSessionPack()}>
                        Confirm pack
                      </Button>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <HandThumbUpIcon className="size-4" />
                        <P size="xs">Renews automatically each month</P>
                      </div>
                      <div className="flex gap-2">
                        <CheckCircleIcon className="size-4" />
                        <P size="xs">
                          Roll over up to{" "}
                          {newSessionPack < 4 ? 4 : newSessionPack} sessions
                        </P>
                      </div>
                      <div className="flex gap-2">
                        <ArrowsRightLeftIcon className="size-4" />
                        <P size="xs">Switch packs anytime you want</P>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
