import { FunctionComponent, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { captureMessage } from "@sentry/react"
import { H2, P } from "@spillchat/puddles"
import "@whereby.com/browser-sdk"

import { LoadingPage } from "common/components/LoadingPage"
import { useAuth } from "common/context/authContext"
import { isSpill3Appointment } from "common/helpers/isSpill3Appointment"
import {
  AppointmentJoinPageAppointmentQueryQuery,
  AppointmentJoinPageAppointmentQueryQueryVariables,
  AppointmentJoinPageUserQueryQuery,
  AppointmentTypeFeature,
} from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"

const queries = {
  appointment: gql(`
    query AppointmentJoinPageAppointmentQuery($id: ID!) {
      appointment(id: $id) {
        id
        roomUrl
        appointmentType
        numberInCourse
        counsellor {
          id
          videoCallUrl
        }
      }
    }
  `),
  user: gql(`
    query AppointmentJoinPageUserQuery {
      user {
        id
        fullName
      }
    }
  `),
}

export const AppointmentJoinPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { appointmentId } = useParams()
  const { user } = useAuth()
  const [hasLoaded, setHasLoaded] = useState(false)
  const { track } = useAnalytics()

  const { data: userData, loading: userLoading } =
    useQuery<AppointmentJoinPageUserQueryQuery>(queries.user, {
      fetchPolicy: "cache-first",
    })

  const { data: appointmentData, loading: appointmentLoading } = useQuery<
    AppointmentJoinPageAppointmentQueryQuery,
    AppointmentJoinPageAppointmentQueryQueryVariables
  >(queries.appointment, {
    variables: { id: appointmentId! },
  })

  const { appointmentType, numberInCourse } = appointmentData?.appointment ?? {}

  const isConsultation =
    appointmentType === AppointmentTypeFeature.SERIES_CONSULTATION_SESSION
  const isOneOff =
    appointmentType === AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION
  const isFirstSession = numberInCourse === 1
  const isMultipleOfSix =
    numberInCourse != undefined ? numberInCourse % 6 === 0 : false

  const feedbackRequested = [
    isConsultation,
    isOneOff,
    isFirstSession,
    isMultipleOfSix,
  ].some(value => value === true)

  // Adding tracking because we're sending out too many feedback requests
  track("Feedback requested for appointment", {
    isConsultation,
    isOneOff,
    isFirstSession,
    isMultipleOfSix,
    appointmentId,
  })

  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const onLeave = () => {
      if (feedbackRequested === true) {
        navigate(`/feedback/session/${appointmentId!}`, { replace: true })
      }
    }
    ref.current?.addEventListener?.("leave", onLeave)
    return () => {
      ref.current?.removeEventListener?.("leave", onLeave)
    }
  }, [hasLoaded])

  useEffect(() => {
    if (!userLoading && !userData?.user) {
      captureMessage(
        `Unable to get user ${
          user?.id ?? "<no AuthContext.user>"
        } for AppointmentLoginPage`,
        "error"
      )
    }
  }, [userLoading])

  if (userLoading || appointmentLoading) return <LoadingPage />

  const useUniqueUrl = isSpill3Appointment(
    appointmentData?.appointment?.appointmentType
  )

  const roomUrl = useUniqueUrl
    ? appointmentData?.appointment?.roomUrl
    : appointmentData?.appointment?.counsellor.videoCallUrl

  if (roomUrl == null || roomUrl === "") {
    captureMessage("Unable to get Whereby room URL for appointment", "error")
    return (
      <div className="absolute flex flex-col gap-2 inset-0 items-center justify-center">
        <H2>Error</H2>
        <P>
          Unable to join session, please follow the "Get help" link in your
          email.
        </P>
      </div>
    )
  }

  if (!hasLoaded) {
    setHasLoaded(true)
  }

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#0C1A66",
      }}
    >
      {
        // @ts-expect-error The next version of @whereby.com/browser-sdk implements typing
        // so for now we just ignore the TS errors
        <whereby-embed
          ref={ref}
          style={{ height: "100vh" }}
          room={roomUrl}
          displayName={userData?.user?.fullName}
          externalId={userData?.user?.id}
          background="off"
          floatSelf="on"
          minimal="on"
          logo="on"
          settingsButton="on"
          chat="on"
          leaveButton="on"
          people="off"
        />
      }
    </div>
  )
}
