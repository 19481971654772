import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  Form,
  H2,
  H3,
  ModalFullScreenInner,
  P,
  SelectableCard,
  TextArea,
} from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"

import {
  AdminTherapySpecialisedSupportRequestQuery,
  AdminTherapySpecialisedSupportRequestQueryVariables,
  AdminTherapySpecialisedSupportReviewApproveMutation,
  AdminTherapySpecialisedSupportReviewApproveMutationVariables,
  AdminTherapySpecialisedSupportReviewDeclineMutation,
  AdminTherapySpecialisedSupportReviewDeclineMutationVariables,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { formatDate } from "common/helpers/formatDate"
import { useGoBack } from "common/hooks/useGoBack"

import { mutations } from "./admin-therapy-specialised-support-review.mutations"
import { queries } from "./admin-therapy-specialised-support-review.queries"
import { formSchema } from "./admin-therapy-specialised-support-review.schema"

import type { AdminTherapySpecialisedSupportReviewForm } from "./admin-therapy-specialised-support-review.schema"

export const AdminTherapySpecialisedSupportReview: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const { packageId, requestId } = useParams()
  const goBack = useGoBack({ isAdmin: true })
  const navigate = useNavigate()

  const { data, loading: queryLoading } = useQuery<
    AdminTherapySpecialisedSupportRequestQuery,
    AdminTherapySpecialisedSupportRequestQueryVariables
  >(queries.getRequest, {
    variables: {
      requestId: requestId ?? "",
      packageId: packageId ?? "",
    },
  })

  const form = useForm<AdminTherapySpecialisedSupportReviewForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      requestId: "",
      decision: true,
      note: "",
    },
  })

  const [approveExtension, { loading: approveLoading }] = useMutation<
    AdminTherapySpecialisedSupportReviewApproveMutation,
    AdminTherapySpecialisedSupportReviewApproveMutationVariables
  >(mutations.approveExtension, {
    refetchQueries: ["AdminTherapySpecialisedSupport"],
    onCompleted() {
      toast.info("Request approved")
      navigate("/admin/therapy/specialised-support/" + packageId)
    },
  })

  const [declineExtension, { loading: declineLoading }] = useMutation<
    AdminTherapySpecialisedSupportReviewDeclineMutation,
    AdminTherapySpecialisedSupportReviewDeclineMutationVariables
  >(mutations.declineExtension, {
    refetchQueries: ["AdminTherapySpecialisedSupport"],
    onCompleted() {
      toast.info("Request declined")
      navigate("/admin/therapy/specialised-support/" + packageId)
    },
  })

  const mutationLoading = declineLoading || approveLoading

  if (isUserLoading || queryLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const handleSubmit = async (
    values: AdminTherapySpecialisedSupportReviewForm
  ) => {
    if (requestId == null || values == null) {
      throw new Error("Request is invalid")
    }

    if (values.decision === true) {
      return await approveExtension({
        variables: {
          id: requestId,
          note: values.note,
        },
      })
    }

    return await declineExtension({
      variables: {
        id: requestId,
        note: values.note,
      },
    })
  }

  return (
    <>
      <Helmet title="Review request | Spill" />
      <ModalFullScreenInner title="Review request" onClose={() => goBack()}>
        <div className="overflow-scroll h-full py-12 px-5">
          <div className="max-w-screen-sm mx-auto w-full">
            <Form.Root {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-2">
                    <H2>
                      {data?.getTherapyExtension?.user?.displayName ?? "A user"}
                      's request for {data?.getCompanyPackageSettingById?.name}
                    </H2>
                    <P muted>
                      Submitted{" "}
                      {formatDate(
                        new Date(data?.getTherapyExtension?.createdAt ?? "")
                      )}
                    </P>
                  </div>
                  <div className="flex flex-col gap-4">
                    <H3>Request details</H3>
                    <div className="flex flex-col gap-2">
                      <P weight="medium">Note</P>
                      <P muted>
                        {data?.getTherapyExtension?.noteFromUser != null &&
                        data?.getTherapyExtension?.noteFromUser != ""
                          ? data?.getTherapyExtension?.noteFromUser
                          : "None given"}
                      </P>
                    </div>
                  </div>
                  <hr />
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col">
                      <H3>Your decision</H3>
                      <P muted>
                        Choose whether to approve or decline this request.
                      </P>
                    </div>
                    <div>
                      <Form.Field
                        control={form.control}
                        name="decision"
                        render={({ field }) => (
                          <Form.Item>
                            <Form.Control>
                              <div className="grid lg:grid-cols-2 gap-4">
                                {[
                                  {
                                    title: "Approve",
                                    subtitle: "Support is given",
                                    value: true,
                                  },
                                  {
                                    title: "Decline",
                                    subtitle: "Support is refused",
                                    value: false,
                                  },
                                ].map(item => {
                                  return (
                                    <SelectableCard
                                      key={item.value.toString()}
                                      size="sm"
                                      checked={field.value === item.value}
                                      title={item.title}
                                      subtitle={item.subtitle}
                                      type="radio"
                                      {...form.register("decision")}
                                      value={item.value.toString()}
                                      onClick={() => {
                                        form.setValue("decision", item.value)
                                      }}
                                    />
                                  )
                                })}
                              </div>
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </div>
                  </div>

                  <>
                    <Form.Field
                      control={form.control}
                      name="note"
                      render={({ field }) => (
                        <Form.Item>
                          <Form.Control>
                            <TextArea
                              placeholder="Type here..."
                              className="h-24"
                              {...field}
                              label={{
                                children: "Add a note",
                              }}
                            />
                          </Form.Control>
                          <Form.Message />
                        </Form.Item>
                      )}
                    />
                  </>
                  <div className="flex items-center gap-4">
                    <Button
                      type="submit"
                      variant="primary"
                      loading={mutationLoading}
                    >
                      Submit
                    </Button>
                    <P size="xs" muted>
                      You won't be able to amend this decision once it's
                      submitted.{" "}
                    </P>
                  </div>
                </div>
              </form>
            </Form.Root>
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
