import { gql } from "@apollo/client"
import { PACKAGE_FIELDS } from "graphql/fragments/therapy-packages.fragment"

export const therapyBookingQueries = {
  getAvailableAppointmentSlots: gql`
    query GetAvailableAppointmentSlots(
      $appointmentType: BookableAppointmentType!
      $startDate: DateTime!
      $endDate: DateTime!
      $filter: AvailableAppointmentSlotsFilter
    ) {
      availableAppointmentSlots(
        appointmentType: $appointmentType
        startDate: $startDate
        endDate: $endDate
        filter: $filter
      ) {
        startTime
      }
    }
  `,
  getNextDayWithAvailability: gql`
    query GetNextAppointmentSlotWithAvailability(
      $appointmentType: BookableAppointmentType!
      $filter: AvailableAppointmentSlotsFilter
    ) {
      nextDayWithAvailability(
        appointmentType: $appointmentType
        filter: $filter
      )
    }
  `,
  getUserInfo: gql`
    query GetUserAppointmentInfo($appointmentType: BookableAppointmentType!) {
      appointmentTypeInformation(appointmentType: $appointmentType) {
        title
        description
      }
      user {
        id
        firstName
        lastName
        primaryEmail
        therapyProfile {
          approximateLocation
          dateOfBirth
          email
          emergencyContactName
          emergencyContactNumber
          firstName
          lastName
          phoneNumber
          pronouns
        }
        ...PackageFields
      }
    }
    ${PACKAGE_FIELDS}
  `,
  getUpdatedFilters: gql`
    query GetUpdatedFilters(
      $appointmentType: BookableAppointmentType!
      $filtersWithoutGender: AvailableAppointmentSlotsFilter!
      $filtersWithoutSpecialism: AvailableAppointmentSlotsFilter!
      $filtersWithoutLanguage: AvailableAppointmentSlotsFilter!
    ) {
      filtersForGender: matchingCriteriaOptions(
        appointmentType: $appointmentType
        filter: $filtersWithoutGender
      ) {
        genders
      }
      filtersForSpecialisms: matchingCriteriaOptions(
        appointmentType: $appointmentType
        filter: $filtersWithoutSpecialism
      ) {
        specialisms
      }
      filtersForLanguages: matchingCriteriaOptions(
        appointmentType: $appointmentType
        filter: $filtersWithoutLanguage
      ) {
        languageCodes
      }
    }
  `,
  getDefaultFilters: gql`
    query GetDefaultBookingFilters(
      $appointmentType: BookableAppointmentType!
      $filter: AvailableAppointmentSlotsFilter!
    ) {
      matchingCriteriaOptions(
        appointmentType: $appointmentType
        filter: $filter
      ) {
        genders
        languageCodes
        specialisms
      }
      user {
        previousCounsellors {
          counsellor {
            id
            firstName
          }
          pmiEnabledforCompany
        }
        company {
          pmi {
            provider
          }
        }
      }
    }
  `,
}
