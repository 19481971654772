import { z } from "zod"

import { PackageState } from "types/graphql"

export const formSchema = z.object({
  numberSessions: z
    .number({ message: "Must select a session count" })
    .min(2, { message: "Must have a minimum of 2 sessions" })
    .max(100, { message: "Must have a maximum of 100 sessions" }),
  forAllEmployees: z.boolean(),
  numberMonthsToUse: z.number().nullable().optional().default(null),
  adminReason: z
    .string()
    .max(250, {
      message: "Please limit your note to 250 characters",
    })
    .optional(),
  iconUrl: z.string().min(1),
  userFacingDescription: z.string().min(1, {
    message: "Please choose a description",
  }),
  visibleToAllEmployees: z.boolean(),
  icon: z.string().min(1, {
    message: "Please choose an icon",
  }),
  name: z.string().trim().min(1, {
    message: "Please choose a name",
  }),
  state: z.enum([
    PackageState.ACTIVE,
    PackageState.AWAITING_COUNSELLORS,
    PackageState.DISABLED,
  ]),
})

export type InviteCustomForm = z.infer<typeof formSchema>
