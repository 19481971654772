import { Route, Routes } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { FunctionComponent } from "react"

import { NotFoundPage } from "common/components/NotFoundPage"
import {
  RequireSubscriptionPlan,
  fragments as RequireSubscriptionPlanFragments,
} from "features/auth/components/RequireSubscriptionPlan"
import { Access } from "features/access/access.page"
import { Billing } from "features/admin/pages/Billing"
import { CheckoutRouter } from "features/admin/pages/CheckoutRouter"
import { Dashboard } from "features/admin/pages/Dashboard/Dashboard"
import { CheckIns } from "features/pulse/pages/CheckIns"
import { PlatformSetup } from "features/admin/pages/PlatformSetup"
import {
  AdminRouterGetUserQuery as QueryData,
  AdminRouterGetUserQueryVariables as QueryVars,
  SpillSubscriptionPlan,
  SpillSubscriptionStatus,
} from "types/graphql"
import { InviteSlack } from "features/invite-slack/invite-slack.page"
import { InviteParenthood } from "features/invite-parenthood/invite-parenthood.page"
import { ChangeSessionPack } from "features/change-session-pack/change-session-pack.page"
import { InviteAdhd } from "features/invite-adhd/invite-adhd.page"
import { AdminTherapySpecialisedSupportCopay } from "features/admin-therapy-specialised-support-edit/co-pay/admin-therapy-specialised-support-copay.page"

import { InviteEmail } from "../invite-email/invite-email.page"
import { InviteBereavement } from "../invite-bereavement/invite-bereavement.page"
import { InviteCustom } from "../invite-custom/invite-custom.page"

import { Therapy } from "./pages/Therapy"
import { GetHelp } from "./pages/GetHelp"
import { AdminHome } from "./pages/Home"
import { AdminDeclineTherapyExtension } from "./pages/Therapy/AdminDeclineTherapyExtension"
import { InvitePeople } from "./pages/InvitePeople"
import { QuoteView } from "./pages/QuoteView"

export const fragments = {
  queryFields: gql`
    fragment AdminRouterQueryFields on Query {
      user {
        id
        role
        company {
          id
          hasInvitedUsers
          subscriptionPlan
          subscriptionStatus
          featuresAndSettings {
            preApproveTherapy {
              value
            }
            therapyPackage {
              active
            }
            adhdSupport {
              active
              value
            }
            customSupport {
              active
            }
          }
        }
      }
      ...RequireSubscriptionPlanQueryFields
    }
    ${RequireSubscriptionPlanFragments.queryFields}
  `,
}

export const queries = {
  getUser: gql`
    query AdminRouterGetUser {
      ...AdminRouterQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const AdminRouter: FunctionComponent = () => {
  const { data, loading } = useQuery<QueryData, QueryVars>(queries.getUser)

  const subscriptionPlan = data?.user?.company?.subscriptionPlan

  const hasEmailInvite =
    subscriptionPlan != null
      ? [
          SpillSubscriptionPlan.TEAM,
          SpillSubscriptionPlan.ESSENTIAL,
          SpillSubscriptionPlan.LITE,
          SpillSubscriptionPlan.PROPER,
        ].includes(subscriptionPlan)
      : false

  const isSubscribed =
    data?.user?.company?.subscriptionStatus === SpillSubscriptionStatus.ACTIVE

  const hasInviteFlow =
    data?.user?.company?.featuresAndSettings?.preApproveTherapy?.value === true

  const hasSpecialisedSupport =
    data?.user?.company?.featuresAndSettings?.therapyPackage?.active === true

  const hasAdhdSupport =
    data?.user?.company?.featuresAndSettings?.adhdSupport?.active === true

  const hasCustomSupport =
    data?.user?.company?.featuresAndSettings?.customSupport?.active === true

  if (loading) return null

  return (
    <Routes>
      <Route index element={<AdminHome />} />
      <Route path="dashboard/*" element={<Dashboard />} />
      <Route
        // if this path changes the frontFormUrl also needs updating in the
        // front form config
        path="help"
        element={
          <GetHelp frontFormUrl="https://webhook.frontapp.com/forms/59a6bbf15a7ec52638c7/GUOp2rRUSGk5fwkbm_BR-fBM0O71dO3wJhX4J0Uq-Jy4FFyfjr-HGSADmBz3EAFYgVQ9AR4YO_97TRPl2ZWC7QflrtAET4elbB0M5217sZuJTUodMW-oV3wEZstLRw" />
        }
      />
      <Route
        path="therapy/extension/decline/:id"
        element={<AdminDeclineTherapyExtension />}
      />
      <Route path="therapy/*" element={<Therapy />} />
      <Route path="session-pack" element={<ChangeSessionPack />} />
      <Route path="access">
        <Route index element={<Access />} />
        {hasInviteFlow && <Route path="invite" element={<InvitePeople />} />}
        {hasEmailInvite && (
          <>
            <Route path="invite/email" element={<InviteEmail />} />
            <Route path="invite/slack" element={<InviteSlack />} />
          </>
        )}
        {hasSpecialisedSupport && (
          <>
            <Route path="invite/bereavement" element={<InviteBereavement />} />
            <Route path="invite/parenthood" element={<InviteParenthood />} />
          </>
        )}
        {hasAdhdSupport && (
          <>
            <Route path="invite/adhd" element={<InviteAdhd />} />
            <Route
              path="invite/adhd/co-pay"
              element={<AdminTherapySpecialisedSupportCopay />}
            />
          </>
        )}
        {hasCustomSupport && (
          <Route path="invite/custom" element={<InviteCustom />} />
        )}
        {subscriptionPlan !== SpillSubscriptionPlan.STARTER && (
          <Route path="install/*" element={<PlatformSetup />} />
        )}
      </Route>
      {!isSubscribed && <Route path="quote" element={<QuoteView />} />}
      <Route path="billing" element={<Billing />} />
      <Route
        path="check-ins"
        element={
          <RequireSubscriptionPlan
            subscriptionPlan={SpillSubscriptionPlan.TEAM}
          >
            <CheckIns />
          </RequireSubscriptionPlan>
        }
      />
      <Route path="checkout/*" element={<CheckoutRouter />} />

      <Route path="*" element={<NotFoundPage className="grow" />} />
    </Routes>
  )
}
