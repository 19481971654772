import { FunctionComponent, useEffect } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import {
  compareAsc,
  format,
  isAfter,
  isBefore,
  parseISO,
  subMinutes,
} from "date-fns"
import {
  Alert,
  Button,
  H1,
  H4,
  HeaderCard,
  P,
  Tooltip,
} from "@spillchat/puddles"
import { useNavigate } from "react-router-dom"
import { useFlagsStatus } from "@unleash/proxy-client-react"
import { captureException, captureMessage } from "@sentry/react"
import {
  CurrencyPoundIcon,
  EyeSlashIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"
import { PACKAGE_FIELDS } from "graphql/fragments/therapy-packages.fragment"

import { FAQ } from "common/components/FAQ"
import { fragments as SessionCardFragments } from "features/therapy/components/SessionCard"
import {
  AppointmentStatus,
  AppointmentTypeFeature,
  SessionsPageGetUserQueryQuery,
  SessionsPageGetUserQueryQueryVariables,
  SpillSubscriptionStatus,
  TherapyAccessReason,
  TherapyExtensionStatus,
  UserRole,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useUser } from "common/context/userContext"
import LapSession from "common/assets/images/product/lap-session.png"
import { FEATURE_FLAGS } from "common/constants/flags"

import { bookingAvailabilityMessage } from "./bookingAvailabilityMessages"
import { SessionCard as BookingCard } from "./SessionCard"
import { SessionPageError } from "./SessionPageError"
import { UsageAsPercentage } from "./components/UsageAsPercentage"
import { PmiCustomAlert } from "./components/PmiCustomAlert"

export const queries = {
  user: gql`
    query SessionsPageGetUserQuery($filter: UserAppointmentsFilter) {
      user {
        id
        role
        revokedAt
        displayName
        billing {
          paymentsEnabled
        }
        accessToTherapyFeatures {
          askATherapist {
            hasAccess
            reason
          }
          consultations {
            hasAccess
            reason
          }
          oneOffs {
            hasAccess
            reason
          }
          private {
            hasAccess
            reason
          }
        }
        ...PackageFields
        featuresAndSettings {
          id
          userCanRequestTherapy {
            value
          }
          courses {
            value
          }
          oneOffs {
            value
          }
          yearlyTherapyBudget {
            value
          }
          userTherapyCap {
            active
          }
          sessionPack {
            value
          }
          pmi {
            value
          }
        }
        company {
          id
          name
          subscriptionPlan
          subscriptionStatus
          inActiveTrialPeriod
          trialStartDate
          trialPeriodEndDate
          capPeriod
          admins {
            id
            displayName
          }
          budgetYearUsage {
            totalBillableUsage
          }
          currentSessionPackUsage
        }
        upcomingCapResetDate
        therapyUsageCap
        numberSessionsUsedInCapPeriod
        companyTherapyCap
        primaryEmail
        therapyExtensions {
          id
          status
          expiryDate
          numberOfSessions
          companyPackageSetting {
            id
          }
        }
        mostRecentPmiRequest {
          id
          provider
          accountNumber
          numberOfSessions
          status
        }
        appointments(filter: $filter) {
          id
          startsAt
          status
          roomUrl
          appointmentType
          title
          counsellor {
            id
            firstName
            lastName
          }
          ...SessionCardAppointmentFields
        }
        ...SessionCardUserFields
      }
    }
    ${SessionCardFragments.appointment}
    ${SessionCardFragments.user}
    ${PACKAGE_FIELDS}
  `,
}

export const SessionsPage: FunctionComponent = () => {
  const flagStatus = useFlagsStatus()
  const navigate = useNavigate()
  const { flags } = useUser()

  const [fetchUser, { data, loading: appointmentsLoading, called }] =
    useLazyQuery<
      SessionsPageGetUserQueryQuery,
      SessionsPageGetUserQueryQueryVariables
    >(queries.user, {
      variables: {
        filter: { includeNoShows: true },
      },
      // set as network only to always get up to date settings for access to therapy features
      fetchPolicy: "network-only",
    })

  useEffect(() => {
    // features flages could be false if the flag is not ready yet
    if (flagStatus.flagsReady === true && !called) {
      void fetchUser()
    }
    if (flagStatus.flagsError != null && !called) {
      captureException(new Error("Error fetching flags on sessions page"))
      void fetchUser()
    }
  }, [])

  const { company } = data?.user ?? {}

  if (appointmentsLoading || !called) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  if (data?.user == null) {
    captureMessage(`User unable to view sessions page`)

    return <SessionPageError />
  }

  // Our data is valid, so we can safely access the data
  const { accessToTherapyFeatures, therapyPackages } = data.user

  const { therapyUsageCap, numberSessionsUsedInCapPeriod, companyTherapyCap } =
    data?.user ?? {}

  const user = data?.user
  const appointments = [...(data?.user?.appointments ?? [])]

  // Sort appointments by date ascending
  appointments.sort((a, b) =>
    compareAsc(parseISO(a.startsAt), parseISO(b.startsAt))
  )

  const upcomingAppointments = appointments.filter(
    appointment =>
      appointment.status === AppointmentStatus.CONFIRMED &&
      isAfter(parseISO(appointment.startsAt), subMinutes(new Date(), 50))
  )

  // We want to show nowshows no matter what in the previous appointments
  const previousAppointments = appointments.filter(
    appointment =>
      appointment.status === AppointmentStatus.NOSHOW ||
      isBefore(parseISO(appointment.startsAt), subMinutes(new Date(), 25))
  )

  if (user == null) return null

  // The rules are based on it being a single or series. So we just want to pass
  // a single example of either and it'll run the rules accordingly.
  const canBookOneOffs =
    accessToTherapyFeatures.oneOffs.at(0)?.hasAccess ?? false

  const canBookSessions =
    accessToTherapyFeatures.consultations.at(0)?.hasAccess ?? false

  const canBookPrivate =
    (accessToTherapyFeatures.private.at(0)?.hasAccess ?? false) &&
    user.role !== UserRole.PLUS_ONE

  const userCanRequestTherapy =
    user?.featuresAndSettings?.userCanRequestTherapy.value ?? false

  const pmiPathwayAvailable = user?.featuresAndSettings?.pmi.value ?? false

  const hasNoSubscription =
    company?.subscriptionStatus === null && !company?.inActiveTrialPeriod
  const hasInactiveSubscription =
    company?.subscriptionStatus === SpillSubscriptionStatus.INACTIVE &&
    !company?.inActiveTrialPeriod
  const userRevoked = user.revokedAt != null

  const bereavementPackage = therapyPackages.find(
    packageDetails => packageDetails.identifier === "BEREAVEMENT"
  )
  const hasLifeEventPackage =
    bereavementPackage &&
    bereavementPackage.numberSessionsGiven > 0 &&
    bereavementPackage.numberSessionsUsed <
      bereavementPackage.numberSessionsGiven

  // For requesting more, we want to check that the user is on their penultimate (or final) session, if courses are enabled
  // If they can only do one-offs, we only want to check that they're on their final session
  const requestableNumberSessionsUsed =
    therapyUsageCap != null && user.featuresAndSettings.courses.value
      ? therapyUsageCap - 1
      : therapyUsageCap

  const hasUserUsedMostAllSessions =
    (numberSessionsUsedInCapPeriod ?? 0) >= (requestableNumberSessionsUsed ?? 0)

  const companyHasUsedMostAllSessionsBudget =
    // we treat null budgets as "unlimited"
    user.featuresAndSettings?.yearlyTherapyBudget?.value != null &&
    (company?.budgetYearUsage?.totalBillableUsage ?? 0) + 1 >=
      user.featuresAndSettings?.yearlyTherapyBudget?.value

  const companyHasUsedMostAllSessionsSessionPack =
    user.featuresAndSettings?.sessionPack?.value != null &&
    company?.currentSessionPackUsage != null &&
    company?.currentSessionPackUsage + 1 >
      user.featuresAndSettings?.sessionPack?.value

  const companyHasUsedMostAllSessions =
    companyHasUsedMostAllSessionsBudget ||
    companyHasUsedMostAllSessionsSessionPack

  const allowRequestOnBudgetOrPack =
    (companyHasUsedMostAllSessionsSessionPack &&
      // we want to make sure we're accounting for the "gifts" a user has when allowing them to request due to their company being out of budget
      // if they have remaining gifts to use, don't allow them to request more sessions
      hasUserUsedMostAllSessions &&
      user.therapyUsageCap != user.companyTherapyCap) ||
    (companyHasUsedMostAllSessions &&
      flags[FEATURE_FLAGS.ALLOW_REQUEST_WHEN_BUDGET_USED] === true)

  /**
   * Users can request more sessions if certain conditions are met and the userCanRequestTherapy value is true.
   * We have multiple options for requesting more sessions:
   * 1. When we're one (or fewer) away from the cap limit, we use a secondary action on the card to request more.
   * 2. When we're fully capped, we swap the primary action on the card to request more.
   * 3. If you do not have enough credits (<4) to book a course, we use a secondary action on the course card to request more
   * 4. If the company is out of budget AND is flagged in ALLOW_REQUEST_WHEN_BUDGET_USED, we show the primary action on the card to request more.
   * 5. If the company uses session packs (instead of budget), and their pack is used up, we show the primary action on the card to request more.
   */
  // the primary action
  const displayRequestMore =
    ((numberSessionsUsedInCapPeriod ===
      (therapyUsageCap != null && therapyUsageCap) &&
      userCanRequestTherapy) ??
      false) ||
    allowRequestOnBudgetOrPack

  // the secondary action
  const promptRequestMore =
    numberSessionsUsedInCapPeriod != null &&
    therapyUsageCap != null &&
    userCanRequestTherapy &&
    // user is out of sesssions OR (company has used session pack AND user does not have extra gifted credits to use)
    (hasUserUsedMostAllSessions ||
      (companyHasUsedMostAllSessionsSessionPack &&
        !(
          !hasUserUsedMostAllSessions &&
          user.therapyUsageCap != user.companyTherapyCap
        )))

  const showPmiPathway =
    flags[FEATURE_FLAGS.PMI_INTEGRATION] === true &&
    pmiPathwayAvailable &&
    (hasUserUsedMostAllSessions || companyHasUsedMostAllSessionsBudget)

  // we don't show the pay privately card if the user is on a session pack and has used up sessions,
  // as it will reset in a few weeks
  const showPrivateBooking =
    hasUserUsedMostAllSessions ||
    companyHasUsedMostAllSessionsBudget ||
    userRevoked

  const promptRequestMoreOnCourses =
    accessToTherapyFeatures.consultations.at(0)?.reason ===
      TherapyAccessReason.NOT_ENOUGH_USER_CAP_CREDITS && userCanRequestTherapy

  const pendingTherapyExtensions = user.therapyExtensions.filter(
    extension =>
      extension.status === TherapyExtensionStatus.PENDING &&
      extension.companyPackageSetting == null // we have a separate way of displaying requests for therapy packages, don't show them here
  )

  const oneOffBookingSessionMessage = bookingAvailabilityMessage(
    AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION,
    accessToTherapyFeatures.oneOffs.at(0)?.reason ?? TherapyAccessReason.OK
  )

  const seriesBookingMessage = bookingAvailabilityMessage(
    AppointmentTypeFeature.SERIES_CONSULTATION_SESSION,
    accessToTherapyFeatures.consultations.at(0)?.reason ??
      TherapyAccessReason.OK,
    {
      numberSessionsUsedInCapPeriod: numberSessionsUsedInCapPeriod ?? 0,
      therapyUsageCap: therapyUsageCap ?? 0,
    }
  )

  const privateSessionBookingMessage = bookingAvailabilityMessage(
    AppointmentTypeFeature.PRIVATE_THERAPY_SESSION,
    accessToTherapyFeatures.private.at(0)?.reason ?? TherapyAccessReason.OK
  )

  const noSubscriptionMessage = {
    title: "No active subscription",
    message: "Your company hasn't yet added their payment details.",
  }

  const isOnboarding =
    appointments.length === 0 && user?.billing?.paymentsEnabled !== true

  // if the user is revoked OR (on a PAYG company, has zero company credits, and cannot request more), we only show the private booking card
  const noAccessToCompanySessions =
    user.revokedAt != null || (therapyUsageCap === 0 && !userCanRequestTherapy)

  const renderOneOffCardButtons = () => {
    const buttons = []
    if (displayRequestMore) {
      buttons.unshift(
        <Button
          variant="secondary"
          size="sm"
          onClick={() => navigate("/therapy/sessions/request")}
        >
          Request more
        </Button>
      )
    } else {
      buttons.unshift(
        <Tooltip.Trigger>
          <Button
            variant="secondary"
            size="sm"
            disabled={!canBookOneOffs || hasNoSubscription}
            onClick={() => {
              const appointmentTypeFeature =
                user.role === UserRole.PLUS_ONE
                  ? AppointmentTypeFeature.PLUS_ONE
                  : AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION
              navigate(`book?appointmentType=${appointmentTypeFeature}`)
            }}
          >
            Book one-off session
          </Button>
        </Tooltip.Trigger>
      )
    }

    if (promptRequestMore && !displayRequestMore) {
      buttons.push(
        <Button
          variant="tertiary"
          size="sm"
          onClick={() => navigate("/therapy/sessions/request")}
        >
          Request more
        </Button>
      )
    }

    return buttons
  }

  const renderCourseCardButtons = () => {
    let buttons = []
    if (promptRequestMore || displayRequestMore) {
      buttons.unshift(
        <Button
          variant="secondary"
          size="sm"
          onClick={() => navigate("/therapy/sessions/request")}
        >
          Request more
        </Button>
      )
    } else {
      buttons.unshift(
        <Tooltip.Trigger>
          <Button
            variant="secondary"
            size="sm"
            disabled={!canBookSessions || hasNoSubscription}
            onClick={() => {
              navigate("book?appointmentType=SERIES_CONSULTATION_SESSION")
            }}
          >
            Book course of therapy
          </Button>
        </Tooltip.Trigger>
      )
    }

    // we want to display the message about why you can't start a course, and allow the user to request more
    if (promptRequestMoreOnCourses) {
      buttons = []

      buttons.push(
        <Button
          variant="secondary"
          size="sm"
          onClick={() => navigate("/therapy/sessions/request")}
        >
          Request more
        </Button>
      )
    }

    return buttons
  }

  const CreditAllowance: FunctionComponent = () => {
    return (
      <>
        {therapyUsageCap != null && numberSessionsUsedInCapPeriod != null && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-6 gap-8 items-start">
              <UsageAsPercentage
                companyName={company?.name ?? ""}
                therapyUsageCap={therapyUsageCap}
                companyTherapyCap={companyTherapyCap ?? 0}
                numberSessionsUsedInCapPeriod={numberSessionsUsedInCapPeriod}
                upcomingCapResetDate={user.upcomingCapResetDate}
                userCanRequestTherapy={userCanRequestTherapy}
                companyNumberSessionsUsedInBudgetYear={
                  company?.budgetYearUsage?.totalBillableUsage ?? 0
                }
                companyYearlyTherapyBudget={
                  user.featuresAndSettings?.yearlyTherapyBudget?.value
                }
                userTherapyCapActive={
                  user.featuresAndSettings.userTherapyCap.active ?? false
                }
                hasInactiveSubscription={hasInactiveSubscription}
                hasLifeEventPackage={hasLifeEventPackage ?? false}
                companySessionPackLimit={
                  user.featuresAndSettings?.sessionPack.value
                }
                companySessionPackUsage={company?.currentSessionPackUsage}
                companyAllowsCourses={
                  user.featuresAndSettings?.courses.value ?? false
                }
                inActiveTrialPeriod={company?.inActiveTrialPeriod}
                trialStartDate={company?.trialStartDate}
                trialEndDate={company?.trialPeriodEndDate}
                revokedAt={user.revokedAt}
                capPeriod={company?.capPeriod}
              />
              {showPmiPathway && (
                <PmiCustomAlert
                  mostRecentPmiRequest={
                    data.user?.mostRecentPmiRequest ?? undefined
                  }
                />
              )}
              {pendingTherapyExtensions.length > 0 && (
                <div className="col-span-1 md:col-span-4 xl:col-span-6 max-w-xs md:max-w-full">
                  <Alert variant="warning" title={"Extension request pending"}>
                    <P size="xs">
                      You requested to extend your therapy cap, so you can book
                      more sessions. Your Spill admin has been asked to respond
                      by{" "}
                      {format(
                        new Date(pendingTherapyExtensions[0]!.expiryDate),
                        "do LLLL"
                      )}
                      .
                    </P>
                  </Alert>
                </div>
              )}
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <div className="space-y-12">
      {
        <section className="flex flex-col gap-4">
          <H1>Sessions</H1>
          {!isOnboarding && (
            <div className="mb-4">
              <CreditAllowance />
            </div>
          )}
          {isOnboarding && (
            <div className="flex justify-between gap-8">
              <div className="flex flex-col gap-4 mb-4 max-w-screen-sm">
                <H4>Welcome to Spill</H4>
                <P>
                  You can book sessions on Spill to talk about almost anything.
                </P>
                <P>
                  People speak with their counsellors about burnout, anxiety,
                  low mood, grief, relationships problems, trauma, loneliness,
                  stress, life events and everything in between.
                </P>
                <P>
                  There are just over 100 counsellors on Spill and all of them
                  have passed our 5-step hiring process ranking in the top 13%
                  of all qualified applicants.
                </P>
              </div>
              <div className="hidden lg:flex">
                <img
                  src={LapSession}
                  alt="Someone has a Spill session using their laptop"
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-y-12">
            <div className="flex flex-col gap-7">
              <H4 sectionHeader>Book Therapy</H4>
              {isOnboarding && <CreditAllowance />}
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 items-start">
                <Tooltip.Provider>
                  {!noAccessToCompanySessions &&
                    user.featuresAndSettings?.oneOffs.value === true &&
                    !hasInactiveSubscription && (
                      <Tooltip.Root>
                        <div className="xl:max-w-xs h-full col-span-6 xl:col-span-4">
                          <HeaderCard
                            title="One-off session"
                            icon={<UserIcon width={24} />}
                            description="Book a one-off session with a therapist on Spill"
                          >
                            <div className="flex flex-wrap gap-4">
                              {renderOneOffCardButtons()}
                            </div>
                          </HeaderCard>
                        </div>
                        {hasNoSubscription && (
                          <Tooltip.Content
                            sideOffset={20}
                            title={noSubscriptionMessage.title}
                          >
                            <>{noSubscriptionMessage.message}</>
                          </Tooltip.Content>
                        )}
                        {!hasNoSubscription && !canBookOneOffs && (
                          <Tooltip.Content
                            sideOffset={20}
                            title={oneOffBookingSessionMessage.title}
                          >
                            <>{oneOffBookingSessionMessage.message}</>
                          </Tooltip.Content>
                        )}
                      </Tooltip.Root>
                    )}

                  {!noAccessToCompanySessions &&
                    user.featuresAndSettings?.courses.value === true &&
                    !hasInactiveSubscription && (
                      <Tooltip.Root>
                        <div className="xl:max-w-xs h-full col-span-6 xl:col-span-4">
                          <HeaderCard
                            title="Course of therapy"
                            icon={<UsersIcon width={24} />}
                            description={`${
                              promptRequestMoreOnCourses
                                ? seriesBookingMessage.message
                                : "See the same therapist for a series of sessions. You’ll start with a consultation to plan your time together."
                            }`}
                          >
                            <div className="flex gap-2">
                              {renderCourseCardButtons()}
                            </div>
                          </HeaderCard>
                        </div>
                        {hasNoSubscription && (
                          <Tooltip.Content
                            sideOffset={20}
                            title={noSubscriptionMessage.title}
                          >
                            <>{noSubscriptionMessage.message}</>
                          </Tooltip.Content>
                        )}
                        {!hasNoSubscription && !canBookSessions && (
                          <Tooltip.Content
                            title={seriesBookingMessage.title}
                            sideOffset={20}
                          >
                            {seriesBookingMessage.message}
                          </Tooltip.Content>
                        )}
                      </Tooltip.Root>
                    )}

                  {(hasInactiveSubscription || showPrivateBooking) && (
                    <Tooltip.Root>
                      <div className="xl:max-w-xs h-full col-span-6 xl:col-span-4">
                        <HeaderCard
                          title="Pay privately"
                          icon={<CurrencyPoundIcon width={24} />}
                          description={`${
                            hasInactiveSubscription
                              ? "You can pay for therapy privately"
                              : "You can pay for therapy privately until your credits renew"
                          }`}
                        >
                          <div className="flex gap-2">
                            <Tooltip.Trigger>
                              <Button
                                onClick={() => {
                                  return user?.billing?.paymentsEnabled === true
                                    ? navigate(
                                        "book?appointmentType=PRIVATE_THERAPY_SESSION"
                                      )
                                    : navigate("/therapy/sessions/private")
                                }}
                                variant="secondary"
                                disabled={!canBookPrivate}
                                size="sm"
                              >
                                Pay privately for Spill therapy
                              </Button>
                            </Tooltip.Trigger>
                          </div>
                        </HeaderCard>
                      </div>

                      {!canBookPrivate && (
                        <Tooltip.Content
                          title={privateSessionBookingMessage.title}
                          sideOffset={20}
                        >
                          {privateSessionBookingMessage.message}
                        </Tooltip.Content>
                      )}
                    </Tooltip.Root>
                  )}
                </Tooltip.Provider>
              </div>
              {isOnboarding && (
                <div className="flex gap-2">
                  <EyeSlashIcon className="text-teal-400 size-4" />
                  <P muted>Spill sessions are completely confidential</P>
                </div>
              )}
            </div>

            {isOnboarding ? (
              <div className="flex flex-col gap-3">
                <H4 sectionHeader>How Spill works</H4>
                <FAQ />
              </div>
            ) : (
              <>
                <div className="flex flex-col gap-7">
                  <H4 sectionHeader>Upcoming Sessions</H4>
                  {appointmentsLoading ? (
                    <div className="flex w-full justify-center items-center">
                      <LoadingSpinner sizeInPixels={32} />
                    </div>
                  ) : (
                    <div className="flex flex-col gap-4 w-full">
                      {upcomingAppointments.length === 0 && (
                        <div className="justify-center items-center w-full flex">
                          <P muted>No upcoming sessions</P>
                        </div>
                      )}
                      <div className="flex flex-col  gap-4">
                        {upcomingAppointments.map(
                          appointment =>
                            appointment != null && (
                              <BookingCard
                                key={appointment.id}
                                appointment={appointment}
                                isUpcoming
                              />
                            )
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-7">
                  <H4 sectionHeader>Past Sessions</H4>
                  {appointmentsLoading ? (
                    <div className="flex w-full justify-center items-center">
                      <LoadingSpinner sizeInPixels={32} />
                    </div>
                  ) : (
                    <div className="flex flex-col gap-4 w-full">
                      {previousAppointments.length === 0 && (
                        <div className="justify-center items-center w-full flex">
                          <P muted>No past sessions</P>
                        </div>
                      )}
                      <div className="flex flex-col gap-4">
                        {previousAppointments.reverse().map(appointment => (
                          <BookingCard
                            key={appointment.id}
                            appointment={appointment}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </section>
      }
    </div>
  )
}
