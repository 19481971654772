import { useMutation, useQuery } from "@apollo/client"
import { FunctionComponent, useState } from "react"
import {
  Alert,
  Button,
  Form,
  H1,
  H2,
  H3,
  Input,
  InputStepper,
  P,
  Select,
  Switch,
  Tabs,
  TextArea,
  Tooltip,
} from "@spillchat/puddles"
import { Link, useNavigate, useParams } from "react-router-dom"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  CheckIcon,
  EyeIcon,
  GlobeEuropeAfricaIcon,
} from "@heroicons/react/24/outline"
import { toast } from "sonner"
import { FormFieldWrapper } from "components/form/FormFieldWrapper"

import {
  AdminReadableUserTherapyPackage,
  AdminTherapySpecialisedSupportDisableMutation,
  AdminTherapySpecialisedSupportDisableMutationVariables,
  AdminTherapySpecialisedSupportEditQuery,
  AdminTherapySpecialisedSupportEditQueryVariables,
  AdminTherapySpecialisedSupportUpdateMutation,
  AdminTherapySpecialisedSupportUpdateMutationVariables,
  CompanyPackageSetting,
  PackageState,
  TherapyExtension,
} from "types/graphql"
import { iconList } from "common/helpers/iconPalette"
import { LoadingPage } from "common/components/LoadingPage"
import AdminTherapyProcessingAlert from "features/admin-therapy/components/AdminTherapyProcessingAlert"
import { AdminTherapyToggleButtons } from "features/admin-therapy/components/AdminTherapyToggleButtons"
import { AdminTherapySpecialisedSupportTable } from "features/admin-therapy/components/AdminTherapySpecialisedSupportTable"
import { Dialog } from "common/components/Dialog"
import { BackButton } from "common/components/BackButton"
import { AdminTherapySpecialisedSupportRequestTable } from "features/admin-therapy/components/AdminTherapySpecialisedSupportRequestTable"

import { queries } from "./admin-therapy-specialised-support-edit.queries"
import { mutations } from "./admin-therapy-specialised-support-edit.mutations"
import { formSchema } from "./admin-therapy-specialised-support-edit.schema"

type FormValues = {
  numberSessions: number
  numberMonthsToUse: number | null
  forAllEmployees: boolean
  visibleToAllEmployees: boolean
  icon: string
  userFacingDescription: string
  name: string
}

export const AdminTherapySpecialisedSupportEdit: FunctionComponent = () => {
  const params = useParams<{ packageId: string | undefined }>()

  const { data, loading } = useQuery<
    AdminTherapySpecialisedSupportEditQuery,
    AdminTherapySpecialisedSupportEditQueryVariables
  >(queries.getPackage, {
    variables: {
      id: params.packageId ?? "",
    },
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return <LoadingPage />
  }

  const pkg = data?.getCompanyPackageSettingById as CompanyPackageSetting

  const users = data?.getCompanyPackageSettingSupportedEmployees as
    | AdminReadableUserTherapyPackage[]
    | null
    | undefined

  const requests = data?.getAllNonCancelledTherapyExtensionsForTherapyPackageId

  return (
    <AdminTherapySpecialisedSupportEditForm
      pkg={pkg}
      users={users}
      requests={requests as TherapyExtension[]}
    />
  )
}

const AdminTherapySpecialisedSupportEditForm: FunctionComponent<{
  pkg: CompanyPackageSetting | null | undefined
  users: AdminReadableUserTherapyPackage[] | null | undefined
  requests: TherapyExtension[] | undefined
}> = ({ pkg, users, requests }) => {
  const navigate = useNavigate()
  const [turnOffModal, setTurnOffModal] = useState(false)
  const toggleTurnOffModal = () => {
    setTurnOffModal(!turnOffModal)
  }

  const [updatePackage] = useMutation<
    AdminTherapySpecialisedSupportUpdateMutation,
    AdminTherapySpecialisedSupportUpdateMutationVariables
  >(mutations.updatePackage, {
    refetchQueries: ["AdminTherapySpecialisedSupportEdit"],
  })

  const [disablePackage] = useMutation<
    AdminTherapySpecialisedSupportDisableMutation,
    AdminTherapySpecialisedSupportDisableMutationVariables
  >(mutations.disablePackage, {
    refetchQueries: ["AdminTherapySpecialisedSupportEdit"],
    variables: {
      packageSettingId: pkg?.id ?? "",
    },
    onCompleted() {
      toggleTurnOffModal()
      toast("Support has been turned off")
    },
  })

  const values = {
    userFacingDescription: pkg?.userFacingDescription ?? "",
    numberSessions: pkg?.numberSessions ?? 4,
    numberMonthsToUse: pkg?.numberMonthsToUse ?? null,
    forAllEmployees: pkg?.forAllEmployees ?? false,
    visibleToAllEmployees: pkg?.visibleToAllEmployees ?? false,
    icon: pkg?.icon ?? "",
    name: pkg?.name ?? "",
  }

  const forms = {
    userFacingDescription: Form.useForm<FormValues>({
      resolver: zodResolver(formSchema),
      values,
    }),
    numberSessions: Form.useForm<FormValues>({
      resolver: zodResolver(formSchema),
      values,
    }),
    numberMonthsToUse: Form.useForm<FormValues>({
      resolver: zodResolver(formSchema),
      values,
    }),
    forAllEmployees: Form.useForm<FormValues>({
      resolver: zodResolver(formSchema),
      values,
    }),
    visibleToAllEmployees: Form.useForm<FormValues>({
      resolver: zodResolver(formSchema),
      values,
    }),
    icon: Form.useForm<FormValues>({
      resolver: zodResolver(formSchema),
      values,
    }),
    name: Form.useForm<FormValues>({
      resolver: zodResolver(formSchema),
      values,
    }),
  }

  const numberMonthsToUse =
    forms.numberMonthsToUse.getValues("numberMonthsToUse")

  const onSubmit = async (variables: FormValues) => {
    if (pkg?.id == null) {
      return
    }

    return await updatePackage({
      variables: {
        ...variables,
        packageSettingId: pkg?.id ?? "",
      },
    })
  }

  const onSwitch = async (variables: Partial<FormValues>) => {
    if (pkg?.id == null) {
      return
    }

    return await updatePackage({
      variables: {
        ...values,
        ...variables,
        packageSettingId: pkg?.id ?? "",
      },
    })
  }

  const autoEnrolDisabled =
    pkg?.state === PackageState.DISABLED ||
    pkg?.state === PackageState.AWAITING_COUNSELLORS ||
    forms.visibleToAllEmployees.getValues("visibleToAllEmployees") === false

  const [tableTab, setTableTab] = useState<"employees" | "requests">(
    "employees"
  )

  return (
    <>
      <div className="flex flex-col gap-12">
        <BackButton to="/admin/therapy/specialised-support" />
        <div className="flex flex-col gap-8">
          {pkg?.state === PackageState.DISABLED && (
            <Alert title="This support has been turned off" variant="warning">
              <P size="xs">
                <Link to="/admin/help?topic=therapy_access">Get in touch</Link>{" "}
                with any questions
              </P>
            </Alert>
          )}
          <div className="flex flex-col lg:flex-row items-start gap-8 lg:gap-24">
            <div className="flex flex-col gap-5 w-full lg:w-3/5">
              <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-5">
                  <Form.Root {...forms.name}>
                    <FormFieldWrapper<FormValues>
                      showDivider={false}
                      disabled={pkg?.state === PackageState.DISABLED}
                      onSubmit={forms.name.handleSubmit(onSubmit)}
                      label={pkg?.name ?? ""}
                      description="The name you've given for this support"
                      displayValue={null}
                      headingComponent={H1}
                      values={["name"]}
                    >
                      <Form.Field
                        control={forms.name.control}
                        name="name"
                        render={({ field }) => (
                          <Form.Item>
                            <Form.Label>Name</Form.Label>
                            <Form.Control>
                              <Input {...field} />
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </FormFieldWrapper>
                  </Form.Root>
                  <Form.Root {...forms.userFacingDescription}>
                    <FormFieldWrapper<FormValues>
                      showDivider={false}
                      disabled={pkg?.state === PackageState.DISABLED}
                      onSubmit={forms.userFacingDescription.handleSubmit(
                        onSubmit
                      )}
                      label="How it works"
                      description="Information about what it's for"
                      displayValue={forms.userFacingDescription.getValues(
                        "userFacingDescription"
                      )}
                      values={["userFacingDescription"]}
                    >
                      <Form.Field
                        control={forms.userFacingDescription.control}
                        name="userFacingDescription"
                        render={({ field }) => (
                          <Form.Item>
                            <Form.Label>Description</Form.Label>
                            <Form.Control>
                              <TextArea className="h-24" {...field} />
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </FormFieldWrapper>
                  </Form.Root>
                </div>
                <div className="flex gap-2 items-center">
                  {pkg?.state === PackageState.ACTIVE && (
                    <Button asChild>
                      <button>
                        <Link to="enrol">Support an employee</Link>
                      </button>
                    </Button>
                  )}
                  {pkg?.state === PackageState.AWAITING_COUNSELLORS && (
                    <Button size="sm" variant="readonly" asChild>
                      <Link
                        to={"/admin/therapy/specialised-support/" + pkg.id}
                        className="pl-2"
                      >
                        <div className="flex gap-1 items-center">
                          <CheckIcon width={16} />
                          <span>Package requested</span>
                        </div>
                      </Link>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:flex flex-col gap-4 grow hidden">
              {pkg?.iconUrl == null ? (
                <div className="w-full h-full aspect-square bg-grey-100 rounded-lg"></div>
              ) : (
                <div className="w-full h-full aspect-square">
                  <img
                    src={pkg?.iconUrl ?? ""}
                    alt=""
                    className="rounded-lg aspect-square object-cover"
                  />
                </div>
              )}
              <hr className="border-t-grey-100" />
              <Form.Root {...forms.icon}>
                <FormFieldWrapper<FormValues>
                  disabled={pkg?.state === PackageState.DISABLED}
                  onSubmit={forms.icon.handleSubmit(onSubmit)}
                  label="Package icon"
                  description="Choose an icon for the sidebar"
                  displayValue={`${forms.icon.getValues("icon")}`}
                  displayIcon={`${forms.icon.getValues("icon")}`}
                  values={["icon"]}
                >
                  <Form.Field
                    control={forms.icon.control}
                    name="icon"
                    render={({ field }) => (
                      <Form.Item>
                        <Form.Label>Package icon</Form.Label>
                        <Form.Control>
                          <Select.Root
                            defaultValue={field.value}
                            onValueChange={value => {
                              forms.icon.setValue("icon", value, {
                                shouldDirty: true,
                              })
                            }}
                          >
                            <Select.Trigger>
                              <Select.Value placeholder="Select an icon" />
                            </Select.Trigger>
                            <Select.Content>
                              {iconList.map(icon => (
                                <Select.Item
                                  key={icon.displayName}
                                  value={`${icon.displayName}`}
                                >
                                  <div className="flex items-center gap-2">
                                    <icon.icon className="size-4" />{" "}
                                    <P>{icon.displayName}</P>
                                  </div>
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </Select.Root>
                        </Form.Control>
                        <Form.Message />
                      </Form.Item>
                    )}
                  />
                </FormFieldWrapper>
              </Form.Root>
            </div>
          </div>
        </div>
        <hr className="border-t-grey-200" />
        <div className="flex flex-col gap-8">
          <div className="flex gap-24">
            <div className="flex flex-col gap-3">
              <H2>Support settings</H2>
              <P muted>See what your employees get</P>
            </div>
          </div>
          <div className="flex flex-col gap-5 lg:w-3/5">
            <Form.Root {...forms.numberSessions}>
              <FormFieldWrapper<FormValues>
                disabled={pkg?.state === PackageState.DISABLED}
                onSubmit={forms.numberSessions.handleSubmit(onSubmit)}
                label="Sessions per person"
                displayValue={forms.numberSessions.getValues("numberSessions")}
                values={["numberSessions"]}
              >
                <Form.Field
                  control={forms.numberSessions.control}
                  name="numberSessions"
                  render={({ field }) => (
                    <Form.Item className="flex flex-col">
                      <Form.Control>
                        <InputStepper
                          value={field.value.toString()}
                          onStepperChange={value => {
                            forms.numberSessions.clearErrors("numberSessions")
                            forms.numberSessions.setValue(
                              "numberSessions",
                              parseInt(value),
                              { shouldDirty: true }
                            )
                          }}
                          {...forms.numberSessions.register("numberSessions", {
                            setValueAs: (value: string) => {
                              forms.numberSessions.clearErrors("numberSessions")
                              if (isNaN(parseInt(value))) {
                                return ""
                              } else {
                                return parseInt(value)
                              }
                            },
                          })}
                        />
                      </Form.Control>
                      <P size="xs" muted>
                        Charged at the normal rate
                      </P>
                      <Form.Message />
                    </Form.Item>
                  )}
                />
              </FormFieldWrapper>
            </Form.Root>
            <Form.Root {...forms.numberMonthsToUse}>
              <FormFieldWrapper<FormValues>
                disabled={pkg?.state === PackageState.DISABLED}
                onSubmit={forms.numberMonthsToUse.handleSubmit(onSubmit)}
                label="Support duration"
                displayValue={
                  numberMonthsToUse !== null && numberMonthsToUse !== undefined
                    ? numberMonthsToUse +
                      (numberMonthsToUse > 1 ? " months" : " month")
                    : "No use by date"
                }
                values={["numberMonthsToUse"]}
              >
                <Form.Field
                  control={forms.numberMonthsToUse.control}
                  name="numberMonthsToUse"
                  render={({ field }) => (
                    <Form.Item className="flex flex-col">
                      <Form.Control>
                        <div className="flex flex-col gap-8">
                          <AdminTherapyToggleButtons
                            onButtonClick={value => {
                              forms.numberMonthsToUse.clearErrors(
                                "numberMonthsToUse"
                              )
                              forms.numberMonthsToUse.setValue(
                                "numberMonthsToUse",
                                value,
                                { shouldDirty: true }
                              )
                            }}
                            onStepperChange={value => {
                              forms.numberMonthsToUse.clearErrors(
                                "numberMonthsToUse"
                              )
                              forms.numberMonthsToUse.setValue(
                                "numberMonthsToUse",
                                value,
                                { shouldDirty: true }
                              )
                            }}
                            value={field.value}
                          />
                          <Form.Message className="!mt-0" />
                        </div>
                      </Form.Control>
                      <Form.Message />
                    </Form.Item>
                  )}
                />
              </FormFieldWrapper>
            </Form.Root>
          </div>
        </div>
        <hr className="border-t-grey-200" />
        <div className="flex flex-col gap-8">
          <div className="flex gap-24">
            <div className="flex flex-col gap-3">
              <H2>Support policy</H2>
              <P muted>
                Choose whether employees can self serve or discover the policy
              </P>
            </div>
          </div>
          <div className="flex gap-8 justify-betwer">
            <div className="flex flex-col gap-5 lg:w-3/5">
              <div className="flex flex-col gap-2">
                <Form.Root {...forms.visibleToAllEmployees}>
                  <Form.Field
                    name="visibleToAllEmployees"
                    render={() => (
                      <Form.Item>
                        <Form.Control>
                          <div className="flex flex-col gap-4">
                            <div className="flex items-center justify-between gap-4">
                              <EyeIcon className="size-8" />
                              <Switch
                                disabled={pkg?.state === PackageState.DISABLED}
                                defaultChecked={forms.visibleToAllEmployees.getValues(
                                  "visibleToAllEmployees"
                                )}
                                onCheckedChange={async visibleToAllEmployees => {
                                  const request: {
                                    visibleToAllEmployees: boolean
                                    forAllEmployees?: boolean
                                  } = { visibleToAllEmployees }

                                  if (visibleToAllEmployees === false) {
                                    request.forAllEmployees = false
                                  }

                                  await onSwitch(request)
                                }}
                              />
                            </div>
                            <div className="flex flex-col gap-2">
                              <H3>Make the policy public</H3>
                              <P muted>
                                Everyone at the company can see the policy and
                                request access
                              </P>
                            </div>
                          </div>
                        </Form.Control>
                        <Form.Message />
                      </Form.Item>
                    )}
                  />
                </Form.Root>
              </div>
              <hr className="border-t border-grey-100" />
              <div className="flex flex-col gap-2">
                <Form.Root {...forms.forAllEmployees}>
                  <Form.Field
                    name="forAllEmployees"
                    render={() => (
                      <Form.Item>
                        <Form.Control>
                          <div className="flex flex-col gap-4">
                            <div className="flex items-center justify-between gap-4">
                              <GlobeEuropeAfricaIcon className="size-8" />
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger
                                    className="text-start"
                                    type="button"
                                  >
                                    <Switch
                                      disabled={autoEnrolDisabled}
                                      defaultChecked={forms.forAllEmployees.getValues(
                                        "forAllEmployees"
                                      )}
                                      onCheckedChange={async forAllEmployees => {
                                        await onSwitch({ forAllEmployees })
                                      }}
                                    />
                                  </Tooltip.Trigger>
                                  {autoEnrolDisabled && (
                                    <Tooltip.Content>
                                      {forms.visibleToAllEmployees.getValues(
                                        "visibleToAllEmployees"
                                      ) === false &&
                                        "This setting is only available when the policy is public"}
                                      {pkg?.state === PackageState.DISABLED &&
                                        "This setting is unavailable as the package has been archived"}
                                      {pkg?.state ===
                                        PackageState.AWAITING_COUNSELLORS &&
                                        "This setting is unavailable as the policy is still awaiting counsellors"}
                                    </Tooltip.Content>
                                  )}
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </div>
                            <div className="flex flex-col gap-2">
                              <H3>Auto-enrol all employees</H3>
                              <P muted>
                                Everyone at the company can use the policy
                                without requesting access
                              </P>
                            </div>
                          </div>
                        </Form.Control>
                        <Form.Message />
                      </Form.Item>
                    )}
                  />
                </Form.Root>
              </div>
            </div>
            {pkg?.state === PackageState.AWAITING_COUNSELLORS && (
              <div className="w-2/5">
                <AdminTherapyProcessingAlert />
              </div>
            )}
          </div>
        </div>
        <hr className="border-t-grey-200" />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-3">
            <H2>Supported employees</H2>
            <P muted>
              Keep track of who on your team has access to this support
            </P>
          </div>
          <div>
            <Tabs.Root defaultValue={tableTab} value={tableTab}>
              <Tabs.List className="mb-3">
                <Tabs.Trigger
                  value="employees"
                  onClick={() => setTableTab("employees")}
                >
                  Supported employees
                </Tabs.Trigger>
                <Tabs.Trigger
                  value="requests"
                  onClick={() => setTableTab("requests")}
                >
                  Requests
                </Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content value="employees">
                {pkg?.forAllEmployees === true ? (
                  <Alert
                    variant="success"
                    title="Your whole team is auto-enrolled"
                  >
                    <P>
                      Everyone at your company can see that {pkg?.name} is
                      enabled and access it in their account.
                    </P>
                  </Alert>
                ) : (
                  <>
                    {users != null && users.length > 0 ? (
                      <AdminTherapySpecialisedSupportTable data={users} />
                    ) : (
                      <Alert
                        action={{
                          label: "Add an employee",
                          onClick: () => navigate("enrol"),
                        }}
                        title="You haven't enrolled anyone yet"
                      >
                        <P>
                          Get started by adding someone to this support. If you
                          want to give everyone access, turn on Auto-enrol in
                          the support visibility settings.
                        </P>
                      </Alert>
                    )}
                  </>
                )}
              </Tabs.Content>
              <Tabs.Content value="requests">
                {requests != null && (
                  <AdminTherapySpecialisedSupportRequestTable data={requests} />
                )}
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </div>
        <hr className="border-t-grey-200" />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-3">
            <H3>Turn off support</H3>
            <P muted>
              If you'd like to retire this support package, you can turn it off.
              Any employees who have already received a support package will
              keep it, but new employees will not be able to receive support.
            </P>
          </div>
          <Button
            variant="destructive"
            disabled={pkg?.state === PackageState.DISABLED}
            onClick={() => toggleTurnOffModal()}
          >
            Turn off support
          </Button>
        </div>
      </div>
      <Dialog
        canClose
        isOpen={turnOffModal}
        onClose={() => toggleTurnOffModal()}
        title="Turn off support?"
        buttons={[
          {
            key: "cancel",
            variant: "secondary",
            children: "Not now",
            onClick: () => toggleTurnOffModal(),
          },
          {
            key: "confirm",
            variant: "destructive",
            children: "Turn off support",
            onClick: async () => {
              await disablePackage()
            },
          },
        ]}
      >
        <P>You will not be able to reverse this without contacting&nbsp;us.</P>
      </Dialog>
    </>
  )
}
