import { Form, H3, P, TextArea } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

interface BirthComplicationsProps {
  checkValid: () => void
}

export const BirthComplications: FunctionComponent<BirthComplicationsProps> = ({
  checkValid,
}) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <H3>
          Are there or were there any complications around birth or pregnancy
          that you'd like your therapist to know about?
        </H3>
        <P muted>Optional</P>
      </div>

      <Form.Field
        control={form.control}
        name="preSession.birthComplications.value"
        render={({ field }) => (
          <Form.Item className="w-full">
            <Form.Control>
              <TextArea
                {...field}
                value={field.value ?? ""}
                rows={5}
                className="h-auto min-h-20 max-h-64"
                onBlur={checkValid}
                placeholder="What brings you to Spill?"
              />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="preSession.birthComplications"
        render={() => (
          <Form.Item className="w-full">
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
