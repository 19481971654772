import { useQuery, useMutation } from "@apollo/client"
import {
  Button,
  P,
  ModalFullScreenInner,
  H2,
  Form,
  TextArea,
} from "@spillchat/puddles"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"
import { useId } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  AskATherapistPageGetFollowUpQuestionQuery,
  AskATherapistPageGetQuestionQuery,
  AskATherapistPageCreateFollowUpAskATherapistQuestionMutation as MutationData,
  AskATherapistPageCreateFollowUpAskATherapistQuestionMutationVariables as MutationVars,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useAnalytics } from "common/context/analyticsContext"
import { formatDate } from "common/helpers/formatDate"

import { type AskATherapistForm, formSchema } from "../ask-a-therapist-schema"
import { queries } from "../ask-a-therapist.queries"
import { mutations } from "../ask-a-therapist.mutations"

import { ViewQuestionAndResponse } from "./ViewQuestionAndResponse"
import { CrisisAlert } from "./CrisisAlert"

export type Question =
  NonNullable<AskATherapistPageGetQuestionQuery>["question"]

export function QuestionModal({
  showAdviceLibrary,
}: {
  showAdviceLibrary?: boolean
}): JSX.Element {
  const formId = useId()
  const { objectId } = useParams()

  const {
    data: questionData,
    loading: questionIsLoading,
    refetch: refetchQuestion,
  } = useQuery<AskATherapistPageGetQuestionQuery>(queries.getQuestion, {
    variables: {
      id: objectId,
    },
  })

  const {
    data: followUpQuestionData,
    loading: followUpQuestionIsLoading,
    refetch,
  } = useQuery<AskATherapistPageGetFollowUpQuestionQuery>(
    queries.getFollowUpQuestion,
    {
      variables: {
        parentId: objectId,
      },
    }
  )

  const { track } = useAnalytics()
  const navigate = useNavigate()

  const form = Form.useForm<AskATherapistForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      question: "",
      counsellorId: undefined,
    },
  })

  const [submitAskATherapistFollowUpQuestion, { loading: isSubmitting }] =
    useMutation<MutationData, MutationVars>(
      mutations.createFollowUpAskATherapistQuestion,
      {
        onError: () => {
          toast.error("Something went wrong, please try again or get in touch.")
        },
        onCompleted: () => {
          track("User submitted a follow up Ask a Therapist Question")
        },
      }
    )

  if (followUpQuestionIsLoading || questionIsLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const followUpQuestion = followUpQuestionData?.followUpQuestion
  const followUpResponse = followUpQuestion?.counsellorResponse
  const question = questionData?.question

  if (question == null) {
    return <P>Error fetching data</P>
  }

  const handleSubmit = async (values: AskATherapistForm) => {
    await submitAskATherapistFollowUpQuestion({
      variables: {
        question: values.question,
        counsellorId: question.counsellorId!, // cannot view this page without a counsellorId
        parentId: question.id,
      },
    })
    await refetch()
  }

  return (
    <ModalFullScreenInner
      title="Your Question"
      onClose={() => navigate("/therapy/ask-a-therapist")}
    >
      <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
        <div className="flex flex-col items-start gap-12">
          <ViewQuestionAndResponse
            question={question}
            key={question.id}
            refetchQuestion={refetchQuestion}
            showAdviceLibrary={showAdviceLibrary}
          />

          {followUpQuestion != null && followUpResponse != null ? (
            <ViewQuestionAndResponse
              key={followUpQuestion?.id}
              question={{
                ...followUpQuestionData!.followUpQuestion!,
                counsellorAvatarUrl: question.counsellorAvatarUrl,
                counsellorFirstName: question.counsellorFirstName,
                counsellorLastName: question.counsellorLastName,
                counsellorProfessionalBody: question.counsellorProfessionalBody,
                counsellorBio: question.counsellorBio,
              }}
              followUp
              refetchQuestion={refetchQuestion}
            />
          ) : (
            <div className="flex flex-col items-start gap-5 self-stretch">
              <div className="flex flex-col items-start gap-3 self-stretch">
                {followUpQuestion == null && followUpResponse == null && (
                  <>
                    <H2>Follow up</H2>
                    <P muted>
                      If there's something about the answer that you want more
                      information on, you can ask one follow up question. This
                      will be answered by the same therapist. You'll only be
                      able to ask one follow up question.
                    </P>
                  </>
                )}
              </div>
              {followUpQuestion != null && followUpResponse == null ? (
                <>
                  <div className="flex flex-col items-start gap-3 self-stretch">
                    <H2>Follow up</H2>
                    <P size="xs" weight="medium">
                      {" "}
                      Asked {formatDate(new Date(question.createdAt))}
                    </P>
                  </div>

                  <P muted>
                    {question.counsellorFirstName +
                      " " +
                      question.counsellorLastName}{" "}
                    will try to get back to you within 1-2 working days. Very
                    rarely is there a longer wait - but if there is, write to us
                    at
                    <a
                      href="mailto:hi@spill.chat"
                      target="_blank"
                      rel="noreferrer"
                      className="underline decoration-solid font-semibold pl-1 text-spill-mono-black"
                    >
                      hi@spill.chat
                    </a>
                  </P>
                  <CrisisAlert />
                </>
              ) : (
                <Form.Root {...form}>
                  <form
                    onSubmit={form.handleSubmit(handleSubmit)}
                    className="w-full"
                    id={formId}
                  >
                    <Form.Field
                      control={form.control}
                      name="question"
                      render={({ field }) => (
                        <Form.Item className="w-full">
                          <Form.Control>
                            <TextArea
                              placeholder="Can you explain this more?"
                              className="h-36"
                              {...field}
                              label={{
                                children: "Message",
                              }}
                            />
                          </Form.Control>
                          <Form.Message />
                        </Form.Item>
                      )}
                    />
                  </form>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    form={formId}
                    size="sm"
                  >
                    Submit
                  </Button>
                </Form.Root>
              )}
            </div>
          )}
        </div>
      </div>
    </ModalFullScreenInner>
  )
}
