import { Form, H3, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

import { UncontrolledCheckboxList } from "./checkListOptions"

interface SessionGoalsProps {
  checkValid: () => void
}

export const SessionGoals: FunctionComponent<SessionGoalsProps> = ({
  checkValid,
}) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const handleCheckboxChange = (
    options: { key: number; label: string; selected: boolean; value: string }[]
  ) => {
    form.setValue("preSession.sessionGoals.options", options)
    checkValid()
  }

  return (
    <div className="flex flex-col gap-3 fieldsContainer">
      <div className="flex flex-col gap-2">
        <H3>
          What are you looking to get out of the session, what is your goal?
        </H3>
        <P muted>Pick as many as apply</P>
      </div>

      <UncontrolledCheckboxList
        name="preSession.sessionGoals"
        options={form.watch("preSession.sessionGoals.options")}
        onChange={handleCheckboxChange}
      />

      <Form.Field
        control={form.control}
        name="preSession.sessionGoals"
        render={({ field: _field }) => (
          <Form.Item className="w-full">
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
