import { FunctionComponent } from "react"
import { useQuery } from "@apollo/client"
import { Button, H1, P, H4, Tooltip, HeaderCard } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { UserIcon } from "@heroicons/react/24/outline"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import ImageLifeEvents from "common/assets/images/product/specialised-support/therapy-adhd.png"
import ImageCounsellor1 from "common/assets/images/product/counsellor-7.png"
import ImageCounsellor2 from "common/assets/images/product/counsellor-8.png"
import ImageCounsellor3 from "common/assets/images/product/counsellor-9.png"
import adviceLibraryImage from "common/assets/images/product/advice-library-image.png"
import {
  AppointmentTypeFeature,
  TherapyAdhdDataQuery,
  TherapyAdhdDataQueryVariables,
  SpillSubscriptionStatus,
  TherapyAccessReason,
} from "types/graphql"
import { bookingAvailabilityMessage } from "features/therapy/pages/SessionsPage/bookingAvailabilityMessages"
import { UsageAsPercentage } from "features/therapy/pages/SessionsPage/components/UsageAsPercentage"

import { queries } from "./therapy-adhd.queries"

export const TherapyAdhdPage: FunctionComponent = () => {
  const navigate = useNavigate()

  const { data, called, loading } = useQuery<
    TherapyAdhdDataQuery,
    TherapyAdhdDataQueryVariables
  >(queries.getData)

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const user = data?.user
  const company = data?.user?.company
  const isAdviceLibraryEnabled = user?.featuresAndSettings.adviceLibrary.value

  if (!user || !company) {
    return <Navigate to="/" />
  }

  const counsellorImages = [
    ImageCounsellor1,
    ImageCounsellor2,
    ImageCounsellor3,
  ]

  const userCanRequestTherapy =
    user?.featuresAndSettings?.userCanRequestTherapy.value ?? false

  const noAccessToCompanySessions =
    user.revokedAt != null ||
    (user.therapyUsageCap === 0 && !userCanRequestTherapy)

  const hasNoSubscription =
    company?.subscriptionStatus === null && !company?.inActiveTrialPeriod

  const hasInactiveSubscription =
    company?.subscriptionStatus === SpillSubscriptionStatus.INACTIVE &&
    !company?.inActiveTrialPeriod

  const canBookOneOffs =
    user.accessToTherapyFeatures.oneOffs.at(0)?.hasAccess ?? false

  const oneOffBookingSessionMessage = bookingAvailabilityMessage(
    AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION,
    user.accessToTherapyFeatures.oneOffs.at(0)?.reason ?? TherapyAccessReason.OK
  )

  const noSubscriptionMessage = {
    title: "No active subscription",
    message: "Your company hasn't yet added their payment details.",
  }

  // Additional resources - If anything else is added to the section, update the conditional so it appears
  const additionalResourcesHasContent = isAdviceLibraryEnabled

  return (
    <>
      <Helmet title="ADHD support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col gap-4 max-w-screen-sm">
            <H1>ADHD support</H1>
            <P>You can book sessions on Spill to talk to a specialist.</P>
            <P>
              Your specialist can help you with practical tips and tools to
              manage ADHD, whether you have a diagnosis or not.
            </P>
            <P>
              If you want to, you can choose to get a diagnosis so you can get
              access to things like medication or further treatment.
            </P>
          </div>
          <div className="hidden lg:flex">
            <img
              src={ImageLifeEvents}
              alt="Someone has a Spill session using their laptop"
            />
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <H4 sectionHeader>Support</H4>
            <P weight="medium">
              You can use your Spill sessions to book coaching with an ADHD
              specialist.
            </P>
          </div>
          <UsageAsPercentage
            therapyUsageCap={user.therapyUsageCap ?? 0}
            companyTherapyCap={user.companyTherapyCap ?? 0}
            numberSessionsUsedInCapPeriod={user.numberSessionsUsedInCapPeriod}
            companyNumberSessionsUsedInBudgetYear={
              company?.budgetYearUsage?.totalBillableUsage ?? 0
            }
            companyYearlyTherapyBudget={
              user?.featuresAndSettings?.yearlyTherapyBudget?.value
            }
            companyName={company.name}
            userCanRequestTherapy={userCanRequestTherapy}
            userTherapyCapActive={
              user.featuresAndSettings.userTherapyCap.active ?? false
            }
            hasInactiveSubscription={hasInactiveSubscription}
            hasLifeEventPackage={false}
            companySessionPackLimit={
              user?.featuresAndSettings?.sessionPack.value
            }
            companySessionPackUsage={company?.currentSessionPackUsage}
            companyAllowsCourses={
              user.featuresAndSettings.courses.value ?? false
            }
            inActiveTrialPeriod={company?.inActiveTrialPeriod}
            capPeriod={company?.capPeriod}
          />
          <div className="grid grid-cols-12 gap-4">
            <Tooltip.Provider>
              {!noAccessToCompanySessions &&
                user.featuresAndSettings?.oneOffs.value === true &&
                !hasInactiveSubscription && (
                  <Tooltip.Root>
                    <div className="max-w-xs col-span-12 lg:col-span-4">
                      <HeaderCard
                        title="ADHD support session"
                        icon={<UserIcon width={24} />}
                        description="Speak to a Spill counsellor who has specific experience with ADHD"
                      >
                        <div className="flex gap-4">
                          <Tooltip.Trigger>
                            <Button
                              variant="secondary"
                              size="sm"
                              disabled={!canBookOneOffs || hasNoSubscription}
                              onClick={() => {
                                const appointmentTypeFeature =
                                  AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION
                                navigate(
                                  `/therapy/sessions/book?appointmentType=${appointmentTypeFeature}&lifeEvent=adhd`
                                )
                              }}
                            >
                              Book a session
                            </Button>
                          </Tooltip.Trigger>
                        </div>
                      </HeaderCard>
                    </div>
                    {hasNoSubscription && (
                      <Tooltip.Content
                        sideOffset={20}
                        title={noSubscriptionMessage.title}
                      >
                        <>{noSubscriptionMessage.message}</>
                      </Tooltip.Content>
                    )}
                    {!hasNoSubscription && !canBookOneOffs && (
                      <Tooltip.Content
                        sideOffset={20}
                        title={oneOffBookingSessionMessage.title}
                      >
                        <>{oneOffBookingSessionMessage.message}</>
                      </Tooltip.Content>
                    )}
                  </Tooltip.Root>
                )}
            </Tooltip.Provider>
            <div className="col-span-12 lg:col-span-4">
              <div className="bg-spill-grey-100 p-4 rounded-lg flex flex-col gap-4">
                <div className="flex items-center -space-x-2">
                  {counsellorImages.map((image, index) => {
                    return (
                      <div
                        key={index}
                        className="w-12 h-12 bg-teal-400 border-2 border-white rounded-full flex items-center justify-center uppercase tracking-wide font-bold text-xs text-teal-100"
                      >
                        <img
                          src={image}
                          alt="A parenthood counsellor"
                          className="rounded-full"
                        />
                      </div>
                    )
                  })}
                </div>
                <P weight="medium">Our ADHD specialists</P>
                <P muted size="xs">
                  Our specialists can give you practical tools and tips for
                  managing&nbsp;ADHD
                </P>
                <Button size="sm" variant="tertiary" asChild>
                  <Link
                    to="https://spill.notion.site/Spill-s-ADHD-specialists-10449f92fc6b80529e0dc34ff0e4e724?pvs=4"
                    target="_blank"
                  >
                    Learn more
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
        {additionalResourcesHasContent === true && (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <H4 sectionHeader>Additional resources</H4>
            </div>
            <div className="grid lg:grid-cols-2">
              <div className="flex rounded-lg bg-yellow-100 overflow-hidden">
                {isAdviceLibraryEnabled === true && (
                  <>
                    <div className="h-full aspect-square w-32 bg-yellow-400">
                      <img
                        src={adviceLibraryImage}
                        className="h-full"
                        alt="Advice library"
                      />
                    </div>
                    <div className="p-4 flex items-center">
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2">
                          <P weight="medium">Advice library</P>
                          <P muted size="xs">
                            Browse answers from therapists specifically
                            about&nbsp;ADHD
                          </P>
                        </div>
                        <Button size="sm" variant="tertiary" asChild>
                          <Link to="/advice-library">Browse now</Link>
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}
