import {
  Button,
  Calendar,
  Form,
  H2,
  IconCard,
  ModalFullScreenInner,
  P,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  formatISO,
  max,
  endOfHour,
  endOfDay,
  differenceInMinutes,
  parseISO,
} from "date-fns"
import { TZDate } from "@date-fns/tz"
import { toast } from "sonner"
import { inRange } from "lodash"
import { toZonedTime } from "date-fns-tz"

import { useGoBack } from "common/hooks/useGoBack"
import {
  AppointmentStatus,
  GetAppointmentRescheduleInfoQuery,
  GetAppointmentRescheduleInfoQueryVariables,
  GetAvailableAppointmentSlotsQuery,
  GetAvailableAppointmentSlotsQueryVariables,
  RescheduleTherapyAppointmentMutation,
  RescheduleTherapyAppointmentMutationVariables,
} from "types/graphql"
import { LoadingPage } from "common/components/LoadingPage"
import { ErrorPage } from "common/components/ErrorPage"
import { therapyBookingQueries } from "features/therapy-booking/therapy-booking.queries"
import { NotFoundPage } from "common/components/NotFoundPage"
import { TimeZoneInput } from "features/therapy-booking/components/therapy-booking-timezone"
import {
  appointmentTypeToBookableAppointmentType,
  getAppointmentTypeIcon,
} from "features/therapy-booking/helpers/appointmentTypeHelper"

import { therapyRescheduleQueries } from "./therapy-rescheule.queries"
import { useTherapyRescheduleForm } from "./hooks/useTherapyRescheduleForm"
import { therapyRescheduleMutations } from "./therapy-reschedule.mutations"
import { therapyRescheduleFormSchema } from "./therapy-reschedule.schema"

const spillEmail = "hi@spill.chat"

export const TherapyReschedule: FunctionComponent = () => {
  const { appointmentId } = useParams()
  const navigate = useNavigate()

  if (appointmentId === undefined) {
    navigate("/therapy/sessions")
    return
  }

  const { data: appointmentData, loading } = useQuery<
    GetAppointmentRescheduleInfoQuery,
    GetAppointmentRescheduleInfoQueryVariables
  >(therapyRescheduleQueries.getAvailableAppointmentSlots, {
    variables: {
      id: appointmentId,
    },
  })

  if (loading) {
    return <LoadingPage />
  }

  if (appointmentData === undefined) {
    toast.error(
      `We couldn't find this appointment. If the problem persists, please contact us at ${spillEmail}.`
    )
    navigate("/therapy/sessions")
    return
  }

  if (
    appointmentData.appointment === null ||
    appointmentData.appointment === undefined
  ) {
    return (
      <NotFoundPage
        className="h-1/2"
        title="Appointment not found"
        redirectButton={
          <Button asChild>
            <Link to="/therapy/sessions">Manage sessions</Link>
          </Button>
        }
      />
    )
  }

  const errorHandler = () => {
    let errorOccured = false
    let errorTitle = "Unable to reschedule this session"
    let errorDescription =
      "An error occured while trying to reschedule this session. Please try again."

    const startsAtDiffInMins = differenceInMinutes(
      parseISO(appointmentData.appointment!.startsAt),
      new Date(),
      { roundingMethod: "floor" }
    )

    if (
      [AppointmentStatus.CANCELLED, AppointmentStatus.NOSHOW].includes(
        appointmentData.appointment!.status
      )
    ) {
      errorTitle = "Session cancelled"
      errorDescription =
        "This session cannot be rescheduled as it has been cancelled."
      errorOccured = true
    }
    // Appointment started more than 50 mins ago
    else if (startsAtDiffInMins < -50) {
      errorTitle = "Session already happened"
      errorDescription =
        "This session cannot be rescheduled as it has already happened."
      errorOccured = true
    }
    // Appointment started less than 50 mins ago
    else if (inRange(startsAtDiffInMins, -50, 0)) {
      errorTitle = "Session already started"
      errorDescription =
        "This session cannot be rescheduled as it has already started."
      errorOccured = true
    }
    //Appointment starts in less than 15 mins
    else if (inRange(startsAtDiffInMins, 15, 0)) {
      errorTitle = "Session starts in less than 15 minutes"
      errorDescription =
        "This session cannot be rescheduled as it starts in less than 15 minutes. \nYou can cancel the session but we will still pay the therapist and therefore still charge your company."
      errorOccured = true
    }
    // Appointment starts in less than 24 hours
    else if (startsAtDiffInMins < 24 * 60) {
      errorTitle = "Session starts in less than 24 hours"
      errorDescription =
        "You cannot reschedule this session as it starts in less than 24 hours.\nYou can cancel the session but we will still pay the therapist and therefore still charge your company."
      errorOccured = true
    }

    if (errorOccured === false) {
      return null
    }

    // Whether video happens in our app (embeded) or directly on Whereby
    const appointmentLocationUrl = `/therapy/sessions/${appointmentData.appointment!.id}/join`

    return (
      <RescheduleErrorInfo title={errorTitle} description={errorDescription}>
        {inRange(startsAtDiffInMins, -25, 15) ? (
          <div className="flex gap-2">
            <Button asChild>
              <Link to={appointmentLocationUrl}>Join now</Link>
            </Button>
            <Button asChild variant="secondary">
              <Link to="/therapy/sessions">Manage sessions</Link>
            </Button>
          </div>
        ) : (
          <Button asChild>
            <Link to="/therapy/sessions">Manage sessions</Link>
          </Button>
        )}
      </RescheduleErrorInfo>
    )
  }

  const error = errorHandler()
  if (error !== null) {
    return error
  }

  return <TherapyRescheduleForm appointmentData={appointmentData} />
}

type TherapyRescheduleFormProps = {
  appointmentData: GetAppointmentRescheduleInfoQuery
}

const TherapyRescheduleForm: FunctionComponent<TherapyRescheduleFormProps> = ({
  appointmentData,
}) => {
  const navigate = useNavigate()
  const goBack = useGoBack()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [cacheState, setCacheState] = useState<number>(0)

  const form = useTherapyRescheduleForm({ appointmentData })

  const getDefaultStartDate = () => {
    //If a start date is not provided, use the current date
    if (appointmentData?.appointment?.startsAt === undefined) {
      return new Date()
    }

    //Return the start date of the appointment -1 day
    const startDate = new Date(appointmentData.appointment.startsAt)
    startDate.setDate(startDate.getDate() - 1)
    return startDate
  }

  const [startDate, setStartDate] = useState<Date>(getDefaultStartDate())

  if (
    appointmentData.appointment === undefined ||
    appointmentData.appointment === null
  ) {
    return <ErrorPage />
  }

  const [rescheduleAppointment] = useMutation<
    RescheduleTherapyAppointmentMutation,
    RescheduleTherapyAppointmentMutationVariables
  >(therapyRescheduleMutations.rescheduleTherapyAppointment, {
    onError: data => {
      setIsSubmitting(false)
      const spillError = data.spillError
      if (spillError.code === "FORBIDDEN") {
        toast.error(`You are not allowed to reschedule this session. 
          If this is incorrect, please get in touch at ${spillEmail}.`)
        navigate(`/therapy/sessions/`)
      } else if (spillError.code === "NOT_FOUND") {
        toast.error("We couldn't find this appointment.")
        navigate(`/therapy/sessions/`)
      } else if (spillError.code === "BAD_REQUEST") {
        //This is likely because the appointment is in the past or too close to the current time
        //This is handled on load but the user might have had the page open
        toast.error("This appointment cannot be rescheduled.")
        //Refresh the page
        navigate(
          `/therapy/sessions/${appointmentData.appointment?.id}/reschedule`
        )
      } else {
        toast.error(`An error occured while trying to reschedule this session.
          Please try again. If the problem persists, please contact us at ${spillEmail}.`)
      }
    },
    onCompleted: data => {
      setIsSubmitting(false)
      if (data.rescheduleAppointment?.id === undefined) {
        //Server didn't error but didn't return an appointment ID
        //Shouldn't happen, most likely something to do with acuity
        toast.error("An error occured while trying to reschedule this session.")
        navigate(`/therapy/sessions/`)
        return
      }

      navigate(
        `/therapy/sessions/booking-confirmation?appointmentId=${data.rescheduleAppointment.id}`
      )
    },
  })

  const onSubmit = async () => {
    // e.preventDefault()
    const mutationVars = therapyRescheduleFormSchema.safeParse(form.getValues())

    if (mutationVars.error) {
      //Issue with the form. Couldn't submit.
      //User shouldn't get this as it means the validation or mapping is missing something
      toast.error(`An error occured while trying to reschedule this session.
          Please try again. If the problem persists, please contact us at ${spillEmail}.`)
      //Refresh the page
      navigate(
        `/therapy/sessions/${appointmentData.appointment?.id}/reschedule`
      )
      return
    }

    if (mutationVars.success) {
      setIsSubmitting(true)
      await rescheduleAppointment({
        variables:
          mutationVars.data as RescheduleTherapyAppointmentMutationVariables,
      })
    }
  }

  const getSelectedTime = () => {
    return form.watch("startTime")
  }

  const [getAvailableAppointmentSlots] = useLazyQuery<
    GetAvailableAppointmentSlotsQuery,
    GetAvailableAppointmentSlotsQueryVariables
  >(therapyBookingQueries.getAvailableAppointmentSlots, {
    fetchPolicy: "network-only",
  })

  const handleFetchAvailableTimes = async (date: Date) => {
    const timeZone = form.watch("timeZone")

    //Dates in are based on the user's timezone
    const startOfDayInTZ = new TZDate(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      timeZone
    )
    const endOfDayInTZ = endOfDay(
      new TZDate(date.getFullYear(), date.getMonth(), date.getDate(), timeZone)
    )

    //Convert to UTC for the server
    const startOfDayUTC = new Date(startOfDayInTZ.toUTCString())
    const endOfDayUTC = new Date(endOfDayInTZ.toUTCString())

    const appointmentTypeRaw = appointmentData.appointment?.appointmentType
    const appointmentType =
      appointmentTypeToBookableAppointmentType(appointmentTypeRaw)
    const { data } = await getAvailableAppointmentSlots({
      variables: {
        appointmentType: appointmentType,
        // If the request start date is in the past, request from the current hour
        startDate: formatISO(max([startOfDayUTC, endOfHour(new Date())])),
        endDate: formatISO(endOfDayUTC),
        filter: {
          counsellorIds: [appointmentData.appointment!.counsellor.id],
        },
      },
    })

    //Results are in UTC time
    const availableSlots =
      data?.availableAppointmentSlots.map(slot => slot.startTime) ?? []

    //Convert them back to the user's timezone
    return availableSlots.map(slot => {
      const slotDate = new Date(slot)
      return toZonedTime(slotDate, timeZone).toISOString()
    })
  }

  const handleTimeZoneChange = (timeZone: string) => {
    setCacheState(cacheState + 1)
    form.setValue("timeZone", timeZone)
  }

  return (
    <ModalFullScreenInner
      title="Book a therapy session"
      onBack={goBack}
      onClose={goBack}
    >
      <div className="flex justify-center pt-12">
        <div className="max-w-screen-lg w-full px-8">
          <Form.Root {...form}>
            <form
              className="flex flex-col gap-8 pb-24"
              onSubmit={e => e.preventDefault()}
            >
              <div className="flex flex-col gap-2">
                <H2>Reschedule appointment</H2>
                <P muted>Choose from the available appointment slots</P>
                <TimeZoneInput onTimeZoneChange={handleTimeZoneChange} />
              </div>
              <div className="grid grid-cols-12 justify-between gap-6">
                <div className="col-span-12 md:col-span-7 lg:col-span-8 flex flex-col gap-12">
                  <div className="flex justify-center md:justify-start">
                    <div className="flex flex-col gap-4">
                      <Calendar
                        startDate={startDate}
                        selectedTime={getSelectedTime()}
                        onTimeSelect={timeSlot => {
                          form.setValue("startTime", timeSlot ?? "", {
                            shouldDirty: true,
                          })
                        }}
                        onStartDateChange={setStartDate}
                        onFetchAvailableTimes={handleFetchAvailableTimes}
                        cacheState={cacheState}
                      />
                      <Button
                        type="button"
                        variant="primary"
                        onClick={onSubmit}
                        disabled={isSubmitting}
                      >
                        Reschedule appointment
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-5 lg:col-span-4 md:pl-12 pl-0 flex justify-end md:order-last order-first">
                  <div className="w-full md:w-72 ">
                    <IconCard
                      title={<>{appointmentData.appointment.title ?? ""}</>}
                      subtitle={`${appointmentData.appointment.duration} minutes, Video call`}
                      icon={getAppointmentTypeIcon(
                        appointmentTypeToBookableAppointmentType(
                          appointmentData.appointment.appointmentType
                        )
                      )}
                    >
                      <div className="flex justify-start gap-2 w-full">
                        <P weight="medium">
                          {appointmentData.appointment.counsellor.firstName}{" "}
                          {appointmentData.appointment.counsellor.lastName}
                        </P>
                      </div>
                    </IconCard>
                  </div>
                </div>
              </div>
            </form>
          </Form.Root>
        </div>
      </div>
    </ModalFullScreenInner>
  )
}

export const RescheduleErrorInfo: FunctionComponent<{
  description: string
  title?: string
  children: React.ReactNode
}> = ({
  children,
  description,
  title = "Unable to reschedule this session",
}) => (
  <div className="flex flex-col gap-6 items-center justify-center min-h-screen p-8 text-center w-full">
    <header className="space-y-2">
      <h1 className="text-4xl">{title}</h1>
      <p className="whitespace-pre">{description}</p>
    </header>
    {children}
  </div>
)
