/**
 * Error page for the ask a therapist form
 */

import { Alert, H1, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"

import { TherapyAccessReason } from "types/graphql"
import { bookingAvailabilityMessage } from "features/therapy/pages/SessionsPage/bookingAvailabilityMessages"

interface AskATherapistErrorProps {
  featureAccessReason: TherapyAccessReason
}

export const AskATherapistError: FunctionComponent<AskATherapistErrorProps> = ({
  featureAccessReason: reason,
}) => {
  const { message } = bookingAvailabilityMessage("AskATherapist", reason)
  return (
    <>
      <Helmet title="Ask a Therapist | Spill" />

      <div className="flex flex-col gap-[inherit] items-start space-y-6 lg:space-y-8 min-h-full">
        <H1>Ask a Therapist</H1>
        <P>
          Send a message to a Spill therapist about anything that&apos;s
          weighing on your mind — a relationship issue, sleeping problems, or
          uncertainty around your life goals.
        </P>

        <P>You&apos;ll get a considered reply within 1-2 working days.</P>

        <Alert variant="warning" title="Ask a Therapist not available">
          <P>{message}</P>
        </Alert>
      </div>
    </>
  )
}
