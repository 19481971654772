import { Accordion, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

const accordionItems = [
  {
    trigger: "Is my question confidential?",
    content: (
      <P muted>
        Ask a Therapist is entirely confidential. That means we do not tell
        anyone from your company anything you share with your counsellor or
        dates and times of when you’re meeting. Because we bill on a
        pay-as-you-go basis, if you do request sessions from your company they
        will see it on their invoice. However, unless you are the only person to
        request (and include your name) or the only person on your company’s
        Spill account, they will not know who had the session.
      </P>
    ),
  },
  {
    trigger: "Does my question get posted to Advice Library?",
    content: (
      <>
        <P>
          Your question does not get posted to the Advice Library automatically.
          Once you have sent your question and received an answer, you can
          decide whether you want the question and answer to be posted
          anonymously to the Advice Library. However, this is 100% optional. The
          default is that you keep the question and answer for yourself.
        </P>
      </>
    ),
  },
  {
    trigger: "Can I share my response if I choose to?",
    content: (
      <P>
        Yes. You can choose to share your response to the Advice Library if you
        would like. In addition, you can also copy your question and response to
        share with your team members or a friend or family member.
      </P>
    ),
  },
  {
    trigger: "Can I ask follow-up questions?",
    content: (
      <>
        <P>
          Yes. By default, you will be able to ask one follow-up question to the
          same therapist that answered your first question. If you still want to
          ask more questions beyond the initial follow-up question, you can ask
          a new question and select the same therapist to answer it.
        </P>
      </>
    ),
  },
]

export const FAQAskATherapist: FunctionComponent = () => {
  return <Accordion items={accordionItems} />
}
