import { Breadcrumbs, Form, ModalFullScreenInner } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import { toast } from "sonner"

import { useGoBack } from "common/hooks/useGoBack"
import {
  BookableAppointmentType,
  BookTherapyAppointmentMutation,
  BookTherapyAppointmentMutationVariables,
  GetUserAppointmentInfoQuery,
  GetUserAppointmentInfoQueryVariables,
} from "types/graphql"
import { LoadingPage } from "common/components/LoadingPage"
import { ErrorPage } from "common/components/ErrorPage"

import { therapyBookingFormSchema } from "./therapy-booking.schema"
import { therapyBookingQueries } from "./therapy-booking.queries"
import { therayBookingMutations } from "./therapy-booking.mutations"
import { TherapyBookingSummaryCardProps } from "./components/therapy-booking-summary-card"
import { TherapyBookingAppointmentBookingTab } from "./tabs/therapy-booking-appointment-booking-tab"
import { TherapyBookingClinicalTab } from "./tabs/therapy-booking-clinical-tab"
import { TherapyBookingPersonalTab } from "./tabs/therapy-booking-personal-tab"
import { TherapyBookingPreSessionTab } from "./tabs/therapy-booking-preSession-tab"
import { useTherapyBookingForm } from "./hooks/useTherapyBookingForm"
const spillEmail = "hi@spill.chat"

export const TherapyBooking: FunctionComponent = () => {
  const [searchParams] = useSearchParams()
  const appointmentTypeSearchParam = searchParams.get("appointmentType")
  const appointmentType = appointmentTypeSearchParam as BookableAppointmentType

  const { data: userData, loading } = useQuery<
    GetUserAppointmentInfoQuery,
    GetUserAppointmentInfoQueryVariables
  >(therapyBookingQueries.getUserInfo, {
    variables: {
      appointmentType: appointmentType,
    },
  })

  if (loading) {
    return <LoadingPage />
  }

  if (userData === undefined) {
    return <ErrorPage />
  }

  return (
    <TherapyBookingForm userData={userData} appointmentType={appointmentType} />
  )
}

type TherapyBookingFormProps = {
  userData: GetUserAppointmentInfoQuery
  appointmentType: BookableAppointmentType
}

const TherapyBookingForm: FunctionComponent<TherapyBookingFormProps> = ({
  userData,
  appointmentType,
}) => {
  const navigate = useNavigate()
  const goBack = useGoBack()
  const [step, setStep] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [firstDay, setFirstDay] = useState(new Date())

  //This is a flag which if true, the user has filled out the form, tried to book, been sent back to pick a new time.
  //Therefore they don't have to go through the whole process again, they can book straight from this tab once they pick a new time.
  const [allowedToBookFromCalendar, setAllowedToBookFromCalendar] =
    useState<boolean>(false)

  const tabs = [
    { label: "Find a slot", isActive: step >= 0, step: 0 },
    { label: "About you", isActive: step >= 1, step: 1 },
    { label: "Pre-session form", isActive: step >= 2, step: 2 },
    { label: "Book", isActive: step >= 3, step: 3 },
    { label: "Confirmation", isActive: step >= 4, step: 4 },
  ]

  const form = useTherapyBookingForm({ userData })
  const [bookAppointment] = useMutation<
    BookTherapyAppointmentMutation,
    BookTherapyAppointmentMutationVariables
  >(therayBookingMutations.bookAppointment, {
    onError: data => {
      setSubmitting(false)
      const spillError = data.spillError
      if (spillError.code === "FORBIDDEN") {
        //User is trying to book something they are not allowed to book.
        //Most likely because admin changed settings while they are trying to book
        toast.error(`You are not allowed to book this session type. 
          If this is incorrect, please get in touch at ${spillEmail}.`)
        navigate(`/therapy/sessions/`)
      } else if (spillError.subCode === "NO_AVAILABLE_COUNSELLORS") {
        //The user needs to pick another timeslot
        toast.error(
          "Sorry this time slot is no longer available. Please pick a new timeslot.",
          {
            duration: 5000,
          }
        )
        setAllowedToBookFromCalendar(true)
        handleEditBooking()
      } else if (spillError.subCode === "INVALID_USER_PROFILE") {
        //The form is missing something. Shouldn't happen, so user should get in touch
        toast.error(
          `We seem to be missing some information. Please check everything has been filled out. 
          If you are still having trouble, please get in touch at ${spillEmail}.`,
          {
            duration: 10000,
            dismissible: true,
          }
        )
      } else if (
        spillError.subCode === "ACUITY_BOOKING_SUCCESS_APPOINTMENT_FAILED"
      ) {
        //The booking was successful but the appointment failed. This is a server issue
        //The appointment should be booked but we can't show the confirmation page
        toast.warning(
          <>
            We have successfully booked your appointment but there was an issue
            displaying your confirmation.
            <br />
            Please check back here later to see your appointment details.
            <br />
            If they don't show up, please get in touch at {spillEmail}.
          </>,
          {
            duration: 10000,
          }
        )
        navigate(`/therapy/sessions/`)
      } else {
        toast.error(
          `Something went wrong, please try again. 
          If the issue persists, please get in touch at ${spillEmail}.`
        )
      }
    },
    onCompleted: data => {
      setSubmitting(false)
      //Redirct to confirmation page
      if (data?.bookAppointment?.id === undefined) {
        //Success but no Appointment ID? Server failed but didn't throw an error
        toast.error(
          `Something went wrong, please try again. 
        If the issue persists, please get in touch at ${spillEmail}.`
        )
        return
      }

      navigate(
        `/therapy/sessions/booking-confirmation?appointmentId=${data.bookAppointment.id}`
      )
    },
    refetchQueries: ["GetAvailableAppointmentSlots"],
  })

  const handleEditBooking = () => {
    setStep(0)
  }

  const summaryCardProps: TherapyBookingSummaryCardProps = {
    appointmentType: appointmentType,
    onEditBooking: handleEditBooking,
    bookingTypeName: userData.appointmentTypeInformation.title,
    bookingTypeDescription: userData.appointmentTypeInformation.description,
  }

  const onSubmit = async () => {
    setSubmitting(true)

    const mutationVars = therapyBookingFormSchema.safeParse(form.getValues())

    if (mutationVars.error) {
      //Issue with the form. Couldn't submit.
      //User shouldn't get this as it means the validation or mapping is missing something
      toast.error(
        `We seem to be missing some information. Please check everything has been filled out. 
        If you are still having trouble, please get in touch at ${spillEmail}.`,
        {
          duration: 10000,
        }
      )
      setSubmitting(false)
      return
    }

    if (mutationVars.success) {
      await bookAppointment({
        variables: mutationVars.data as BookTherapyAppointmentMutationVariables,
      })
    }
  }

  const handleFirstDayChange = (date: Date) => {
    setFirstDay(date)
  }

  return (
    <ModalFullScreenInner
      title="Book a therapy session"
      showBackButton={step > 0}
      onBack={() => setStep(step - 1)}
      onClose={goBack}
    >
      <div className="flex justify-center">
        <div className="max-w-screen-xl w-full px-8">
          <Form.Root {...form}>
            <form
              className="flex flex-col gap-12 pb-24"
              onSubmit={e => e.preventDefault()}
            >
              <Breadcrumbs
                tabs={tabs}
                onStepChange={setStep}
                currentStep={step}
              ></Breadcrumbs>
              {step === 0 && (
                <TherapyBookingAppointmentBookingTab
                  firstDay={firstDay}
                  onFirstDayChange={handleFirstDayChange}
                  allowedToBookFromCalendar={allowedToBookFromCalendar}
                  onSubmit={onSubmit}
                  appointmentType={appointmentType}
                  onNext={() => setStep(step + 1)}
                />
              )}
              {step === 1 && (
                <TherapyBookingPersonalTab
                  summaryCardProps={summaryCardProps}
                  onEditBooking={() => setStep(0)}
                  onNext={() => setStep(step + 1)}
                />
              )}
              {step === 2 && (
                <TherapyBookingClinicalTab
                  summaryCardProps={summaryCardProps}
                  onEditBooking={() => setStep(0)}
                  onNext={() => setStep(step + 1)}
                />
              )}
              {step === 3 && (
                <TherapyBookingPreSessionTab
                  isSubmitting={submitting}
                  summaryCardProps={summaryCardProps}
                  onEditBooking={() => setStep(0)}
                  onNext={onSubmit}
                />
              )}
            </form>
          </Form.Root>
        </div>
      </div>
    </ModalFullScreenInner>
  )
}
