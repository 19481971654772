import { isEmpty, omitBy } from "lodash"

import {
  AvailableAppointmentSlotsFilter,
  BookableAppointmentType,
} from "types/graphql"

import { TherapyBookingFormSchema } from "../hooks/useTherapyBookingForm"

export const transformFiltersForGQL = (
  filter: TherapyBookingFormSchema["filter"],
  appointmentType: BookableAppointmentType
): AvailableAppointmentSlotsFilter => {
  const pmiOnlySession = appointmentType === BookableAppointmentType.PMI_SESSION
  const previousCounsellorSelected =
    filter.previousCounsellorId != null &&
    filter.previousCounsellorId != "" &&
    filter.previousCounsellorId != undefined

  const counsellorIdsInUrl = (filter?.counsellorIds?.length ?? 0) > 0
  return {
    ...omitBy(
      {
        counsellorIds: counsellorIdsInUrl
          ? filter.counsellorIds
          : previousCounsellorSelected
            ? [filter.previousCounsellorId]
            : null,
        gender: previousCounsellorSelected ? null : filter.gender,
        languageCode: previousCounsellorSelected ? null : filter.language,
        specialism: previousCounsellorSelected ? null : filter.specialism,
        lifeEvent: filter.lifeEvent,
      },
      isEmpty
    ),
    pmiOnly: pmiOnlySession
      ? true
      : previousCounsellorSelected
        ? undefined
        : filter.pmiOnly,
  }
}
