import { useQuery } from "@apollo/client"
import { H2, Indicator, ModalFullScreenInner, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"

import { InviteSlackGetChannelsQuery } from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useGoBack } from "common/hooks/useGoBack"

import { queries } from "./invite-slack.queries"

export const InviteSlack: FunctionComponent = () => {
  const goBack = useGoBack({ isAdmin: true })

  const { data, loading } = useQuery<InviteSlackGetChannelsQuery>(
    queries.getChannels,
    {
      fetchPolicy: "cache-first",
    }
  )

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const channels = new Set(
    data?.user?.company?.platforms
      .filter(platform => platform.groups.length > 0)
      .map(platform => platform.groups.map(group => group.name))
      .flat()
  )

  const slackName = data?.user?.company?.platforms
    .filter(platform => platform.groups.length > 0)
    .map(platform => platform.name)

  return (
    <>
      <Helmet title="Invite via Slack | Spill" />

      <ModalFullScreenInner title="Invite via Slack" onClose={() => goBack()}>
        <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
          <div className="max-w-lg mx-auto w-full">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <H2>Invite team members via&nbsp;Slack</H2>
                <P>
                  To invite people to Spill via Slack simply add people to one
                  of the the following channels in the{" "}
                  <strong>{slackName} Slack</strong>&nbsp;workspace
                </P>
              </div>
              {channels.size > 0 && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Channels</P>
                  <div className="flex flex-wrap items-center gap-2">
                    {Array.from(channels.values()).map(channel => {
                      return (
                        <Indicator variant="ok" key={channel}>
                          #{channel}
                        </Indicator>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
