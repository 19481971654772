import { getCountryForTimezone } from "countries-and-timezones"
import {
  CountryCallingCode,
  CountryCode,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js"

type PhoneNumberWithCountryCode = {
  phoneNumber: string
  countryInfo: CountryCodeMapped
}

export type CountryCodeMapped = {
  countryCode: CountryCode
  phonePrefix: CountryCallingCode
}

export const phoneCountryCodes: CountryCodeMapped[] = getCountries()
  .map(country => {
    return {
      countryCode: country,
      phonePrefix: getCountryCallingCode(country),
    }
  })
  .sort((a, b) => +a.phonePrefix - +b.phonePrefix)
  .filter((a, i, arr) => {
    return arr.findIndex(b => b.phonePrefix === a.phonePrefix) === i
  })

const extractPhoneCountryCode = (
  number: string
): CountryCodeMapped | undefined => {
  const possibleDDIs: {
    countryCode: CountryCode
    phonePrefix: CountryCallingCode
  }[] = []

  phoneCountryCodes.forEach(country => {
    if (number.startsWith(country.phonePrefix)) {
      possibleDDIs.push(country)
    }
  })

  return possibleDDIs[0]
}

export const getCountryCodeFromTimezone = (
  timeZone: string,
  fallbackCountryCode: CountryCode = "GB"
): CountryCode => {
  const currentCountry = getCountryForTimezone(timeZone)?.id
  const currentCountryCode = phoneCountryCodes.find(
    country => country.countryCode === currentCountry
  )?.countryCode

  return currentCountryCode ?? fallbackCountryCode
}

export const extractPhoneNumberAndCountryCode = (
  rawNumber: string,
  appTimeZone?: string
): PhoneNumberWithCountryCode => {
  //Sometimes numbers have a + at the start due to legacy reasons.
  //These will be replaced as they get next submitted in the form
  const number = rawNumber.startsWith("+") ? rawNumber.substring(1) : rawNumber

  const countryCodeMapped = extractPhoneCountryCode(number)

  //If a country code is found, return the phone number without the country code
  if (countryCodeMapped) {
    return {
      phoneNumber: number.substring(countryCodeMapped.phonePrefix.length),
      countryInfo: countryCodeMapped,
    }
  }

  //If the app time zone is provided, get the country code for the time zone
  if (appTimeZone !== undefined) {
    const currentCountry = getCountryForTimezone(appTimeZone)?.id
    const currentCountryCode = phoneCountryCodes.find(
      country => country.countryCode === currentCountry
    )?.countryCode

    if (currentCountryCode !== undefined) {
      //If no country code is found, return the phone number and undefined for the country code
      return {
        phoneNumber: number,
        countryInfo: {
          countryCode: currentCountryCode,
          phonePrefix: getCountryCallingCode(currentCountryCode),
        },
      }
    }
  }

  //No country code found on the timezone or the phone number.
  //Default to UK. Last resort - Front end cannot handle undefined country codes
  //(Unlikely to happen as the app time zone should always be provided)
  return {
    phoneNumber: number,
    countryInfo: {
      countryCode: "GB",
      phonePrefix: getCountryCallingCode("GB"),
    },
  }
}
