import { gql } from "@apollo/client"

export const mutations = {
  toggleAdhdSupport: gql(`
    mutation InviteAdhdToggle($packageSettingId: ID!, $state: PackageState!) {
      toggleCompanyPackageSettingsState(packageSettingId: $packageSettingId, state: $state) {
        id
        state
      }
    }
  `),
  updateAdhdCoPayAddon: gql(`
    mutation InviteAdhdUpdateCoPay($enabled: Boolean!, $companyContribution: Float!) {
      createOrUpdateCoPayAddOn(enabled: $enabled, companyContribution: $companyContribution) {
        id
        properties {
          enabled
          companyContribution
        }
      }
    }
  `),
}
