import { gql } from "@apollo/client"

export const therapyRescheduleMutations = {
  rescheduleTherapyAppointment: gql`
    mutation RescheduleTherapyAppointment(
      $id: ID!
      $counsellorId: ID!
      $startsAt: String!
    ) {
      rescheduleAppointment(
        id: $id
        counsellorId: $counsellorId
        startsAt: $startsAt
      ) {
        id
      }
    }
  `,
}
