import { gql } from "@apollo/client"

export const mutations = {
  signUpBuyer: gql`
    mutation QuoteSignupCompany(
      $displayName: String!
      $email: String!
      $companyName: String!
      $subscriptionPlan: SpillSubscriptionPlan
    ) {
      signUpBuyer(
        displayName: $displayName
        email: $email
        companyName: $companyName
        subscriptionPlan: $subscriptionPlan
      ) {
        deviceId
        preAuthSessionId
      }
    }
  `,
  createCompanyTherapyQuote: gql`
    mutation QuoteSignupNeeds(
      $therapyNeeds: [String!]!
      $numberEmployees: Int
      $recommendedPlan: SpillSubscriptionPlan!
    ) {
      createCompanyTherapyQuote(
        therapyNeeds: $therapyNeeds
        numberEmployees: $numberEmployees
        recommendedPlan: $recommendedPlan
      )
    }
  `,
  updateCompanySessionPack: gql`
    mutation QuoteSignupUpdateCompanySessionPack($sessionPack: Int!) {
      updateSessionPack(sessionPackSize: $sessionPack) {
        sessionPackSize
      }
    }
  `,
}
