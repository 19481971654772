import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Breadcrumbs,
  Button,
  Form,
  H2,
  Label,
  ModalFullScreenInner,
  P,
  Select,
  SelectableCard,
  Tabs,
  TextArea,
  Tooltip,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useFieldArray, useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom"
import { toast } from "sonner"
import { addMonths, format } from "date-fns"

import {
  AdminTherapySpecialisedSupportUserListQuery,
  AccountStatus,
  PackageType,
  AdminTherapySpecialisedSupportEnrolMutation,
  AdminTherapySpecialisedSupportEnrolMutationVariables,
  AdminTherapySpecialisedSupportPackageQuery,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  formatEmailParameters,
  useSendEmail,
} from "common/hooks/api/useSendEmail"
import { useGoBack } from "common/hooks/useGoBack"
import {
  AdminTherapySpecialisedSupportEnrolTable,
  AdminTherapySpecialisedSupportEnrolTableRow,
} from "features/admin-therapy/components/AdminTherapySpecialisedSupportEnrolTable"

import { mutations } from "./admin-therapy-specialised-support-enrol.mutations"
import { queries } from "./admin-therapy-specialised-support-enrol.queries"
import { formSchema } from "./admin-therapy-specialised-support-enrol.schema"

import type { AdminTherapySpecialisedSupportForm } from "./admin-therapy-specialised-support-enrol.schema"

export const AdminTherapySpecialisedSupportEnrol: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const { packageId } = useParams()
  const goBack = useGoBack({ isAdmin: true })
  const [step, setStep] = useState(0)

  const { data: userData, loading: userLoading } =
    useQuery<AdminTherapySpecialisedSupportUserListQuery>(queries.getUserList, {
      fetchPolicy: "cache-first",
    })

  const { data: packageData, loading: packageLoading } =
    useQuery<AdminTherapySpecialisedSupportPackageQuery>(queries.getPackage, {
      variables: {
        id: packageId,
      },
    })

  const form = useForm<AdminTherapySpecialisedSupportForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userId: "",
      selectedIds: [],
      notificationType: "none",
      note: "",
    },
  })

  const { prepend, remove } = useFieldArray({
    control: form.control,
    name: "selectedIds",
  })

  const [enrolEmployee, { loading }] = useMutation<
    AdminTherapySpecialisedSupportEnrolMutation,
    AdminTherapySpecialisedSupportEnrolMutationVariables
  >(mutations.enrolEmployee, {
    refetchQueries: ["AdminTherapySpecialisedSupport"],
  })

  const [emailUser, emailUserResult] = useSendEmail()

  if (isUserLoading || userLoading || packageLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const expirationDate =
    packageData?.getCompanyPackageSettingById?.numberMonthsToUse != null
      ? addMonths(
          new Date(),
          packageData?.getCompanyPackageSettingById?.numberMonthsToUse
        ).toISOString()
      : addMonths(new Date(), 6).toISOString()

  const sendTestEmail = async () => {
    const primaryEmail = userData?.user?.primaryEmail

    if (primaryEmail != undefined && primaryEmail !== "") {
      await emailUser({
        variables: {
          emails: [primaryEmail],
          templateName: "support-package-notification",
          parameters: formatEmailParameters({
            adminNote: form.getValues("note"),
            packageName: packageData?.getCompanyPackageSettingById?.name,
            packageDescription:
              packageData?.getCompanyPackageSettingById?.userFacingDescription,
            expirationDate:
              packageData?.getCompanyPackageSettingById?.numberMonthsToUse !=
              null
                ? format(
                    addMonths(
                      new Date(),
                      packageData.getCompanyPackageSettingById.numberMonthsToUse
                    ),
                    "MMM yyyy"
                  )
                : null,
            numberOfSessions:
              packageData?.getCompanyPackageSettingById?.numberSessions,
          }),
        },
      })

      return toast.info(`An email has been sent to ${primaryEmail ?? "you"}`)
    }

    return toast.error("Unable to find your email")
  }

  const handleRecipient = () => setStep(1)

  const handleSubmit = async (values: AdminTherapySpecialisedSupportForm) => {
    const userFromList = userData?.user?.company?.users.find(user => {
      return user.id === values.userId
    })

    const email = userFromList?.platformUsers[0]?.email

    await enrolEmployee({
      variables: {
        userIds: values.selectedIds.map(obj => obj.id),
        packageType: PackageType.CUSTOM,
        numberOfSessions:
          packageData?.getCompanyPackageSettingById?.numberSessions ?? 6.5,
        adminNote: values.note,
        companyPackageSettingId: packageData?.getCompanyPackageSettingById?.id,
        expirationDate,
      },
    })

    if (values.notificationType === "email") {
      if (email === undefined || email === null) {
        return toast.error("Unable to send email to this user")
      }

      await emailUser({
        variables: {
          emails: [email],
          templateName: "support-package-notification",
          parameters: formatEmailParameters({
            adminNote: form.getValues("note"),
            packageName: packageData?.getCompanyPackageSettingById?.name,
            packageDescription:
              packageData?.getCompanyPackageSettingById?.userFacingDescription,
            expirationDate:
              packageData?.getCompanyPackageSettingById?.numberMonthsToUse !=
              null
                ? format(
                    addMonths(
                      new Date(),
                      packageData.getCompanyPackageSettingById.numberMonthsToUse
                    ),
                    "MMM yyyy"
                  )
                : null,
            numberOfSessions:
              packageData?.getCompanyPackageSettingById?.numberSessions,
          }),
        },
      })
    }

    return setStep(2)
  }

  const userList = userData?.user?.company?.users.filter(user => {
    return (
      user.platformUsers[0]?.email !== null &&
      user.platformUsers[0]?.email !== undefined &&
      user.accountStatus === AccountStatus.ACTIVE
    )
  })

  const isSelected = (id: string) => {
    return form.getValues("selectedIds").some(obj => obj.id === id)
  }

  const tableList: AdminTherapySpecialisedSupportEnrolTableRow[] | undefined =
    userList
      ?.filter(list => isSelected(list.id))
      .map(item => {
        return {
          id: item.id,
          userDisplayName: item.fullName,
          numberOfSessions:
            packageData?.getCompanyPackageSettingById?.numberSessions ?? 6.5,
          expirationDate,
        }
      })

  const selectedIdsEmpty = form.getValues("selectedIds").length <= 0
  const userListEmpty = (userList ?? []).length <= 0
  const isLoading = loading || emailUserResult.loading

  const tabs = [
    { label: "Recipients", isActive: step >= 0, step: 0 },
    { label: "Settings", isActive: step >= 1, step: 1 },
    { label: "Confirmation", isActive: step >= 2, step: 2 },
  ]

  return (
    <>
      <Helmet title="Enrol employees | Spill" />
      <ModalFullScreenInner title="Enrol employees" onClose={() => goBack()}>
        <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
          <Tabs.Root
            defaultValue={step.toString()}
            value={step.toString()}
            className="flex flex-col gap-6 lg:gap-12"
          >
            <Form.Root {...form}>
              <Breadcrumbs
                onStepChange={setStep}
                currentStep={step}
                tabs={tabs}
              ></Breadcrumbs>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Tabs.Content value="0">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>Who do you want to support?</H2>
                      <P>
                        Choose who in your team has access to this custom
                        support package.
                      </P>
                    </div>
                    <div className="flex flex-col gap-4">
                      <Form.Field
                        control={form.control}
                        name="userId"
                        render={({ field }) => (
                          <Form.Item>
                            <Form.Control>
                              <Select.Root
                                {...field}
                                disabled={userListEmpty}
                                onValueChange={id => {
                                  if (isSelected(id)) {
                                    return
                                  }

                                  prepend({ id })
                                }}
                              >
                                <Select.Trigger>
                                  <Select.Value
                                    placeholder={
                                      !userListEmpty
                                        ? "Select employee"
                                        : "No employees available"
                                    }
                                  />
                                </Select.Trigger>
                                <Select.Content side="bottom">
                                  {userList
                                    ?.filter(user => {
                                      return !isSelected(user.id)
                                    })
                                    .map(user => {
                                      return (
                                        <Select.Item
                                          key={user.id}
                                          value={user.id}
                                        >
                                          {user.fullName}
                                          {user.platformUsers[0]?.email !==
                                            undefined &&
                                            ` (${user.platformUsers[0]?.email})`}
                                        </Select.Item>
                                      )
                                    })}
                                </Select.Content>
                              </Select.Root>
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </div>
                    {tableList && tableList.length > 0 && (
                      <AdminTherapySpecialisedSupportEnrolTable
                        data={tableList}
                        onRemove={value => remove(value)}
                      />
                    )}
                    <div className="flex justify-between items-center">
                      <Button
                        disabled={selectedIdsEmpty}
                        onClick={() => handleRecipient()}
                        variant="primary"
                      >
                        Next
                      </Button>
                      <Tooltip.Provider>
                        <Tooltip.Root>
                          <Tooltip.Trigger
                            className="cursor-help"
                            type="button"
                          >
                            <Button variant="tertiary" size="sm" asChild>
                              <Link to="/admin/access">
                                {!userListEmpty
                                  ? "Employee not in list?"
                                  : "Invite your team to Spill"}
                              </Link>
                            </Button>
                          </Tooltip.Trigger>

                          <Tooltip.Content>
                            <div className="flex flex-col gap-1 text-spill-mono-black">
                              <P>
                                Invite them to Spill first via the Support page
                              </P>
                              <P muted size="xs">
                                You can find the Support page in the Therapy tab
                                in the sidebar on the left
                              </P>
                            </div>
                          </Tooltip.Content>
                        </Tooltip.Root>
                      </Tooltip.Provider>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value="1">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <H2>How do you want to let them know?</H2>
                      <P>Choose how you want the support to be announced.</P>
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label>Notification</Label>
                      <div>
                        <Form.Field
                          control={form.control}
                          name="notificationType"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <div className="grid lg:grid-cols-2 gap-4">
                                  {[
                                    {
                                      title: "Email notification",
                                      subtitle: "Spill will email them",
                                      value: "email",
                                    },
                                    {
                                      title: "I'll tell them",
                                      subtitle: "Spill won't contact them",
                                      value: "none",
                                    },
                                  ].map(item => {
                                    return (
                                      <SelectableCard
                                        key={item.value}
                                        size="sm"
                                        checked={field.value === item.value}
                                        title={item.title}
                                        subtitle={item.subtitle}
                                        type="radio"
                                        {...form.register("notificationType")}
                                        value={item.value}
                                        onClick={() => {
                                          form.setValue(
                                            "notificationType",
                                            item.value as "email" | "none"
                                          )
                                        }}
                                      />
                                    )
                                  })}
                                </div>
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                    </div>
                    {form.getValues("notificationType") === "email" && (
                      <>
                        <Form.Field
                          control={form.control}
                          name="note"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <TextArea
                                  placeholder="Write text here..."
                                  className="h-48"
                                  {...field}
                                  label={{
                                    children:
                                      "Add a note to their email notification (optional)",
                                  }}
                                />
                              </Form.Control>
                              <Form.Message />
                              <div
                                className="underline cursor-pointer"
                                onKeyDown={async e => {
                                  if (e.key === "Enter") {
                                    await sendTestEmail()
                                  }
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={async () => await sendTestEmail()}
                              >
                                <P>
                                  Send a test email to yourself to see what it
                                  looks like
                                </P>
                              </div>
                            </Form.Item>
                          )}
                        />
                      </>
                    )}
                    <div className="flex items-center gap-4">
                      <Button
                        onClick={() => setStep(step - 1)}
                        variant="secondary"
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        loading={isLoading}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value="2">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>
                        {form.getValues("selectedIds").length}{" "}
                        {form.getValues("selectedIds").length === 1
                          ? "employee has"
                          : "employees have"}{" "}
                        been enrolled in{" "}
                        {packageData?.getCompanyPackageSettingById?.name}.
                      </H2>
                      <P>
                        We've added your employees to this package. They'll be
                        able to use those sessions when they go to their Spill
                        account.
                      </P>
                    </div>
                    <Button asChild>
                      <Link
                        to={`/admin/therapy/specialised-support/${packageData?.getCompanyPackageSettingById?.id}`}
                      >
                        Finish
                      </Link>
                    </Button>
                  </div>
                </Tabs.Content>
              </form>
            </Form.Root>
          </Tabs.Root>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
