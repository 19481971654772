import { Tabs, H1 } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"

import { FeedbackPage } from "./admin-engagement-feedback-page.tsx.js"
import { UsagePage } from "./admin-engagement-usage-page.js"

export const EngagementPage: React.FC = () => {
  return (
    <>
      <Helmet title="Engagement | Spill" />
      <H1>Engagement</H1>
      <Tabs.Root defaultValue="usage">
        <Tabs.List className="my-8">
          <Tabs.Trigger value="usage">Usage</Tabs.Trigger>
          <Tabs.Trigger value="feedback">Feedback</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="usage">
          <UsagePage />
        </Tabs.Content>
        <Tabs.Content value="feedback">
          <FeedbackPage />
        </Tabs.Content>
      </Tabs.Root>
    </>
  )
}
