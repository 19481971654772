import { FunctionComponent } from "react"
import { H2, P } from "@spillchat/puddles"
import { useMutation, useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { toast } from "sonner"

import { formatCurrency } from "common/helpers/formatNumber"
import {
  UpgradePlanGetUserQuery,
  UpgradeToTeamPlanMutation,
  UpgradeToTeamPlanMutationVariables,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

import { mutations } from "./UpgradePlan.mutations"
import { queries } from "./UpgradePlan.queries"
import { UpgradePlanItem } from "./UpgradePlanItem"

type UpgradePlanModalProps = {
  onClose: () => void
}

export const UpgradePlanModal: FunctionComponent<{ onClose: () => void }> = ({
  onClose,
}: UpgradePlanModalProps) => {
  const { data, loading: userLoading } = useQuery<UpgradePlanGetUserQuery>(
    queries.getUser,
    {
      fetchPolicy: "cache-first",
    }
  )

  const currentPrice = formatCurrency(
    data?.user?.company?.planPrice?.perSession ?? 90
  )
  const [upgradeToTeamPlan, { loading: upgradeToTeamPlanLoading }] =
    useMutation<UpgradeToTeamPlanMutation, UpgradeToTeamPlanMutationVariables>(
      mutations.upgradeToTeamPlan
    )

  const upgradePlan = async () => {
    try {
      await upgradeToTeamPlan()

      window.location.reload()
    } catch {
      toast.error("Unable to upgrade your plan - please contact hi@spill.chat")
    }
  }

  if (userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col items-center gap-12 max-w-screen-lg md:px-12 px-4 mx-auto py-12 max-h-screen overflow-scroll">
        <div className="text-center">
          <H2>Pricing that's designed to&nbsp;be&nbsp;simple</H2>
        </div>
        <div className="grid md:grid-cols-2 w-full gap-4">
          <UpgradePlanItem
            plan={{
              title: "Starter Plan",
              subtitle:
                "Support an employee who's struggling with their mental health",
              formattedPrice: "£0",
              priceDescription: (
                <P size="xs">
                  <span className="font-semibold">+ pay-as-you-go</span> at{" "}
                  {currentPrice} / session
                </P>
              ),
              buttonText: "Stay on Starter Plan",
              buttonVariant: "secondary",
              buttonDisabled: false,
              handleButtonClick: () => onClose(),
              features: {
                title: "Starter Plan features:",
                list: [
                  "Access to courses of therapy",
                  "Next-day sessions with a professional",
                  "Choose an allowance of 4, 6 or 8 sessions",
                ],
              },
            }}
          />
          <UpgradePlanItem
            plan={{
              title: "Team Plan",
              subtitle:
                "Turn on Spill mental health support for your whole company",
              formattedPrice: "£5",
              priceDescription: (
                <P size="xs">
                  <span className="font-semibold">+ pay-as-you-go</span> at £65
                  / session and £20 / Ask a Therapist
                </P>
              ),
              buttonText: "Switch to Team Plan",
              buttonVariant: "primary",
              handleButtonClick: async () => await upgradePlan(),
              buttonDisabled: upgradeToTeamPlanLoading,
              features: {
                title: "Everything in Starter Plan, plus:",
                list: [
                  "Access to one-off sessions",
                  "Library of professional mental health advice",
                  "Easy access via Slack or MS Teams",
                  "Proactive check ins",
                ],
              },
            }}
          />
        </div>
        <div className="text-center">
          <P>
            Questions? Read more about how the Team Plan works{" "}
            <Link
              className="underline"
              to="https://spill.notion.site/Spill-s-Team-Plan-b2e9e85d19de43b1878f363d03b0a25f"
              target="_blank"
            >
              here
            </Link>{" "}
            or book a call to speak with somebody{" "}
            <Link
              className="underline"
              to="https://www.spill.chat/book-demo"
              target="_blank"
            >
              here
            </Link>
            .
          </P>
        </div>
      </div>
    </>
  )
}
