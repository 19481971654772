/**
 * Allows the user to set the total amount for each user
 */

import { H4, P, Alert, SelectableCard, Tooltip } from "@spillchat/puddles"
import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { z } from "zod"

import { SpillSubscriptionPlan } from "types/graphql"

import { BillingFormValues } from "."

interface TherapyTimeCapProps {
  userTherapyCap?:
    | {
        active?: boolean | undefined
        value?: number | null | undefined
      }
    | null
    | undefined
  upcomingCapResetDate?: string | null
  capPeriod?: number | null
  subscriptionPlan?: SpillSubscriptionPlan | null
}

/**
 * Available options for the session cap based on the subscription plan
 */
const sessionCapOptions = {
  [SpillSubscriptionPlan.STARTER]: [
    { value: "2", label: "2 sessions", description: "Upgrade to access" },
    { value: "4", label: "4 sessions", description: "Upgrade to access" },
    { value: "6", label: "6 sessions", description: "Upgrade to access" },
    { value: "8", label: "8 sessions", description: "Upgrade to access" },
    { value: null, label: "Unlimited", description: "Upgrade to access" },
  ],
  [SpillSubscriptionPlan.TEAM]: [
    { value: "2", label: "2 sessions", description: "Max per employee" },
    { value: "4", label: "4 sessions", description: "Max per employee" },
    { value: "6", label: "6 sessions", description: "Max per employee" },
    { value: "8", label: "8 sessions", description: "Max per employee" },
    { value: null, label: "Unlimited", description: "No maximum" },
  ],
  [SpillSubscriptionPlan.LITE]: [
    // this is not shown but we need to add it for typing
    { value: "2", label: "2 sessions", description: "Max per employee" },
    { value: "4", label: "4 sessions", description: "Max per employee" },
    { value: "6", label: "6 sessions", description: "Max per employee" },
    { value: "8", label: "8 sessions", description: "Max per employee" },
    { value: null, label: "Unlimited", description: "No maximum" },
  ],
  [SpillSubscriptionPlan.PROPER]: [
    // this is not shown but we need to add it for typing
    { value: "2", label: "2 sessions", description: "Max per employee" },
    { value: "4", label: "4 sessions", description: "Max per employee" },
    { value: "6", label: "6 sessions", description: "Max per employee" },
    { value: "8", label: "8 sessions", description: "Max per employee" },
    { value: null, label: "Unlimited", description: "No maximum" },
  ],
  [SpillSubscriptionPlan.ESSENTIAL]: [
    { value: "2", label: "2 sessions", description: "Max per employee" },
    {
      value: "4",
      label: "4 sessions",
      description: "Not available on this plan",
    },
    {
      value: "6",
      label: "6 sessions",
      description: "Not available on this plan",
    },
    {
      value: "8",
      label: "8 sessions",
      description: "Not available on this plan",
    },
    {
      value: null,
      label: "Unlimited",
      description: "Not available on this plan",
    },
  ],
}

export const UserTherapyCap: FunctionComponent<TherapyTimeCapProps> = props => {
  const { register, setValue, watch } = useFormContext<BillingFormValues>()
  const watchedSessionCap = watch("userTherapyCap")
  const watchedAvailableCourses = watch("courses")
  const propsSchema = z.object({
    userTherapyCap: z.object({
      active: z.boolean(),
      value: z
        .union([z.number(), z.null()])
        .transform(value => value?.toString() ?? null),
    }),
    upcomingCapResetDate: z.string().nullable(),
  })

  const safeParsedProps = propsSchema.safeParse(props)
  const requiresUpgrade = props.userTherapyCap?.active === false

  useEffect(() => {
    if (safeParsedProps.success) {
      setValue("userTherapyCap", safeParsedProps.data.userTherapyCap.value)
    }
  }, [])

  return (
    <div className="flex flex-col gap-4">
      <H4 sectionHeader>
        Pre-approved sessions {requiresUpgrade && "(Team Plan Only)"}
      </H4>
      <P>
        {safeParsedProps.success && (
          <>
            {requiresUpgrade ? (
              <>
                Set a default maximum number of therapy sessions invited people
                can have initially. This is in addition to the an initial
                half-session consultation.
              </>
            ) : props.subscriptionPlan === SpillSubscriptionPlan.ESSENTIAL ? (
              <>
                Set a maximum number of therapy sessions a member of the team
                can book with Spill every {props.capPeriod ?? 6} months.
              </>
            ) : (
              <>
                Set a maximum number of therapy sessions a member of the team
                can book with Spill every {props.capPeriod ?? 6} months. Read
                more{" "}
                <a
                  className="underline underline-offset-1"
                  href="https://spill.notion.site/Managing-therapy-spend-on-Spill-a16c4fdf074f4aa6b124354976081c56"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </>
            )}
          </>
        )}
      </P>

      {/* We render a message if the userTherapyCap is something that's not in the default options */}
      {!safeParsedProps.success ? null : (
        <>
          {!requiresUpgrade &&
            props.subscriptionPlan != null &&
            !sessionCapOptions[props.subscriptionPlan]
              .map(option => option.value)
              .includes(safeParsedProps.data.userTherapyCap.value) && (
              <Alert
                variant="warning"
                title={`You're currently set to a custom cap of ${safeParsedProps.data.userTherapyCap.value?.toString()} hours`}
              />
            )}
        </>
      )}

      <div className="grid grid-cols-1 gap-8 md:grid-cols-6">
        {(props.subscriptionPlan != null
          ? sessionCapOptions[props.subscriptionPlan]
          : []
        ).map(option => {
          const twoHourCapDisabled =
            option.value === "2" && watchedAvailableCourses
          const disabledByEssentialPlan =
            option.value !== "2" &&
            props.subscriptionPlan === SpillSubscriptionPlan.ESSENTIAL

          return (
            <div key={`userTherapyCap-${option.value}`} className="col-span-2">
              <Tooltip.Provider>
                <Tooltip.Root>
                  {/* The tooltip inexplicably centers the child text 🙃 */}
                  <Tooltip.Trigger className="w-full text-start" type="button">
                    <SelectableCard
                      size="lg"
                      title={option.label}
                      value={option.value ?? undefined}
                      disabled={
                        requiresUpgrade ||
                        twoHourCapDisabled ||
                        disabledByEssentialPlan
                      }
                      // Puddles doesn't seem to persist react hook form state. We have a todo to fix this.
                      // But this works for now.
                      checked={watchedSessionCap === option.value}
                      onClick={() => {
                        setValue("userTherapyCap", option.value)
                      }}
                      {...register("userTherapyCap")}
                      subtitle={option.description}
                      type="radio"
                    />
                  </Tooltip.Trigger>
                  {requiresUpgrade && (
                    <Tooltip.Content>
                      Only available on Team Plan
                    </Tooltip.Content>
                  )}
                  {!requiresUpgrade && twoHourCapDisabled && (
                    <Tooltip.Content>
                      The 2 hour cap is only available to companies who do not
                      offer their employees therapy courses.
                    </Tooltip.Content>
                  )}
                </Tooltip.Root>
              </Tooltip.Provider>
            </div>
          )
        })}
      </div>
    </div>
  )
}
