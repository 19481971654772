import { Button, Form, H3, Input, P, ToggleButton } from "@spillchat/puddles"
import { FunctionComponent, useCallback, useMemo, useState } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

const emotionOptions = [
  "Angry",
  "Anxious",
  "Ashamed",
  "Curious",
  "Drained",
  "Frustrated",
  "Guilty",
  "Hopeless",
  "Lonely",
  "Sad",
  "Stressed",
  "Other",
]

const frequencyOptions = ["Rarely", "Sometimes", "Often", "Always"]

interface CurrentEmotionsProps {
  checkValid: () => void
}

export const CurrentEmotions: FunctionComponent<CurrentEmotionsProps> = ({
  checkValid,
}) => {
  const [otherEmotionsEnabled, setOtherEmotionsEnabled] =
    useState<boolean>(false)
  const [customEmotionControls, setCustomEmotionControls] = useState<string[]>(
    []
  )
  const [customEmotionCurrentKey, setCustomEmotionCurrentKey] =
    useState<number>(0)

  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const currentEmotions = useMemo(() => {
    return form.getValues("preSession.currentEmotions")
  }, [form.watch("preSession.currentEmotions")])

  const updateCurrentEmotions = (
    key: string,
    emotion: string,
    frequency?: string,
    selected: boolean = true
  ) => {
    //If the emotion is other, enable the other emotions field
    if (emotion === "Other") {
      setOtherEmotionsEnabled(selected)
      if (selected) {
        //Add a control if there are none
        if (customEmotionControls.length === 0) {
          setCustomEmotionControls([`customEmotion${customEmotionCurrentKey}`])
          setCustomEmotionCurrentKey(customEmotionCurrentKey + 1)
        }

        checkValid()
        return
      }

      //Remove the custom emotions from currentEmotions
      form.setValue(
        "preSession.currentEmotions",
        currentEmotions.filter(
          selectedEmotion =>
            !customEmotionControls.includes(selectedEmotion.key)
        )
      )
      setCustomEmotionControls([])
      checkValid()
      return
    }

    //If the emotion is already in the list
    if (emotionSelected(key)) {
      //If selected is false, remove it
      if (selected === false) {
        form.setValue(
          "preSession.currentEmotions",
          currentEmotions.filter(selectedEmotion => selectedEmotion.key !== key)
        )
        checkValid()
        return
      }

      //If selected is true, update the frequency
      form.setValue(
        "preSession.currentEmotions",
        currentEmotions.map(selectedEmotion => {
          if (selectedEmotion.key === key) {
            return {
              key: key,
              emotion: emotion,
              frequency: frequency,
            }
          }
          return selectedEmotion
        })
      )
      checkValid()
      return
    }

    //Emotion is not in the list and is not selected. Can exit
    if (selected === false) {
      return
    }

    //Add the emotion to the list
    form.setValue("preSession.currentEmotions", [
      ...currentEmotions,
      {
        key: key,
        emotion: emotion,
        frequency: frequency,
      },
    ])
  }

  const emotionSelected = useCallback(
    (key: string) => {
      if (currentEmotions === undefined) {
        return false
      }

      if (key === "Other") {
        return otherEmotionsEnabled
      }

      return currentEmotions.some(
        selectedEmotion => selectedEmotion.key === key
      )
    },
    [currentEmotions, otherEmotionsEnabled]
  )

  const frequencyControl = (
    key: string,
    emotion: string,
    selectedFrequency?: string
  ) => {
    return (
      <div key={"freq-" + key} className=" grid grid-cols-5 gap-2 ">
        <div className="col-span-1 flex items-center ">
          <P weight="bold">{emotion}</P>
        </div>
        {frequencyOptions.map(frequency => (
          <div key={key + frequency} className="col-span-1">
            <ToggleButton
              widthClass="w-full"
              toggled={selectedFrequency === frequency}
              onClick={() => {
                if (selectedFrequency === frequency) {
                  updateCurrentEmotions(key, emotion, undefined)
                  return
                }
                updateCurrentEmotions(key, emotion, frequency)
              }}
            >
              {frequency}
            </ToggleButton>
          </div>
        ))}
      </div>
    )
  }

  const customEmotionControl = () => {
    return (
      <div className="space-y-2">
        <div className="flex flex-col gap-2">
          <H3>Other Emotions</H3>
          <P muted>Add one additional emotion at a time</P>
        </div>
        <div className="flex flex-col gap-4">
          {customEmotionControls.map((customEmotionControl, i) => (
            <div key={customEmotionControl} className="flex flex-col gap-5">
              <div className="grid grid-cols-5 gap-3">
                <div className="col-span-3">
                  <Input
                    placeholder="e.g. Happy"
                    value={
                      currentEmotions.find(
                        selectedEmotion =>
                          selectedEmotion.key === customEmotionControl
                      )?.emotion ?? ""
                    }
                    onChange={e => {
                      updateCurrentEmotions(
                        customEmotionControl,
                        e.target.value,
                        undefined,
                        e.target.value !== ""
                      )
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <div className="flex items-center h-full">
                    <Button
                      variant="tertiary"
                      onClick={() => {
                        updateCurrentEmotions(
                          customEmotionControl,
                          "",
                          undefined,
                          false
                        )
                        const newCustomEmotionControls =
                          customEmotionControls.filter(
                            control => control !== customEmotionControl
                          )
                        setCustomEmotionControls(newCustomEmotionControls)

                        if (newCustomEmotionControls.length === 0) {
                          setOtherEmotionsEnabled(false)
                        }
                      }}
                    >
                      <XMarkIcon height={24} width={24} />
                    </Button>
                  </div>
                </div>
              </div>
              {i === customEmotionControls.length - 1 && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setCustomEmotionControls([
                      ...customEmotionControls,
                      `customEmotion${customEmotionCurrentKey}`,
                    ])
                    setCustomEmotionCurrentKey(customEmotionCurrentKey + 1)
                  }}
                >
                  Add another
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-2">
        <H3>In the last 2 weeks have you felt any of the following?</H3>
        <P muted>Pick as many as apply</P>
      </div>

      <Form.Field
        control={form.control}
        name="preSession.currentEmotions"
        render={() => (
          <Form.Item className="w-full flex flex-col gap-8">
            <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-2 ">
              {emotionOptions.map(emotion => (
                <div key={"emotion-" + emotion} className="col-span-1">
                  <ToggleButton
                    widthClass="w-full"
                    toggled={emotionSelected(emotion)}
                    onClick={() =>
                      updateCurrentEmotions(
                        emotion,
                        emotion,
                        undefined,
                        !emotionSelected(emotion)
                      )
                    }
                  >
                    {emotion}
                  </ToggleButton>
                </div>
              ))}
            </div>
            {otherEmotionsEnabled && customEmotionControl()}
            {currentEmotions.length > 0 && (
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                  <H3>
                    How often in the last 2 weeks have you been feeling like
                    this?
                  </H3>
                  <P muted>Choose a frequency for each emotion</P>
                </div>
                {currentEmotions.map(emotion =>
                  frequencyControl(
                    emotion.key,
                    emotion.emotion,
                    emotion.frequency
                  )
                )}
              </div>
            )}
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
