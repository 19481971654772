import { useQuery } from "@apollo/client"
import { createPortal } from "react-dom"
import { captureMessage } from "@sentry/react"
import { Button, H1, H2, H3, P, Separator, Tooltip } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { z } from "zod"

import imageAskATherapist from "common/assets/images/product/ask-a-therapist.png"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  AskATherapistPageGetDataQuery,
  TherapyAccessReason,
  SpillSubscriptionStatus,
  AskATherapistPageGetCounsellorsQuery,
} from "types/graphql"

import { QuestionsList } from "./components/QuestionsList"
import { FAQAskATherapist } from "./components/FAQAskATherapist"
import { AskATherapistError } from "./components/error"
import { queries } from "./ask-a-therapist.queries"
import { CrisisAlert } from "./components/CrisisAlert"
import { AskATherapistQuestion } from "./components/QuestionForm"

export const AskATherapistPage: FunctionComponent = () => {
  const [isAskingQuestion, setIsAskingQuestion] = useState(false)

  const {
    data: usageData,
    loading: usageDataLoading,
    refetch,
  } = useQuery<AskATherapistPageGetDataQuery>(queries.getAskATherapistData)

  const { data: counsellorData, loading: counsellorDataLoading } =
    useQuery<AskATherapistPageGetCounsellorsQuery>(
      queries.getAskATherapistCounsellors
    )

  if (usageDataLoading || counsellorDataLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  // Validate the user data, mainly the rules around the accessToTherapyFeatures
  const requiredRulesUI = z.object({
    accessToTherapyFeatures: z.object({
      askATherapist: z.array(
        z.object({
          hasAccess: z.boolean().refine(value => value === true, {
            message: "User has no access to Ask a Therapist",
          }),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
    }),
    company: z.object({
      subscriptionStatus: z.nullable(z.string()),
      inActiveTrialPeriod: z.boolean(),
    }),
  })

  const sessionBookingRules = requiredRulesUI.safeParse(usageData?.user)
  if (!sessionBookingRules.success) {
    captureMessage(
      `User ${
        usageData?.user?.id ?? ""
      } unable to ask question: ${sessionBookingRules.error.issues
        .map(issue => issue.message)
        .join(", ")}`
    )

    if (usageData?.user?.accessToTherapyFeatures == null) {
      return (
        <>We were unable to display the Ask a Therapist page at this time.</>
      )
    }

    return (
      <AskATherapistError
        featureAccessReason={
          usageData?.user?.accessToTherapyFeatures.askATherapist.at(0)
            ?.reason ?? TherapyAccessReason.OK
        }
      />
    )
  }

  return (
    <>
      <Helmet title="Ask a Therapist | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex flex-col-reverse lg:flex-row justify-between gap-12 lg:gap-24 grow">
          <div className="flex flex-col gap-5 max-w-lg">
            <div className="flex flex-col items-start gap-3 self-stretch">
              <H1>Ask a Therapist</H1>
              <H3>How it works</H3>
              <P muted>
                Send a message to a Spill therapist about anything that's
                weighing on your mind — a relationship issue, sleeping problems,
                or uncertainty around your life goals.
              </P>
              <P muted>
                You'll get a considered reply within 1-2 working days.
              </P>
            </div>
            <CrisisAlert />
            <div>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger>
                    <Button
                      variant="primary"
                      onClick={() => setIsAskingQuestion(true)}
                      disabled={
                        sessionBookingRules.data.company.subscriptionStatus !==
                          SpillSubscriptionStatus.ACTIVE &&
                        !sessionBookingRules.data.company.inActiveTrialPeriod
                      }
                    >
                      Ask a question
                    </Button>
                    {sessionBookingRules.data.company.subscriptionStatus ===
                      null &&
                      !sessionBookingRules.data.company.inActiveTrialPeriod && (
                        <Tooltip.Content>
                          Your company hasn't yet added their payment details.
                        </Tooltip.Content>
                      )}
                    {sessionBookingRules.data.company.subscriptionStatus ===
                      SpillSubscriptionStatus.INACTIVE && (
                      <Tooltip.Content>Company not active.</Tooltip.Content>
                    )}
                  </Tooltip.Trigger>
                </Tooltip.Root>
              </Tooltip.Provider>
            </div>
          </div>
          <div className="hidden lg:block">
            <img
              className="rounded-lg aspect-[7/10] w-full lg:max-w-64"
              src={imageAskATherapist}
              alt="Someone asking a therapist a question via Spill"
            />
          </div>
        </div>
        <Separator />
        {usageData?.user?.askATherapistQuestions?.length !== 0 && (
          <>
            <div className="flex flex-col items-start gap-8 max-w-xl">
              <div className="flex flex-col items-start gap-3 self-stretch">
                <H2>Your questions</H2>
                <P muted>See a list of questions you've asked before</P>
              </div>
              <QuestionsList user={usageData?.user} />
            </div>
          </>
        )}
        <div className="flex flex-col items-start gap-8 max-w-screen-sm">
          <div className="flex flex-col items-start gap-3 self-stretch">
            <H2>FAQs</H2>
            <P muted>Find out more about how Ask a Therapist works</P>
          </div>
          <div className="flex flex-col items-start self-stretch max-w-xl ">
            <FAQAskATherapist />
          </div>
        </div>
      </section>
      {isAskingQuestion &&
        createPortal(
          <AskATherapistQuestion
            onClose={() => setIsAskingQuestion(false)}
            subscriptionActive={
              sessionBookingRules.data.company.subscriptionStatus ===
              SpillSubscriptionStatus.ACTIVE
            }
            counsellors={
              counsellorData
                ? counsellorData.allQuestionAnsweringCounsellors
                : []
            }
            refetch={refetch}
            isOpen={isAskingQuestion}
          />,
          document.body
        )}
    </>
  )
}
