import { Navigate, useLocation } from "react-router-dom"

import { useAuth } from "common/context/authContext"

import type { FunctionComponent, PropsWithChildren } from "react"

export const RequireAuth: FunctionComponent<PropsWithChildren> = props => {
  const { pathname, search } = useLocation()
  const { user, isUserLoading, signOut } = useAuth()

  if (isUserLoading) return null

  const redirect = encodeURIComponent(pathname + search)

  if (user === null) {
    void signOut()
    return (
      <Navigate
        replace
        to={{
          pathname: "/signin",
          search: redirect ? `redirect=${redirect}` : "",
        }}
      />
    )
  }

  return <>{props.children}</>
}
