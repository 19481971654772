/**
 * This is the success/confirmation page that we show the user after they've
 * successfully installed the Slack app.
 */

import { gql, useMutation, useQuery } from "@apollo/client"
import { AnimatePresence, motion } from "framer-motion"
import { FunctionComponent, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { toast } from "sonner"
import { FormProvider, useForm } from "react-hook-form"
import { Button } from "@spillchat/puddles"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import {
  GroupSelectGetCompanyQuery,
  GroupSelectAddAppToExternalGroupMutation as AddAppMutationData,
  GroupSelectAddAppToExternalGroupMutationVariables as AddAppMutationVariables,
  CompanyOnboardingStep,
  GroupSelectGetCompanyQueryVariables,
} from "types/graphql"
import { Select } from "common/components/FormElements/Select"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { NotFound404Page } from "common/components/NotFound404Page"
import { useOnboarding } from "common/context/onboardingContext"

import { platformTypesInfo, platformSetupAnimationVariants } from "."

const queries = {
  getCompany: gql`
    query GroupSelectGetCompany($companyPlatformId: ID!) {
      company {
        id
        platforms(filter: { ids: [$companyPlatformId] }) {
          id
          name
          externalId
          platformType
          avatarUrl
          domain
          externalGroups {
            id
            name
          }
        }
      }
    }
  `,
}

const mutations = {
  addAppToExternalGroup: gql`
    mutation GroupSelectAddAppToExternalGroup(
      $companyPlatformId: ID!
      $groupExternalId: String!
    ) {
      addAppToExternalGroup(
        companyPlatformId: $companyPlatformId
        groupExternalId: $groupExternalId
      )
    }
  `,
}

export const GroupSelect: FunctionComponent = () => {
  const { platformType } = useParams()
  const platformTypeInfo = platformTypesInfo[platformType ?? ""]
  const { markAsComplete } = useOnboarding()

  const [searchParams] = useSearchParams()
  const companyPlatformId = searchParams.get("companyPlatformId")

  const { data: companyData, loading } = useQuery<
    GroupSelectGetCompanyQuery,
    GroupSelectGetCompanyQueryVariables
  >(queries.getCompany, {
    fetchPolicy: "cache-first",
    skip: companyPlatformId == null,
    variables: { companyPlatformId: companyPlatformId as string },
  })

  const platformData = companyData?.company?.platforms[0]

  const [addAppToExternalGroup] = useMutation<
    AddAppMutationData,
    AddAppMutationVariables
  >(mutations.addAppToExternalGroup)

  const navigate = useNavigate()

  if (!platformTypeInfo || companyPlatformId === null) {
    return <NotFound404Page />
  }

  if (!platformData && !loading) {
    return (
      <>
        <AnimatePresence>
          <motion.div
            key="content"
            variants={platformSetupAnimationVariants}
            className="align-center flex flex-col items-center justify-center gap-12"
            transition={{
              duration: 1.25,
              staggerChildren: 0.25,
              delay: 0.2,
            }}
            initial="initial"
            animate="animate"
          >
            <motion.h2
              className="text-center text-5xl text-blue-800"
              variants={platformSetupAnimationVariants}
            >
              We weren't able to find your{" "}
              {platformTypeInfo.companyPlatformTerm}.
            </motion.h2>
            <motion.div
              variants={platformSetupAnimationVariants}
              className="flex max-w-sm gap-2"
            >
              <InformationCircleIcon className="size-10" />
              <p className="text-xs text-blue-800">
                We weren't able to find your {platformTypeInfo.name}{" "}
                {platformTypeInfo.companyPlatformTerm}. Please try again. If the
                issue persists, please email hi@spill.chat .
              </p>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </>
    )
  }

  const handleConfirm = async ({
    selectedExternalGroupId,
  }: {
    selectedExternalGroupId: string
  }) => {
    try {
      await addAppToExternalGroup({
        variables: {
          companyPlatformId,
          groupExternalId: selectedExternalGroupId,
        },
      })

      if (markAsComplete) {
        await markAsComplete(CompanyOnboardingStep.ADDED_TEAM)
      }
      navigate(
        `/admin/access/install/${platformData?.platformType.toLowerCase()}/link?companyPlatformId=${platformData?.id}`
      )
    } catch {
      toast.error("We weren't able to add Spill. Please try again.", {
        description: "If the issue persists, please get in touch",
      })
    }
  }

  return (
    <AnimatePresence>
      <motion.div
        key="content"
        variants={platformSetupAnimationVariants}
        className="align-center flex flex-col items-center justify-center gap-12"
        transition={{
          duration: 1.25,
          staggerChildren: 0.25,
          delay: 0.2,
        }}
        initial="initial"
        animate="animate"
      >
        <motion.h2
          className="max-w-2xl text-center text-2xl text-blue-800 sm:text-5xl"
          variants={platformSetupAnimationVariants}
        >
          You can now add Spill to a channel 🎉
        </motion.h2>

        {platformData ? (
          <SelectGroupForm {...platformData} handleConfirm={handleConfirm} />
        ) : (
          <LoadingSpinner sizeInPixels={50} />
        )}
      </motion.div>
    </AnimatePresence>
  )
}

type FormValues = {
  selectedExternalGroupId: string
}

const SelectGroupForm: FunctionComponent<
  GroupSelectGetCompanyQuery["company"]["platforms"][number] & {
    handleConfirm: (args: FormValues) => Promise<void>
  }
> = ({
  name: platformName,
  avatarUrl,
  domain,
  externalGroups,
  handleConfirm,
}) => {
  const [isConfirming, setIsConfirming] = useState(false)

  const sortedGroups = [...externalGroups].sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  const initialSelectedGroupId =
    sortedGroups.find(group => group.name === "general")?.id ??
    sortedGroups[0]?.id

  const formMethods = useForm<FormValues>({
    defaultValues: {
      selectedExternalGroupId: initialSelectedGroupId,
    },
  })

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(async formValues => {
          try {
            setIsConfirming(true)
            await handleConfirm(formValues)
          } finally {
            setIsConfirming(false)
          }
        })}
        className="align-center flex flex-col items-center justify-center gap-4"
      >
        <motion.div
          variants={platformSetupAnimationVariants}
          className="flex gap-4 rounded-lg border border-blue-200 bg-mono-white p-4 text-blue-800 shadow-sm"
        >
          <div className="flex aspect-square h-12 w-12 items-center justify-center rounded-md bg-teal-200 font-bold">
            {typeof avatarUrl === "string" ? (
              <img src={avatarUrl} className="rounded-md" alt="" />
            ) : (
              <div>
                {platformName
                  .split(" ")
                  .map(word => word[0])
                  .join("")
                  .toUpperCase()}
              </div>
            )}
          </div>
          <div>
            <h3 className="text-lg font-bold">{platformName}</h3>
            <p>{domain}</p>
          </div>
        </motion.div>
        <motion.div
          variants={platformSetupAnimationVariants}
          className="flex max-w-md flex-col gap-2"
        >
          <p className="text-center text-sm text-blue-800">
            Spill will create an account for all the users in this channel, but
            nobody will be contacted by Spill until you tell us. You can always
            uninstall. This is a list of your public channels - if you want to
            add Spill to a private channel, follow the instructions{" "}
            <a
              className="underline"
              target="_blank"
              href="https://spill.notion.site/Change-the-channel-Spill-is-in-9292d7ef384e4dd689a52a77648989bb"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <Select
            id="group-select"
            name="selectedExternalGroupId"
            register={formMethods.register}
            options={sortedGroups.map(group => ({
              label: group.name,
              value: group.id,
              selected: group.id === initialSelectedGroupId,
            }))}
          />
        </motion.div>
        <motion.div>
          <Button loading={isConfirming} type="submit">
            Confirm
          </Button>
        </motion.div>
      </form>
    </FormProvider>
  )
}
