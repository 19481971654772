import { gql } from "@apollo/client"

import { fragments } from "./ask-a-therapist.queries"

export const mutations = {
  createAskATherapistQuestion: gql`
    mutation AskATherapistPageCreateAskATherapistQuestion(
      $question: String!
      $counsellorId: ID
    ) {
      createAskATherapistQuestion(
        question: $question
        questionType: ASK_A_THERAPIST
        counsellorId: $counsellorId
      ) {
        ...AskATherapistPageAskATherapistQuestionFields
      }
    }
    ${fragments.askATherapistQuestionFields}
  `,
  createFollowUpAskATherapistQuestion: gql`
    mutation AskATherapistPageCreateFollowUpAskATherapistQuestion(
      $question: String!
      $parentId: ID!
      $counsellorId: ID!
    ) {
      createFollowUpAskATherapistQuestion(
        question: $question
        questionType: ASK_A_THERAPIST
        parentId: $parentId
        counsellorId: $counsellorId
      ) {
        ...AskATherapistPageAskATherapistQuestionFields
      }
    }
    ${fragments.askATherapistQuestionFields}
  `,
  allowAddingToAdviceLibrary: gql`
    mutation AskATherapistPageAllowAddingToAdviceLibrary($id: ID!) {
      allowAddingAATToAdviceLibrary(askATherapistQuestionId: $id) {
        ...AskATherapistPageAskATherapistQuestionFields
      }
    }
    ${fragments.askATherapistQuestionFields}
  `,
  cancelAllowAddingToAdviceLibrary: gql`
    mutation AskATherapistPageCancelAllowAddingToAdviceLibrary($id: ID!) {
      cancelAddingAATToAdviceLibrary(askATherapistQuestionId: $id) {
        ...AskATherapistPageAskATherapistQuestionFields
      }
    }
    ${fragments.askATherapistQuestionFields}
  `,
}
