import { Form, H3, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

import { UncontrolledCheckboxList } from "./checkListOptions"

interface SupportAreasProps {
  checkValid: () => void
}

export const SupportAreas: FunctionComponent<SupportAreasProps> = ({
  checkValid,
}) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const handleCheckboxChange = (
    options: { key: number; label: string; selected: boolean; value: string }[]
  ) => {
    form.setValue("preSession.supportAreas.options", options)
    checkValid()
  }

  return (
    <div className="flex flex-col gap-3 fieldsContainer">
      <div className="flex flex-col gap-2">
        <H3>What areas of your life are you looking for support with?</H3>
        <P muted>Pick as many as apply</P>
      </div>

      <UncontrolledCheckboxList
        name="preSession.supportAreas"
        options={form.watch("preSession.supportAreas.options")}
        onChange={handleCheckboxChange}
      />

      <Form.Field
        control={form.control}
        name="preSession.supportAreas"
        render={({ field: _field }) => (
          <Form.Item className="w-full">
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
