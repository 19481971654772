import { Form, H3, P, TextArea } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

interface RecentLossAffectedHowProps {
  checkValid: () => void
}

export const RecentLossAffectedHow: FunctionComponent<
  RecentLossAffectedHowProps
> = ({ checkValid }) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <H3>How has this affected you? What has it brought up for you?</H3>
        <P muted></P>
      </div>

      <Form.Field
        control={form.control}
        name="preSession.recentLossAffectedHow.value"
        render={({ field }) => (
          <Form.Item className="w-full">
            <Form.Control>
              <TextArea
                {...field}
                value={field.value ?? ""}
                rows={5}
                className="h-auto min-h-20 max-h-64"
                onBlur={checkValid}
                placeholder='e.g. "My friend died almost a year ago and I don&apos;t know how to cope with the anniversary" or "I recently lost a family member and feel completely numb"'
              />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="preSession.recentLossAffectedHow"
        render={() => (
          <Form.Item className="w-full">
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
