import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"

import {
  BookableAppointmentType,
  Gender,
  GetDefaultBookingFiltersQuery,
  GetDefaultBookingFiltersQueryVariables,
} from "types/graphql"

import { therapyBookingQueries } from "../therapy-booking.queries"

interface useFilteringOptionsProps {
  appointmentType: BookableAppointmentType
  lifeEventId?: string
}

type useDefaultFilterOptionsResult = {
  filters: useDefaultFilterOptionsResultFilters | undefined
  loading: boolean
}

type useDefaultFilterOptionsResultFilters = {
  languageCodes: string[] | undefined
  genders: Gender[] | undefined
  pmiProvider: string | undefined
  specialisms: string[] | undefined
  previousCounsellors: {
    id: string | undefined
    firstName: string | undefined
    pmiEnabledforCompany?: boolean | undefined | null
  }[]
}

export function useDefaultFilterOptions(
  props: useFilteringOptionsProps
): useDefaultFilterOptionsResult {
  const [filters, setFilters] = useState<
    useDefaultFilterOptionsResultFilters | undefined
  >(undefined)

  const { data, loading } = useQuery<
    GetDefaultBookingFiltersQuery,
    GetDefaultBookingFiltersQueryVariables
  >(therapyBookingQueries.getDefaultFilters, {
    variables: {
      appointmentType: props.appointmentType,
      filter: { lifeEvent: props.lifeEventId },
    },
  })

  const previousCounsellors =
    data?.user?.previousCounsellors?.map(c => {
      return {
        id: c.counsellor?.id,
        firstName: c.counsellor?.firstName,
        pmiEnabledforCompany: c.pmiEnabledforCompany,
      }
    }) ?? []

  useEffect(() => {
    if (loading) return
    const defaultFilters: useDefaultFilterOptionsResultFilters = {
      specialisms: data?.matchingCriteriaOptions?.specialisms,
      languageCodes: data?.matchingCriteriaOptions?.languageCodes,
      genders: (data?.matchingCriteriaOptions?.genders as Gender[]) ?? [],
      pmiProvider: data?.user?.company?.pmi?.provider,
      previousCounsellors: previousCounsellors,
    }
    setFilters(defaultFilters)
  }, [data, loading])

  return { filters, loading }
}
