import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Alert,
  Breadcrumbs,
  Button,
  Form,
  H2,
  Label,
  ModalFullScreenInner,
  P,
  Select,
  SelectableCard,
  Tabs,
  TextArea,
  Tooltip,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import { addMonths, addYears, format } from "date-fns"

import {
  InviteBereavementCreateTherapyPackageMutation,
  InviteBereavementCreateTherapyPackageMutationVariables,
  InviteBereavementUserListQuery,
  AccountStatus,
  PackageType,
  PeopleListItemMakeAppUserMutation,
  PeopleListItemMakeAppUserMutationVariables,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  formatEmailParameters,
  useSendEmail,
} from "common/hooks/api/useSendEmail"
import { useGoBack } from "common/hooks/useGoBack"

import { mutations } from "./invite-bereavement.mutations"
import { queries } from "./invite-bereavement.queries"
import { formSchema } from "./invite-bereavement.schema"

import type { InviteBereavementForm } from "./invite-bereavement.schema"

type User = {
  // id is either the app-user id or the platform-user id
  id: string
  // If appUserId is null then we will need to
  // create one before creating therapy package
  appUserId?: string | null
  name: string
  email?: string | null
}

export const InviteBereavement: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const goBack = useGoBack({ isAdmin: true })
  const [step, setStep] = useState(0)

  const [makeAppUser] = useMutation<
    PeopleListItemMakeAppUserMutation,
    PeopleListItemMakeAppUserMutationVariables
  >(mutations.makeAppUser, {
    refetchQueries: ["PeopleListGetUser"],
    awaitRefetchQueries: true,
  })

  const { data: userData, loading: userLoading } =
    useQuery<InviteBereavementUserListQuery>(queries.getUserList, {
      fetchPolicy: "cache-first",
    })

  const appUsers = userData?.user?.company?.users ?? []
  const pendingUsers = userData?.user?.company?.pendingUsers ?? []

  const availableUsers: User[] = appUsers
    .filter(appUser => {
      return appUser.accountStatus === AccountStatus.ACTIVE
    })
    .map(appUser => {
      return {
        id: appUser.id,
        appUserId: appUser.id,
        name: appUser.fullName,
        email: appUser.platformUsers[0]?.email,
      }
    })

  for (const platformUser of pendingUsers) {
    availableUsers.push({
      id: platformUser.id,
      appUserId: null,
      name: platformUser.name,
      email: platformUser.email,
    })
  }

  const form = Form.useForm<InviteBereavementForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userId: "",
      notificationType: "email",
      note: "",
    },
  })

  const [createTherapyPackage, { loading }] = useMutation<
    InviteBereavementCreateTherapyPackageMutation,
    InviteBereavementCreateTherapyPackageMutationVariables
  >(mutations.createTherapyPackage)

  const [emailUser, emailUserResult] = useSendEmail()

  if (isUserLoading || userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const sendTestEmail = async () => {
    const primaryEmail = userData?.user?.primaryEmail

    if (primaryEmail != undefined && primaryEmail !== "") {
      await emailUser({
        variables: {
          emails: [primaryEmail ?? ""],
          templateName: "support-package-notification",
          parameters: formatEmailParameters({
            adminNote: form.getValues("note"),
            packageName: "Bereavement support",
            packageDescription: `There is no right way to grieve. Get support from Spill at the moments where you need it most.

You have 6 sessions to use whenever you’d like - they’re yours to use when you’re ready to talk. You can choose to use them all at once, or to spread them out over a longer period of time.

You will be talking to one of our bereavement specialists, who have extensive experience working with bereavement and loss.`,
            expirationDate: format(addYears(new Date(), 2), "MMM yyyy"),
            numberOfSessions: "6",
          }),
        },
      })

      return toast.info(`An email has been sent to ${primaryEmail ?? "you"}`)
    }

    return toast.error("Unable to find your email")
  }

  const handleRecipient = async () => {
    const valid = await form.trigger(["userId"], {
      shouldFocus: true,
    })

    if (valid) {
      setStep(1)
    }
  }

  const handleSubmit = async (values: InviteBereavementForm) => {
    const userFromList = availableUsers.find(user => {
      return user.id === values.userId
    })

    // If the platform user doesn't have an app-user yet,
    // then we need to create it so we have a user id to give the
    // therapy package creator
    let targetUserId
    if (userFromList?.appUserId !== null) {
      targetUserId = userFromList?.appUserId
    } else {
      const { data } = await makeAppUser({
        variables: { platformUserId: userFromList?.id },
      })
      if (data?.makeAppUserForPlatformUser?.user?.id == null) {
        throw Error(
          "Platform user " +
            userFromList?.id +
            " does not have an associated app-user and we have just failed to make one"
        )
      }
      targetUserId = data.makeAppUserForPlatformUser.user.id
    }

    const email = userFromList?.email

    const defaultNumberSessions = 6.5
    const defaultNumberMonthsToUse = 24

    await createTherapyPackage({
      variables: {
        userIds: [targetUserId ?? "UnknownUser"],
        packageType: PackageType.BEREAVEMENT,
        numberOfSessions: defaultNumberSessions,
        adminNote: values.note,
        expirationDate: addMonths(
          new Date(),
          defaultNumberMonthsToUse
        ).toISOString(),
      },
    })

    if (values.notificationType === "email") {
      if (email === undefined || email === null) {
        return toast.error("Unable to send email to this user")
      }

      await emailUser({
        variables: {
          emails: [email],
          templateName: "support-package-notification",
          parameters: formatEmailParameters({
            adminNote: form.getValues("note"),
            packageName: "Bereavement support",
            packageDescription: `There is no right way to grieve. Get support from Spill at the moments where you need it most.

You have 6 sessions to use whenever you’d like - they’re yours to use when you’re ready to talk. You can choose to use them all at once, or to spread them out over a longer period of time.

You will be talking to one of our bereavement specialists, who have extensive experience working with bereavement and loss.`,
            expirationDate: format(addYears(new Date(), 2), "MMM yyyy"),
            numberOfSessions: "6",
          }),
        },
      })
    }

    return setStep(2)
  }

  const selectedUser = availableUsers.find(user => {
    return user.id === form.getValues("userId")
  })

  const userListEmpty = (availableUsers ?? []).length <= 0

  const individualCap =
    userData?.user?.company?.featuresAndSettings.userTherapyCap

  const isLoading = loading || emailUserResult.loading

  const tabs = [
    { label: "Recipient", isActive: step >= 0, step: 0 },
    { label: "Configure", isActive: step >= 1, step: 1 },
    { label: "Access", isActive: step >= 2, step: 2 },
  ]

  return (
    <>
      <Helmet title="Add Bereavement Support | Spill" />
      <ModalFullScreenInner
        title="Add Bereavement support"
        onClose={() => goBack()}
      >
        <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
          <Tabs.Root
            defaultValue={step.toString()}
            value={step.toString()}
            className="flex flex-col gap-6 lg:gap-12"
          >
            <Form.Root {...form}>
              <Breadcrumbs
                onStepChange={setStep}
                currentStep={step}
                tabs={tabs}
              />
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Tabs.Content value="0">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>Who would you like to provide support to?</H2>
                      <P>
                        Choose the employee who would benefit from support with
                        a life event on Spill:
                      </P>
                    </div>
                    <div className="flex flex-col gap-4">
                      <Form.Field
                        control={form.control}
                        name="userId"
                        render={({ field }) => (
                          <Form.Item>
                            <Form.Control>
                              <Select.Root
                                {...field}
                                disabled={userListEmpty}
                                onValueChange={field.onChange}
                              >
                                <Select.Trigger>
                                  <Select.Value
                                    placeholder={
                                      !userListEmpty
                                        ? "Select employee"
                                        : "No employees available"
                                    }
                                  />
                                </Select.Trigger>
                                <Select.Content side="bottom">
                                  {availableUsers
                                    ?.sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                    .map(user => (
                                      <Select.Item
                                        key={user.id}
                                        value={user.id}
                                      >
                                        {user.name}
                                        {user.email !== undefined &&
                                          user.email !== null &&
                                          ` (${user.email})`}
                                      </Select.Item>
                                    ))}
                                </Select.Content>
                              </Select.Root>
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                      <div className="flex items-center">
                        <Tooltip.Provider>
                          <Tooltip.Root>
                            <Tooltip.Trigger
                              className="cursor-help"
                              type="button"
                            >
                              <span className="underline decoration-dotted">
                                <P size="xs">
                                  <Link to="/admin/access">
                                    {!userListEmpty
                                      ? "Employee not in list?"
                                      : "Invite your team to Spill"}
                                  </Link>
                                </P>
                              </span>
                            </Tooltip.Trigger>

                            <Tooltip.Content>
                              <div className="flex flex-col gap-1 text-spill-mono-black">
                                <P>
                                  Invite them to Spill first via the Support
                                  page
                                </P>
                                <P muted size="xs">
                                  You can find the Support page in the Therapy
                                  tab in the sidebar on the left
                                </P>
                              </div>
                            </Tooltip.Content>
                          </Tooltip.Root>
                        </Tooltip.Provider>
                      </div>
                    </div>
                    <Button
                      disabled={userListEmpty}
                      onClick={async () => await handleRecipient()}
                      variant="primary"
                    >
                      Next
                    </Button>
                  </div>
                </Tabs.Content>
                <Tabs.Content value="1">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <H2>Configure their support</H2>
                      <P>
                        Configure the settings for the support you're sending
                      </P>
                    </div>
                    <div>
                      <Alert
                        title="Bereavement support doesn’t use an employee’s pre-approved sessions or company budget"
                        variant="warning"
                      >
                        <P size="xs">
                          Bereavement support is a course of 6 sessions - 4 now
                          and 2 for a later date, around an anniversary or
                          birthday.{" "}
                          {individualCap?.active === true && (
                            <>
                              This support is in addition to the{" "}
                              {individualCap?.value} pre-approved sessions.{" "}
                              <Link
                                to="/admin/therapy/settings"
                                className="underline"
                              >
                                See pre-approved settings.
                              </Link>
                            </>
                          )}
                        </P>
                      </Alert>
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label>
                        How would you like to notify them about their life event
                        support?
                      </Label>
                      <div>
                        <Form.Field
                          control={form.control}
                          name="notificationType"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <div className="grid lg:grid-cols-2 gap-4">
                                  {[
                                    {
                                      title: "Email notification",
                                      subtitle: "Spill will email them",
                                      value: "email",
                                    },
                                    {
                                      title: "I'll tell them",
                                      subtitle: "Spill won't contact them",
                                      value: "none",
                                    },
                                  ].map(item => {
                                    return (
                                      <SelectableCard
                                        key={item.value}
                                        size="sm"
                                        checked={field.value === item.value}
                                        title={item.title}
                                        subtitle={item.subtitle}
                                        type="radio"
                                        {...form.register("notificationType")}
                                        value={item.value}
                                        onClick={() => {
                                          form.setValue(
                                            "notificationType",
                                            item.value as "email" | "none"
                                          )
                                        }}
                                      />
                                    )
                                  })}
                                </div>
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                    </div>
                    {form.getValues("notificationType") === "email" && (
                      <>
                        <Form.Field
                          control={form.control}
                          name="note"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <TextArea
                                  placeholder="Write text here..."
                                  className="h-48"
                                  {...field}
                                  label={{
                                    children:
                                      "Add a note to their email notification (optional)",
                                  }}
                                />
                              </Form.Control>
                              <Form.Message />
                              <div
                                className="underline cursor-pointer"
                                onKeyDown={async e => {
                                  if (e.key === "Enter") {
                                    await sendTestEmail()
                                  }
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={async () => await sendTestEmail()}
                              >
                                <P>
                                  Send a test email to yourself to see what it
                                  looks like
                                </P>
                              </div>
                            </Form.Item>
                          )}
                        />
                      </>
                    )}
                    <div className="flex items-center gap-4">
                      <Button
                        onClick={() => setStep(step - 1)}
                        variant="secondary"
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        loading={isLoading}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value="2">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>
                        {selectedUser?.name.split(" ")[0]}'s support has been
                        added
                      </H2>
                      <P>
                        We've added the extra support for {selectedUser?.name}.
                        They'll be able to use those sessions when they go to
                        their Spill account.
                      </P>
                    </div>
                    <Button variant="secondary" asChild>
                      <Link to="/admin/therapy">Go back to Support</Link>
                    </Button>
                  </div>
                </Tabs.Content>
              </form>
            </Form.Root>
          </Tabs.Root>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
