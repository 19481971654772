import { ApexOptions } from "apexcharts"
import { format } from "date-fns"
import { FunctionComponent } from "react"
import Chart from "react-apexcharts"

type ChartBarStackedProps = {
  series: {
    name: string
    color: string
    data: {
      x: string
      y: number | null
    }[]
  }[]
  stacked?: boolean
}

export const ChartBarStacked: FunctionComponent<ChartBarStackedProps> = ({
  series,
  stacked = true,
}) => {
  const dates = series.map(item => item.data.map(data => data.x))

  const flatDates = [...new Set(dates.flat(1))].map(date => {
    return new Date(date).getTime()
  })
  const minDate = Math.min(...flatDates)
  const maxDate = Math.max(...flatDates)

  const config: { options: ApexOptions; series: ApexAxisChartSeries } = {
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      yaxis: {
        forceNiceScale: true,
      },
      xaxis: {
        type: "datetime",
        min: minDate,
        max: maxDate,
        labels: {
          format: "MMM yy",
          showDuplicates: false,
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: "Inter",
        },
        x: {
          show: true,
          formatter(val) {
            const date = new Date(val)
            return format(date, "MMM yyyy")
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "left",
        floating: false,
        fontSize: "12px",
        fontFamily: "Inter",
        showForZeroSeries: false,
        showForNullSeries: false,
        fontWeight: 500,
        height: 48,
        offsetY: 12,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          shape: "circle",
          strokeWidth: 4,
          offsetX: 0,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 16,
          vertical: 0,
        },
      },
    },
    series,
  }

  return (
    <Chart
      options={config.options}
      series={config.series}
      type="bar"
      style={{ width: "100%" }}
      height={320}
    />
  )
}
