import { gql } from "@apollo/client"

export const mutations = {
  cancelRequest: gql(`
    mutation TherapySpecialisedSupportIndexCancel(
      $id: ID!
    ) {
      cancelTherapyPackageExtension(id: $id) {
        id
        status
      }
    }
  `),
}
