import { Link } from "react-router-dom"
import { Button } from "@spillchat/puddles"

import bagHeadYellow from "common/assets/images/bag-head-yellow.png"

export function NotAuthorisedPage(): JSX.Element | null {
  return (
    <main className="items-center justify-center">
      <div className="flex flex-col gap-lg text-center items-center max-w-md">
        <img src={bagHeadYellow} alt="Bag Head" className="w-32 md:w-48" />
        <header>
          <h2>Oops...</h2>
          <p>Looks like you aren't authorised to access this area.</p>
        </header>
        <Button asChild>
          <Link to="/">Back to Home</Link>
        </Button>
      </div>
    </main>
  )
}
