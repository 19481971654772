import { useNavigate } from "react-router-dom"
import { Button, P, Indicator, Separator } from "@spillchat/puddles"

import { Avatar } from "common/components/Avatar"
import { AskATherapistPageGetDataQuery } from "types/graphql"
import { formatDate } from "common/helpers/formatDate"

export function QuestionsList({
  user,
}: {
  user: AskATherapistPageGetDataQuery["user"]
}): JSX.Element {
  if (!user || user.askATherapistQuestions == null) {
    return <P>You haven't asked any questions yet.</P>
  }

  const questions = [...user.askATherapistQuestions].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )

  const navigate = useNavigate()

  return (
    <>
      <div className="flex flex-col items-start gap-5 self-stretch">
        {questions.map((question, index) => {
          const counsellorName = `${question.counsellorFirstName} ${question.counsellorLastName}`

          const counsellorAvatarUrl =
            question.counsellorAvatarUrl as unknown as string

          return (
            <div
              key={question.id}
              className="flex flex-col items-start gap-4 self-stretch"
            >
              <div className="flex justify-between items-center self-stretch ">
                <Indicator
                  variant={
                    question.counsellorResponse == null ? "warning" : "ok"
                  }
                >
                  {question.counsellorResponse == null ? "Pending" : "Answered"}
                </Indicator>
                <P muted size="xs">
                  {formatDate(new Date(question.createdAt))}
                </P>
              </div>
              <div className="w-full">
                <P weight="medium" className="line-clamp-3">
                  {question.question}
                </P>
              </div>
              <div className="flex justify-between items-center self-stretch">
                {question.counsellorResponse != null && (
                  <div className="flex items-center gap-2">
                    <Avatar
                      url={counsellorAvatarUrl}
                      name={counsellorName}
                      size="xs"
                    />
                    <P
                      muted
                      size="xs"
                      truncate
                    >{`Answered by ${question.counsellorFirstName}`}</P>
                  </div>
                )}
              </div>
              <Button
                variant="secondary"
                size="sm"
                onClick={() =>
                  navigate(`/therapy/ask-a-therapist/${question.id}`)
                }
                disabled={question.counsellorResponse == null}
              >
                View Answer
              </Button>
              <Separator
                className={`mt-5 ${
                  index === questions.length - 1
                    ? "bg-spill-grey-200"
                    : "bg-spill-grey-100"
                }`}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}
