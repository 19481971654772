import { useLazyQuery } from "@apollo/client"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import {
  Button,
  H2,
  IconCard,
  ModalFullScreenInner,
  P,
  Tag,
} from "@spillchat/puddles"
import { useNavigate } from "react-router-dom"
import { UserIcon } from "@heroicons/react/24/outline"
import { toZonedTime } from "date-fns-tz"

import {
  GetTherapyBookingConfirmationQuery,
  GetTherapyBookingConfirmationQueryVariables,
} from "types/graphql"
import { LoadingPage } from "common/components/LoadingPage"
import { ErrorPage } from "common/components/ErrorPage"
import { toPascalCase } from "common/helpers/stringFormat"
import { useApp } from "common/context/appContext"

import { therapyBookingConfirmationQueries } from "./therapy-booking-confirmation.queries"

export const TherapyBookingConfirmation: FunctionComponent = () => {
  const [getAppointmentInfo, { data, loading }] = useLazyQuery<
    GetTherapyBookingConfirmationQuery,
    GetTherapyBookingConfirmationQueryVariables
  >(therapyBookingConfirmationQueries.getAppointment)

  useEffect(() => {
    //Get appointment id from url
    const urlParams = new URLSearchParams(window.location.search)
    const appointmentId = urlParams.get("appointmentId")
    if (appointmentId !== null) {
      void getAppointmentInfo({
        variables: {
          appointmentId: appointmentId,
        },
      })
    }
  }, [])

  if (loading) {
    return <LoadingPage />
  }

  if (data === undefined) {
    return <ErrorPage />
  }

  return <TherapyBookingConfirmationContent data={data} />
}

const TherapyBookingConfirmationContent: FunctionComponent<{
  data: GetTherapyBookingConfirmationQuery
}> = ({ data }) => {
  const [tags, setTags] = useState<string[]>([])
  const navigate = useNavigate()
  const { appTimeZone } = useApp()

  //Get selected time and date in format Friday, 22 Nov 2024 and 4.00pm
  const selectedTime = useMemo(() => {
    const time = data?.appointment?.startsAt

    if (time === undefined || time === null || time === "") {
      return undefined
    }

    const utcDate = new Date(time)
    const TZDate = toZonedTime(utcDate, appTimeZone)

    const weekday = TZDate.toLocaleDateString("en-GB", { weekday: "long" })
    return {
      day: `${weekday}, ${TZDate.toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })}`,
      time: TZDate.toLocaleTimeString("en-GB", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
        .replace(" ", "")
        .toLowerCase(),
    }
  }, [data])

  useEffect(() => {
    const filter = data?.appointment?.matchingCriteria
    const newTags = []

    if (filter?.gender !== null && filter?.gender !== undefined) {
      newTags.push(toPascalCase(filter?.gender).replace("_", "-"))
    }

    if (filter?.languageCode !== undefined && filter?.languageCode !== null) {
      const countryName = new Intl.DisplayNames([filter?.languageCode], {
        type: "language",
      }).of(filter?.languageCode)

      if (countryName !== undefined) {
        newTags.push(countryName)
      }
    }

    if (filter?.specialism !== undefined && filter?.specialism !== null) {
      newTags.push(toPascalCase(filter?.specialism))
    }

    setTags(newTags)
  }, [data])

  return (
    <ModalFullScreenInner
      title="Book a therapy session"
      onBack={() => navigate("/therapy/sessions")}
      onClose={() => navigate("/therapy/sessions")}
    >
      <div className="flex justify-center pt-12">
        <div className="max-w-screen-sm w-full px-8 grid grid-cols-12">
          <div className="flex flex-col gap-12 col-span-12">
            <div className="flex flex-col gap-2">
              <H2>Your session is booked</H2>
              <P muted>
                You’ll be able to see your upcoming session in your account.{" "}
              </P>
            </div>

            <IconCard
              title={data.appointment?.title}
              subtitle={data.appointment?.description}
              icon={<UserIcon width={24} height={24} />}
            >
              <div className="flex justify-between gap-2 w-full">
                <P weight="medium">{selectedTime?.day}</P>
                <P muted>{selectedTime?.time}</P>
              </div>
              <div className="flex flex-wrap gap-2 my-2">
                {tags?.map(tag => (
                  <Tag key={tag} selectable={false} selected={false}>
                    {tag}
                  </Tag>
                ))}
              </div>
            </IconCard>
            <Button
              variant="primary"
              onClick={() => navigate("/therapy/sessions")}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </ModalFullScreenInner>
  )
}
