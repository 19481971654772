import { gql } from "@apollo/client"

export const CUSTOM_SUPPORT_FIELDS = gql`
  fragment CustomSupportFields on User {
    id
    therapyPackages {
      identifier
      therapyPackages {
        numberSessionsUsed
        numberOfSessions
        expirationDate
      }
      companyPackageSetting {
        id
        name
        userFacingDescription
        icon
        iconUrl
        numberSessions
        packageType
      }
      mostRecentTherapyExtensionRequest {
        id
      }
    }
  }
`
