import { gql } from "@apollo/client"

import { fragments as ProductUpdatesFragments } from "./components/ProductUpdates.fragments"

export const fragments = {
  queryFields: gql`
    fragment AdminHomeQueryFields on Query {
      user {
        id
        hasCompletedAdminOnboarding
        company {
          id
          subscriptionStatus
          inActiveTrialPeriod
          trialPeriodEndDate
          trialStartDate
          currentSessionPackUsage
          subscriptionStatus
          featuresAndSettings {
            askATherapist {
              active
              value
            }
            pulse {
              active
              value
            }
            sessionPack {
              value
            }
          }
          billableUserCount
          subscriptionPlan
          onboardingSteps {
            hasApprovedSettings
            hasAddedTeam
            hasSeenUserView
          }
          platforms {
            id
            hasAnnouncedOnboarding
            platformType
          }
          allUsage {
            therapyHours
            askATherapistQuestions
            distinctUserPulseTriages
            month
          }
          recommendedActions {
            companyId
            title
            message
            icon
            url
            dismissable
          }
        }
      }
    }
  `,
}

export const queries = {
  getCompany: gql`
    query AdminHomeGetCompany {
      ...AdminHomeQueryFields
      ...ProductUpdatesGetProductUpdates
    }
    ${fragments.queryFields}
    ${ProductUpdatesFragments.getProductUpdates}
  `,
}
