import { z } from "zod"

export const formSchema = z.object({
  question: z
    .string()
    .min(1, {
      message: "Please enter a question",
    })
    .refine(value => value.trim().length > 0, {
      message: "Please enter a question",
    }),
  counsellorId: z.string().optional(),
})

export type AskATherapistForm = z.infer<typeof formSchema>
