/**
 * A card that gives users a quick overview of the plan they are on, and suggests another
 * option that they can switch to.
 */

import { FunctionComponent, useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { toast } from "sonner"
import { Button, H2, H3, P, Indicator } from "@spillchat/puddles"
import { CheckIcon, LinkIcon } from "@heroicons/react/24/outline"

import { cn } from "common/helpers/cn"
import { useTheme } from "common/hooks/useTheme"
import {
  PlanCardGetDataQuery,
  PlanCardGetDataQueryVariables,
  RemainingAllowance,
} from "types/graphql"
import { Tooltip } from "common/components/Tooltip"
import { formatCurrency } from "common/helpers/formatNumber"

import { useTherapyContext } from ".."

type AddonItemIdsKeys = "perUser" | "perQuestion" | "perSession"

interface PlanCardProps {
  /**
   * Whether this is the current plan or not.
   */
  isCurrent: boolean
  /**
   * The function to trigger when we select this plan.
   */
  onSelect: () => void
}

export const fragments = {
  queryFields: gql`
    fragment PlanCardQueryFields on Query {
      company {
        id
        currencyCode
        currentAndEstimatedPricing {
          currentMonthlySpend
          estimatedMonthlySpend
        }
        billableUserCount
        remainingAllowance {
          month
          oneOffsCovered
          coursesCovered
        }
        planPrice {
          perUser
        }
      }
      getSuggestedPlanDetails {
        perUser
        perQuestion
        perSession
      }
    }
  `,
}

export const queries = {
  getPlan: gql`
    query PlanCardGetData {
      ...PlanCardQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const PlanCard: FunctionComponent<PlanCardProps> = props => {
  const { colors } = useTheme()
  const { data, loading, error } = useQuery<
    PlanCardGetDataQuery,
    PlanCardGetDataQueryVariables
  >(queries.getPlan)
  const [formattedPrice, setFormattedPrice] = useState({
    monthly: "",
    annual: "",
  })

  const {
    currentSessionCoverage,
    setEstimatedMonthlyCost,
    setIndividualUnitPrice,
    setCurrentSessionCoverage,
  } = useTherapyContext()

  // set the current coverage data, if we haven't already, to be displayed on the current plan side
  // (this is set in the downgrade chart but we want to make sure we set it even if the advice is not to downgrade)
  const getLastCoverageData = (
    usageData: Partial<RemainingAllowance>[] | undefined
  ) => {
    return usageData?.[usageData.length - 1] ?? {}
  }

  const calculateCoverage = (lastCoverageData: Partial<RemainingAllowance>) => {
    const oneOffsCovered = lastCoverageData.oneOffsCovered ?? 0
    const coursesCovered = lastCoverageData.coursesCovered ?? 0

    const oneOffsUsage = oneOffsCovered
    const coursesUsage = coursesCovered * 6.5

    return oneOffsUsage + coursesUsage
  }

  const coverageData = data?.company?.remainingAllowance
  const lastCoverageData = getLastCoverageData(coverageData)

  useEffect(() => {
    if (coverageData !== null) {
      const newCoverage = Math.round(calculateCoverage(lastCoverageData) * 12)
      setCurrentSessionCoverage(newCoverage)
    }
  }, [currentSessionCoverage, coverageData, lastCoverageData])

  const currentMonthlySpend =
    (data?.company?.currentAndEstimatedPricing.currentMonthlySpend ?? 1) /
    (data?.company?.billableUserCount ?? 1)

  /**
   * Calculates the total for a plan. Takes into account the number of users
   * in the company.
   */
  useEffect(() => {
    const itemCost = props.isCurrent
      ? { price: currentMonthlySpend, currency: data?.company?.currencyCode }
      : { price: getSuggestedPlanItem("perUser"), currency: "GBP" }
    const userCount = data?.company?.billableUserCount

    if (itemCost?.price == null || userCount == null) {
      setFormattedPrice({
        monthly: "-",
        annual: "-",
      })

      setIndividualUnitPrice("-")
      return
    }

    const monthlyPrice = itemCost.price * userCount

    setFormattedPrice({
      monthly: formatCurrency(monthlyPrice, itemCost.currency),
      annual: formatCurrency(monthlyPrice * 12, itemCost.currency),
    })
    setIndividualUnitPrice(formatCurrency(itemCost.price, itemCost.currency))
    setEstimatedMonthlyCost(formatCurrency(itemCost.price * userCount))
  }, [data?.company, loading, props.isCurrent])

  if (error) {
    toast.error(
      "We can't currently suggest a plan for you. Please get in touch with us."
    )
    return null
  }

  /**
   * Returns the suggested plan item based on the feature we pass in.
   */
  const getSuggestedPlanItem = (feature: AddonItemIdsKeys) => {
    return data?.getSuggestedPlanDetails?.[feature]
  }

  /**
   * Returns the suggested price for a given feature. We can optinally remove
   * the formatting if we want to do calculations with the price.
   */
  const getSuggestedPrice = (feature: AddonItemIdsKeys, format = true) => {
    const pricedItem = data?.getSuggestedPlanDetails?.[feature]

    /**
     * When the item is the perUser and it's the current plan, we need to
     * add the amount of current users to the price.
     */
    const isLegacyPerUserItem = feature === "perUser" && props.isCurrent
    const legacyPlanPriceDifference =
      (data?.company?.planPrice?.perUser ?? 0) - 4

    const priceOfItem = isLegacyPerUserItem
      ? Math.max(pricedItem ?? 0 + legacyPlanPriceDifference, 0)
      : (pricedItem ?? 0)

    if (format) {
      const formattedPrice = formatCurrency(priceOfItem, "GBP")
      return formattedPrice
    }

    return priceOfItem
  }

  if (loading) {
    return (
      <div className="flex flex-col relative w-full h-full border-grey-200 border rounded-lg p-4 lg:p-8 bg-grey-100 min-h-[700px] animate-pulse" />
    )
  }

  return (
    <div className="flex flex-col gap-8 relative w-full h-full border-grey-200 border rounded-lg p-8">
      <div className={cn("absolute -top-3")}>
        <Indicator variant={props.isCurrent ? "info" : "ok"}>
          {props.isCurrent ? "Current" : "Suggested"}
        </Indicator>
      </div>
      <div className="flex flex-col gap-4">
        {props.isCurrent ? <H3>Legacy plan</H3> : <H3>Pay–as–you–go</H3>}
      </div>
      <div className="flex flex-col gap-4">
        <H2>
          {getSuggestedPrice("perUser")}{" "}
          <P size="xs" muted>
            per person per month
          </P>
        </H2>
        <div className="w-2/3">
          <P>
            {props.isCurrent ? (
              <>
                <strong>+ therapy pot</strong> of {currentSessionCoverage}{" "}
                sessions per year at{" "}
                {formatCurrency(
                  currentMonthlySpend > 4
                    ? currentMonthlySpend - 4
                    : currentMonthlySpend
                )}{" "}
                / person per month
              </>
            ) : (
              <>
                <strong>+ pay-as-you-go</strong> at{" "}
                {getSuggestedPrice("perSession")} / session and{" "}
                {getSuggestedPrice("perQuestion")} / Ask a Therapist
              </>
            )}
          </P>
        </div>
        {props.isCurrent ? (
          <Button variant="secondary" onClick={props.onSelect}>
            Stay on Legacy Plan
          </Button>
        ) : (
          <Button variant="primary" onClick={props.onSelect}>
            Switch to Pay–as–you–go Plan
          </Button>
        )}
        <div className="flex flex-col gap-2">
          <ul className="flex flex-col gap-4">
            <li>
              {props.isCurrent ? (
                <div className="flex items-center gap-2">
                  <CheckIcon className="size-4" color={colors.teal[400]} />
                  <P>Pay for a pot of therapy</P>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <CheckIcon className="size-4" color={colors.teal[400]} />
                  <P>Pay for therapy when used</P>
                  <Indicator variant="ok">New</Indicator>
                </div>
              )}
            </li>
            <li>
              <div className="flex items-center gap-2">
                <CheckIcon className="size-4" color={colors.teal[400]} />
                <div className="grow">
                  <P>
                    Next day{" "}
                    <Tooltip content="Spill Therapy reliably reduces negative mental health symptoms in 72% of cases. We do this by only hiring the top 13% of qualified therapist applicants and then providing extra supervision and training, as well as by letting clients select therapists by specialism.">
                      <span className="inline-flex underline decoration-dotted">
                        Spill Therapy
                      </span>
                    </Tooltip>{" "}
                    sessions
                  </P>
                </div>
              </div>
            </li>
            <li>
              <div className="flex items-center gap-2">
                <CheckIcon className="size-4" color={colors.teal[400]} />
                <P>
                  Proactive care with{" "}
                  <Tooltip content="Spill regularly asks your team how they’re feeling and then flags anyone at risk of poor mental health or burnout. A Spill counsellor will then reach out to anyone at risk.">
                    <span className="inline-flex underline decoration-dotted">
                      Spill Safety Net
                    </span>
                  </Tooltip>
                </P>
              </div>
            </li>
            <li>
              <div className="flex items-center gap-2">
                <CheckIcon className="size-4" color={colors.teal[400]} />
                <P>Easy access via Slack or MS Teams</P>
              </div>
            </li>
            {props.isCurrent === false && (
              <li>
                <div className="flex items-center gap-2">
                  <CheckIcon className="size-4" color={colors.teal[400]} />
                  <P>
                    Manage spend with{" "}
                    <a
                      href="https://spill.notion.site/spill/Introducing-Therapy-Budgets-4610a28dd2ad42b1ad573e9ba8742f27"
                      title="Introducing Therapy Budgets"
                      className="inline-flex underline items-center gap-0.5"
                    >
                      <span>Therapy Budgets</span>
                      <LinkIcon className="size-4" />
                    </a>
                  </P>
                  <Indicator variant="ok">New</Indicator>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-2 flex-1 justify-end">
        <div className="flex justify-between w-full">
          <P weight="medium">Monthly bill</P>
          <div className="flex gap-1 justify-between items-center">
            <Tooltip
              className="tooltip tooltip-sm min-w-[280px]"
              content={
                props.isCurrent
                  ? "This is your per person cost (£4 + therapy pot cost) multiplied by the number of people on the team who currently have access to Spill."
                  : "This is your per person cost (£4) multiplied by the number of people on the team who currently have access to Spill. This is the minimum cost per month assuming no one uses a therapy session."
              }
            >
              <div className="inline-flex underline decoration-dotted">
                <P>From {formattedPrice.monthly} / month</P>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <P weight="medium">Estimated annual bill</P>
          <div className="flex gap-1 justify-between items-center">
            <Tooltip
              className="tooltip tooltip-sm min-w-[280px]"
              content={
                props.isCurrent
                  ? "This is your monthly bill x 12. It’s estimated because the exact cost will fluctuate depending on how many people have access to Spill each month."
                  : "This is your monthly per person cost (x 12) and Spill’s estimate of how much therapy the team will use in the year. For example, if your team used 7 sessions last year, we’ll add the cost of 7 sessions to your estimated annual bill. We always estimate to include at least 5 sessions (even if past usage has been lower), to avoid surprising bills."
              }
            >
              <div className="inline-flex underline decoration-dotted">
                {props.isCurrent ? (
                  <P>From {formattedPrice.annual} / year</P>
                ) : (
                  <P>
                    From{" "}
                    {formatCurrency(
                      (data?.company?.currentAndEstimatedPricing
                        ?.estimatedMonthlySpend ?? 0) * 12
                    )}{" "}
                    / year
                  </P>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}
