import { Form, H3, Input, P, ToggleButton } from "@spillchat/puddles"
import { FunctionComponent, useMemo } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

interface CopingRatingProps {
  checkValid: () => void
}

export const CopingRating: FunctionComponent<CopingRatingProps> = ({
  checkValid,
}) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const copingRating = useMemo(() => {
    return form.getValues("preSession.copingRating")
  }, [form.watch("preSession.copingRating")])

  const updateCopingRating = (rating: number) => {
    if (rating === copingRating) {
      form.setValue("preSession.copingRating", null)
      checkValid()
      return
    }
    form.setValue("preSession.copingRating", rating)
    checkValid()
  }

  return (
    <div className="flex flex-col gap-3 fieldsContainer">
      <div className="flex flex-col gap-2">
        <H3>With this is mind how have you been coping the last few weeks?</H3>
        <P muted>1 is not well at all, 10 is extremely well</P>
      </div>
      <Form.Field
        control={form.control}
        name="preSession.copingRating"
        render={({ field }) => (
          <Form.Item className="w-full">
            <Form.Control>
              <Input {...field} value={field.value ?? ""} type="hidden" />
            </Form.Control>
            <div className=" grid grid-cols-5 lg:grid-cols-10 gap-2 ">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rating => {
                return (
                  <div key={rating} className="col-span-1">
                    <ToggleButton
                      widthClass="w-full"
                      toggled={copingRating === rating}
                      onClick={() => updateCopingRating(rating)}
                    >
                      {rating}
                    </ToggleButton>
                  </div>
                )
              })}
            </div>
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
