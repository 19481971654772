import { QueryResult } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { H2, Separator, P, H3, Button, Alert } from "@spillchat/puddles"
import { EyeSlashIcon } from "@heroicons/react/24/outline"
import { useMutation } from "@apollo/client"
import { toast } from "sonner"

import { useAnalytics } from "common/context/analyticsContext"
import { Avatar } from "common/components/Avatar"
import { formatDate } from "common/helpers/formatDate"
import {
  AskATherapistPageAllowAddingToAdviceLibraryMutation as AllowMutationData,
  AskATherapistPageAllowAddingToAdviceLibraryMutationVariables as AllowMutationVars,
  AskATherapistPageCancelAllowAddingToAdviceLibraryMutation as CancelMutationData,
  AskATherapistPageCancelAllowAddingToAdviceLibraryMutationVariables as CancelMutationVars,
} from "types/graphql"

import { mutations } from "../ask-a-therapist.mutations"

import { type Question } from "./ViewQuestionModal"

export function ViewQuestionAndResponse({
  question,
  followUp,
  refetchQuestion,
  showAdviceLibrary,
}: {
  question: Question
  followUp?: boolean
  refetchQuestion: QueryResult["refetch"]
  showAdviceLibrary?: boolean
}): JSX.Element {
  const navigate = useNavigate()

  // will never happen as parent will error out if question is null
  if (question == null || question === undefined) {
    navigate("/ask-a-therapist")
    return <></>
  }

  const counsellorName = `${question.counsellorFirstName ?? "Spill"} ${question.counsellorLastName ?? "Therapist"}`

  const counsellorAvatarUrl = question.counsellorAvatarUrl as unknown as string

  const { track } = useAnalytics()

  const [allowAddingToAdviceLibrary, { loading: isAllowSubmitting }] =
    useMutation<AllowMutationData, AllowMutationVars>(
      mutations.allowAddingToAdviceLibrary,
      {
        onError: () => {
          toast.error("Something went wrong, please try again or get in touch.")
        },
        onCompleted: () => {
          track("User submitted their question to the advice library")
        },
      }
    )

  const [cancelAllowAddingToAdviceLibrary, { loading: isCancelSubmitting }] =
    useMutation<CancelMutationData, CancelMutationVars>(
      mutations.cancelAllowAddingToAdviceLibrary,
      {
        onError: () => {
          toast.error("Something went wrong, please try again or get in touch.")
        },
        onCompleted: () => {
          track("User submitted their question to the advice library")
        },
      }
    )

  const handleSubmit = async (id: string) => {
    await allowAddingToAdviceLibrary({
      variables: {
        id: id,
      },
    })

    await refetchQuestion()
  }

  const handleCancel = async (id: string) => {
    await cancelAllowAddingToAdviceLibrary({
      variables: {
        id: id,
      },
    })
    await refetchQuestion()
  }

  return (
    <>
      <div className="flex flex-col items-start gap-8 self-stretch">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <H2>
            {followUp == true ? "Your follow-up question" : "Your question"}
          </H2>
          <P muted>
            <span className="whitespace-pre-line [overflow-wrap:anywhere]">
              {question.question}
            </span>
          </P>
          <P size="xs" weight="medium">
            {" "}
            Asked {formatDate(new Date(question.createdAt))}
          </P>
        </div>
      </div>
      <Separator className="bg-spill-grey-200" />
      <div className="flex flex-col items-start gap-8 self-stretch">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <div className="flex justify-between items-center self-stretch">
            <H2>Therapist Answer</H2>
            <div className="flex items-center gap-4">
              <EyeSlashIcon className="w-6 h-6 flex-shrink-0 text-spill-blue-800" />
              <P muted size="xs">
                Only visible to you
              </P>
            </div>
          </div>
          <div className="flex flex-col items-start gap-5">
            <P muted>
              <span className="whitespace-pre-line [overflow-wrap:anywhere]">
                {question.counsellorResponse}
              </span>
            </P>
            {question.spillResponse != null && (
              <div className="flex items-center gap-6 self-stretch">
                <div className="flex flex-col items-start gap-3">
                  <H3>Spill's 2p</H3>
                  <P muted>
                    <span className="whitespace-pre-line">
                      {question.spillResponse}
                    </span>
                  </P>
                </div>
              </div>
            )}
            {question.answeredAt != null && (
              <P size="xs" weight="medium">
                Answered {formatDate(new Date(question.answeredAt))}
              </P>
            )}
          </div>
        </div>
        <Separator className="bg-spill-grey-100" />
        <div className="flex flex-col items-start gap-8 self-stretch">
          <div className="flex items-end gap-16">
            <div className="flex flex-col items-start gap-3">
              <P muted size="xs">
                Response from
              </P>
              <div className="flex pb-px justify-end items-center">
                <div className="flex items-start gap-2">
                  <Avatar
                    url={counsellorAvatarUrl}
                    name={counsellorName}
                    size="lg"
                  />
                  <div className="flex flex-col items-start gap-2">
                    <P weight="medium">{counsellorName}</P>
                    <P muted size="xs">
                      {question.counsellorProfessionalBody}
                    </P>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex flex-col items-start gap-2 self-stretch">
            <P size="xs" weight="medium">
              Bio
            </P>
            <P muted size="xs">
              {question.counsellorBio}
            </P>
          </div>
          {showAdviceLibrary == true && (
            <>
              <Separator className="bg-spill-grey-100" />
              <div className="flex flex-col items-start gap-5 self-stretch">
                <div className="flex items-center gap-6 self-stretch">
                  <div className="flex flex-col items-start gap-3">
                    <H3>Add this to Advice Library</H3>
                    <P muted size="xs" className="whitespace-pre-line">
                      You can opt to have your question and answered added to
                      the library of searchable advice. It covers different
                      topics and is open to everyone on Spill. Once your
                      submission is approved, it can’t be revoked
                    </P>
                  </div>
                </div>
                {question.allowedAddingToAdviceLibraryAt == null &&
                (followUp == null || followUp == false) ? (
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={async () => await handleSubmit(question.id)}
                    disabled={isAllowSubmitting}
                  >
                    Submit for review
                  </Button>
                ) : question.addedToAdviceLibraryAt != null ? (
                  <Alert
                    variant="success"
                    title="Added to Advice Library"
                    color="blue"
                  >
                    <P size="xs">
                      We’ve approved your submission to the library of
                      searchable advice, and your question and answer are
                      available for others to read.
                    </P>
                  </Alert>
                ) : (
                  <div className="flex items-center gap-4">
                    <Button size="sm" variant="primary" disabled>
                      Requested
                    </Button>
                    <Button
                      size="sm"
                      variant="destructive"
                      color="red"
                      disabled={isCancelSubmitting}
                      onClick={async () => await handleCancel(question.id)}
                    >
                      Cancel Submission
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}

          <Separator className="bg-spill-grey-200" />
        </div>
      </div>
    </>
  )
}
