import { H4, P, SelectableCard } from "@spillchat/puddles"
import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import {
  CalendarDateRangeIcon,
  CurrencyPoundIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline"

import { BooleanFeature, SpillSubscriptionPlan } from "types/graphql"
import { useAuth } from "common/context/authContext"

import { BillingFormValues } from "."

interface PlusOneProps {
  plusOneFeaturesAndSettings?: BooleanFeature
  capPeriod?: number
}

export const PlusOneSettings: FunctionComponent<PlusOneProps> = ({
  plusOneFeaturesAndSettings,
  capPeriod,
}: PlusOneProps) => {
  const { user } = useAuth()
  const { watch, setValue, register } = useFormContext<BillingFormValues>()

  useEffect(() => {
    setValue("plusOne", plusOneFeaturesAndSettings?.value ?? false)
  }, [])

  if (plusOneFeaturesAndSettings?.active !== true) {
    return <></>
  }

  const plusOneValue = watch("plusOne")

  const isLegacyCompany =
    user?.company?.subscriptionPlan != null &&
    [SpillSubscriptionPlan.LITE, SpillSubscriptionPlan.PROPER].includes(
      user?.company.subscriptionPlan
    )

  return (
    <div className="flex flex-col gap-4">
      <H4 sectionHeader>Plus Ones</H4>
      <P>
        Choose whether employees can gift a free session to friends or family.
      </P>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <SelectableCard
          size="lg"
          title="Plus Ones"
          checked={plusOneValue}
          type="checkbox"
          subtitle="Allow people to gift a session to a loved one"
          {...register("plusOne")}
          onClick={() => setValue("plusOne", !plusOneValue)}
        >
          <div className="mt-5 flex flex-col gap-3">
            <P weight="medium">For your plan:</P>
            <span className="flex flex-row items-center gap-2">
              <CalendarDateRangeIcon className="size-4 text-grey-400" />
              <P size="xs">Sessions come out of employees’ individual cap</P>
            </span>
            <span className="flex flex-row items-center gap-2">
              <CurrencyPoundIcon className="size-4 text-grey-400" />
              <P size="xs">
                {isLegacyCompany
                  ? "No additional cost to you"
                  : "Sessions charged as standard"}
              </P>
            </span>
            <span className="flex flex-row items-center gap-2">
              <UserPlusIcon className="size-4 text-grey-400" />
              <P size="xs">
                Maximum 1 Plus One per employee, every {capPeriod ?? 6} months
              </P>
            </span>
          </div>
        </SelectableCard>
      </div>
    </div>
  )
}
