import { gql } from "@apollo/client"

export const queries = {
  getNeedsData: gql`
    query AdminTherapyNeeds {
      user {
        id
        company {
          id
          lockedBudget
          therapyBudgetResetDate
          trialStartDate
          featuresAndSettings {
            yearlyTherapyBudget {
              value
            }
            sessionPack {
              active
            }
          }
          budgetYearUsage {
            totalBillableUsage
          }
          billingAdvice
        }
      }
    }
  `,
}
