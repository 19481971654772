import { gql } from "@apollo/client"

export const therapyRescheduleQueries = {
  getAvailableAppointmentSlots: gql`
    query GetAppointmentRescheduleInfo($id: ID!) {
      appointment(id: $id) {
        id
        appointmentType
        startsAt
        status
        duration
        title
        description
        counsellor {
          id
          calendarId
          firstName
          lastName
          videoCallUrl
          avatarUrl
          bio
        }
      }
      user {
        id
        therapyProfile {
          email
        }
      }
    }
  `,
}
