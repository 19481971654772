import { P } from "@spillchat/puddles"
import { ReactNode } from "react"

import { Avatar } from "common/components/Avatar"
import { AdviceLibraryGetCounsellorQuery } from "types/graphql"

type AdviceLibraryCounsellorProps = {
  counsellor: AdviceLibraryGetCounsellorQuery["questionAnsweringCounsellor"]
}

export default function AdviceLibraryCounsellor({
  counsellor,
}: AdviceLibraryCounsellorProps): ReactNode {
  return (
    <div className="flex flex-col gap-8 border-y py-8">
      <div className="flex flex-col gap-2">
        <P muted>Response from</P>
        <div className="flex gap-4 items-center">
          {counsellor.avatarUrl != null ? (
            <Avatar
              url={counsellor.avatarUrl}
              name={counsellor.firstName + counsellor.lastName}
              size="lg"
            />
          ) : (
            <span className="w-12 h-12 bg-mono-black rounded-full flex"></span>
          )}
          <div className="flex flex-col">
            <P weight="medium">
              {counsellor?.firstName} {counsellor?.lastName}
            </P>
            <div className="flex items-center gap-4">
              <P size="xs" muted>
                {counsellor.professionalBody != null &&
                  counsellor.professionalBody + " "}
                Counsellor
              </P>
            </div>
          </div>
        </div>
      </div>
      {counsellor.bio != null && (
        <div className="flex flex-col gap-2">
          <P weight="medium">Bio</P>
          <P muted>{counsellor.bio}</P>
        </div>
      )}
    </div>
  )
}
