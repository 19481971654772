import { useLocation, useNavigate } from "react-router-dom"

type GoBackProps = { to?: string }

interface useGoBackProps {
  isAdmin?: boolean
}

export function useGoBack({ isAdmin = false }: useGoBackProps = {}): (
  props?: GoBackProps
) => void {
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = (props?: GoBackProps) => {
    if (props?.to != null) {
      return navigate(props?.to)
    }

    if (location.key === "default") {
      if (isAdmin === true) {
        return navigate("/admin")
      }
      return navigate("/")
    } else {
      return navigate(-1)
    }
  }

  return goBack
}
