import { FunctionComponent, useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { AnimatePresence } from "framer-motion"
import { Helmet } from "react-helmet-async"

import { useApp } from "common/context/appContext"
import { useAuth } from "common/context/authContext"
import { NotFound404Page } from "common/components/NotFound404Page"
import { RequireAuth } from "features/auth/components/RequireAuth"

import { StepperProgress } from "../../components/StepperProgress"

import { GroupSelect } from "./GroupSelect"
import { InitialPlatformSetup } from "./InitialPlatformSetup"
import { PlatformSelect } from "./PlatformSelect"
import { SetupSuccessUnauthenticated } from "./SetupSuccessUnauthenticated"
import { TeamsPermissionsPending } from "./TeamsPermissionsPending"
import { LinkUserAccounts } from "./LinkUserAccounts"

export const platformSetupAnimationVariants = {
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
}

export type PlatformTypeInfo = {
  name: string
  gatewayPath: string
  companyPlatformTerm: string
  tutorialWistiaId: string
}

export const platformTypesInfo: Record<string, PlatformTypeInfo> = {
  slack: {
    name: "Slack",
    gatewayPath: "/auth/slack/set-up",
    companyPlatformTerm: "workspace",
    tutorialWistiaId: "90xfxev8cn",
  },
  teams: {
    name: "Teams",
    gatewayPath: "/auth/teams/set-up",
    companyPlatformTerm: "organization",
    tutorialWistiaId: "",
  },
}

/**
 * Steps for setting up Spill on a platform.
 *
 * Partially works for unauthenticated users, so parts can be completed by a Slack/Teams admin even
 * if they're not the buyer.
 */
export const PlatformSetup: FunctionComponent = () => {
  const location = useLocation()
  const { user } = useAuth()

  const { setPageBlob } = useApp()
  useEffect(() => setPageBlob("none"), [])

  const steps = [
    { id: 1, name: "Choose Platform" },
    { id: 2, name: "Add Spill" },
    { id: 3, name: "Add Users" },
    { id: 4, name: "Link Accounts" },
  ]

  return (
    <>
      <Helmet title={`Add Spill to your team | Spill`} />

      <div className="flex items-center justify-center bg-mono-white">
        <div className="flex flex-col space-y-12 justify-center max-w-screen-lg grow">
          <AnimatePresence mode="wait">
            <Routes location={location}>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <StepperProgress currentStep={1} steps={steps} />
                    <PlatformSelect />
                  </RequireAuth>
                }
              />
              <Route
                path="/:platformType"
                element={
                  <>
                    <StepperProgress currentStep={2} steps={steps} />
                    <InitialPlatformSetup />
                  </>
                }
              />
              <Route
                path="/teams/pending/:platformExternalId"
                element={
                  <>
                    <StepperProgress currentStep={2} steps={steps} />
                    <TeamsPermissionsPending />
                  </>
                }
              />
              <Route
                path="/:platformType/success"
                element={
                  // The backend redirects here after installing the Slack app.
                  // It doesn't know if the user is authenticated at that point,
                  // so both of these pages have to be at the same route.
                  user ? (
                    <>
                      <StepperProgress currentStep={3} steps={steps} />
                      <GroupSelect />
                    </>
                  ) : (
                    <SetupSuccessUnauthenticated />
                  )
                }
              />
              <Route
                path="/:platformType/link"
                element={
                  <>
                    <StepperProgress currentStep={4} steps={steps} />
                    <LinkUserAccounts />
                  </>
                }
              />
              <Route path="*" element={<NotFound404Page />} />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}
