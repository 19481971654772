import { ColumnDef } from "@tanstack/react-table"
import { DataTable, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { CompanySharedFeedback } from "types/graphql"

export type FeedbackTableRow = Omit<CompanySharedFeedback, "id">

type FeedbackTableProps = {
  data: FeedbackTableRow[]
}

export const NewFeedbackTable: FunctionComponent<FeedbackTableProps> = ({
  data,
}: FeedbackTableProps) => {
  const columns: ColumnDef<FeedbackTableRow>[] = [
    {
      accessorKey: "fullName",
      header: "Name",
      enableSorting: true,
      cell: ({ row }) => {
        const feedback = row.original
        const userName = feedback.userName ?? "Anonymous"
        return (
          <div className="block py-2">
            <span className={userName === "Anonymous" ? "italic" : ""}>
              <P>{userName}</P>
            </span>
          </div>
        )
      },
    },
    {
      id: "feedback",
      header: "Comment",
      cell: ({ row }) => {
        const feedback = row.original
        return <P muted>{feedback.feedbackText}</P>
      },
    },
    {
      id: "month",
      header: "Date",
      cell: ({ row }) => {
        const feedback = row.original
        const date = new Date(feedback.month)
        return (
          <span className="whitespace-nowrap">
            <P>
              {date.toLocaleString("default", {
                month: "short",
                day: "numeric",
              })}{" "}
              {date.getFullYear()}
            </P>
          </span>
        )
      },
    },
    {
      id: "month",
      header: "User type",
      cell: () => {
        return (
          <span className="whitespace-nowrap">
            <P>Placeholder for now</P>
          </span>
        )
      },
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={true} />
}
