import { Column, ColumnDef } from "@tanstack/react-table"
import {
  DataTable,
  Indicator,
  P,
  DataTableColumnSort,
} from "@spillchat/puddles"
import { FunctionComponent, ReactElement } from "react"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

import { AccountStatus, PlatformType, UserRole } from "types/graphql"
import { convertPlatformTypeToName } from "features/admin/helpers/convertPlatformTypeToName"

import { AccessTableAction } from "./AccessTableAction"

export type TableUser = {
  accountStatus: AccountStatus
  fullName: string
  identifier?: string
  platformType?: PlatformType
  role: Exclude<UserRole, UserRole.PLUS_ONE>
  email?: string
} & ({ userId: string } | { platformUserId: string })

type AccessTableProps = {
  data: TableUser[]
  userId?: string
}

const roleMap = {
  [UserRole.ADMIN]: "Admin",
  [UserRole.STANDARD]: "Standard",
}

const statusMap = {
  [AccountStatus.ACTIVE]: "Active",
  [AccountStatus.NO_ACCESS]: "Disabled",
  [AccountStatus.NO_ACTIVE_SUBSCRIPTION]: "Active",
  [AccountStatus.NOT_FOUND]: "Not Found",
  [AccountStatus.PENDING_INTEGRATION]: "Pending",
}

export const AccessTable: FunctionComponent<AccessTableProps> = ({
  data,
  userId,
}: AccessTableProps) => {
  const columns: ColumnDef<TableUser>[] = [
    {
      accessorKey: "identifier",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return <DataTableColumnSort<TableUser> name="Name" column={column} />
      },
      cell: ({ row }) => {
        const person = row.original
        return (
          <div className="flex gap-1 items-center">
            <span>{person.identifier}</span>
            {person.accountStatus === AccountStatus.NOT_FOUND && (
              <ExclamationCircleIcon className="text-red-600 size-4" />
            )}
          </div>
        )
      },
    },
    {
      accessorKey: "role",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return <DataTableColumnSort<TableUser> name="Role" column={column} />
      },
      cell: ({ row }) => roleMap[row.original.role],
    },
    {
      accessorKey: "platformType",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return (
          <DataTableColumnSort<TableUser> name="Invited via" column={column} />
        )
      },
      cell: ({ row }) =>
        row.original.platformType != null
          ? convertPlatformTypeToName(row.original.platformType)
          : "-",
    },
    {
      accessorKey: "accountStatus",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return (
          <DataTableColumnSort<TableUser>
            name="Billing status"
            column={column}
          />
        )
      },
      cell: ({ row }) => {
        const accountStatus = row.original.accountStatus
        const status = statusMap[accountStatus]

        let indicator: "ok" | "issue" | "warning"

        if (
          accountStatus == AccountStatus.ACTIVE ||
          accountStatus == AccountStatus.NO_ACTIVE_SUBSCRIPTION
        ) {
          indicator = "ok"
        } else if (accountStatus == AccountStatus.PENDING_INTEGRATION) {
          indicator = "warning"
        } else {
          indicator = "issue"
        }

        return <Indicator variant={indicator}>{status}</Indicator>
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const person = row.original
        return (
          <AccessTableAction
            person={person}
            originalPlatform={row.original.platformType}
            loggedInUserId={userId}
          />
        )
      },
    },
  ]

  return (
    <div>
      <P weight="bold" className="-mb-3">
        Find a team member
      </P>
      <DataTable
        data={data}
        columns={columns}
        showPagination={true}
        searchColumn="identifier"
        searchPlaceholder="e.g. Jane Doe"
      />
    </div>
  )
}
