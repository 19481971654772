import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Alert,
  Breadcrumbs,
  Button,
  Form,
  H2,
  InputStepper,
  Label,
  ModalFullScreenInner,
  P,
  Select,
  SelectableCard,
  Tabs,
  TextArea,
  Tooltip,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import { addYears, format } from "date-fns"

import {
  InviteParenthoodCreateTherapyPackageMutation,
  InviteParenthoodCreateTherapyPackageMutationVariables,
  InviteParenthoodUserListQuery,
  AccountStatus,
  PackageType,
  PeopleListItemMakeAppUserMutation,
  PeopleListItemMakeAppUserMutationVariables,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  useSendEmail,
  formatEmailParameters,
} from "common/hooks/api/useSendEmail"

import { useGoBack } from "../../common/hooks/useGoBack"

import { mutations } from "./invite-parenthood.mutations"
import { queries } from "./invite-parenthood.queries"
import { formSchema } from "./invite-parenthood.schema"

import type { InviteParenthoodForm } from "./invite-parenthood.schema"

type User = {
  // id is either the app-user id or the platform-user id
  id: string
  // If appUserId is null then we will need to
  // create one before creating therapy package
  appUserId?: string | null
  name: string
  email?: string | null
}

export const InviteParenthood: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const goBack = useGoBack({ isAdmin: true })
  const [step, setStep] = useState(0)

  const [makeAppUser] = useMutation<
    PeopleListItemMakeAppUserMutation,
    PeopleListItemMakeAppUserMutationVariables
  >(mutations.makeAppUser, {
    refetchQueries: ["PeopleListGetUser"],
    awaitRefetchQueries: true,
  })

  const { data: userData, loading: userLoading } =
    useQuery<InviteParenthoodUserListQuery>(queries.getUserList, {
      fetchPolicy: "cache-first",
    })

  const appUsers = userData?.user?.company?.users ?? []
  const pendingUsers = userData?.user?.company?.pendingUsers ?? []

  const availableUsers: User[] = appUsers
    .filter(appUser => {
      return appUser.accountStatus === AccountStatus.ACTIVE
    })
    .map(appUser => {
      return {
        id: appUser.id,
        appUserId: appUser.id,
        name: appUser.fullName,
        email: appUser.platformUsers[0]?.email,
      }
    })

  for (const platformUser of pendingUsers) {
    availableUsers.push({
      id: platformUser.id,
      appUserId: null,
      name: platformUser.name,
      email: platformUser.email,
    })
  }

  const form = Form.useForm<InviteParenthoodForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userId: "",
      notificationType: "email",
      sessionCount: 6,
      note: "",
    },
  })

  const [createTherapyPackage, { loading }] = useMutation<
    InviteParenthoodCreateTherapyPackageMutation,
    InviteParenthoodCreateTherapyPackageMutationVariables
  >(mutations.createTherapyPackage)

  const [emailUser, emailUserResult] = useSendEmail()

  if (isUserLoading || userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const sendTestEmail = async () => {
    const primaryEmail = userData?.user?.primaryEmail

    if (primaryEmail != undefined && primaryEmail !== "") {
      await emailUser({
        variables: {
          emails: [primaryEmail],
          templateName: "support-package-notification",
          parameters: formatEmailParameters({
            adminNote: form.getValues("note"),
            packageName: "Parenthood support",
            packageDescription: `Welcoming a child looks different for everyone. Get support from Spill at the moments where you need it most.

You have these extra sessions to use whenever you’d like - they're yours to use when you are going on leave, during your time off or when you're thinking about a return to work.

You will be talking to one of our parenthood specialists, who have done extensive work with new parents who have had both positive and difficult experiences with the transition into parenthood.`,
            expirationDate: format(addYears(new Date(), 2), "MMM yyyy"),
            numberOfSessions: form.getValues("sessionCount"),
          }),
        },
      })

      return toast.info(`An email has been sent to ${primaryEmail ?? "you"}`)
    }

    return toast.error("Unable to find your email")
  }

  const handleRecipient = async () => {
    const valid = await form.trigger(["userId"], {
      shouldFocus: true,
    })

    if (valid) {
      setStep(1)
    }
  }

  const handleSubmit = async (values: InviteParenthoodForm) => {
    const userFromList = availableUsers.find(user => {
      return user.id === values.userId
    })

    // If the platform user doesn't have an app-user yet,
    // then we need to create it so we have a user id to give the
    // therapy package creator
    let targetUserId
    if (userFromList?.appUserId !== null) {
      targetUserId = userFromList?.appUserId
    } else {
      const { data } = await makeAppUser({
        variables: { platformUserId: userFromList?.id },
      })
      if (data?.makeAppUserForPlatformUser?.user?.id === null) {
        throw Error(
          "Platform user " +
            userFromList?.id +
            " does not have an associated app-user and we have just failed to make one"
        )
      }
      targetUserId = data?.makeAppUserForPlatformUser?.user?.id
    }

    await createTherapyPackage({
      variables: {
        userIds: [targetUserId ?? "UnknownUser"],
        packageType: PackageType.PARENTHOOD,
        numberOfSessions: values.sessionCount ? values.sessionCount + 0.5 : 6.5,
        adminNote: values.note,
        expirationDate: addYears(new Date(), 2).toISOString(),
      },
    })

    const email = userFromList?.email

    if (values.notificationType === "email") {
      if (email === undefined || email === null) {
        return toast.error("Unable to send email to this user")
      }

      await emailUser({
        variables: {
          emails: [email],
          templateName: "support-package-notification",
          parameters: formatEmailParameters({
            adminNote: form.getValues("note"),
            packageName: "Parenthood support",
            packageDescription: `Welcoming a child looks different for everyone. Get support from Spill at the moments where you need it most.

You have these extra sessions to use whenever you’d like - they're yours to use when you are going on leave, during your time off or when you're thinking about a return to work.

You will be talking to one of our parenthood specialists, who have done extensive work with new parents who have had both positive and difficult experiences with the transition into parenthood.`,
            expirationDate: format(addYears(new Date(), 2), "MMM yyyy"),
            numberOfSessions: form.getValues("sessionCount"),
          }),
        },
      })
    }

    return setStep(2)
  }

  const selectedUser = availableUsers.find(user => {
    return user.id === form.getValues("userId")
  })

  const userListEmpty = (availableUsers ?? []).length <= 0

  const isLoading = loading || emailUserResult.loading

  const tabs = [
    { label: "User", isActive: step >= 0, step: 0 },
    { label: "Configure", isActive: step >= 1, step: 1 },
    { label: "Check", isActive: step >= 2, step: 2 },
  ]

  return (
    <>
      <Helmet title="Add Parenthood Support | Spill" />

      <ModalFullScreenInner
        title="Add Parenthood support"
        onClose={() => goBack()}
      >
        <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
          <div className="max-w-screen-sm mx-auto w-full">
            <Tabs.Root
              defaultValue={step.toString()}
              value={step.toString()}
              className="flex flex-col gap-6 lg:gap-12"
            >
              <Form.Root {...form}>
                <Breadcrumbs
                  onStepChange={setStep}
                  currentStep={step}
                  tabs={tabs}
                />
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <Tabs.Content value="0">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>Who would you like to provide support to?</H2>
                        <P>
                          Choose the employee who would benefit from support
                          with a life event on Spill:
                        </P>
                      </div>
                      <div className="flex flex-col gap-4">
                        <Form.Field
                          control={form.control}
                          name="userId"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <Select.Root
                                  {...field}
                                  disabled={userListEmpty}
                                  onValueChange={field.onChange}
                                >
                                  <Select.Trigger>
                                    <Select.Value
                                      placeholder={
                                        !userListEmpty
                                          ? "Select employee"
                                          : "No employees available"
                                      }
                                    />
                                  </Select.Trigger>
                                  <Select.Content side="bottom">
                                    {availableUsers
                                      ?.sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                      )
                                      .map(user => (
                                        <Select.Item
                                          key={user.id}
                                          value={user.id}
                                        >
                                          {user.name}
                                          {user.email !== undefined &&
                                            user.email !== null &&
                                            ` (${user.email})`}
                                        </Select.Item>
                                      ))}
                                  </Select.Content>
                                </Select.Root>
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                        <div className="flex items-center">
                          <Tooltip.Provider>
                            <Tooltip.Root>
                              <Tooltip.Trigger
                                className="cursor-help"
                                type="button"
                              >
                                <span className="underline decoration-dotted">
                                  <P size="xs">
                                    <Link to="/admin/access">
                                      {!userListEmpty
                                        ? "Employee not in list?"
                                        : "Invite your team to Spill"}
                                    </Link>
                                  </P>
                                </span>
                              </Tooltip.Trigger>

                              <Tooltip.Content>
                                <div className="flex flex-col gap-1 text-spill-mono-black">
                                  <P>
                                    Invite them to Spill first via the Support
                                    page
                                  </P>
                                  <P muted size="xs">
                                    You can find the Support page in the Therapy
                                    tab in the sidebar on the left
                                  </P>
                                </div>
                              </Tooltip.Content>
                            </Tooltip.Root>
                          </Tooltip.Provider>
                        </div>
                      </div>
                      <Button
                        disabled={userListEmpty}
                        onClick={async () => await handleRecipient()}
                        variant="primary"
                      >
                        Next
                      </Button>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="1">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2">
                        <H2>Configure their support</H2>
                        <P>
                          Configure the settings for the support you're sending
                        </P>
                      </div>
                      <div>
                        <Alert
                          title="Parenthood support sessions don’t use an employee’s session cap or company budget"
                          variant="warning"
                        >
                          <P size="xs">
                            We recommend giving up to 8 sessions to cover time
                            before leave, during leave and the return to work.
                            Each situation is different, though, so you can
                            customise support below.
                          </P>
                        </Alert>
                      </div>
                      <div className="flex flex-col gap-2">
                        <Label>
                          How would you like to notify them about their life
                          event support?
                        </Label>
                        <div>
                          <Form.Field
                            control={form.control}
                            name="notificationType"
                            render={({ field }) => (
                              <Form.Item>
                                <Form.Control>
                                  <div className="grid lg:grid-cols-2 gap-4">
                                    {[
                                      {
                                        title: "Email notification",
                                        subtitle: "Spill will email them",
                                        value: "email",
                                      },
                                      {
                                        title: "I'll tell them",
                                        subtitle: "Spill won't contact them",
                                        value: "none",
                                      },
                                    ].map(item => {
                                      return (
                                        <SelectableCard
                                          key={item.value}
                                          size="sm"
                                          checked={field.value === item.value}
                                          title={item.title}
                                          subtitle={item.subtitle}
                                          type="radio"
                                          {...form.register("notificationType")}
                                          value={item.value}
                                          onClick={() => {
                                            form.setValue(
                                              "notificationType",
                                              item.value as "email" | "none"
                                            )
                                          }}
                                        />
                                      )
                                    })}
                                  </div>
                                </Form.Control>
                                <Form.Message />
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <Label>
                          We recommend 8 sessions but you can customise your
                          package below
                        </Label>
                        <div className="flex items-start">
                          <Form.Field
                            control={form.control}
                            name="sessionCount"
                            render={({ field }) => (
                              <Form.Item className="flex flex-col">
                                <Form.Control>
                                  <InputStepper
                                    value={field.value.toString()}
                                    onStepperChange={value => {
                                      form.clearErrors("sessionCount")
                                      form.setValue(
                                        "sessionCount",
                                        parseInt(value)
                                      )
                                    }}
                                    {...form.register("sessionCount", {
                                      setValueAs: (value: string) => {
                                        form.clearErrors("sessionCount")
                                        if (isNaN(parseInt(value))) {
                                          return ""
                                        } else {
                                          return parseInt(value)
                                        }
                                      },
                                    })}
                                  />
                                </Form.Control>
                                <Form.Message className="!mt-0" />
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                      {form.getValues("notificationType") === "email" && (
                        <>
                          <Form.Field
                            control={form.control}
                            name="note"
                            render={({ field }) => (
                              <Form.Item>
                                <Form.Control>
                                  <TextArea
                                    placeholder="Write text here..."
                                    className="h-48"
                                    {...field}
                                    label={{
                                      children:
                                        "Add a note to their email notification (optional)",
                                    }}
                                  />
                                </Form.Control>
                                <Form.Message />
                                <div
                                  className="underline cursor-pointer"
                                  onKeyDown={async e => {
                                    if (e.key === "Enter") {
                                      await sendTestEmail()
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                  onClick={async () => await sendTestEmail()}
                                >
                                  <P>
                                    Send a test email to yourself to see what it
                                    looks like
                                  </P>
                                </div>
                              </Form.Item>
                            )}
                          />
                        </>
                      )}
                      <div className="flex items-center gap-4">
                        <Button
                          onClick={() => setStep(step - 1)}
                          variant="secondary"
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          loading={isLoading}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="2">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>
                          {selectedUser?.name.split(" ")[0]}'s support has been
                          added
                        </H2>
                        <P>
                          We've added the extra support for {selectedUser?.name}
                          . They'll be able to use those sessions when they go
                          to their Spill account.
                        </P>
                      </div>
                      <Button variant="secondary" asChild>
                        <Link to="/admin/therapy">Go back to Support</Link>
                      </Button>
                    </div>
                  </Tabs.Content>
                </form>
              </Form.Root>
            </Tabs.Root>
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
