import { Form, H3, Input, P, ToggleButton } from "@spillchat/puddles"
import { FunctionComponent, useMemo } from "react"

import { TherapyBookingFormSchema } from "features/therapy-booking/hooks/useTherapyBookingForm"

interface ParentLeaveProps {
  checkValid: () => void
}
export const ParentLeave: FunctionComponent<ParentLeaveProps> = ({
  checkValid,
}) => {
  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const parentalLeave = useMemo(() => {
    return form.getValues("preSession.parentalLeave.value")
  }, [form.watch("preSession.parentalLeave.value")])

  const updateParentalLeave = (status: string) => {
    if (status === parentalLeave) {
      form.setValue("preSession.parentalLeave.value", null)
      checkValid()
      return
    }
    form.setValue("preSession.parentalLeave.value", status)
    checkValid()
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-2">
        <H3>With this in mind, what is your current parental leave status?</H3>
        <P muted>Pick the option that best describes your current situation</P>
      </div>
      <Form.Field
        control={form.control}
        name="preSession.parentalLeave"
        render={({ field }) => (
          <Form.Item className="w-full">
            <Form.Control>
              <Input
                {...field}
                value={field.value?.value ?? ""}
                type="hidden"
              />
            </Form.Control>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
              {[
                "About to go on leave",
                "Currently on leave",
                "Returning to work",
              ].map(status => {
                return (
                  <div key={status} className="col-span-1">
                    <ToggleButton
                      widthClass="w-full"
                      toggled={parentalLeave === status}
                      onClick={() => updateParentalLeave(status)}
                    >
                      {status}
                    </ToggleButton>
                  </div>
                )
              })}
            </div>
            <Form.Message />
          </Form.Item>
        )}
      />
    </div>
  )
}
