import { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import { FireIcon } from "@heroicons/react/24/outline"

import { Markdown } from "common/components/Markdown"

import { useAnalyticsPulse } from "../hooks/useAnalyticsPulse"

const markdown = `## It's time to talk about burnout.
Actually, you don’t need to say anything, once in a while we’ll swap your usual team Check-in with a special edition Burnout Check-in.

**Answer three questions** to assess your risk of burnout. A Spill therapist might reach out to you depending on your results.

This is between you and Spill. **No one from your company can see your responses**.`

interface BurnoutIntroPageProps {
  nextQuestion: () => void
}

export const BurnoutIntroPage: FunctionComponent<
  BurnoutIntroPageProps
> = props => {
  const { meetingUrlId } = useParams()
  const { track } = useAnalyticsPulse()

  useEffect(() => {
    track("User Opened Burnout Intro Page")
  }, [])

  if (meetingUrlId === undefined) return null

  return (
    <div className="flex flex-col gap-md items-center max-w-lg">
      <FireIcon className="fill-red-600 size-32" />

      <div className="flex flex-col gap-sm">
        <Markdown
          className="cursor-default gap-[inherit]"
          options={{
            overrides: {
              h2: { props: { className: "leading-snug text-grey-100" } },
              p: {
                props: {
                  className: "font-semibold text-grey-100 tracking-wide",
                },
              },
            },
          }}
        >
          {markdown}
        </Markdown>
      </div>

      <button
        className="btn-neutral-dark btn-lg w-full bg-grey-400 shadow-lg"
        onClick={props.nextQuestion}
        type="button"
      >
        Continue
      </button>
    </div>
  )
}
