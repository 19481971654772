import {
  Alert,
  Button,
  Form,
  H3,
  P,
  Select,
  Separator,
  Switch,
} from "@spillchat/puddles"
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useQuery } from "@apollo/client"

import {
  BookableAppointmentType,
  Gender,
  GetUpdatedFiltersQuery,
  GetUpdatedFiltersQueryVariables,
} from "types/graphql"
import { toPascalCase } from "common/helpers/stringFormat"

import { useDefaultFilterOptions } from "../hooks/useDefaultFilterOptions"
import { therapyBookingQueries } from "../therapy-booking.queries"
import { TherapyBookingFormSchema } from "../hooks/useTherapyBookingForm"

interface TherapyBookingFiltersProps {
  onFiltersChange: () => void
  appointmentType: BookableAppointmentType
}

export const TherapyBookingFilters: FunctionComponent<
  TherapyBookingFiltersProps
> = ({ onFiltersChange, appointmentType }) => {
  const [showPreviousCounsellorFilter, setShowPreviousCounsellorFilter] =
    useState<boolean>(true)
  const [showPMIOnlyFilter, setShowPMIOnlyFilter] = useState<boolean>(true)
  const [showLanguageFilter, _setShowLanguageFilter] = useState<boolean>(true)
  const [showGenderFilter, _setShowGenderFilter] = useState<boolean>(true)
  const [showSpecialismFilter, setShowSpecialismFilter] =
    useState<boolean>(true)
  const [onlyShowPMIEnabledCounsellors, setOnlyShowPMIEnabledCounsellors] =
    useState<boolean>(false)

  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const selectedGender = form.watch("filter.gender")
  const selectedLanguage = form.watch("filter.language")
  const selectedSpecialism = form.watch("filter.specialism")
  const pmiOnly = form.watch("filter.pmiOnly")
  const counsellorIds = form.watch("filter.counsellorIds")
  const lifeEvent = form.watch("filter.lifeEvent")

  const { filters, loading: _loadingFilters } = useDefaultFilterOptions({
    appointmentType,
    lifeEventId: lifeEvent,
  })

  useEffect(() => {
    //Hide and show filters based on the appointmentType
    const lifeEvents = [
      BookableAppointmentType.BEREAVEMENT_CONSULTATION,
      BookableAppointmentType.BEREAVEMENT_SESSION,
      BookableAppointmentType.PARENTHOOD_CONSULTATION,
      BookableAppointmentType.PARENTHOOD_SESSION,
    ]

    const isLifeEvent = lifeEvents.includes(appointmentType)

    if (isLifeEvent) {
      setShowPreviousCounsellorFilter(false)
      setShowSpecialismFilter(false)
    }

    if (appointmentType === BookableAppointmentType.PMI_SESSION) {
      setOnlyShowPMIEnabledCounsellors(true)
      setShowPMIOnlyFilter(false)
    }

    const privateSessions = [
      BookableAppointmentType.PRIVATE_COURSE_SESSION_25_MINUTES,
      BookableAppointmentType.PRIVATE_THERAPY_SESSION,
    ]
    if (privateSessions.includes(appointmentType)) {
      setShowPMIOnlyFilter(false)
    }

    if ((counsellorIds?.length ?? 0) > 0) {
      setShowPreviousCounsellorFilter(false)
    }
  }, [appointmentType, form])

  const [showFiltersNotInEffectMessage, setShowFiltersNotInEffectMessage] =
    useState<boolean>(false)
  const [previousCounsellorInPMI, setPreviousCounsellorInPMI] =
    useState<boolean>(false)

  const [previousCounsellorSelected, setPreviousCounsellorSelected] =
    useState<boolean>(false)

  const { data: newData, previousData } = useQuery<
    GetUpdatedFiltersQuery,
    GetUpdatedFiltersQueryVariables
  >(therapyBookingQueries.getUpdatedFilters, {
    variables: {
      appointmentType: appointmentType,
      filtersWithoutGender: {
        pmiOnly,
        lifeEvent,
        specialism: selectedSpecialism === "" ? undefined : selectedSpecialism,
        languageCode: selectedLanguage,
        counsellorIds: counsellorIds,
      },
      filtersWithoutSpecialism: {
        pmiOnly,
        lifeEvent,
        //Select input forces gender to "" if it's null. Fix to make it null again
        gender:
          (selectedGender as string) == ""
            ? undefined
            : (selectedGender as Gender),
        languageCode: selectedLanguage,
        counsellorIds: counsellorIds,
      },
      filtersWithoutLanguage: {
        pmiOnly,
        lifeEvent,
        gender:
          (selectedGender as string) == ""
            ? undefined
            : (selectedGender as Gender),
        specialism: selectedSpecialism === "" ? undefined : selectedSpecialism,
        counsellorIds: counsellorIds,
      },
    },
  })

  const data = newData ?? previousData

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    // If Previous Counsellor is selected. The other filters are reset
    const selectedPreviousCounsellorId = form.watch(
      "filter.previousCounsellorId"
    )

    const PreviousCounsellorIsSelected =
      selectedPreviousCounsellorId !== "" &&
      selectedPreviousCounsellorId !== undefined &&
      selectedPreviousCounsellorId !== null
    setShowFiltersNotInEffectMessage(PreviousCounsellorIsSelected)
    setPreviousCounsellorSelected(PreviousCounsellorIsSelected)

    const selectedCounsellorCanUsePMI =
      filters?.previousCounsellors?.find(
        c => c.id === selectedPreviousCounsellorId
      )?.pmiEnabledforCompany === true

    //Update form with selected Counsellor ID
    if (PreviousCounsellorIsSelected) {
      form.setValue("selectedCounsellorInfo", {
        id: form.watch("filter.previousCounsellorId") ?? "",
        firstName:
          filters?.previousCounsellors?.find(
            c => c.id === form.watch("filter.previousCounsellorId")
          )?.firstName ?? "",
        pmiEnabledforCompany: selectedCounsellorCanUsePMI,
      })
    } else {
      form.setValue("selectedCounsellorInfo", undefined)
    }

    setPreviousCounsellorInPMI(selectedCounsellorCanUsePMI)
    form.setValue("filter.pmiOnly", selectedCounsellorCanUsePMI)

    if (PreviousCounsellorIsSelected) {
      resetFilters(false)
    }

    return
  }, [form.watch("filter.previousCounsellorId")])

  useEffect(() => {
    const subscription = form.watch((_value, { name }) => {
      if (name?.includes("filter.") === true) {
        onFiltersChange()
      }
    })

    return () => subscription.unsubscribe()
  }, [form.watch])

  //Setup showing messages
  useEffect(() => {
    if (filters === undefined) return
    if (filters?.pmiProvider === undefined) {
      setShowPMIOnlyFilter(false)
    }

    const selectedPreviousCounsellorId = form.watch(
      "filter.previousCounsellorId"
    )
    const PreviousCounsellorIsSelected =
      selectedPreviousCounsellorId !== "" &&
      selectedPreviousCounsellorId !== undefined &&
      selectedPreviousCounsellorId !== null

    setPreviousCounsellorSelected(PreviousCounsellorIsSelected)
    if (PreviousCounsellorIsSelected) {
      setShowFiltersNotInEffectMessage(true)

      if (form.watch("filter.pmiOnly") === true) {
        const selectedCounsellorCanUsePMI =
          filters?.previousCounsellors?.find(
            c => c.id === selectedPreviousCounsellorId
          )?.pmiEnabledforCompany === true
        setPreviousCounsellorInPMI(!selectedCounsellorCanUsePMI)
      }
    }
  }, [filters])

  //Filter Options
  const genderSelectOptions = useMemo(
    () => [
      ...(filters?.genders?.map(g => {
        return {
          key: g as string,
          label: toPascalCase(g).replace("_", "-"),
          value: g as string,
        }
      }) ?? []),
    ],
    [filters?.genders]
  )

  const languageOptions = useMemo(() => {
    if (filters?.languageCodes === undefined) return []

    const availableLanguageCodes = new Set(filters.languageCodes)

    const parsedLanguages = [
      ...Array.from(availableLanguageCodes)
        .sort((a, b) => a.localeCompare(b))
        .flatMap(code => {
          if (code === "") return []
          const label = new Intl.DisplayNames([code], { type: "language" }).of(
            code
          )
          if (label === undefined) return []
          return { key: code, label, value: code }
        }),
    ]

    return parsedLanguages
  }, [filters?.languageCodes])

  const previousCounsellorWithPMIEnabled = useMemo(() => {
    if (filters?.previousCounsellors === undefined) return []

    return filters?.previousCounsellors
      .filter(c => {
        return c.pmiEnabledforCompany === true && c.id !== undefined
      })
      .map(c => {
        return c.id as string
      })
  }, [filters?.previousCounsellors])

  const previousCounsellorSelectOptions = useCallback(
    (onlyPMIEnabled: boolean = false) => [
      ...(filters?.previousCounsellors
        ?.filter(c => !onlyPMIEnabled || c.pmiEnabledforCompany)
        .map(g => {
          return {
            key: g.id ?? "1",
            label: g.firstName ?? "",
            value: g.id ?? "1",
          }
        }) ?? []),
    ],
    [filters?.previousCounsellors]
  )

  const resetFilters = (includingPreviousCounsellor: boolean = true) => {
    form.setValue("filter.gender", "")
    form.setValue("filter.language", "en")
    form.setValue("filter.specialism", "")
    if (includingPreviousCounsellor) {
      form.setValue("filter.previousCounsellorId", "")
    }
  }

  const specialismSelectOptions = useMemo(
    () => [
      ...(filters?.specialisms?.map(s => {
        return { key: s, label: s, value: s }
      }) ?? []),
    ],
    [filters?.specialisms]
  )

  const filterSelectOption = (
    fieldName:
      | "filter.gender"
      | "filter.language"
      | "filter.previousCounsellorId"
      | "filter.specialism",
    label: string,
    placeholder: string,
    options: { key: string; label: string; value: string }[],
    optional: boolean = false,
    availableValues?: string[],
    disabled?: boolean
  ) => {
    if (optional) {
      options = [
        {
          key: "UNSET",
          label: placeholder,
          value: "UNSET",
        },
        ...options,
      ]
    }

    const unavailableMessage = (label: string) => {
      if (fieldName === "filter.previousCounsellorId") {
        return (
          <span className="text-spill-mono-black">
            <span className="font-bold">{label}</span> is not registered with{" "}
            <span className="font-bold">{filters?.pmiProvider}</span>
            {"."}
            <br />
            You can still continue therapy with{" "}
            <span className="font-bold">{label}</span> using your Spill credits,
            but after you run out of credits, you may have to use a different
            counsellor if you'd like to use your health insurance to pay for
            sessions.
          </span>
        )
      }

      return (
        <span className="text-spill-mono-black">
          Sorry, we don't have any availablity with{" "}
          <span className="font-bold">"{label}"</span> in combination with the
          other filters
        </span>
      )
    }

    //If availableValues is not provided, all options are available
    //If optional is true, add "UNSET" to available values (As you can always pick "Any")
    availableValues =
      availableValues === undefined
        ? [...options.map(o => o.key)]
        : optional === true
          ? ["UNSET", ...availableValues]
          : availableValues
    return (
      <Form.Field
        control={form.control}
        name={fieldName}
        render={({ field }) => (
          <Form.Item>
            <Form.Label htmlFor={field.name}>
              <P weight="bold">{label}</P>
            </Form.Label>
            <Form.Control>
              <Select.Root
                disabled={disabled}
                toolTipsEnabled
                {...field}
                value={field.value ?? undefined}
                onValueChange={v => {
                  if (v === "UNSET") {
                    field.onChange("")
                    return
                  }
                  field.onChange(v)
                }}
              >
                <Select.Trigger>
                  <Select.Value placeholder={placeholder} />
                </Select.Trigger>
                <Select.Content>
                  {options.map(option => (
                    <Select.Item
                      key={option.key}
                      value={option.value}
                      tooltipProps={
                        availableValues?.includes(option.key) === true
                          ? undefined
                          : {
                              side: "left",
                              children: <>{unavailableMessage(option.label)}</>,
                            }
                      }
                      className={`${availableValues?.includes(option.key) === true ? "" : "text-spill-grey-400 focus:!text-spill-grey-400"}`}
                    >
                      {option.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Form.Control>
            <Form.Message />
          </Form.Item>
        )}
      />
    )
  }

  const filtersText =
    (showPreviousCounsellorFilter &&
      previousCounsellorSelectOptions(onlyShowPMIEnabledCounsellors).length >
        0) ||
    showPMIOnlyFilter
      ? "Other filters"
      : "Filters"

  return (
    <>
      <div className="flex gap-6 flex-col">
        {showPreviousCounsellorFilter &&
          previousCounsellorSelectOptions(onlyShowPMIEnabledCounsellors)
            .length > 0 && (
            <>
              <div className="flex flex-col gap-5">
                <H3>Previous Counsellors</H3>
                <div className="flex flex-col gap-2">
                  {filterSelectOption(
                    "filter.previousCounsellorId",
                    "Select Counsellor",
                    `Any counsellor...`,
                    previousCounsellorSelectOptions(
                      onlyShowPMIEnabledCounsellors
                    ),
                    true,
                    form.watch("filter.pmiOnly") === true
                      ? previousCounsellorWithPMIEnabled
                      : undefined
                  )}
                </div>
              </div>
              <Separator />
            </>
          )}

        {showPMIOnlyFilter && (
          <>
            {/* Switch in form */}
            <Form.Field
              control={form.control}
              name="filter.pmiOnly"
              render={({ field }) => (
                <Form.Item>
                  <div className="flex gap-2 justify-between">
                    <Form.Label htmlFor={field.name}>
                      <H3>Eligible with {filters?.pmiProvider}</H3>
                    </Form.Label>
                    <Form.Control>
                      <Switch
                        disabled={previousCounsellorSelected}
                        name={field.name}
                        checked={field.value}
                        onCheckedChange={e => {
                          form.setValue(
                            "filter.pmiProvider",
                            e ? filters?.pmiProvider : undefined
                          )
                          field.onChange(e)
                        }}
                      />
                    </Form.Control>
                  </div>
                  {!previousCounsellorSelected && (
                    <P muted>
                      You can continue therapy with your health insurance if you
                      run out of Spill credits.{" "}
                      <Button variant="tertiary" asChild>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://spill.notion.site/How-Spill-works-with-health-insurance-7eaf9b1ef5894717915bf5b259cd1122"
                          title="Learn more about using your health insurance"
                        >
                          Learn more
                        </a>
                      </Button>
                    </P>
                  )}
                  {previousCounsellorSelected && previousCounsellorInPMI && (
                    <Alert variant="success">
                      <P>
                        Your selected counsellor is registered to continue
                        therapy with your health insurance if you run out of
                        Spill credits.
                        <br />
                        <br />
                        <Button variant="tertiary" size="sm" asChild>
                          <a
                            className="text-sp"
                            target="_blank"
                            rel="noreferrer"
                            href="https://spill.notion.site/How-Spill-works-with-health-insurance-7eaf9b1ef5894717915bf5b259cd1122"
                            title="Learn more about using your health insurance"
                          >
                            Learn more
                          </a>
                        </Button>
                      </P>
                    </Alert>
                  )}
                  {previousCounsellorSelected && !previousCounsellorInPMI && (
                    <Alert variant="warning">
                      <P>
                        Your selected counsellor is not registered to continue
                        therapy with your health insurance if you run out of
                        Spill credits.
                        <br />
                        <br />
                        <Button variant="tertiary" size="sm" asChild>
                          <a
                            className="text-sp"
                            target="_blank"
                            rel="noreferrer"
                            href="https://spill.notion.site/How-Spill-works-with-health-insurance-7eaf9b1ef5894717915bf5b259cd1122"
                            title="Learn more about using your health insurance"
                          >
                            Learn more
                          </a>
                        </Button>
                      </P>
                    </Alert>
                  )}
                </Form.Item>
              )}
            />
            <Separator />
          </>
        )}
        <div className="flex flex-col gap-2">
          <H3>{filtersText}</H3>
        </div>
        {showLanguageFilter &&
          filterSelectOption(
            "filter.language",
            "Preferred language",
            `Any language...`,
            languageOptions,
            false,
            data?.filtersForLanguages?.languageCodes,
            previousCounsellorSelected
          )}
        {showGenderFilter &&
          filterSelectOption(
            "filter.gender",
            "Counsellor gender",
            `Any gender...`,
            genderSelectOptions,
            true,
            data?.filtersForGender?.genders,
            previousCounsellorSelected
          )}
        {showSpecialismFilter &&
          filterSelectOption(
            "filter.specialism",
            "Specialism",
            `Any specialism...`,
            specialismSelectOptions,
            true,
            data?.filtersForSpecialisms?.specialisms,
            previousCounsellorSelected
          )}
        {showFiltersNotInEffectMessage === true && (
          <Alert variant="info">
            <P>
              Additional filters don’t apply when you have a previous counsellor
              selected
            </P>
          </Alert>
        )}
        <Button variant="tertiary" onClick={() => resetFilters()}>
          Clear all
        </Button>
      </div>
    </>
  )
}
