/**
 * A context that relates to the current user.
 * Has information that relate to things like name, id, flags etc.
 */

import { useFlag, useFlagsStatus } from "@unleash/proxy-client-react"
import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
} from "react"

import { FEATURE_FLAGS } from "common/constants/flags"
import { SpillSubscriptionPlan } from "types/graphql"

import { useAuth } from "./authContext"

interface UserContextData {
  id?: string
  displayName?: string
  company: {
    id?: string
    subscriptionPlan?: SpillSubscriptionPlan
  }
  revokedAt?: string
  flags: Record<FEATURE_FLAGS, boolean>
  loadingUserValues: boolean
}

const defaultUserContextData: UserContextData = {
  id: undefined,
  displayName: undefined,
  company: {
    id: undefined,
    subscriptionPlan: undefined,
  },
  revokedAt: undefined,
  flags: {
    [FEATURE_FLAGS.INVITE_EMAIL_IN_TEAM]: false,
    [FEATURE_FLAGS.ALLOW_REQUEST_WHEN_BUDGET_USED]: false,
    [FEATURE_FLAGS.PMI_INTEGRATION]: false,
    [FEATURE_FLAGS.ADHD_CO_PAY]: false,
    [FEATURE_FLAGS.PMI_FILTER]: false,
    [FEATURE_FLAGS.DOMAIN_ACCESS]: false,
    [FEATURE_FLAGS.NEW_ACCESS_PAGE]: false,
    [FEATURE_FLAGS.NEW_ENGAGEMENT_PAGE]: false,
  },
  loadingUserValues: false,
}

const UserContext = createContext<UserContextData>(defaultUserContextData)

export const UserProvider: FunctionComponent<PropsWithChildren> = props => {
  const { flagsReady } = useFlagsStatus()
  const { isUserLoading, user } = useAuth()

  const isFlagEnabled = (flag: boolean) => {
    return flag && flagsReady === true
  }

  const userValues: UserContextData = {
    id: user?.id,
    displayName: user?.displayName,
    company: {
      id: user?.company?.id,
      subscriptionPlan: user?.company?.subscriptionPlan,
    },
    revokedAt: user?.revokedAt ?? undefined,
    flags: {
      [FEATURE_FLAGS.INVITE_EMAIL_IN_TEAM]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.INVITE_EMAIL_IN_TEAM)
      ),
      [FEATURE_FLAGS.ALLOW_REQUEST_WHEN_BUDGET_USED]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.ALLOW_REQUEST_WHEN_BUDGET_USED)
      ),
      [FEATURE_FLAGS.PMI_INTEGRATION]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.PMI_INTEGRATION)
      ),
      [FEATURE_FLAGS.ADHD_CO_PAY]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.ADHD_CO_PAY)
      ),
      [FEATURE_FLAGS.PMI_FILTER]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.PMI_FILTER)
      ),
      [FEATURE_FLAGS.DOMAIN_ACCESS]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.DOMAIN_ACCESS)
      ),
      [FEATURE_FLAGS.NEW_ACCESS_PAGE]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.NEW_ACCESS_PAGE)
      ),
      [FEATURE_FLAGS.NEW_ENGAGEMENT_PAGE]: isFlagEnabled(
        useFlag(FEATURE_FLAGS.NEW_ENGAGEMENT_PAGE)
      ),
    },
    loadingUserValues: isUserLoading && flagsReady,
  }

  return (
    <UserContext.Provider value={userValues}>
      {props.children}
    </UserContext.Provider>
  )
}

export function useUser(): UserContextData {
  return useContext(UserContext)
}
