/**
 * This component shows us the initial view for setting up Spill on a platform.
 */
import { FunctionComponent, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { H2, H3, H4, P, Alert, CopyField } from "@spillchat/puddles"
import { PlayIcon, LinkIcon } from "@heroicons/react/24/outline"
import { toast } from "sonner"

import { Dialog } from "common/components/Dialog"
import { config } from "config"
import { useAuth } from "common/context/authContext"
import { NotFound404Page } from "common/components/NotFound404Page"
import TeamsLogo from "common/assets/images/Microsoft-Teams-logo.svg?react"
import SlackLogo from "common/assets/images/Slack-logo.svg?react"
import { useAnalytics } from "common/context/analyticsContext"
import { WistiaPlayer } from "common/components/WistiaPlayer"

import { platformTypesInfo } from "."

/**
 * Instructions for adding Spill to a particular platform.
 *
 * The platform is taken from the `:platformType` path parameter.
 *
 * This page may also be shown to unauthenticated users (for example, the buyer may not be a Teams
 * admin so will send the link to them). So this page must work for both authenticated and
 * unauthenticated users. The company id is also automatically added to the `state` query param.
 */
export const InitialPlatformSetup: FunctionComponent = () => {
  const { user, isUserLoading } = useAuth()
  const { track } = useAnalytics()
  const { platformType } = useParams()
  const platformTypeInfo = platformTypesInfo[platformType ?? ""]

  const [showingTutorial, setShowingTutorial] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const stateParam = searchParams.get("state")
  const errorParam = searchParams.get("error")

  // Set the state query param so the link can be shared with unauthenticated users.
  useEffect(() => {
    const id = user?.company?.id
    if (user && id != null) {
      setSearchParams(prev => ({ ...Object.fromEntries(prev), state: id }), {
        replace: true,
      })
    }
  }, [user])

  useEffect(() => {
    if (errorParam === null) return

    track("User had error during setup", {
      Platform: platformTypeInfo?.name ?? "Unknown",
      Error: errorParam,
    })
  }, [errorParam])

  if (!platformTypeInfo || (stateParam === null && !user && !isUserLoading)) {
    return <NotFound404Page />
  }

  const trackWithProperties = (eventName: string) => {
    track(eventName, { Platform: platformTypeInfo.name, IsBuyer: !!user })
  }

  const gatewayUrl =
    stateParam !== null
      ? new URL(platformTypeInfo.gatewayPath, config.spill.gateway.baseUrl)
      : null
  gatewayUrl?.searchParams.set("state", stateParam ?? "")

  return (
    <>
      <>
        <div className="flex justify-center flex-col gap-4">
          {errorParam !== null && (
            <Alert variant="danger" title="Couldn't install Spill">
              <P size="xs">
                There was an issue adding Spill: {errorParam}. Please make sure
                you're a {platformTypeInfo.name} admin and signed in to the
                right account, then try again. If the issue persists, get in
                touch at hi@spill.chat
              </P>
            </Alert>
          )}
          <div>
            <H2>Add Spill to {platformTypeInfo.name}</H2>
          </div>
          <div className="mb-4">
            <P>
              Sign in with {platformTypeInfo.name} to get started – set up takes
              less than 3 minutes.
            </P>
            <P>
              We’ll ask for a few permissions in order to securely connect Spill
              to your {platformTypeInfo.name}{" "}
              {platformTypeInfo.companyPlatformTerm}. You can see how this works
              by watching the tutorial.
            </P>
          </div>

          <div className="w-full text-center flex justify-between border border-grey-200 rounded-lg min-h-[300px]">
            <a
              href={gatewayUrl?.toString()}
              className="flex flex-col items-center w-1/2 bg-mono-white hover:bg-grey-100 rounded-tl-lg rounded-bl-lg transition-colors justify-center gap-4"
            >
              <div className="border-r my-2 w-full flex flex-col items-center">
                {platformTypeInfo.name === "Slack" ? (
                  <SlackLogo className="py-4 max-w-[48px]" />
                ) : (
                  <TeamsLogo className="py-4 max-w-[48px]" />
                )}
                <H4>
                  {gatewayUrl
                    ? `Sign in to ${platformTypeInfo.name}`
                    : "Loading..."}
                </H4>
              </div>
            </a>

            <button
              className="flex flex-col cursor-pointer items-center w-1/2 bg-mono-white hover:bg-grey-100 rounded-tr-lg rounded-br-lg transition-colors justify-center"
              onClick={() => {
                setShowingTutorial(true)
                trackWithProperties("User opened setup tutorial video")
              }}
            >
              <div className="my-2 w-full flex flex-col items-center gap-4">
                <div className="flex flex-col aspect-video w-32 relative">
                  <PlayIcon className="absolute mt-8 ml-12 size-8" />
                </div>
                <div className="flex flex-col gap-1">
                  <H4>Watch a tutorial video</H4>
                  <P size="xs">
                    Add Spill to your company {platformTypeInfo.name}{" "}
                    {platformTypeInfo.companyPlatformTerm}
                  </P>
                </div>
              </div>
            </button>
          </div>
        </div>
      </>
      <div className="flex flex-col w-full text-center justify-center gap-8">
        <div className="flex flex-col gap-2">
          <H3>Not your company’s {platformTypeInfo.name} admin?</H3>
          <P>Send an admin a link to this page to get setup.</P>
        </div>
        <div className="flex justify-center">
          <CopyField
            icon={<LinkIcon />}
            copyValue={window.location.href}
            onCopied={() => {
              toast.success(
                `You can now paste it into a message to your ${
                  platformTypeInfo?.name ?? ""
                } admin.`
              )
              trackWithProperties("User copied setup instructions link")
            }}
            onFail={() =>
              toast.error(
                "There was an issue copying the link. You can copy it manually from the URL bar."
              )
            }
          />
        </div>
      </div>
      <Dialog
        title={`Add Spill to your company ${platformTypeInfo.name} Workspace`}
        maxWidth="2xl"
        onClose={() => setShowingTutorial(false)}
        isOpen={showingTutorial}
        canClose={true}
      >
        {platformTypeInfo.tutorialWistiaId !== "" && (
          <WistiaPlayer
            elementId="wistia-player"
            videoId={platformTypeInfo.tutorialWistiaId}
          />
        )}
      </Dialog>
    </>
  )
}
