import { ChatBubbleLeftRightIcon, CheckIcon } from "@heroicons/react/24/outline"
import { Button, H3, H4, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import { PlatformType } from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"

import { CompanyPlatform } from "../access.page"

interface IntegrationsAccessProps {
  userId: string
  companyId: string
  companyPlatforms: CompanyPlatform[]
  openInviteDialog: (companyPlatformId: string) => void
}

export const IntegrationsAccess: FunctionComponent<IntegrationsAccessProps> = ({
  companyPlatforms,
  userId,
  companyId,
  openInviteDialog,
}) => {
  const { track } = useAnalytics()

  const integrationsPlatforms = companyPlatforms.filter(companyPlatform => {
    return (
      companyPlatform.platformType == PlatformType.SLACK ||
      companyPlatform.platformType == PlatformType.TEAMS
    )
  })

  return (
    <div className="flex flex-col gap-y-5 w-2/3">
      {/* Header of app integrations section */}
      <div className="flex flex-col gap-y-3">
        <ChatBubbleLeftRightIcon className="size-6" />
        <H3>App integrations</H3>
        {integrationsPlatforms.length > 0 ? (
          <P muted>You've added Spill to a workspace</P>
        ) : (
          <div className="flex flex-row gap-x-1">
            <P muted>Access Spill directly through Slack or MS Teams.</P>
            <Button variant="tertiary" asChild>
              <Link
                onClick={() =>
                  track("Admin clicks to setup up first integration", {
                    userId: userId,
                    companyId: companyId,
                    sourceUrl: window.location.href,
                  })
                }
                to={{ pathname: "/admin/access/install" }}
              >
                Set up
              </Link>
            </Button>
          </div>
        )}
      </div>

      {/* Showing the integrations if they exist */}
      {integrationsPlatforms.length > 0 && (
        <>
          {integrationsPlatforms.map(integrationPlatform => (
            <IntegrationAccess
              key={integrationPlatform.id}
              companyPlatform={integrationPlatform}
              userId={userId}
              companyId={companyId}
              openInviteDialog={openInviteDialog}
            />
          ))}
          <Button variant="secondary" asChild>
            <Link
              onClick={() =>
                track("Admin clicks to add another workspace", {
                  userId: userId,
                  companyId: companyId,
                  sourceUrl: window.location.href,
                })
              }
              to={{ pathname: "/admin/access/install" }}
            >
              Add another workspace
            </Link>
          </Button>
        </>
      )}
    </div>
  )
}

type IntegrationAccessProps = {
  userId: string
  companyId: string
  companyPlatform: CompanyPlatform
  openInviteDialog: (companyPlatformId: string) => void
}

const IntegrationAccess: FunctionComponent<IntegrationAccessProps> = ({
  userId,
  companyId,
  companyPlatform,
  openInviteDialog,
}) => {
  const { track } = useAnalytics()

  const invitePlatform = () => {
    openInviteDialog(companyPlatform.id)
    track("Admin clicks to invite workspace", {
      userId: userId,
      companyPlatformId: companyPlatform.id,
      companyId: companyId,
      sourceUrl: window.location.href,
    })
  }

  const allChannels = companyPlatform.allowAccessAll
  let channels = companyPlatform.groups
    .map(group => {
      return `#${group.name}`
    })
    .join(" ")

  if (channels.length > 50) {
    channels = channels.slice(0, 50) + "..."
  }

  return (
    <div className="flex flex-col gap-y-3">
      <div className="border p-3 rounded-lg flex flex-row gap-x-3 justify-between items-center">
        {companyPlatform.avatarUrl != null ? (
          <img
            src={companyPlatform.avatarUrl}
            className="rounded-xl size-[60px]"
            alt=""
          />
        ) : (
          <div className="size-[60px] bg-spill-teal-600 rounded-xl"></div>
        )}
        <div className="grow flex flex-col">
          <H3>{companyPlatform.name}</H3>
          <P muted>{allChannels ? "All channels" : channels}</P>
        </div>
        <Button asChild variant="tertiary">
          <Link
            onClick={() =>
              track("Admin clicks to edit workspace", {
                userId: userId,
                companyPlatformId: companyPlatform.id,
                companyId: companyId,
                sourceUrl: window.location.href,
              })
            }
            to={{ pathname: "TODO: WHERE ARE WE GOING" }}
          >
            Edit
          </Link>
        </Button>
      </div>
      {companyPlatform.hasAnnouncedOnboarding ? (
        <div className="flex flex-row gap-x-4 items-center">
          <CheckIcon className="size-6 text-spill-blue-800" />
          <P muted>Channel members are automatically invited</P>
        </div>
      ) : (
        <div className="flex flex-col gap-y-1 bg-yellow-100 p-5 rounded-lg">
          <H4>Channel members haven't been invited</H4>
          <P className="mb-5">
            Once you’ve checked your user access list, click invite to send an
            automatic DM to all channel members. You only need to do this once –
            in future, anyone who joins these channels will also receive a DM.
          </P>
          <Button onClick={invitePlatform} variant="primary">
            Invite
          </Button>
        </div>
      )}
    </div>
  )
}
