import { gql, useMutation, useQuery } from "@apollo/client"
import { FunctionComponent } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { toast } from "sonner"
import { captureException, captureMessage } from "@sentry/react"
import { format } from "date-fns"
import { Alert, Button, H1, H4, P } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { z } from "zod"

import {
  SpillSubscriptionPlan,
  TherapyAccessReason,
  TherapyExtensionRequestGetDataQuery,
  TherapyExtensionRequestRequestTherapyExtensionMutation,
  TherapyExtensionStatus,
} from "types/graphql"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"

import { queries as sessionsPageQueries } from "./SessionsPage/index"

export const queries = {
  getData: gql`
    query TherapyExtensionRequestGetData {
      user {
        accessToTherapyFeatures {
          askATherapist {
            hasAccess
            reason
          }
          consultations {
            hasAccess
            reason
          }
          oneOffs {
            hasAccess
            reason
          }
        }
        id
        displayName
        upcomingCapResetDate
        numberSessionsUsedInCapPeriod
        therapyUsageCap
        companyTherapyCap
        therapyExtensions {
          id
          status
          expiryDate
        }
        featuresAndSettings {
          id
          userCanRequestTherapy {
            value
          }
          userTherapyCap {
            active
          }
          yearlyTherapyBudget {
            value
          }
          sessionPack {
            active
            value
          }
        }
        company {
          id
          subscriptionPlan
          budgetYearUsage {
            totalBillableUsage
          }
          currentSessionPackUsage
          admins {
            id
            displayName
          }
        }
      }
    }
  `,
}

export const mutations = {
  requestTherapyExtension: gql`
    mutation TherapyExtensionRequestRequestTherapyExtension(
      $request: RequestTherapyExtensionInput!
    ) {
      requestTherapyExtension(request: $request) {
        id
        numberOfSessions
      }
    }
  `,
}

const formatAdminNames = (adminNames: string[]): string => {
  if (adminNames.length === 0) return "the person"
  if (adminNames.length === 1) return String(adminNames[0])
  if (adminNames.length === 2)
    return `${String(adminNames[0])} and ${String(adminNames[1])}`

  const moreAdmins = adminNames.slice(2)
  return `${String(adminNames[0])}, ${String(adminNames[1])} and ${
    moreAdmins.length
  } other${moreAdmins.length > 1 ? "s" : ""}`
}

export const TherapyExtensionRequest: FunctionComponent = () => {
  const { flags } = useUser()
  const navigate = useNavigate()
  const { data, loading, error } =
    useQuery<TherapyExtensionRequestGetDataQuery>(queries.getData, {
      // set as network only to always get up to date settings for access to therapy features
      fetchPolicy: "network-only",
    })

  const formMethods = useForm({
    defaultValues: {
      numberOfSessions: "2",
      includeName: true,
      noteFromUser: "",
    },
  })
  const watchIncludeName = useWatch({
    name: "includeName",
    control: formMethods.control,
  })
  const [requestTherapyExtension, { loading: isSubmitting }] =
    useMutation<TherapyExtensionRequestRequestTherapyExtensionMutation>(
      mutations.requestTherapyExtension,
      {
        refetchQueries: [sessionsPageQueries.user],
      }
    )

  const submit = async () => {
    try {
      const values = formMethods.getValues()
      await requestTherapyExtension({
        variables: {
          request: {
            noteFromUser: values.noteFromUser,
            // number of sessions is a string because of the radio buttons
            numberOfSessions: Number(values.numberOfSessions),
            isAnonymous: !values.includeName,
          },
        },
        onCompleted: () => {
          toast.success("Your request has been sent.")
          formMethods.reset()
          navigate("/therapy/sessions")
        },
        onError: () => {
          toast.error("Something went wrong. Please try again.")
        },
      })
    } catch (error) {
      toast.error("Something went wrong. Please try again.")
      captureException(error)
    }
  }

  if (loading) return null

  const user = data?.user

  if (error != null || user == null) {
    return (
      <>
        <Helmet title="Therapy | Spill" />
        <div className="flex flex-col gap-6">
          <button className="underline w-fit" onClick={() => navigate(-1)}>
            Back
          </button>
          <Alert
            variant="danger"
            title="Something went wrong. Please try again."
          />
        </div>
      </>
    )
  }

  // Validate the user data, mainly the rules around the accessToTherapyFeatures
  const requiredRulesUI = z.object({
    accessToTherapyFeatures: z.object({
      askATherapist: z.array(
        z.object({
          hasAccess: z.boolean(),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
      consultations: z.array(
        z.object({
          hasAccess: z.boolean(),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
      oneOffs: z.array(
        z.object({
          hasAccess: z.boolean(),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
    }),
  })

  const sessionBookingRules = requiredRulesUI.safeParse(data?.user)
  if (!sessionBookingRules.success) {
    captureMessage(
      `User ${
        data?.user?.id ?? ""
      } unable to book therapy session: ${sessionBookingRules.error.issues
        .map(issue => issue.message)
        .join(", ")}`
    )
    return (
      <Alert
        variant="danger"
        title="It looks like you can't request more sessions right now"
      >
        <P size="xs">
          If you think this is a mistake or would like more information please
          contact therapy@spill.chat.
        </P>
      </Alert>
    )
  }

  // Our data is valid, so we can safely access the data
  const { accessToTherapyFeatures } = sessionBookingRules.data

  const closeEnoughToCap =
    user.numberSessionsUsedInCapPeriod + 1 >= (user?.therapyUsageCap ?? 0)

  const userHasHitCap =
    user.numberSessionsUsedInCapPeriod + 1 > (user?.therapyUsageCap ?? 0)

  const needsMoreForCourse =
    accessToTherapyFeatures.consultations.at(0)?.reason ===
    TherapyAccessReason.NOT_ENOUGH_USER_CAP_CREDITS

  const companyHasUsedMostAllSessionsBudget =
    // we treat null budgets as "unlimited"
    user.featuresAndSettings?.yearlyTherapyBudget?.value != null &&
    (user.company?.budgetYearUsage?.totalBillableUsage ?? 0) + 1 >=
      user.featuresAndSettings?.yearlyTherapyBudget?.value

  const companyHasUsedMostAllSessionsSessionPack =
    user.featuresAndSettings?.sessionPack?.value != null &&
    user.company?.currentSessionPackUsage != null &&
    user.company?.currentSessionPackUsage + 1 >=
      user.featuresAndSettings?.sessionPack?.value

  const companyHasUsedAllSessionsSessionPack =
    user.featuresAndSettings?.sessionPack?.value != null &&
    user.company?.currentSessionPackUsage != null &&
    user.company?.currentSessionPackUsage + 1 >
      user.featuresAndSettings?.sessionPack?.value

  const allowRequestOnBudgetOrPack =
    (companyHasUsedMostAllSessionsSessionPack &&
      (user.company?.subscriptionPlan == SpillSubscriptionPlan.ESSENTIAL || // if we're on the Essential plan, the company being out of sessions is enough to request more
        // if the company is on a non-Essential plan,
        // we want to make sure we're accounting for the "gifts" a user has when allowing them to request due to their company being out of budget
        // if they have remaining gifts to use, don't allow them to request more sessions
        (closeEnoughToCap &&
          user.therapyUsageCap != user.companyTherapyCap))) ||
    (companyHasUsedMostAllSessionsBudget &&
      flags[FEATURE_FLAGS.ALLOW_REQUEST_WHEN_BUDGET_USED] === true)

  const canRequestMoreSessions =
    (closeEnoughToCap || needsMoreForCourse || allowRequestOnBudgetOrPack) &&
    user.featuresAndSettings?.userCanRequestTherapy.value

  if (!canRequestMoreSessions) {
    return (
      <>
        <Helmet title="Therapy | Spill" />
        <div className="flex flex-col gap-6">
          <button className="underline w-fit" onClick={() => navigate(-1)}>
            Back
          </button>

          <Alert
            variant="danger"
            title="It looks like you can't request more sessions right now"
          >
            <P size="xs">
              If you think this is a mistake or would like more information
              please contact therapy@spill.chat.
            </P>
          </Alert>
        </div>
      </>
    )
  }

  const pendingTherapyExtensions = user.therapyExtensions.filter(
    extension => extension.status === TherapyExtensionStatus.PENDING
  )
  if (pendingTherapyExtensions.length > 0) {
    return (
      <>
        <Helmet title="Therapy | Spill" />
        <div className="flex flex-col gap-6">
          <button className="underline w-fit" onClick={() => navigate(-1)}>
            Back
          </button>
          <Alert variant="warning" title="Extension request pending">
            <P size="xs">
              You requested to extend your therapy cap, so you can book more
              sessions. Your Spill admin has been asked to respond by{" "}
              {format(
                new Date(pendingTherapyExtensions[0]!.expiryDate),
                "d MMM yyyy"
              )}
              .
            </P>
          </Alert>
        </div>
      </>
    )
  }

  // we only show the monthly reset message if the user hasn't hit their cap already
  const showSessionPackMonthlyResetMessage =
    user.featuresAndSettings?.sessionPack.active === true &&
    companyHasUsedAllSessionsSessionPack &&
    !userHasHitCap
  const showSessionPackUserCapMessage =
    user.featuresAndSettings?.sessionPack.active === true && userHasHitCap

  return (
    <>
      <Helmet title="Therapy | Spill" />
      <div className="flex flex-col gap-6">
        <button className="underline w-fit" onClick={() => navigate(-1)}>
          Back
        </button>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(submit)}
            className="flex flex-col gap-8 items-start justify-center w-full"
          >
            <H1>Request a Spill session</H1>
            {showSessionPackMonthlyResetMessage && (
              <>
                <P>Your company has used their monthly sessions.</P>
                <P>
                  Until then your company encourages the team to request more
                  therapy sessions above the default in situations where they
                  would benefit from it.
                </P>
              </>
            )}
            {showSessionPackUserCapMessage && (
              <>
                <P>
                  You have used up your set of therapy credits paid for by your
                  company. Your therapy credits automatically renew
                  {user.upcomingCapResetDate != null
                    ? ` on ${format(
                        new Date(user.upcomingCapResetDate),
                        "d MMMM yyyy"
                      )}`
                    : ""}
                  .
                </P>
                <P>
                  Until then your company encourages the team to request more
                  therapy sessions above the default in situations where they
                  would benefit from it.
                </P>
              </>
            )}
            {user.featuresAndSettings?.sessionPack.active === false &&
              user.featuresAndSettings?.userTherapyCap.active === true && (
                <>
                  <P>
                    You've reached the cap for therapy sessions paid for by your
                    company. Your cap automatically resets
                    {user.upcomingCapResetDate != null
                      ? ` on ${format(
                          new Date(user.upcomingCapResetDate),
                          "d MMMM yyyy"
                        )}`
                      : ""}
                    .
                  </P>

                  <P>
                    Until then, if you'd like to arrange more sessions, please
                    request an extension to your therapy cap. We recommend
                    speaking with your manager about it before submitting the
                    request.
                  </P>
                </>
              )}
            {user.featuresAndSettings?.sessionPack.active === false &&
              user.featuresAndSettings?.userTherapyCap.active === false && (
                <P>
                  You've reached the cap for therapy sessions paid for by your
                  company. If you'd like to arrange more sessions, please
                  request an extension. We recommend speaking with your manager
                  about it before submitting the request.
                </P>
              )}

            <div className="flex flex-col gap-4">
              <H4>Request more sessions</H4>

              <P>
                Your request will be sent to{" "}
                {formatAdminNames(
                  user.company?.admins.map(user => user.displayName) ?? []
                )}{" "}
                who manage{user.company?.admins.length === 1 ? "s" : ""} Spill
                at your company. They will have 7 days to approve the request.
              </P>

              <div className="flex flex-col gap-2">
                <P>How many extra sessions would you like to request?</P>
                <label className="flex gap-2 font-inter text-sm cursor-pointer">
                  <input
                    type="radio"
                    {...formMethods.register("numberOfSessions", {
                      required: true,
                    })}
                    id="extraSessions-2"
                    value={"2"}
                  />
                  2 sessions
                </label>
                <label className="flex gap-2 font-inter text-sm cursor-pointer">
                  <input
                    type="radio"
                    {...formMethods.register("numberOfSessions", {
                      required: true,
                    })}
                    id="extraSessions-4"
                    value={"4"}
                  />
                  4 sessions
                </label>
                <label className="flex gap-2 font-inter text-sm cursor-pointer">
                  <input
                    type="radio"
                    {...formMethods.register("numberOfSessions", {
                      required: true,
                    })}
                    id="extraSessions-6"
                    value={"6"}
                  />
                  6 sessions
                </label>
              </div>

              <label
                htmlFor="noteFromUser"
                className="flex flex-col gap-2 font-inter text-sm"
              >
                Why are you requesting extra sessions?
                <textarea
                  className="border border-spill-grey-200 rounded-lg p-4 w-full h-32 bg-spill-grey-100"
                  id="noteFromUser"
                  placeholder="Hey - as discussed in our meeting, it would be really helpful to have these extra sessions"
                  {...formMethods.register("noteFromUser", { required: false })}
                />
              </label>

              <div className="flex flex-row justify-between items-center w-full">
                <P>
                  From{" "}
                  {watchIncludeName && user.displayName !== undefined
                    ? user.displayName
                    : "Anonymous"}
                </P>
                <div className="flex flex-row gap-2 items-center">
                  <label
                    htmlFor="includeName"
                    className=" flex gap-1 font-inter text-sm"
                  >
                    <input
                      type="checkbox"
                      id="includeName"
                      {...formMethods.register("includeName", {
                        required: true,
                      })}
                    />
                    Include name
                  </label>
                </div>
              </div>

              <Button loading={isSubmitting} onClick={submit}>
                Submit request
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  )
}
