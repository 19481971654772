import { gql } from "@apollo/client"

export const queries = {
  getSettings: gql`
    query InviteAdhdSettings {
      user {
        id
        company {
          id
          featuresAndSettings {
            adhdSupport {
              value
              active
            }
            userTherapyCap {
              value
            }
          }
          adhdCompanyPackageSetting {
            id
            state
            addOns {
              id
              addOnType
              properties {
                enabled
                companyContribution
              }
            }
          }
        }
      }
    }
  `,
}
