import {
  Button,
  Form,
  H2,
  Input,
  Label,
  P,
  Select,
  Separator,
} from "@spillchat/puddles"
import { FunctionComponent, useMemo } from "react"
import { getCountryCallingCode } from "libphonenumber-js"

import { phoneCountryCodes } from "common/helpers/phone-country-codes"
import { monthMap } from "common/helpers/validateDateOfBirth"

import { useBookingFormValidation } from "../hooks/useBookingFormValidation"
import { TherapyBookingFormWrapper } from "../components/therapy-booking-form-wrapper"
import { TherapyBookingSummaryCardProps } from "../components/therapy-booking-summary-card"
import { TherapyBookingViewEditField } from "../components/therapy-booking-viewEditField"
import { TherapyBookingFormSchema } from "../hooks/useTherapyBookingForm"

interface TherapyBookingPersonalTabProps {
  onNext: () => void
  onEditBooking: () => void
  summaryCardProps: TherapyBookingSummaryCardProps
}

export const TherapyBookingPersonalTab: FunctionComponent<
  TherapyBookingPersonalTabProps
> = (props: TherapyBookingPersonalTabProps) => {
  const { validationFormSectionAndContinue, checkValid, registerFieldRef } =
    useBookingFormValidation({
      formSection: "userInfo",
      onNext: props.onNext,
    })

  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const formatedDateOfBirthFromForm = useMemo(() => {
    const dayOfBirth = form.watch("userInfo.dateOfBirth.dayOfBirth")
    const monthOfBirth = form.watch("userInfo.dateOfBirth.monthOfBirth")
    const yearOfBirth = form.watch("userInfo.dateOfBirth.yearOfBirth")
    if (
      dayOfBirth !== undefined &&
      monthOfBirth !== undefined &&
      yearOfBirth !== undefined
    ) {
      const monthAsLong = monthMap.find(
        m => m.displayValue === monthOfBirth
      )?.label
      return `${dayOfBirth} ${monthAsLong} ${yearOfBirth}`
    }

    return undefined
  }, [
    form.watch("userInfo.dateOfBirth.dayOfBirth"),
    form.watch("userInfo.dateOfBirth.monthOfBirth"),
    form.watch("userInfo.dateOfBirth.yearOfBirth"),
  ])

  const formattedPhoneNumber = useMemo(() => {
    const countryCode = form.watch("userInfo.phoneCountryCode")
    const phoneNumber = form.watch("userInfo.phone")
    if (countryCode !== undefined && phoneNumber !== undefined) {
      const extCode = getCountryCallingCode(countryCode)
      return `+(${extCode ?? ""}) ${phoneNumber}`
    }

    return undefined
  }, [form.watch("userInfo.phoneCountryCode"), form.watch("userInfo.phone")])

  return (
    <>
      <TherapyBookingFormWrapper
        summaryCardProps={props.summaryCardProps}
        onEditBooking={props.onEditBooking}
        header={
          <>
            <H2>Personal information</H2>
            <P muted>Double check your personal details.</P>
          </>
        }
      >
        <TherapyBookingViewEditField
          registerFields={registerFieldRef}
          validationFieldNames={["userInfo.firstName", "userInfo.lastName"]}
          validationFieldValues={[
            form.watch("userInfo.firstName"),
            form.watch("userInfo.lastName"),
          ]}
          title="Your name"
          description="So your counsellor knows what to call you"
          readOnlyValue={`${form.watch("userInfo.firstName") ?? ""} ${form.watch("userInfo.lastName") ?? ""}`}
        >
          <div className="flex gap-4">
            <Form.Field
              control={form.control}
              name="userInfo.firstName"
              render={({ field }) => (
                <Form.Item className="w-full">
                  <Form.Label htmlFor={field.name}>
                    <P weight="medium">First Name</P>
                  </Form.Label>
                  <Form.Control>
                    <Input
                      {...field}
                      onBlur={checkValid}
                      type="text"
                      placeholder="First name"
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
            <Form.Field
              control={form.control}
              name="userInfo.lastName"
              render={({ field }) => (
                <Form.Item className="w-full">
                  <Form.Label htmlFor={field.name}>
                    <P weight="medium">Last Name</P>
                  </Form.Label>
                  <Form.Control>
                    <Input
                      {...field}
                      onBlur={checkValid}
                      type="text"
                      placeholder="Last name"
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </div>
        </TherapyBookingViewEditField>
        <Separator />
        <TherapyBookingViewEditField
          registerFields={registerFieldRef}
          validationFieldNames={["userInfo.pronouns"]}
          validationFieldValues={[form.watch("userInfo.pronouns")]}
          title="Pronouns"
          description="So your counsellor knows how to address you"
          readOnlyValue={form.watch("userInfo.pronouns") ?? ""}
        >
          <div className="flex gap-4">
            <Form.Field
              control={form.control}
              name="userInfo.pronouns"
              render={({ field }) => (
                <Form.Item className="w-full">
                  <Form.Label htmlFor={field.name}>
                    <P weight="medium">Pronouns (Optional)</P>
                  </Form.Label>
                  <Form.Control>
                    <Input
                      {...field}
                      value={field.value}
                      onBlur={checkValid}
                      type="text"
                      placeholder="e.g. She/her"
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </div>
        </TherapyBookingViewEditField>
        <Separator />
        <TherapyBookingViewEditField
          registerFields={registerFieldRef}
          validationFieldNames={["userInfo.dateOfBirth"]}
          validationFieldValues={[
            form.watch("userInfo.dateOfBirth.dayOfBirth"),
            form.watch("userInfo.dateOfBirth.monthOfBirth"),
            form.watch("userInfo.dateOfBirth.yearOfBirth"),
          ]}
          title="Date of birth"
          description="So we can verify your age"
          readOnlyValue={formatedDateOfBirthFromForm}
        >
          <div className="flex flex-col gap-2">
            <div className="flex flex-col sm:flex-row gap-4">
              <Form.Field
                control={form.control}
                name="userInfo.dateOfBirth.dayOfBirth"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Day</P>
                    </Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={async e => {
                          // Pad the day with a 0 if it's a single digit when the user leaves the input
                          if (e.target.value.length === 1) {
                            e.target.value = "0" + e.target.value
                            field.onChange(e.target.value)
                          }

                          await checkValid()
                        }}
                        type="number"
                        placeholder="e.g. 01"
                        min={1}
                        max={31}
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={form.control}
                name="userInfo.dateOfBirth.monthOfBirth"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Month</P>
                    </Form.Label>
                    <Form.Control>
                      <Select.Root
                        {...field}
                        onOpenChange={checkValid}
                        onValueChange={async v => {
                          field.onChange(v)
                          await checkValid()
                        }}
                      >
                        <Select.Trigger>
                          <Select.Value placeholder={`Select month`} />
                        </Select.Trigger>
                        <Select.Content>
                          {monthMap.map(option => (
                            <Select.Item
                              key={option.value}
                              value={option.displayValue}
                            >
                              {option.label}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={form.control}
                name="userInfo.dateOfBirth.yearOfBirth"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Year</P>
                    </Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        onBlur={async e => {
                          // Pad the day with a 0 if it's a single digit when the user leaves the input
                          if (e.target.value.length === 2) {
                            //If the 2 digit year is less than current year, it is assumed to be in the 21st century
                            if (
                              parseInt(e.target.value) <
                              new Date().getFullYear() % 100
                            ) {
                              e.target.value = "20" + e.target.value
                            } else {
                              e.target.value = "19" + e.target.value
                            }

                            field.onChange(e.target.value)
                          }

                          await checkValid()
                        }}
                        type="number"
                        placeholder="e.g. 1984"
                        min={1900}
                        max={new Date().getFullYear()}
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
            </div>
            <Form.Field
              control={form.control}
              name="userInfo.dateOfBirth"
              render={() => (
                <Form.Item className="w-full">
                  <Form.Message />
                </Form.Item>
              )}
            />
          </div>
        </TherapyBookingViewEditField>
        <Separator />
        <TherapyBookingViewEditField
          registerFields={registerFieldRef}
          validationFieldNames={["userInfo.phoneCountryCode", "userInfo.phone"]}
          validationFieldValues={[
            form.watch("userInfo.phoneCountryCode"),
            form.watch("userInfo.phone"),
          ]}
          title="Phone number"
          description="In case Spill needs to contact you"
          readOnlyValue={formattedPhoneNumber}
        >
          <div className="space-y-2">
            <Label htmlFor={"userInfo.phone"}>
              <P weight="medium" size="default">
                Phone number
              </P>
            </Label>
            <div className="flex gap-4">
              <Form.Field
                control={form.control}
                name="userInfo.phoneCountryCode"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Control>
                      <Select.Root
                        {...field}
                        onValueChange={async v => {
                          field.onChange(v)
                          await checkValid()
                        }}
                      >
                        <Select.Trigger>
                          <Select.Value placeholder={`Select Ext.`} />
                        </Select.Trigger>
                        <Select.Content>
                          {phoneCountryCodes.map(option => (
                            <Select.Item
                              key={option.countryCode}
                              value={option.countryCode}
                            >
                              +{option.phonePrefix}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    </Form.Control>
                  </Form.Item>
                )}
              />
              <Form.Field
                control={form.control}
                name="userInfo.phone"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Control>
                      <Input
                        {...field}
                        onBlur={checkValid}
                        type="tel"
                        placeholder="XXXX XXXXXXX"
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
            </div>
          </div>
        </TherapyBookingViewEditField>
        <Separator />
        <TherapyBookingViewEditField
          registerFields={registerFieldRef}
          validationFieldNames={["userInfo.email"]}
          validationFieldValues={[form.watch("userInfo.email")]}
          title="Email address"
          description="You can use this to log into your sessions. We will also send session reminders here"
          readOnlyValue={form.watch("userInfo.email") ?? ""}
        >
          <Form.Field
            control={form.control}
            name="userInfo.email"
            render={({ field }) => (
              <Form.Item className="w-full">
                <Form.Label htmlFor={field.name}>
                  <P weight="medium">Personal email</P>
                </Form.Label>
                <Form.Control>
                  <Input
                    {...field}
                    onBlur={checkValid}
                    type="email"
                    placeholder="e.g. jane@example.com"
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
        </TherapyBookingViewEditField>
      </TherapyBookingFormWrapper>

      <Button
        variant="primary"
        type="button"
        onClick={validationFormSectionAndContinue}
      >
        Save and continue
      </Button>
    </>
  )
}
