import { gql, useQuery } from "@apollo/client"
import {
  ArrowsRightLeftIcon,
  Bars3Icon,
  BookOpenIcon,
  ChartBarSquareIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  DocumentCurrencyPoundIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  GiftIcon,
  HeartIcon,
  HomeIcon,
  LifebuoyIcon,
  LightBulbIcon,
  LockClosedIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  StarIcon,
} from "@heroicons/react/24/outline"
import {
  Button,
  DropdownMenu,
  LogoMenu,
  NavigationMenu,
  Navigation as NavigationPuddles,
  P,
} from "@spillchat/puddles"
import cn from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { CUSTOM_SUPPORT_FIELDS } from "graphql/fragments/custom-support.fragment"
import { PACKAGE_FIELDS } from "graphql/fragments/therapy-packages.fragment"
import {
  ComponentPropsWithoutRef,
  FunctionComponent,
  useEffect,
  useState,
} from "react"
import {
  Link,
  NavLink,
  NavLinkProps,
  useLocation,
  useMatch,
} from "react-router-dom"

import SpillLogo from "common/assets/logo/logo.svg?react"
import { useApp } from "common/context/appContext"
import { useAuth } from "common/context/authContext"
import { getIconByDisplayName, HeroIconName } from "common/helpers/iconPalette"
import { queries } from "features/admin/components/quote-view/QuoteView.queries"
import { TrialWidget } from "features/admin/trial/TrialWidget"
import {
  NavigationQueryFieldsFragment,
  PackageState,
  PackageType,
  QuoteViewGetQuoteQuery,
  QuoteViewGetQuoteQueryVariables,
  SpillSubscriptionStatus,
  UserRole,
} from "types/graphql"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"

export const fragments = {
  queryFields: gql`
    fragment NavigationQueryFields on Query {
      user {
        id
        displayName
        primaryEmail
        role
        revokedAt
        appointments {
          id
          startsAt
        }
        company {
          id
          name
          subscriptionPlan
          subscriptionStatus
          currentSessionPackUsage
          inActiveTrialPeriod
          trialStartDate
          trialPeriodEndDate
        }
        featuresAndSettings {
          id
          sessionPack {
            value
          }
          askATherapist {
            value
          }
          adviceLibrary {
            value
          }
          pulse {
            active
          }
          plusOne {
            value
          }
        }
        visibleUnclaimedTherapyPackages {
          id
          name
        }
        ...PackageFields
        ...CustomSupportFields
      }
    }
    ${CUSTOM_SUPPORT_FIELDS}
    ${PACKAGE_FIELDS}
  `,
}

interface NavigationProps {
  data?: NavigationQueryFieldsFragment
}

/**
 * The main navigation to be used on Spill 3.0+ pages.
 */
export const Navigation: FunctionComponent<NavigationProps> = props => {
  const { isBannerVisible, hintUserSwitch } = useApp()
  const { pathname } = useLocation()
  const { signOut } = useAuth()
  const { flags } = useUser()
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false)

  const therapyPackages = props.data?.user?.therapyPackages
  const bereavementPackage = therapyPackages?.find(
    packageDetails =>
      packageDetails.identifier === PackageType.BEREAVEMENT.toString()
  )
  const parenthoodPackage = therapyPackages?.find(
    packageDetails =>
      packageDetails.identifier === PackageType.PARENTHOOD.toString()
  )

  const hasCustomSupport =
    props.data?.user?.featuresAndSettings.customSupport.value ?? false

  const hasClaimableSpecialisedSupport =
    [
      ...(props.data?.user?.visibleUnclaimedTherapyPackages ?? []),
      ...(props.data?.user?.therapyPackages ?? []),
    ].length > 0

  const lifeEvents = {
    bereavement: bereavementPackage,
    parenthood: parenthoodPackage,
    adhdSupport:
      props.data?.user?.company?.adhdCompanyPackageSetting?.state ===
      PackageState.ACTIVE,
  }

  const isAdmin = props.data?.user?.role === UserRole.ADMIN
  const isAdminRoute = !!useMatch("/admin/*")
  const isSubscribed =
    props.data?.user?.company?.subscriptionStatus ===
    SpillSubscriptionStatus.ACTIVE

  const customTherapyPackages = props.data?.user?.therapyPackages.filter(
    pkg => {
      return (
        pkg.companyPackageSetting?.id != null &&
        pkg.companyPackageSetting.packageType === PackageType.CUSTOM
      )
    }
  )

  // We need to ensure the nav closes inbetween page views.
  useEffect(() => {
    setIsMobileMenuVisible(false)
  }, [pathname])

  const theme = isAdminRoute ? "white" : "blue"
  const user = props.data?.user

  const isAATEnabled = user?.featuresAndSettings.askATherapist.value
  const isAdviceLibraryEnabled = user?.featuresAndSettings.adviceLibrary.value
  const isPulseEnabled = user?.featuresAndSettings.pulse.active

  const { data: quoteData } = useQuery<
    QuoteViewGetQuoteQuery,
    QuoteViewGetQuoteQueryVariables
  >(queries.getQuote, {
    fetchPolicy: "cache-first",
    skip: !isAdmin,
  })

  const {
    currentSessionPackUsage,
    trialPeriodEndDate,
    trialStartDate,
    inActiveTrialPeriod,
  } = user?.company ?? {}

  const sessionPackCount = user?.featuresAndSettings.sessionPack.value
  const hasTrial = inActiveTrialPeriod
  const hasInactiveTrial =
    inActiveTrialPeriod === false && trialStartDate !== null
  const currentSessionPackUsagePercentage =
    ((currentSessionPackUsage ?? 0) / (sessionPackCount ?? 0)) * 100

  const getDynamicIcon = (iconName: HeroIconName) => {
    return getIconByDisplayName(iconName)?.icon ?? StarIcon
  }

  const userHasAccessToNewEngagementPage =
    flags[FEATURE_FLAGS.NEW_ENGAGEMENT_PAGE]

  return (
    <>
      <AnimatePresence>
        {isMobileMenuVisible && (
          <motion.div
            className="fixed inset-0 z-50 bg-grey-600/25 backdrop-blur md:hidden"
            animate={{
              backdropFilter: "blur(4px)",
              background: "rgba(23, 23, 23, 0.25)",
            }}
            exit={{
              backdropFilter: "blur(0px)",
              background: "rgba(23, 23, 23, 0)",
            }}
            initial={{
              backdropFilter: "blur(0px)",
              background: "rgba(23, 23, 23, 0)",
            }}
            onClick={() => setIsMobileMenuVisible(false)}
          />
        )}
      </AnimatePresence>
      <div
        className={cn(
          "fixed top-0 z-50 h-full -translate-x-full transition-transform duration-300 md:fixed md:translate-x-0 md:transition-none",
          {
            "translate-x-0": isMobileMenuVisible,
            "-translate-x-full": !isMobileMenuVisible,
          },
          isBannerVisible === true
            ? "md:top-10 md:h-[calc(100vh-40px)]"
            : "md:top-0 md:h-screen"
        )}
      >
        <NavigationPuddles
          data-theme={theme}
          logoMenu={
            <LogoMenu companyName={props.data?.user?.company?.name ?? ""}>
              {user && (
                <Link to="/profile">
                  <DropdownMenu.Item className="p-0">
                    <DropdownMenu.LabelGroup>
                      <DropdownMenu.Label className="truncate cursor-pointer select-auto">
                        {user.displayName}
                      </DropdownMenu.Label>
                      <DropdownMenu.Label
                        className="truncate cursor-pointer select-auto"
                        muted
                      >
                        <span
                          className="block truncate max-w-44"
                          title={user.primaryEmail ?? ""}
                        >
                          {user.primaryEmail}
                        </span>
                      </DropdownMenu.Label>
                    </DropdownMenu.LabelGroup>
                  </DropdownMenu.Item>
                </Link>
              )}
              <DropdownMenu.Separator />
              <DropdownMenu.Group>
                {isAdmin && isAdminRoute ? (
                  <>
                    <Link to="/admin/access">
                      <DropdownMenu.Item
                        icon={
                          <>
                            <LockClosedIcon className="size-4" />
                          </>
                        }
                      >
                        Access
                      </DropdownMenu.Item>
                    </Link>
                    <Link to="/admin/billing">
                      <DropdownMenu.Item
                        icon={
                          <>
                            <CreditCardIcon className="size-4" />
                          </>
                        }
                      >
                        Billing
                      </DropdownMenu.Item>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/resources/books-and-videos">
                      <DropdownMenu.Item
                        icon={
                          <>
                            <BookOpenIcon className="size-4" />
                          </>
                        }
                      >
                        Library
                      </DropdownMenu.Item>
                    </Link>
                    <Link to="/resources/exercises">
                      <DropdownMenu.Item
                        icon={
                          <>
                            <DocumentTextIcon className="size-4" />
                          </>
                        }
                      >
                        Exercises
                      </DropdownMenu.Item>
                    </Link>
                    <Link to="/resources/crisis-lines">
                      <DropdownMenu.Item
                        icon={
                          <>
                            <PhoneIcon className="size-4" />
                          </>
                        }
                      >
                        Crisis lines
                      </DropdownMenu.Item>
                    </Link>
                    {user?.featuresAndSettings.plusOne.value === true && (
                      <Link to="/therapy/plus-one">
                        <DropdownMenu.Item
                          icon={
                            <>
                              <GiftIcon className="size-4" />
                            </>
                          }
                        >
                          Plus One
                        </DropdownMenu.Item>
                      </Link>
                    )}
                  </>
                )}
                <Link to={isAdmin && isAdminRoute ? "/admin/help" : "help"}>
                  <DropdownMenu.Item
                    icon={
                      <>
                        <QuestionMarkCircleIcon className="size-4" />
                      </>
                    }
                  >
                    Help
                  </DropdownMenu.Item>
                </Link>
              </DropdownMenu.Group>
              <DropdownMenu.Separator />
              <DropdownMenu.Group>
                <DropdownMenu.Item
                  onClick={async () => {
                    await signOut()
                    window.location.assign("/")
                  }}
                >
                  Log out
                </DropdownMenu.Item>
              </DropdownMenu.Group>
            </LogoMenu>
          }
          navigationMenuItems={
            isAdmin && isAdminRoute ? (
              <>
                <>
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <HomeIcon className="size-6" />
                      </>
                    }
                    to="/admin"
                  >
                    Home
                  </NavigationMenuItemNavLink>
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <ChartBarSquareIcon className="size-6" />
                      </>
                    }
                    to="/admin/dashboard/engagement"
                  >
                    Engagement
                  </NavigationMenuItemNavLink>
                  {userHasAccessToNewEngagementPage && (
                    <NavigationMenuItemNavLink
                      icon={
                        <>
                          <ClipboardDocumentCheckIcon className="size-6" />
                        </>
                      }
                      to="/admin/dashboard/engagement-v2"
                    >
                      EngagementV2
                    </NavigationMenuItemNavLink>
                  )}
                  {isPulseEnabled === true && (
                    <NavigationMenuItemNavLink
                      icon={
                        <>
                          <ClipboardDocumentCheckIcon className="size-6" />
                        </>
                      }
                      to="/admin/dashboard/checkins"
                    >
                      Check-ins
                    </NavigationMenuItemNavLink>
                  )}
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <HeartIcon className="size-6" />
                      </>
                    }
                    to="/admin/therapy"
                  >
                    Therapy
                  </NavigationMenuItemNavLink>
                </>
                {!isSubscribed &&
                  quoteData?.getCompanyTherapyQuoteRecommendedPlan !== null && (
                    <NavigationMenuItemNavLink
                      icon={
                        <>
                          <DocumentCurrencyPoundIcon className="size-6" />
                        </>
                      }
                      to="/admin/quote"
                    >
                      Quote
                    </NavigationMenuItemNavLink>
                  )}
              </>
            ) : (
              <>
                <NavigationMenuItemNavLink
                  icon={
                    <>
                      <HeartIcon className="size-6" />
                    </>
                  }
                  to={"/therapy/sessions"}
                >
                  Sessions
                </NavigationMenuItemNavLink>
                {hasCustomSupport && hasClaimableSpecialisedSupport && (
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <StarIcon className="size-6" />
                      </>
                    }
                    to="/therapy/specialised-support"
                  >
                    Specialised support
                  </NavigationMenuItemNavLink>
                )}
                {lifeEvents.adhdSupport === true && (
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <LightBulbIcon className="size-6" />
                      </>
                    }
                    to="/therapy/specialised-support/adhd"
                  >
                    ADHD support
                  </NavigationMenuItemNavLink>
                )}
                {lifeEvents.bereavement != null && (
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <LifebuoyIcon className="size-6" />
                      </>
                    }
                    to="/therapy/specialised-support/bereavement"
                  >
                    Bereavement support
                  </NavigationMenuItemNavLink>
                )}
                {lifeEvents.parenthood != null && (
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <LifebuoyIcon className="size-6" />
                      </>
                    }
                    to="/therapy/specialised-support/parenthood"
                  >
                    Parenthood support
                  </NavigationMenuItemNavLink>
                )}
                {customTherapyPackages != null && (
                  <>
                    {customTherapyPackages.map(pkg => {
                      const { companyPackageSetting } = pkg
                      const iconName =
                        companyPackageSetting?.icon as HeroIconName
                      const CustomIcon = getDynamicIcon(iconName)
                      return (
                        <NavigationMenuItemNavLink
                          icon={
                            <CustomIcon className="size-6 min-w-6 min-h-6" />
                          }
                          key={pkg.companyPackageSetting?.id}
                          to={`/therapy/specialised-support/${pkg.companyPackageSetting?.id}`}
                        >
                          <span className="truncate">
                            {pkg.companyPackageSetting?.name}
                          </span>
                        </NavigationMenuItemNavLink>
                      )
                    })}
                  </>
                )}
                {isAATEnabled === true &&
                  props.data?.user?.revokedAt == null && (
                    <NavigationMenuItemNavLink
                      icon={
                        <>
                          <EnvelopeIcon className="size-6" />
                        </>
                      }
                      to="/therapy/ask-a-therapist"
                    >
                      Ask a Therapist
                    </NavigationMenuItemNavLink>
                  )}
                {isAdviceLibraryEnabled === true && (
                  <NavigationMenuItemNavLink
                    icon={
                      <>
                        <BookOpenIcon className="size-6" />
                      </>
                    }
                    to="/advice-library"
                  >
                    Advice Library
                  </NavigationMenuItemNavLink>
                )}
              </>
            )
          }
          footerNavigationMenuItems={
            isAdmin ? (
              <>
                {hasTrial == true && isAdminRoute && (
                  <TrialWidget
                    {...{
                      currentSessionPackUsage,
                      inActiveTrialPeriod,
                      hasInactiveTrial,
                      trialPeriodEndDate,
                      sessionPackCount,
                      currentSessionPackUsagePercentage,
                    }}
                  />
                )}
                <AnimatePresence>
                  {hintUserSwitch === true && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        y: 10,
                        scale: 0.75,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        scale: 1,
                        transition: {
                          delay: 2,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        y: 10,
                        scale: 0.75,
                      }}
                    >
                      <div className="rounded-lg bg-mono-white p-4 shadow-md">
                        <P size="xs">
                          This is what your team will see
                          {hasTrial == false &&
                            `, but with session
                          credits added`}
                          . Switch back to admin view by clicking below.
                        </P>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <NavigationMenuItemNavLink
                  icon={
                    <>
                      <ArrowsRightLeftIcon className="size-6" />
                    </>
                  }
                  to={isAdminRoute ? "/therapy/sessions" : "/admin"}
                >
                  Switch to {isAdminRoute ? "employee" : "admin"} view
                </NavigationMenuItemNavLink>
              </>
            ) : (
              <></>
            )
          }
        />
      </div>
      {/* Mobile top bar */}
      <div
        className={cn(
          "sticky z-40 flex h-16 w-full items-center justify-between border-b-2 border-blue-200 bg-blue-100 px-2 md:hidden",
          isBannerVisible ? "top-10" : "top-0"
        )}
      >
        {/* Button to open nav on mobile */}
        <Button
          variant="tertiary"
          onClick={() => setIsMobileMenuVisible(curr => !curr)}
        >
          <Bars3Icon className="size-8" />
        </Button>
        <SpillLogo className="h-6 fill-teal-400" />
        <div className="w-12" />
      </div>
    </>
  )
}

const NavigationMenuItemNavLink: FunctionComponent<
  NavLinkProps & ComponentPropsWithoutRef<typeof NavigationMenu.Link>
> = props => (
  <NavigationMenu.Item>
    <NavLink end to={props.to}>
      {({ isActive }) => (
        <NavigationMenu.Link
          active={isActive}
          color={props.color}
          icon={props.icon}
        >
          {props.children}
        </NavigationMenu.Link>
      )}
    </NavLink>
  </NavigationMenu.Item>
)
