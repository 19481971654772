import { gql } from "@apollo/client"

export const mutations = {
  createPackage: gql(`
    mutation InviteCustomCreatePackage(
      $numberSessions: Float!
      $forAllEmployees: Boolean!
      $numberMonthsToUse: Float
      $adminReason: String
      $iconUrl: String
      $packageType: PackageType
      $lifeEventId: ID
      $userFacingDescription: String
      $visibleToAllEmployees: Boolean
      $icon: String
      $name: String
      $state: PackageState!
    ) {
      createCustomCompanyPackageSetting(
        numberSessions: $numberSessions
        forAllEmployees: $forAllEmployees
        numberMonthsToUse: $numberMonthsToUse
        adminReason: $adminReason
        iconUrl: $iconUrl
        packageType: $packageType
        lifeEventId: $lifeEventId
        userFacingDescription: $userFacingDescription
        visibleToAllEmployees: $visibleToAllEmployees
        icon: $icon
        name: $name
        state: $state
      ) {
        id
        companyId
      }
    }
  `),
  updateAdhdCoPayAddon: gql(`
    mutation InviteCustomUpdateCoPay($enabled: Boolean!, $companyContribution: Float!) {
      createOrUpdateCoPayAddOn(enabled: $enabled, companyContribution: $companyContribution) {
        id
        properties {
          enabled
          companyContribution
        }
      }
    }
  `),
}
