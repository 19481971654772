import { Button, H3, P } from "@spillchat/puddles"
import {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react"
import { Form } from "@spillchat/puddles"
import { FieldValues, Path } from "react-hook-form"

import { iconList } from "common/helpers/iconPalette"

type FormFieldWrapperProps<T> = {
  children: ReactNode
  displayValue: string | number | null
  displayIcon?: string | null
  description?: string
  disabled?: boolean
  label: string
  values?: Path<T>[]
  showDivider?: boolean
  headingComponent?: FunctionComponent<PropsWithChildren>
  keepHeadingSize?: boolean
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>
}

enum State {
  OPEN = "open",
  CLOSED = "closed",
}

export function FormFieldWrapper<T extends FieldValues>({
  children,
  displayValue,
  displayIcon,
  description,
  disabled,
  label,
  values,
  onSubmit,
  showDivider = true,
  headingComponent,
  keepHeadingSize = false,
}: FormFieldWrapperProps<T>): ReactNode {
  const context = Form.useFormContext<T>()

  const watchSubmit = context?.formState.isSubmitSuccessful

  useEffect(() => {
    if (watchSubmit) {
      toggleState()
    }
  }, [watchSubmit])

  const isStatic = values == undefined

  const isEmpty = !isStatic
    ? context.getValues(values).every(value => Boolean(value) === false)
    : true

  const [state, setState] = useState<State>(State.CLOSED)

  const Heading =
    headingComponent != null && (keepHeadingSize || state !== State.OPEN)
      ? headingComponent
      : H3

  const toggleState = () => {
    if (state === State.OPEN) {
      context.reset()
      setState(State.CLOSED)
    } else {
      setState(State.OPEN)
    }
  }

  const getAction = () => {
    if (state === State.OPEN) {
      return {
        label: "Cancel",
      }
    }

    if (isEmpty == true) {
      return { label: "Add" }
    }

    return { label: "Edit" }
  }

  const showDescription =
    state === State.OPEN &&
    description != undefined &&
    Boolean(displayValue) === false &&
    displayValue !== null

  const icon = iconList.find(icon => {
    return icon.displayName === displayIcon
  })

  return (
    <form
      onSubmit={onSubmit}
      className={`flex flex-col gap-3 grow ${showDivider ? "border-b border-grey-100 last:border-none pb-8 last:pb-0" : ""}`}
    >
      <div
        className={`flex justify-between gap-4 ${state === State.OPEN ? "items-center" : "items-center"}`}
      >
        <div className={`flex flex-col ${showDescription ? "gap-2" : ""}`}>
          <Heading>{label}</Heading>
          {showDescription && <P muted>{description}</P>}
        </div>
        <div>
          {!isStatic && (
            <Button
              variant="tertiary"
              disabled={disabled}
              onClick={() => toggleState()}
            >
              {getAction().label}
            </Button>
          )}
        </div>
      </div>
      {state === State.OPEN ? (
        <div className="flex flex-col gap-4">
          <div>{children}</div>
          <Button
            type="submit"
            disabled={!context.formState.isDirty}
            loading={context.formState.isSubmitting}
          >
            Save
          </Button>
        </div>
      ) : (
        <>
          {(displayValue == "" || displayValue == null) &&
          isStatic === false ? (
            <></>
          ) : (
            <div className="overflow-x-scroll flex flex-row items-center">
              {icon !== null && icon !== undefined && (
                <icon.icon className="size-4 mx-1" />
              )}
              <P muted>
                {isStatic === false
                  ? displayValue
                  : displayValue != ""
                    ? displayValue
                    : "N/A"}
              </P>
            </div>
          )}
        </>
      )}
    </form>
  )
}
