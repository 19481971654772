import { useQuery } from "@apollo/client"
import { FunctionComponent } from "react"
import {
  CalendarDateRangeIcon,
  CheckIcon,
  HeartIcon,
  LightBulbIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline"
import { Button, H2, P } from "@spillchat/puddles"
import { Link } from "react-router-dom"
import { groupBy } from "lodash"

import { SpecialisedSupportItem } from "features/specialised-support/components/SpecialisedSupportItem"
import imageParenthood from "common/assets/images/product/specialised-support/therapy-parenthood.png"
import imageBereavement from "common/assets/images/product/specialised-support/therapy-bereavement.png"
import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import customPlaceholder from "common/assets/images/product/specialised-support/therapy-custom-grey.png"
import {
  AdminTherapySpecialisedSupportQuery,
  AdminTherapySpecialisedSupportQueryVariables,
  PackageState,
  PackageType,
} from "types/graphql"
import { SpecialisedSupportItemCreate } from "features/specialised-support/components/SpecialisedSupportItemCreate"

import { queries } from "./admin-therapy-specialised-support.queries"

export const AdminTherapySpecialisedSupport: FunctionComponent = () => {
  const { data } = useQuery<
    AdminTherapySpecialisedSupportQuery,
    AdminTherapySpecialisedSupportQueryVariables
  >(queries.getSupportData, {
    nextFetchPolicy: "network-only",
  })

  const { allCompanyPackageSettings } = data?.user?.company ?? {}

  const groupedPackages = groupBy(allCompanyPackageSettings, "packageType")

  const hasAdhdSupport =
    data?.user?.company?.featuresAndSettings.adhdSupport.active ?? false
  const hasCustomSupport =
    data?.user?.company?.featuresAndSettings.customSupport.active ?? false
  const hasArchived =
    allCompanyPackageSettings?.some(
      pkg => pkg.state === PackageState.DISABLED
    ) ?? false

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <H2>Preset support packages</H2>
          <P muted>Suggested packages tailored to different life events</P>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5">
          <SpecialisedSupportItem
            title="Bereavement support"
            subtitle="For grieving employees who might be on leave, or struggling on the anniversary of losing a loved one."
            list={[
              {
                Icon: UserPlusIcon,
                text: "Sessions with a bereavement specialist",
              },
              {
                Icon: HeartIcon,
                text: "A default of 6 sessions per package",
              },
              {
                Icon: CalendarDateRangeIcon,
                text: "Sessions can be used over a period of two years",
              },
            ]}
            image={imageBereavement}
          >
            <div className="flex gap-2 items-center">
              <Button variant="primary" size="sm" asChild>
                <Link to="/admin/access/invite/bereavement">Get started</Link>
              </Button>
              <Button variant="secondary" size="sm" asChild>
                <Link to="https://spill.notion.site/9ba168e8b01a4f578765d1cbd4d97630?pvs=4">
                  Read manager guide
                </Link>
              </Button>
            </div>
          </SpecialisedSupportItem>
          <SpecialisedSupportItem
            title="Parenthood support"
            subtitle="For new parents going through the joys of welcoming a child and the challenges around returning to work."
            list={[
              {
                Icon: UserPlusIcon,
                text: "Sessions with a parenthood specialist",
              },
              {
                Icon: HeartIcon,
                text: "A recommended 8 sessions per package",
              },
              {
                Icon: CalendarDateRangeIcon,
                text: "Sessions can be used over a period of two years",
              },
            ]}
            image={imageParenthood}
          >
            <Button variant="primary" size="sm" asChild>
              <Link to="/admin/access/invite/parenthood">Get started</Link>
            </Button>
          </SpecialisedSupportItem>
          {hasAdhdSupport && (
            <SpecialisedSupportItem
              title="ADHD support"
              subtitle="For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not."
              list={[
                {
                  Icon: LightBulbIcon,
                  text: "Sessions with an ADHD specialist",
                },
                {
                  Icon: HeartIcon,
                  text: "A recommended 2 sessions per employee",
                },
                {
                  Icon: CalendarDateRangeIcon,
                  text: "Sessions come out of employee's existing credits",
                },
              ]}
              image={imageAdhd}
            >
              {groupedPackages[PackageType.ADHD] ? (
                <Button variant="primary" size="sm" asChild>
                  <Link to="/admin/access/invite/adhd">Edit support</Link>
                </Button>
              ) : (
                <Button variant="primary" size="sm" asChild>
                  <Link to="/admin/therapy/specialised-support/adhd-support">
                    Get started
                  </Link>
                </Button>
              )}
            </SpecialisedSupportItem>
          )}
        </div>
      </div>
      {hasCustomSupport && (
        <>
          <hr />
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <H2>Custom support</H2>
              <P muted>Create your own custom support package</P>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5">
              <div className="lg:col-span-2">
                <SpecialisedSupportItemCreate />
              </div>
              <>
                {allCompanyPackageSettings
                  ?.filter(pkg => pkg.state !== PackageState.DISABLED)
                  .filter(pkg => pkg.packageType === PackageType.CUSTOM)
                  .map((pkg, index) => {
                    return (
                      <SpecialisedSupportItem
                        key={index}
                        title={pkg.name ?? "Custom package"}
                        subtitle={`Custom package for ${data?.user?.company?.name ?? "your company"}`}
                        image={pkg.iconUrl ?? customPlaceholder}
                        list={[
                          {
                            Icon: HeartIcon,
                            text: `Up to ${pkg.numberSessions} sessions per person`,
                          },
                          {
                            Icon: CalendarDateRangeIcon,
                            text:
                              pkg.numberMonthsToUse == null
                                ? "No time limit to access support"
                                : `Access support for up to ${pkg.numberMonthsToUse} months`,
                          },
                        ]}
                      >
                        <div className="flex gap-2 items-center">
                          {pkg?.state === PackageState.AWAITING_COUNSELLORS ? (
                            <>
                              <Button variant="primary" size="sm" asChild>
                                <Link
                                  to={
                                    "/admin/therapy/specialised-support/" +
                                    pkg.id
                                  }
                                >
                                  Edit support
                                </Link>
                              </Button>
                              <Button size="sm" variant="readonly" asChild>
                                <div className="flex gap-1 items-center">
                                  <CheckIcon width={16} />
                                  <span>Package requested</span>
                                </div>
                              </Button>
                            </>
                          ) : (
                            <Button size="sm" variant="primary" asChild>
                              <Link
                                to={
                                  "/admin/therapy/specialised-support/" + pkg.id
                                }
                              >
                                Edit support
                              </Link>
                            </Button>
                          )}
                        </div>
                      </SpecialisedSupportItem>
                    )
                  })}
              </>
            </div>
          </div>
          {hasArchived && (
            <>
              <hr />
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-3">
                  <H2>Archived support packages</H2>
                  <P muted>
                    Packages you set up in the past that you've archived
                  </P>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5">
                  {hasCustomSupport && (
                    <>
                      {allCompanyPackageSettings
                        ?.filter(pkg => pkg.state === PackageState.DISABLED)
                        .filter(pkg => pkg.packageType === PackageType.CUSTOM)
                        .map((pkg, index) => {
                          return (
                            <SpecialisedSupportItem
                              key={index}
                              title={pkg.name ?? "Custom package"}
                              subtitle={`Custom package for ${data?.user?.company?.name ?? "your company"}`}
                              image={pkg.iconUrl ?? customPlaceholder}
                              list={[
                                {
                                  Icon: HeartIcon,
                                  text: `Up to ${pkg.numberSessions} sessions per person`,
                                },
                                {
                                  Icon: CalendarDateRangeIcon,
                                  text:
                                    pkg.numberMonthsToUse == null
                                      ? "No time limit to access support"
                                      : `Access support for up to ${pkg.numberMonthsToUse} months`,
                                },
                              ]}
                            >
                              <Button variant="secondary" size="sm" asChild>
                                <Link
                                  to={
                                    "/admin/therapy/specialised-support/" +
                                    pkg.id
                                  }
                                >
                                  See package
                                </Link>
                              </Button>
                            </SpecialisedSupportItem>
                          )
                        })}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
