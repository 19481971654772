import { FunctionComponent, useEffect } from "react"

import {
  TherapyBookingSummaryCard,
  TherapyBookingSummaryCardProps,
} from "./therapy-booking-summary-card"

interface TherapyBookingFormWrapperProps {
  onEditBooking: () => void
  header?: React.ReactNode
  children?: React.ReactNode
  filterTags?: string[]
  summaryCardProps: TherapyBookingSummaryCardProps
}

export const TherapyBookingFormWrapper: FunctionComponent<
  TherapyBookingFormWrapperProps
> = (props: TherapyBookingFormWrapperProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="max-w-screen-lg">
        <div className="grid grid-cols-12 justify-between gap-8">
          <div className="col-span-12 md:col-span-7 lg:col-span-8 flex flex-col gap-12">
            <div className="flex flex-col gap-2">{props.header}</div>
            <div className="flex flex-col gap-8">{props.children}</div>
          </div>
          <div className="col-span-12 md:col-span-5 lg:col-span-4 md:pl-12 pl-0 flex justify-end">
            <div className="w-full md:w-72 ">
              <TherapyBookingSummaryCard {...props.summaryCardProps} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
