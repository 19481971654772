import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import {
  P,
  H2,
  Alert,
  H1,
  H3,
  Separator,
  Tooltip,
  Button,
} from "@spillchat/puddles"
import { useQuery } from "@apollo/client"

import {
  EngagementGetCompanyFeedbackQuery as QueryData,
  EngagementGetCompanyFeedbackQueryVariables as QueryVars,
  CompanyFeedbackAggregates,
} from "types/graphql"
import { queries } from "features/admin-engagement/admin-engagement.queries"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import companyPublicReviewWidget from "common/assets/images/company-public-review-widget.svg"

import { NewFeedbackTable } from "./components/FeedbackTable"

export function FeedbackPage(): ReactElement {
  const { data, loading: companyDataLoading } = useQuery<QueryData, QueryVars>(
    queries.getCompanyFeedback,
    { fetchPolicy: "cache-first" }
  )

  if (companyDataLoading) {
    return (
      <>
        <LoadingSpinner sizeInPixels={50} />
      </>
    )
  }

  const feedback = data?.user?.company?.feedback
  const aggregates = feedback?.aggregates
  const hasTrial = data?.user?.company?.trialStartDate !== null
  const insufficientFeedback =
    feedback?.numberOfResponses !== undefined
      ? feedback?.numberOfResponses < 3
      : true

  return (
    <div className="flex max-w-screen-lg flex-col items-start gap-8">
      <div className="flex flex-col items-start gap-8 w-full">
        <div className="flex justify-between items-center self-stretch">
          <H2>Overview</H2>
          <P size="xs" muted>
            Total responses: {feedback?.numberOfResponses}
          </P>
        </div>

        <div className="grid gap-4 w-full lg:grid-cols-3 ">
          <div className="flex flex-col items-start gap-2">
            <div className="flex gap-2 items-baseline">
              <H1>{aggregates?.wouldRecommend?.toFixed(1) ?? "-"}</H1>
              {isDefined(aggregates?.wouldRecommend) && (
                <P size="xs" muted>
                  / 10.0
                </P>
              )}
            </div>
            <P muted>Would recommend Spill</P>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-baseline">
              <H1>{aggregates?.metTheirGoals?.toFixed(1) ?? "-"}</H1>
              {isDefined(aggregates?.metTheirGoals) && (
                <P size="xs" muted>
                  / 10.0
                </P>
              )}
            </div>
            <P muted>Felt the session met their goals</P>
          </div>
          <div className="flex flex-col gap-2">
            <H1>
              {isDefined(aggregates?.wouldSeeAgain)
                ? `${Number((aggregates?.wouldSeeAgain ?? 0) * 100).toFixed(1)}%`
                : "-"}
            </H1>
            <P muted>Would see their counsellor again</P>
          </div>
        </div>
        {insufficientFeedback ? (
          <Alert title="You don’t have enough responses" variant="info">
            <P size="xs">
              We need at least 3 responses before we can show you an accurate
              summary.
            </P>
          </Alert>
        ) : (
          <Alert
            title="What happens if a session doesn't meet somebody's&nbsp;goals?"
            variant="success"
          >
            <P size="xs">
              Spill's clinical team reaches out to any employee who has a
              session on Spill and rates it less than 8/10 to support them in
              finding a counsellor who might better suit their needs.
            </P>
          </Alert>
        )}
      </div>
      <Separator className="bg-spill-grey-200" />

      <div className="flex flex-col items-start gap-8 self-stretch">
        <div className="flex justify-between items-end self-stretch">
          <div className="flex flex-col items-start gap-3">
            <H2>Comments</H2>
            <P muted>Feedback shared with you</P>
          </div>
          <div className="flex gap-1 items-center sm:gap-0.5">
            <P muted size="xs">
              All feedback shared is opt-in{" "}
            </P>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <InformationCircleIcon className="size-4 text-grey-600" />
                </Tooltip.Trigger>
                <Tooltip.Content title="Explicit permission">
                  Spill gets explicit permission from employees to share
                  feedback with you.
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        </div>
        <div className="flex flex-col gap-4 self-stretch ">
          {feedback?.sharedFeedback == null ||
            (feedback?.sharedFeedback.length === 0 ? (
              <Alert title="You don't have any feedback" variant="info">
                <P size="xs">
                  Nobody has shared any feedback comments with you yet.
                </P>
              </Alert>
            ) : (
              <NewFeedbackTable data={feedback?.sharedFeedback ?? []} />
            ))}
        </div>
      </div>
      <Separator className="bg-spill-grey-200" />
      {!hasTrial && (
        <div className="flex flex-col items-start gap-8 self-stretch">
          <div className="flex flex-col items-start gap-3">
            <H2>Mental Health Support Rating</H2>
            <P muted> A widget for your careers page</P>
          </div>
          <div className="flex justify-between items-center self-stretch">
            <div className="flex  flex-col items-start gap-5">
              <div className="flex flex-col items-start gap-3 self-stretch">
                <H3>How it works</H3>
                <P muted>
                  Get early access to our live embeddable widget for your
                  company careers page with thanks left by employees for having
                  access to Spill.
                </P>
              </div>
              <Link
                to="https://spillchat.typeform.com/to/h8tXTqev"
                target="_blank"
              >
                <Button>Register my interest</Button>
              </Link>
            </div>
            <div className="flex justify-center align-center">
              <img
                src={companyPublicReviewWidget}
                alt="Spill's company public review widget"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const isDefined = (value: CompanyFeedbackAggregates["metTheirGoals"]) => {
  if (value === null || value === undefined) {
    return false
  }

  return true
}
