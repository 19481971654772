/**
 * Allows us to easilly access onboarding related functions and features from any part of the app.
 * Will only be made available to users to have the admin
 */

/**
 * A context that relates to the current user.
 * Has information that relate to things like name, id, flags etc.
 */

import { gql, useMutation, useQuery } from "@apollo/client"
import { captureException } from "@sentry/react"
import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from "react"
import { useLocation } from "react-router-dom"
import { toast } from "sonner"

import {
  CompanyOnboardingStep,
  OnboardingContextCompleteStepMutation,
  OnboardingContextCompleteStepMutationVariables,
  UserRole,
} from "types/graphql"
import {
  CompanyOboardingSteps,
  OnboardingContextUserQueryQuery,
} from "types/graphql"

import { useApp } from "./appContext"

interface OnboardingContextData {
  stepsProgress: Record<
    keyof Omit<CompanyOboardingSteps, "__typename">,
    boolean
  >
  markAsComplete?: (step: CompanyOnboardingStep) => Promise<void>
}

const defaultUserContextData: OnboardingContextData = {
  stepsProgress: {
    hasAddedTeam: false,
    hasApprovedSettings: false,
    hasSeenUserView: false,
  },
}

const OnboardingContext = createContext<OnboardingContextData>(
  defaultUserContextData
)

const fragments = {
  queryFields: gql`
    fragment OnboardingContextQueryFields on Query {
      user {
        id
        role
        company {
          id
          subscriptionPlan
          onboardingSteps {
            hasAddedTeam
            hasApprovedSettings
            hasSeenUserView
          }
        }
      }
    }
  `,
}

const queries = {
  OnboardingContextGetSteps: gql`
    query OnboardingContextUserQuery {
      ...OnboardingContextQueryFields
    }
    ${fragments.queryFields}
  `,
}

const mutations = {
  OnboardingContextCompleteStep: gql`
    mutation OnboardingContextCompleteStep($feature: CompanyOnboardingStep!) {
      completeCompanyOnboardingStep(onboardingStep: $feature)
    }
  `,
}

export const OnboardingProvider: FunctionComponent<
  PropsWithChildren
> = props => {
  const { data, loading } = useQuery<OnboardingContextUserQueryQuery>(
    queries.OnboardingContextGetSteps
  )
  const location = useLocation()
  const [completeOnboardingStep] = useMutation<
    OnboardingContextCompleteStepMutation,
    OnboardingContextCompleteStepMutationVariables
  >(mutations.OnboardingContextCompleteStep, {
    refetchQueries: [queries.OnboardingContextGetSteps],
  })
  const { setHintUserSwitch } = useApp()
  // A list of the completed steps in one client side session (as in, refresh breaks this)
  const completedSteps = []

  const onboardingValues: Omit<CompanyOboardingSteps, "__typename"> = {
    hasAddedTeam: data?.user?.company?.onboardingSteps.hasAddedTeam ?? false,
    hasApprovedSettings:
      data?.user?.company?.onboardingSteps.hasApprovedSettings ?? false,
    hasSeenUserView:
      data?.user?.company?.onboardingSteps.hasSeenUserView ?? false,
  }

  // We listen to where to user is going in the app. When they do certain actions, we can mark things off the onboarding list
  useEffect(() => {
    if (loading === true) return
    if (location.pathname) {
      if (location.pathname === "/admin/therapy/settings") {
        if (!onboardingValues.hasApprovedSettings) {
          // Delay this a bit so the user can have a chance to look at the page
          setTimeout(async () => {
            await completeStep(CompanyOnboardingStep.APPROVED_SETTINGS)
          }, 2000)
        }
      }

      if (location.pathname === "/therapy/sessions") {
        if (!onboardingValues.hasSeenUserView) {
          // Delay this a bit so the user can have a chance to look at the page
          setTimeout(async () => {
            await completeStep(CompanyOnboardingStep.SEEN_USER_VIEW)
          }, 2000)
          setHintUserSwitch(true)
        }
      }

      if (location.pathname.startsWith("/admin")) {
        setHintUserSwitch(false)
      }
    }
  }, [location, onboardingValues, data, loading])

  const completeStep = async (step: CompanyOnboardingStep) => {
    try {
      if (data?.user?.role == UserRole.ADMIN) {
        const response = await completeOnboardingStep({
          variables: { feature: step },
          refetchQueries: [queries.OnboardingContextGetSteps],
        })
        if (response.data) {
          completedSteps.push(step)
        }
      }
    } catch {
      toast("We were unable to mark this step as completed, please try again.")
      captureException("Unable to complete onboarding step")
    }
  }

  if (Object.values(onboardingValues).some(field => field === false)) {
    return (
      <OnboardingContext.Provider
        value={{
          stepsProgress: onboardingValues,
          markAsComplete: completeStep,
        }}
      >
        {props.children}
      </OnboardingContext.Provider>
    )
  }
  return <>{props.children}</>
}

export function useOnboarding(): OnboardingContextData {
  return useContext(OnboardingContext)
}
