import { fromZonedTime } from "date-fns-tz"
import { z } from "zod"

export const therapyRescheduleFormSchema = z
  .object({
    appointmentId: z.string(),
    counsellorId: z.string(),
    startTime: z.string(),
    timeZone: z.string(),
  })
  .transform(data => {
    const startTimeUtc = fromZonedTime(
      data.startTime,
      data.timeZone
    ).toISOString()

    return {
      id: data.appointmentId,
      counsellorId: data.counsellorId,
      startsAt: startTimeUtc,
      timeZone: data.timeZone,
    }
  })
